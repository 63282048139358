import { FC } from "react";
import { Form, Space, Switch } from "antd";

import { useHydrateForm } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, TooltipInfo } from "@ni/common/ui";

import { useInsuranceWizardApi } from "../../hooks/useInsuranceWizardApi";

import { coverageOption, transactorOption } from "./constants";

export const PremiumWaivingPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const { wizardResponse, processWizardRequest } = useInsuranceWizardApi();

  useHydrateForm({
    form,
    entityFields: (wizardResponse.insuranceTemplate?.values as { code: string; value: string }[]) ?? [],
    keys: {
      strings: [coverageOption.code, transactorOption.code],
    },
    allowParse: true,
  });

  const onFinish = (values: FormValues) => {
    void processWizardRequest({
      pageId: wizardResponse?.pageId as number,
      tenantId: wizardResponse.tenant?.id,
      insuranceTemplateId: wizardResponse.insuranceTemplate?.id,
      collectedValues: values as {
        [key: string]: string;
      },
    });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Premium Waiving"
      pageSubtitle="Insurance premium can be waived in case customer has paid his statement cycle in full and computation base is zero.  You can also specify to waive insurance premium for transactor customers who paid in full, the previous cycle's statement balance"
      level="tenant"
      additionalRoute="insurance-program-templates"
      size="md"
      formSize="md"
      gap={16}
      submitHandler={onFinish}
    >
      <Space direction="horizontal">
        <Form.Item name={coverageOption.code} valuePropName="checked" initialValue={coverageOption.default}>
          <Switch />
        </Form.Item>
        <TooltipInfo
          label={coverageOption.name}
          tooltipProps={{
            title: coverageOption.tooltip,
          }}
        />
      </Space>

      <Space direction="horizontal">
        <Form.Item name={transactorOption.code} valuePropName="checked" initialValue={transactorOption.default}>
          <Switch />
        </Form.Item>
        <TooltipInfo
          label={transactorOption.name}
          tooltipProps={{
            title: transactorOption.tooltip,
          }}
        />
      </Space>
    </CustomFormWrapper>
  );
};
