import { MouseEventHandler } from "react";
import { Button, Form, Input, Modal } from "antd";

import styles from "./styles.module.scss";

interface ConfirmModalProps {
  isOpened: boolean;
  isLoading: boolean;
  onCancel: () => void;
  onConfirm: MouseEventHandler<HTMLElement>;
  title: string;
  type: string;
  propertyName?: string;
  description?: string;
}

export const ConfirmModal = ({
  isOpened,
  isLoading,
  onCancel,
  onConfirm,
  title,
  type,
  description,
  propertyName = "Name",
}: ConfirmModalProps) => {
  const [form] = Form.useForm();

  return (
    <Modal
      className={styles["modal-confirm"]}
      title={title}
      open={isOpened}
      width="800px"
      onCancel={onCancel}
      footer={[
        <div className={styles["modal-confirm-footer"]}>
          <Button className={styles["modal-confirm-button"]} key={`back-${Math.random()}`} onClick={onCancel}>
            Cancel
          </Button>
          {(type === "duplicate" ||
            type === "duplicate-page" ||
            type === "duplicate-workflow" ||
            type === "duplicate-element") && (
            <Button
              className={styles["modal-confirm-button"]}
              disabled={false}
              key={`submit-${Math.random()}`}
              type="primary"
              loading={isLoading}
              onClick={form.submit}
            >
              Confirm
            </Button>
          )}
          {type === "delete" && (
            <Button
              className={styles["modal-confirm-button"]}
              disabled={false}
              key={`submit-${Math.random()}`}
              type="primary"
              loading={isLoading}
              onClick={onConfirm}
            >
              Ok
            </Button>
          )}
          {type === "delete-variable" && (
            <Button
              className={styles["modal-confirm-button"]}
              disabled={false}
              key={`submit-${Math.random()}`}
              type="primary"
              loading={isLoading}
              onClick={onConfirm}
            >
              Confirm
            </Button>
          )}
        </div>,
      ]}
    >
      {type === "duplicate" && (
        <Form form={form} layout="vertical" onFinish={onConfirm} key={Math.random()}>
          <Form.Item name="description">{description}</Form.Item>
          <Form.Item required={true} name="newName" label={`New ${propertyName}`}>
            <Input />
          </Form.Item>
        </Form>
      )}
      {type === "duplicate-element" && (
        <Form form={form} layout="vertical" onFinish={onConfirm} key={Math.random()}>
          <Form.Item name="description">{description}</Form.Item>
          <Form.Item required={true} name="newCode" label="New code">
            <Input />
          </Form.Item>
        </Form>
      )}
      {type === "duplicate-page" && (
        <Form form={form} layout="vertical" onFinish={onConfirm} key={Math.random()}>
          <Form.Item name="description">{description}</Form.Item>
          <Form.Item required={true} name="newCode" label="New Code">
            <Input />
          </Form.Item>
          <Form.Item required={true} name="newHeader" label="NewHeader">
            <Input />
          </Form.Item>
        </Form>
      )}
      {type === "duplicate-workflow" && (
        <Form form={form} layout="vertical" onFinish={onConfirm} key={Math.random()}>
          <Form.Item name="description">{description}</Form.Item>
          <Form.Item required={true} name="businessDescription" label="Business description">
            <Input />
          </Form.Item>
          <Form.Item required={true} name="code" label="Code">
            <Input />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

ConfirmModal.defaultProps = {
  description: "",
  propertyName: "Name",
};
