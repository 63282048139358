import { ReactElement } from "react";
import { Checkbox, Form, InputNumber, Radio, Select, Space } from "antd";
import cn from "classnames";
import { StoreValue } from "rc-field-form/lib/interface";

import { QuestionCircleFilled } from "@ant-design/icons";
import {
  amfAccMoment,
  amfAccMomentEvent,
  amfCardMoment,
  nicAmfCardWaiveUnexpOptions,
  nicLockedCardRcOptions,
  nicStmtFeeFlagOptions,
} from "@ni/common/mocks";
import { TooltipInfo } from "@ni/common/ui";

import styles from "./styles.module.scss";

const { Option } = Select;

export type TabKey = "annual" | "monthly" | "statement" | "inactivity" | "overlimit";

const getAmfAccMomentOptions = (): ReactElement[] =>
  amfAccMoment.map(val => (
    <Radio key={val.key} value={val.key}>
      <TooltipInfo label={val.value} tooltipProps={val?.tooltip ? { title: val.tooltip } : {}} />
    </Radio>
  ));
const getAmfAccStartOptions = (hasTooltip?: boolean): ReactElement[] =>
  amfAccMomentEvent.map(val => (
    <Radio key={val.key} value={val.key}>
      <TooltipInfo
        label={val.value}
        tooltipProps={val?.tooltip && hasTooltip ? { title: val.tooltip as string } : {}}
      />
    </Radio>
  ));
const getAmfCardMomentOptions = (): ReactElement[] =>
  amfCardMoment.map(val => (
    <Radio key={val.key} value={val.key}>
      {val.value}
    </Radio>
  ));
const getNicAmfCardWaiveUnexpOptions = (): ReactElement[] =>
  nicAmfCardWaiveUnexpOptions.map(val => (
    <Radio key={val.key} value={val.key}>
      {val.value}
    </Radio>
  ));
const getNicMmfCardWaiveUnexpOptions = (): ReactElement[] =>
  nicAmfCardWaiveUnexpOptions.map(val => (
    <Radio key={val.key} value={val.key}>
      {val.value}
    </Radio>
  ));
const getNicStmtFeeFlagOptions = (): ReactElement[] =>
  nicStmtFeeFlagOptions.map(val => (
    <Checkbox key={val.key} value={val.key}>
      {val.value}
    </Checkbox>
  ));
const getNicInactPeriodUnitOptions = (): ReactElement[] =>
  nicLockedCardRcOptions.map(val => (
    <Option key={val.value} value={val.value}>
      {val.label}
    </Option>
  ));
const getNicOvlFeeRepeatModeOptions = (): ReactElement[] =>
  [
    {
      value: "ONCE",
      label: "Once account is overlimit",
      tooltip: "The fee will be charged on the evening of the day account goes over limit, if over limit still exists",
    },
    {
      value: "END_OF_BILLING",
      label: "At end of each billing cycle",
      tooltip: "The fee will be charged at the last evening of each billing cycle, if over limit still exists",
    },
    {
      value: "ONCE_AND_END_OF_BILLING",
      label: "Once account is overlimit and at the last evening of each billing cycle",
      tooltip:
        "The fee will be charged on the evening of the day account goes over limit, if over limit still exists, and at the last evening of each billing cycle, if over limit still exists",
    },
    {
      value: "ONCE_AND_MONTHLY",
      label: "Once account is overlimit and at the first evening of each billing cycle",
      tooltip:
        "The fee will be charged on the evening of the day account goes over limit, if over limit still exists, and at the first evening of each billing cycle, if over limit still exists",
    },
  ].map(val => (
    <Radio key={val.value} value={val.value}>
      <TooltipInfo
        label={val.label}
        tooltipProps={{
          title: val.tooltip,
        }}
      />
    </Radio>
  ));
const validateNumberInput = (value: StoreValue): Promise<string | void> => {
  if (!value) {
    return Promise.reject(new Error("Inactivity period number should not be null"));
  }
  if (Number(value).toFixed() !== String(value)) {
    return Promise.reject(new Error("Inactivity period number should be an integer number"));
  }
  if (Number(value) < 0) {
    return Promise.reject(new Error("Inactivity period number should not be negative"));
  }
  return Promise.resolve();
};

export const tabsPages = {
  annual: {
    title: <div title="Annual">Annual membership fee</div>,
    page: (accountMoment?: string, cardMoment?: string) => (
      <Space direction="vertical" size="large">
        <p>
          A membership fee is a recurring fee that clients are required to pay annually in order to maintain the
          benefits and privileges associated with the product
        </p>
        <Form.Item
          name="amf-acc-moment"
          label="Charge date for primary card (for account)"
          className="input-group-label"
          tooltip={{
            title: "Choose the base date to charge this annual fee on this upcoming date anniversaries",
            icon: <QuestionCircleFilled />,
          }}
        >
          <Radio.Group>
            <Space direction="vertical">{getAmfAccMomentOptions()}</Space>
          </Radio.Group>
        </Form.Item>

        {accountMoment === "S" && (
          <Form.Item
            name="nic-amf-acc-start-mode"
            label="On the event of"
            className="input-group-label"
            initialValue="A"
          >
            <Radio.Group>
              <Space direction="vertical">{getAmfAccStartOptions(true)}</Space>
            </Radio.Group>
          </Form.Item>
        )}

        <Form.Item
          name="amf-card-moment"
          label="Charge date for supplementary cards"
          className="input-group-label"
          tooltip={{
            title: "Choose the base date to charge this annual fee on this upcoming date anniversaries",
            icon: <QuestionCircleFilled />,
          }}
        >
          <Radio.Group>
            <Space direction="vertical">{getAmfCardMomentOptions()}</Space>
          </Radio.Group>
        </Form.Item>

        {cardMoment === "S" && (
          <Form.Item
            name="nic-amf-card-start-mode"
            label="On the event of"
            className="input-group-label"
            initialValue="A"
          >
            <Radio.Group>
              <Space direction="vertical">{getAmfAccStartOptions()}</Space>
            </Radio.Group>
          </Form.Item>
        )}

        <Form.Item
          name="nic-amf-card-waive-unexp"
          label="Supplementary card waiving options"
          className="input-group-label"
          tooltip={{
            title: "Cards are issued and re-issued inactivated",
            icon: <QuestionCircleFilled />,
          }}
        >
          <Radio.Group>
            <Space direction="vertical">{getNicAmfCardWaiveUnexpOptions()}</Space>
          </Radio.Group>
        </Form.Item>
      </Space>
    ),
  },
  monthly: {
    title: (
      <TooltipInfo
        label="Monthly membership fee"
        tooltipProps={{
          title: "The fee will be charged on statement (billing) day by default",
          className: styles["tab-title-tooltip"],
        }}
      />
    ),
    page: () => (
      <Space direction="vertical" size="large">
        <p>
          A membership fee is a recurring fee that clients are required to pay monthly in order to maintain the benefits
          and privileges associated with the product
        </p>
        <Form.Item
          name="nic-mmf-card-waive-unexp"
          label="Supplementary card waiving options"
          className="input-group-label"
          tooltip={{
            title: "Cards are issued and re-issued inactivated",
            icon: <QuestionCircleFilled />,
          }}
        >
          <Radio.Group>
            <Space direction="vertical">{getNicMmfCardWaiveUnexpOptions()}</Space>
          </Radio.Group>
        </Form.Item>
      </Space>
    ),
  },
  statement: {
    title: <div title="Statement">Statement fee</div>,
    page: () => (
      <Space direction="vertical" size="large">
        <p>
          Statement fee is the fixed amount to charge to the customer with the following statement flags: E-mail
          statement, Physical statement, or Both. The statement fee will be posted on billing date
        </p>
        <Form.Item
          name="nic-stmt-fee-flag"
          label="Choose the client’s statement flags to apply your charge"
          className="input-group-label"
          tooltip={{
            title:
              "Account holders could select one of the listed flags. For example, if you want to apply the fee for clients with “Physical statement” and “Both” flags, select two options: “Physical statement” and “Both formats”",
            icon: <QuestionCircleFilled />,
          }}
        >
          <Checkbox.Group>
            <Space direction="vertical">{getNicStmtFeeFlagOptions()}</Space>
          </Checkbox.Group>
        </Form.Item>
      </Space>
    ),
  },
  inactivity: {
    title: <div title="Inactivity">Inactivity fee</div>,
    page: () => (
      <Space direction="vertical" size="large">
        <p>
          Inactivity fee is the fee charged when there is no activity on the account for specific period. The inactivity
          state is defined by absence of financial activities on card excluding fees and charges transactions
        </p>
        <Form.Item
          name="nic-inact-period-unit"
          label="Inactivity period type"
          className={cn("input-group-label", styles["fees-product-input-width"])}
        >
          <Select placeholder="Inactivity period type">{getNicInactPeriodUnitOptions()}</Select>
        </Form.Item>
        <Form.Item
          name="nic-inact-period"
          label="Inactivity period number"
          className={cn("input-group-label", styles["fees-product-input-width"])}
          rules={[
            () => ({
              validator(_, value) {
                return validateNumberInput(value);
              },
            }),
          ]}
          tooltip={{
            title:
              "If the parameter is set as 3 months, then inactivity fee will be charged from the 4th month, if there was no financial activity on the account",
            icon: <QuestionCircleFilled />,
          }}
        >
          <InputNumber />
        </Form.Item>
      </Space>
    ),
  },
  overlimit: {
    title: <div title="Overlimit">Overlimit fee</div>,
    page: () => (
      <Space direction="vertical" size="large">
        <p>Overlimit fee is charged when the utilization of a credit card exceeds the allowed credit limit</p>
        <Form.Item
          name="nic-ovl-fee-repeat-mode"
          label="Overlimit fee charge options"
          className={cn("input-group-label", styles["fees-product-input-width"])}
          tooltip={{
            title: "In any of the options below, over limit fee is charged only once per cycle",
            icon: <QuestionCircleFilled />,
          }}
        >
          <Radio.Group>
            <Space direction="vertical">{getNicOvlFeeRepeatModeOptions()}</Space>
          </Radio.Group>
        </Form.Item>
      </Space>
    ),
  },
} as const;
