import {
  CreditRedIcon,
  DualArrowsRedIcon,
  ExclamationPointRedIcon,
  InstallmentRedIcon,
  LoyaltyRedIcon,
  PercentRedIcon,
  PercentRoundedRedIcon,
  SpeedometerRedIcon,
} from "@ni/common/icons";

export type SettingsOverviewSectionType = {
  title: string;
  titleIcon?: JSX.Element;
  dependency?: string;
  dependencyType?: string;
  elements: {
    title: string;
    text?: string;
    href: string;
    dependency?: string;
    dependencyType?: string;
  }[];
};

export const SETTINGS_OVERVIEW_LIST: SettingsOverviewSectionType[] = [
  {
    title: "Transaction Restrictions",
    titleIcon: <ExclamationPointRedIcon />,
    elements: [
      {
        title: "",
        text: "Restriction certain transaction types, for example contactless, e-commerce transactions or transactions with specific Merchant Category Code (MCC).",
        href: "transaction-restrictions",
      },
    ],
  },

  {
    title: "Transaction Limits",
    titleIcon: <DualArrowsRedIcon />,
    elements: [
      {
        title: "Account Limits – Daily",
        text: "Account limits govern the spending velocity per day for the cards related to the account. Both primary and supplementary card transactions contribute towards the account limits.",
        href: "account-limits-daily",
      },
      {
        title: "Account Limits – Billing Period",
        text: "Account limits govern the spending velocity per billing cycle for the cards related to the account. Both primary and supplementary card transactions contribute towards the account limits.",
        href: "account-limits-billing-period",
        dependency: "$..productValues[?(@.fieldCode == 'balance-owner' && @.value == 'CMS')]",
        dependencyType: "PRODUCT",
      },
      {
        title: "Card Limits – Daily",
        text: "Card daily limits, controls spending per day on the card level independently from account limits, whichever limit is reached first.",
        href: "card-limits-daily",
      },
      {
        title: "Card Limits – Monthly",
        text: "Card monthly limits, controls spending per month on the card level independently from account limits, whichever limit is reached first.",
        href: "card-limits-monthly",
      },
      {
        title: "Card Limits – Billing Period",
        text: "Card limits, controls spending per billing cycle on the card level independently from account limits, whichever limit is reached first.",
        href: "card-limits-billing-period",
        dependency: "$..productValues[?(@.fieldCode == 'balance-owner' && @.value == 'CMS')]",
        dependencyType: "PRODUCT",
      },
    ],
  },

  {
    title: "Top-up Limits",
    titleIcon: <SpeedometerRedIcon />,
    elements: [
      {
        title: "Additional Rules",
        text: "Configuration of customized additional rules for top-up limits.",
        href: "additional-rules",
      },
      {
        title: "Account Limits",
        text: "Top-up limits for account refer to the maximum frequency and amount of money that can be loaded or added to the cards related to the account within a specific timeframe.",
        href: "account-limits",
      },
      {
        title: "Card Limits",
        text: "Top-up limits for card refer to the maximum frequency and amount of money that can be loaded or added to the card within a specific timeframe.",
        href: "card-limits",
      },
    ],
    dependency:
      "$..productValues[?(@.fieldCode == 'balance-owner' && @.value == 'CMS')] AND $..productValues[?(@.fieldCode == 'product-type' && @.value == 'Prepaid')]",
    dependencyType: "PRODUCT",
  },

  {
    title: "Payment Limits",
    titleIcon: <SpeedometerRedIcon />,
    elements: [
      {
        title: "Additional Rules",
        text: "Configuration of customized additional rules for payment limits.",
        href: "additional-rules",
      },
      {
        title: "Account Limits",
        text: "Payment limits for account refer to the maximum frequency and amount of money that can be loaded or added to the cards related to the account within a specific timeframe",
        href: "account-limits",
      },
      {
        title: "Card Limits",
        text: "Payment limits for card refer to the maximum frequency and amount of money that can be loaded or added to the card within a specific timeframe",
        href: "card-limits",
      },
    ],
    dependency:
      "$..productValues[?(@.fieldCode == 'balance-owner' && @.value == 'CMS')] AND $..productValues[?(@.fieldCode == 'product-type' && @.value == 'Credit')]",
    dependencyType: "PRODUCT",
  },

  {
    title: "General Fees",
    titleIcon: <PercentRedIcon />,
    elements: [
      {
        title: "Membership Fees",
        text: "A card membership fee is a recurring fee. It could be annual or monthly fee that cardholders are required to pay in order to maintain the benefits and privileges associated with the card.",
        href: "membership-fees",
      },
      {
        title: "Joining Fee",
        text: "A joining fee is a one-time fee charged to customer upon account opening, it is separate from any recurring membership fees or dues.",
        href: "joining-fee",
      },
      {
        title: "Card Production Fees",
        text: "A card production fee is a one-time fee to cover the cost of producing and issuing the physical card. It is typically charged when a new card is issued or when a card is replaced due to loss, damage, or expiration.",
        href: "card-production-fees",
      },
      {
        title: "Statement Fee",
        text: "A statement fee is a charge imposed for the provision of monthly or periodic account statements to the cardholder. It is a fee that covers the cost of generating, processing, and mailing the statement to the cardholder.",
        href: "statement-fee",
      },
      {
        title: "Inactivity Fee",
        text: "An inactivity fee is charged when an account remains inactive for a certain period of time. It is intended to cover the costs associated with maintaining the account, providing customer support, and ensuring compliance with regulations.",
        href: "inactivity-fees",
        dependency: "$..productValues[?(!(@.fieldCode == 'curr-setup' && @.value == 'mult'))]",
        dependencyType: "PRODUCT",
      },
      {
        title: "Overlimit Fee",
        text: "An overlimit fee is charged when the utilization of a card exceeds the allowed credit limit",
        href: "overlimit-fee",
        dependency:
          "$..productValues[?(@.fieldCode == 'balance-owner' && @.value == 'CMS')] AND $..productValues[?(@.fieldCode == 'product-type' && @.value == 'Credit')]",
        dependencyType: "PRODUCT",
      },
      {
        title: "Late Payment Fee",
        text: "A late payment fee is charged to cardholder when the minimum payment is not made towards due amount until the configured late payment date",
        href: "late-payment-fee",
        dependency:
          "$..productValues[?(@.fieldCode == 'balance-owner' && @.value == 'CMS')] AND $..productValues[?(@.fieldCode == 'product-type' && @.value == 'Credit')]",
        dependencyType: "PRODUCT",
      },
      {
        title: "Credit Limit Modification Fee",
        text: "A credit limit modification fee can be charged when a client requests an increase or decrease in their permanent or temporary credit limit.",
        href: "credit-limit-modification-fees",
        dependency:
          "$..productValues[?(@.fieldCode == 'balance-owner' && @.value == 'CMS')] AND $..productValues[?(@.fieldCode == 'product-type' && @.value == 'Credit')]",
        dependencyType: "PRODUCT",
      },
    ],
  },

  {
    title: "Transaction Fees",
    titleIcon: <PercentRoundedRedIcon />,
    elements: [
      {
        title: "Card Transaction Fees",
        text: "A card transaction fee is a charge for processing certain types of transactions to cover the costs include transaction authorization, clearing, settlement, network fees, and other operational expenses. This fee is applied on a per-transaction basis.",
        href: "card-transaction-fees",
      },
      {
        title: "Top-up Fees",
        text: "A top-up fee is charged when adding funds or reloading a prepaid card or account. It is a fee applied to the process of increasing the balance or value of the card or account.",
        href: "top-up-fees",
        dependency:
          "$..productValues[?(@.fieldCode == 'balance-owner' && @.value == 'CMS')] AND $..productValues[?(@.fieldCode == 'product-type' && @.value == 'Prepaid')]",
      },
      {
        title: "Payment Fees",
        text: "A payment fee is charged for transactions which are made by the customers to clear the card outstanding balances. The payment amount could be any amount which reduces the current balance and increases the available amount.",
        href: "payment-fees",
        dependency:
          "$..productValues[?(@.fieldCode == 'balance-owner' && @.value == 'CMS')] AND $..productValues[?(@.fieldCode == 'product-type' && @.value == 'Credit')]",
      },
      {
        title: "Currency Conversion Fees",
        text: "A currency conversion fee, also known as a foreign exchange fee or forex fee, is charged when a transaction involves converting one currency into another. It applies when a card is used to make a purchase or withdraw cash in a currency different from the card currency.",
        href: "currency-conversion-fees",
      },
      {
        title: "Balance Inquiry Fees",
        text: "A balance inquiry fee is a fixed amount that can be charged to a cardholder in case of account balance inquiry through ATMs or POS or other designated channels.",
        href: "balance-inquiry-fees",
      },
      {
        title: "Decline Fees",
        text: "A declined fee is charged when transaction is not approved by the issuer due to different reasons such as insufficient funds, incorrect PIN etc.",
        href: "decline-fee",
      },
    ],
    dependency: "$..productValues[?(@.fieldCode == 'balance-owner' && @.value == 'CMS')]",
  },

  {
    title: "Loyalty",
    titleIcon: <LoyaltyRedIcon />,
    elements: [
      {
        title: "General Settings",
        text: "The following settings such as loyalty enrollment fee, cashback and external voucher redemption rate, welcome bonus apply universally to all loyalty programs associated with the product",
        href: "loyalty-settings?tab=pointsRedemption",
      },
      {
        title: "Loyalty Programs",
        text: "The following settings such as transaction eligibility criteria, point accrual rate, point expiry are specific for each loyalty program connected with the product",
        href: "loyalty-programs",
      },
    ],
    dependency: "$..shortLoyaltyPrograms[?(@.state == 'ACTIVE')]",
    dependencyType: "PRODUCT",
  },

  {
    title: "Credit Product Settings",
    titleIcon: <CreditRedIcon />,
    elements: [
      {
        title: "Credit Limit",
        text: "Credit limit is the total line of credit that can be used by an account. The page contains configuration of cash limit and shadow limit.",
        href: "credit-limit",
      },
      {
        title: "Payment Due",
        text: "Minimum to Pay (MTP) amount is calculated at the end of each billing cycle for credit cards. The customer is expected to make a payment equal to or greater than the minimum to pay amount by the due date, to avoid late payment fees and being delinquent. The page contains configuration of functional dates and minimum payment requirements.",
        href: "payment-due",
      },
      {
        title: "Interest Calculation",
        text: "The page contains configuration of annual interest rate for retail and cash balances. The settings determine the balance components to which a zero interest rate is applied.",
        href: "interest-calculation",
      },
    ],
    dependency:
      "$..productValues[?(@.fieldCode == 'balance-owner' && @.value == 'CMS')] AND $..productValues[?(@.fieldCode == 'product-type' && @.value == 'Credit')]",
    dependencyType: "PRODUCT",
  },

  {
    title: "Installments",
    titleIcon: <InstallmentRedIcon />,
    elements: [
      {
        title: "Installment Payment Plans",
        text: "The Installment Payment is a convenient option that allows clients to divide the total amount of a retail transaction or the outstanding balance on a credit card into manageable Equated Monthly Installments. The page contains configuration of interest rate and processing fee for each Installment Payment Plan.",
        href: "installment-payment-plans",
      },
    ],
    dependency: "$..plans[?(@.state== 'ACTIVE')]",
    dependencyType: "TENANT",
  },

  {
    title: "Insurance",
    titleIcon: <LoyaltyRedIcon />,
    elements: [
      {
        title: "Insurance Programs",
        text: "This following shows customizable settings for applied insurance program, such as Premium settings, Coverage amount, insurance cancellation age limit and insurance waiving for transactor customers.",
        href: "insurance-programs",
      },
    ],
    dependency:
      "$..productValues[?(@.fieldCode == 'product-type' && @.value == 'Credit')] AND $..shortInsurancePrograms[?(@.state== 'ACTIVE')]",
    dependencyType: "PRODUCT",
  },
];
