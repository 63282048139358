import { Form, Input, notification, Space, Switch, Typography } from "antd";
import NumberFormat from "react-number-format";
import { useParams } from "react-router-dom";

import {
  INACT_FEE_BILLING_EN,
  INACT_FEE_ONCE_EN,
  NIC_INACT_FEE_BILLING_VAL,
  NIC_INACT_FEE_ONCE_VAL,
  SERVICE_UNAVAILABLE,
} from "@ni/common/constants";
import { useHydrateForm, useProductSettings } from "@ni/common/hooks";
import { CustomFormWrapper, PageItemLayoutGeneral, TooltipInfo } from "@ni/common/ui";
import { getErrorInstance } from "@ni/common/utils";

import { usePct } from "../../../../hooks";

import styles from "../../styles.module.scss";

export const InactivityFeePage = () => {
  const { id: tenantId, productId, pctId } = useParams<{ id: string; productId: string; pctId: string }>();
  const { currentProduct, productCurrency } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });

  const { pct, onSavePct } = usePct({
    pctId: parseInt(pctId ?? "0", 10),
  });

  const [form] = Form.useForm();
  const isFeeOnceEnabled = Form.useWatch<boolean>(INACT_FEE_ONCE_EN, form);
  const feeOnceValue = Form.useWatch<string>(NIC_INACT_FEE_ONCE_VAL, form);
  const isFeeBillingEnabled = Form.useWatch<boolean>(INACT_FEE_BILLING_EN, form);
  const feeBillingValue = Form.useWatch<string>(NIC_INACT_FEE_BILLING_VAL, form);

  useHydrateForm({
    form,
    entityFields: pct?.pctProductValues ?? [],
    keys: {
      strings: [INACT_FEE_ONCE_EN, NIC_INACT_FEE_ONCE_VAL, INACT_FEE_BILLING_EN, NIC_INACT_FEE_BILLING_VAL],
    },
  });

  const onFinish = async () => {
    const values = {
      [INACT_FEE_ONCE_EN]: String(isFeeOnceEnabled),
      [NIC_INACT_FEE_ONCE_VAL]: isFeeOnceEnabled ? String(feeOnceValue).replace(/ /g, "") : "",
      [INACT_FEE_BILLING_EN]: String(isFeeBillingEnabled),
      [NIC_INACT_FEE_BILLING_VAL]: isFeeBillingEnabled ? String(feeBillingValue).replace(/ /g, "") : "",
    };

    try {
      await onSavePct(values);
    } catch (error) {
      const errorInstance = getErrorInstance(error);
      notification.error({
        placement: "topRight",
        duration: 3,
        message: (
          <div>
            {errorInstance?.response?.status ?? 400} <br />
            {SERVICE_UNAVAILABLE}
          </div>
        ),
      });
    }
  };

  const relatedLinks = [
    {
      href: `/tenant/${tenantId}/product/${currentProduct.id || ""}/fees-settings`,
      label: `Fee Settings - ${currentProduct?.displayName || ""}`,
    },
    {
      href: `/tenant/${tenantId}/fees-tenant-configuration`,
      label: "Fee Settings - Global",
    },
  ];

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Inactivity Fee"
      pageSubtitle={
        <TooltipInfo
          largeLabel="Inactivity fee is the fee charged when there is no activity on the account for specific period (3 months by
        default). The inactivity state is defined by absence of financial activities on card excluding fees and
        charges transactions."
          tooltipProps={{
            title:
              "You can change inactivity period window at product settings (visit Fees Product Configuration page).",
          }}
        />
      }
      submitHandler={onFinish}
      size="md"
      formSize="md"
      level="pct"
      submitLabel="Save"
      relatedLinks={relatedLinks}
    >
      <PageItemLayoutGeneral className={styles["section__wrapper--gray"]}>
        <Space direction="vertical" size={16}>
          <Space direction="horizontal">
            <Form.Item valuePropName="checked" name={INACT_FEE_ONCE_EN}>
              <Switch />
            </Form.Item>
            <Typography.Text strong={true}>Enable one-time inactivity fee</Typography.Text>
          </Space>

          {isFeeOnceEnabled && (
            <Form.Item
              name={NIC_INACT_FEE_ONCE_VAL}
              label="Amount charged once (on exceeding inactivity period)"
              rules={[{ required: isFeeOnceEnabled, message: "Amount charged once is required" }]}
            >
              <NumberFormat
                customInput={Input}
                addonAfter={productCurrency}
                allowNegative={false}
                thousandSeparator=" "
                disabled={!isFeeOnceEnabled}
                decimalScale={2}
                decimalSeparator="."
                fixedDecimalScale={true}
              />
            </Form.Item>
          )}
        </Space>
      </PageItemLayoutGeneral>

      <PageItemLayoutGeneral className={styles["section__wrapper--gray"]}>
        <Space direction="vertical" size={16}>
          <Space direction="horizontal">
            <Form.Item valuePropName="checked" name={INACT_FEE_BILLING_EN}>
              <Switch />
            </Form.Item>
            <Typography.Text strong={true}>
              <TooltipInfo
                label="Enable regular inactivity fee"
                tooltipProps={{
                  title: "This charge will be collected on each statement day for as long as inactive",
                }}
              />
            </Typography.Text>
          </Space>

          {isFeeBillingEnabled && (
            <Form.Item
              name={NIC_INACT_FEE_BILLING_VAL}
              label="Amount charged on the regular base (on statement day)"
              rules={[{ required: isFeeBillingEnabled, message: "Amount charged on the regular base is required" }]}
            >
              <NumberFormat
                customInput={Input}
                addonAfter={productCurrency}
                allowNegative={false}
                thousandSeparator=" "
                disabled={!isFeeBillingEnabled}
                decimalScale={2}
                decimalSeparator="."
                fixedDecimalScale={true}
              />
            </Form.Item>
          )}
        </Space>
      </PageItemLayoutGeneral>
    </CustomFormWrapper>
  );
};
