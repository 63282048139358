import { FC } from "react";
import { Form, InputNumber } from "antd";

import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper } from "@ni/common/ui";
import { minMaxValidator } from "@ni/common/utils";
import { InstallmentPaymentPlanWizardRequest, InstallmentPaymentPlanWizardResponse } from "@ni/sdk/models";

import { NIC_IPP_INST_SCHEME_PLAN_TENOR } from "../../constants/forms.constants";
import { useIPPWizardApi } from "../../hooks";

export const IPPTenorPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();

  const { processWizardRequest } = useIPPWizardApi();

  const [, setIsLoading] = useReduxState("isLoading", false);
  const [wizardResponse] = useReduxState<InstallmentPaymentPlanWizardResponse>(
    "installmentPaymentPlanWizard",
    {} as InstallmentPaymentPlanWizardResponse,
  );

  useHydrateForm(
    {
      form,
      entityFields: wizardResponse.plan?.planValues ?? [],
      keys: {
        strings: [NIC_IPP_INST_SCHEME_PLAN_TENOR],
      },
      allowParse: false,
    },
    [wizardResponse],
  );

  const onFinish = async (values: FormValues) => {
    setIsLoading(true);

    try {
      await processWizardRequest({
        pageId: wizardResponse.pageId as number,
        tenantId: wizardResponse?.tenant?.id,
        planId: wizardResponse?.plan?.id,
        collectedValues: values as InstallmentPaymentPlanWizardRequest["collectedValues"],
      });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="IPP Tenor"
      pageSubtitle="This parameter specifies the tenor of the installment plan. An equal monthly installment amount is calculated based on the tenor."
      level="tenant"
      size="md"
      formSize="md"
      gap={40}
      submitHandler={onFinish}
      additionalRoute="./installment-payment-plans"
    >
      <Form.Item
        name={NIC_IPP_INST_SCHEME_PLAN_TENOR}
        label="Number of months to split payment / balance."
        rules={[
          { required: true, message: "Number of months is required" },
          () => ({
            validator(_, value: number) {
              return minMaxValidator(value, 1, null);
            },
          }),
        ]}
      >
        <InputNumber precision={0} className="w-p-100" />
      </Form.Item>
    </CustomFormWrapper>
  );
};
