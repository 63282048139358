import React, { FC, useEffect } from "react";
import { Col, Form, FormInstance, Input } from "antd";
import { Rule } from "rc-field-form/lib/interface";
import NumberFormat from "react-number-format";

import { FeeField, FeeRow, FormValues } from "@ni/common/types";

export interface TierField extends Pick<FeeField, "type"> {
  name?: string;
  disabled?: boolean;
  initialValue?: string;
}

export interface TierRow extends Pick<FeeRow, "rowLabel"> {
  fields: TierField[];
}

interface PointAccrualTableFieldProps {
  form: FormInstance<FormValues>;
  tierRows: TierRow[];
  row: TierRow;
  field: TierField;
  rules: Rule[];
  addonAfter: string;
  decimalScale: number;
}

export const PointAccrualTableField: FC<PointAccrualTableFieldProps> = ({
  form,
  tierRows,
  row,
  field,
  rules,
  addonAfter,
  decimalScale,
}) => {
  const currentMaxValue = Form.useWatch(field.name?.includes("max") ? field.name : "", form);

  const currentRowIndex = tierRows.findIndex(tierRow => tierRow.rowLabel === row.rowLabel);

  useEffect(() => {
    const nextMinTierField = tierRows[currentRowIndex + 1]?.fields.find(nextField => nextField.name?.includes("min"));
    if (nextMinTierField) form.setFieldValue(nextMinTierField?.name ?? "", currentMaxValue);
  }, [currentMaxValue, currentRowIndex, form, tierRows]);

  useEffect(() => {
    const prevMaxTierField = tierRows[currentRowIndex - 1]?.fields.find(nextField => nextField.name?.includes("max"));
    const currentMinName = field.name?.includes("min") ? field.name : "";
    if (prevMaxTierField) form.setFieldValue(currentMinName, form.getFieldValue(prevMaxTierField.name!));
  }, [currentRowIndex, field.name, form, tierRows]);

  return (
    <Col span={Math.round(22 / (row.fields.length - 1))}>
      <Form.Item name={field.name} rules={rules} initialValue={field.initialValue}>
        <NumberFormat
          customInput={Input}
          addonAfter={addonAfter}
          allowNegative={false}
          isNumericString={true}
          thousandSeparator=" "
          decimalScale={decimalScale}
          decimalSeparator="."
          fixedDecimalScale={true}
          disabled={field.disabled}
          defaultValue={field.initialValue}
        />
      </Form.Item>
    </Col>
  );
};
