import { useEffect, useLayoutEffect, useMemo } from "react";
import { Button, Flex, Form, Input, Space } from "antd";
import { useNavigate } from "react-router-dom";

import { SearchOutlined } from "@ant-design/icons";
import { initialQpPagination, useDebaunce, useParamsList, useQpApi, useReduxState } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { FilterControls, PageHeading } from "@ni/common/ui";
import { updateFilters } from "@ni/common/utils";

import { QPEditTable } from "../../components";
import { useQpFilters } from "../../hooks";

import styles from "./styles.module.scss";

export const QuestionnaireProcessingData = () => {
  const {
    params: { rowKey },
  } = useParamsList(["rowKey"]);

  const [form] = Form.useForm<FormValues>();
  const [searchForm] = Form.useForm<FormValues>();

  const searchQuery = Form.useWatch<string>("search", searchForm);
  const debouncedQuery = useDebaunce({ value: searchQuery, delay: 500 });

  const [isLoading] = useReduxState<boolean>("isLoading", false);

  const navigate = useNavigate();
  const { qpData, setQpData, saveQpData, setFilters, pagination, setPagination } = useQpApi({ isFetchEnabled: true });
  const { qpInputFilters, qpRowkeys, currentRowkey, resetState } = useQpFilters({ form });

  const filterKeys = useMemo(() => qpInputFilters.map(x => x.name), [qpInputFilters]);

  useEffect(() => {
    if (debouncedQuery !== undefined) {
      setFilters(filters => {
        return updateFilters(filterKeys, filters, undefined, debouncedQuery);
      });

      setPagination(initialQpPagination);
    }
  }, [debouncedQuery, setPagination, setFilters, filterKeys]);

  const handleValuesChange = (changedValues: FormValues) => {
    const newValues = Object.keys(changedValues ?? {}).reduce((acc, key) => {
      const value = changedValues ? changedValues[key] : undefined;

      if (Array.isArray(value)) {
        acc[key] = value.join(",");
      } else {
        switch (key) {
          case "rowkey.product":
            acc["rowkey.rowkey"] = value !== undefined ? value : (form.getFieldValue("rowkey.rowkey") as string);
            break;

          case "rowkey.pct":
            acc["rowkey.rowkey"] = value !== undefined ? value : (form.getFieldValue("rowkey.product") as string);
            break;

          default:
            acc[key] = value !== undefined ? value : "";
            break;
        }
      }

      return acc;
    }, {} as FormValues);

    setFilters(filters => {
      return updateFilters(filterKeys, filters, newValues, debouncedQuery ?? "");
    });

    setPagination(initialQpPagination);
  };

  useLayoutEffect(() => {
    if (rowKey && rowKey === currentRowkey) {
      handleValuesChange({ "rowkey.rowkey": rowKey } as FormValues);
      form.setFieldValue("rowkey.rowkey", rowKey);
    } else if (!rowKey && currentRowkey && !form.getFieldValue("rowkey.rowkey")) {
      resetState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowKey]);

  const handleReviewChanges = () => {
    navigate("/admin/questionnaire-processing-confirmation");
  };

  return (
    <Space direction="vertical" className={styles["qp-layout"]}>
      <PageHeading
        text="Questionnaire Processing (QP) Data"
        extraElements={[
          <Flex key="qp-search" flex={1}>
            <Form form={searchForm} className={styles["qp-form-search"]}>
              <Form.Item name="search">
                <Input placeholder="Search" prefix={<SearchOutlined />} className={styles["qp-search"]} />
              </Form.Item>
            </Form>
          </Flex>,
        ]}
      />

      <FilterControls
        form={form}
        inputs={qpInputFilters}
        inputWidth={160}
        fullWidth={true}
        onValuesChange={handleValuesChange}
        className="p-l-40 p-r-40"
      />

      <Space direction="vertical" size={32} className={styles["qp-content"]}>
        <QPEditTable
          data={qpData?.content ?? []}
          setData={setQpData}
          saveQpData={saveQpData}
          pagination={pagination}
          qpRowkeys={qpRowkeys}
          currentRowkey={currentRowkey}
          setPagination={setPagination}
          setFilters={setFilters}
          isLoading={isLoading}
          reviewMode={false}
        />

        <Button
          size="large"
          type="primary"
          htmlType="button"
          loading={isLoading}
          className={styles["qp-button"]}
          onClick={handleReviewChanges}
          disabled={!currentRowkey}
        >
          Review changes
        </Button>
      </Space>
    </Space>
  );
};
