import { FC, useMemo, useRef, useState } from "react";
import { Button, Drawer, Select } from "antd";

import { BlockCodeType, notSpecified, ProductBcParams } from "@ni/common/constants";
import { useReduxState } from "@ni/common/hooks";
import { AfterWizardEditPageTitle, ListPanel, PageItemLayoutElements, PageItemLayoutGeneral } from "@ni/common/ui";
import { TenantApi } from "@ni/sdk/apis";
import { BlockCode, Tenant } from "@ni/sdk/models";

import { BlockCodeEditing, BlockItem, BlockItemProps } from "../../components";

import styles from "./styles.module.scss";

const tenantsServiceApi = new TenantApi();

export const BlockCodesList: FC = () => {
  const [tenant, setTenant] = useReduxState<Tenant>("tenant", {});
  const [isCodeBased, setIsCodeBased] = useState(true);
  const [isLoading, setIsLoading] = useReduxState<boolean>("isLoading");

  const [selectedCode, setSelectedCode] = useState("");
  const [open, setOpen] = useState(false);
  const saveDefaultHandler = useRef<() => void>(null);
  const saveHandler = useRef<() => void>(null);
  const [formHasError, setFormHasError] = useState(false);

  const blockCodeNamesList = useMemo(() => {
    let namesList = Array<Omit<BlockItemProps, "onEditClick" | "onPriorityChange" | "isLoading" | "onReset">>();

    Object.values(BlockCodeType).forEach(value => {
      const bcName = tenant.blockCodes
        ?.filter(bc => bc.code === value)
        ?.find(bc => bc.fieldCode === ProductBcParams.name)?.value;

      const bcPriority =
        tenant.blockCodes?.filter(bc => bc.code === value)?.find(bc => bc.fieldCode === ProductBcParams.priority)
          ?.value ?? "00";

      namesList = [
        ...namesList,
        {
          code: value,
          name: bcName || notSpecified,
          priority: bcPriority,
        },
      ];
    });
    return namesList;
  }, [tenant.blockCodes]);

  const blockCodePriorityList = useMemo(() => {
    if (blockCodeNamesList?.length) {
      const priortyList = [...blockCodeNamesList].sort((a, b) => {
        return +(a.priority as unknown as number) < +(b.priority as unknown as number)
          ? -1
          : a.priority > b.priority
            ? 1
            : 0;
      });
      return priortyList.reverse();
    }
    return [];
  }, [blockCodeNamesList]);

  const handlePriorityChange = async (payload: BlockCode) => {
    if (tenant.id) {
      setIsLoading(true);
      try {
        const response = await tenantsServiceApi.editTenant({ blockCodes: [payload] }, tenant?.id);
        if (response?.data?.id) {
          setTenant(response?.data);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const handleResetBlock = async (code: string) => {
    if (tenant?.id) {
      try {
        const response = await tenantsServiceApi.deleteBlockCode(tenant?.id, code);
        if (response.data.length > 0) {
          const updatedTenant = {
            ...tenant,
            blockCodes: tenant.blockCodes?.filter(x => x.code !== response.data[0].code),
          };
          setTenant(updatedTenant);
        }
        setIsLoading(false);
      } catch {
        setIsLoading(false);
      }
    }
  };

  const handleSort = (type: "a" | "p") => {
    setIsCodeBased(type === "a");
  };

  const onEditClickHandler = (code: string) => {
    setSelectedCode(code);
    setOpen(true);
  };

  const closeDrawerHandler = () => {
    setSelectedCode("");
    setOpen(false);
  };

  const formErrorHandler = (hasError: boolean) => {
    setFormHasError(hasError);
  };

  return (
    <div className={styles["block-codes-wrapper"]}>
      <PageItemLayoutGeneral>
        <PageItemLayoutElements>
          <AfterWizardEditPageTitle title="Block codes" />
          <div className={styles["section-description"]}>
            Block codes are used for controlling account or card processes such as the authorization, statement
            production, reporting, transaction posting, and other functions. These block codes indicate to the system
            and to customer service personnel how to treat the account.
          </div>
          <div className={styles["section-description"]}>
            Currently system provides the ability to define up to 27 codes (A–Z and space) that indicate a special
            account condition or status.
          </div>
        </PageItemLayoutElements>

        <div>
          <Select placeholder="Sort by" onChange={handleSort} className={styles["sort-link-container"]}>
            <Select.Option value="a">Alphabetic</Select.Option>
            <Select.Option value="p">Priority</Select.Option>
          </Select>

          <ListPanel>
            {[...(isCodeBased ? blockCodeNamesList : blockCodePriorityList)].map(item => (
              <BlockItem
                key={item.name + item.code}
                code={item.code}
                priority={item.priority}
                name={item.name}
                onEditClick={onEditClickHandler}
                onPriorityChange={handlePriorityChange}
                onReset={handleResetBlock}
                isLoading={isLoading}
              />
            ))}
          </ListPanel>
        </div>

        {open && (
          <div>
            <Drawer
              className={styles["block-codes-drawer-component"]}
              title={`Block code "${selectedCode}"`}
              placement="right"
              open={open}
              width="99%"
              onClose={closeDrawerHandler}
              keyboard={false}
              maskClosable={false}
              extra={
                <>
                  <Button
                    loading={isLoading}
                    className={styles["block-codes-default-submit-button"]}
                    type="default"
                    size="large"
                    onClick={() => (saveDefaultHandler.current ? saveDefaultHandler.current() : {})}
                  >
                    Return to defaults
                  </Button>
                  <Button
                    loading={isLoading}
                    className={styles["block-codes-editing-submit-button"]}
                    type="primary"
                    size="large"
                    disabled={formHasError}
                    onClick={() => (saveHandler.current ? saveHandler.current() : {})}
                  >
                    Save
                  </Button>
                </>
              }
            >
              <BlockCodeEditing
                blockCode={selectedCode}
                saveDefaultHandler={saveDefaultHandler}
                saveHandler={saveHandler}
                formErrorHandler={formErrorHandler}
              />
            </Drawer>
          </div>
        )}
      </PageItemLayoutGeneral>
    </div>
  );
};
