import { FC, ReactNode, useEffect, useMemo, useState } from "react";
import { Form } from "antd";
import { useParams } from "react-router-dom";

import { useHydrateForm } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, Tabs } from "@ni/common/ui";

import { useInsuranceProgramPctApi } from "../../../hooks";

import { KeyTab, tab1, tab2, tab3 } from "./constants";

export const PctInsuranceSettings: FC = () => {
  const [initialValues, setInitialValues] = useState<FormValues>({});

  const { pctId } = useParams<{ pctId: string }>();

  const [form] = Form.useForm<FormValues>();
  const { currentInsuranceProgram: currentInsurance, editInsurance, currency } = useInsuranceProgramPctApi();

  const formHydratorValues = useHydrateForm({
    form,
    entityFields:
      currentInsurance.pctValues && currentInsurance?.pctValues?.length > 0
        ? currentInsurance.pctValues
        : currentInsurance.values && currentInsurance?.values?.length > 0
        ? currentInsurance.values
        : [],
    keys: {
      strings: [
        tab1.capOption.code,
        tab1.fixedOption.code,
        tab1.percentageOption.code,
        tab2.maximumCoverageOption.code,
        tab3.WaivePremiumOption.code,
        tab3.cancellationAgeLimitOption.code,
      ],
    },
    allowParse: true,
  });

  useEffect(() => {
    setInitialValues(formHydratorValues);
  }, [formHydratorValues]);

  const tabList = useMemo(() => {
    const tabsContent: { [tab in KeyTab]: ReactNode } = {
      setting: tab1.page({ currency: currency ?? "" }),
      coverage: tab2.page({ currency: currency ?? "" }),
      waiving: tab3.page(),
    } as const;

    return [
      {
        key: "setting",
        label: <div title="setting">Premuim Settings</div>,
        children: tabsContent.setting,
      },
      {
        key: "coverage",
        label: <div title="coverage">Coverage Settings</div>,
        children: tabsContent.coverage,
      },
      {
        key: "waiving",
        label: <div title="waiving">Waiving and Cancellation Settings</div>,
        children: tabsContent.waiving,
      },
    ];
  }, [currency]);

  const onFinish = async (values: FormValues) => {
    setInitialValues(values);
    await editInsurance(values, parseInt(pctId ?? "0", 10), parseInt(pctId ?? "0", 10));
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Insurance Premium Settings"
      pageSubtitle="Insurance settings can be customized for a specific pricing control table for a cardholder group by changing below fields. While un-changed parameters are inherited from the product level set up for this insurance program"
      level="product"
      size="md"
      formSize="md"
      gap={40}
      submitLabel="Save"
      linkTitle="Insurance Programs"
      submitHandler={onFinish}
      additionalRoute={`/pct/${pctId}/insurance-programs`}
    >
      <Tabs
        form={form}
        pagesList={tabList}
        initialValues={initialValues}
        onSave={onFinish}
        isCheckEnabled={true}
        discardAfterChangeTab={false}
      />
    </CustomFormWrapper>
  );
};
