import { useMemo } from "react";

import { NIC_MTP_VAL_RATE } from "@ni/common/constants";
import { ProductValue } from "@ni/sdk/models";

export const useProductMtpPercentage = (productValues?: ProductValue[]): string => {
  return useMemo(() => {
    if (productValues) {
      const mtpPercentage = productValues?.find(x => x.fieldCode === NIC_MTP_VAL_RATE)?.value;
      return mtpPercentage ?? "0";
    }
    return "0";
  }, [productValues]);
};
