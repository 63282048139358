import { FC, ReactNode, useEffect, useState } from "react";
import axios from "axios";
import { ErrorSection } from "ni-ui-kit";
import { useLocation, useNavigate } from "react-router-dom";

import { HttpError } from "@ni/common/types";

import { errorCodes, errorDetails } from "./constants";

type ErrorObject = {
  code: string;
  title: string;
  message: string;
};

export const ErrorBoundary: FC<{ children: ReactNode }> = ({ children }) => {
  const [error, setError] = useState<ErrorObject | null>(null);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const errorHandler = (err: HttpError) => {
      if (err?.response?.status && errorCodes.includes(err.response.status)) {
        if (err.response.data.statusCode) {
          setError(prev =>
            prev === null
              ? {
                  code: String(err.response.status),
                  title: errorDetails[err.response.status].title,
                  message: errorDetails[err.response.status].message || err.response.data.errorMessage,
                }
              : prev,
          );
        }
      }
    };

    const interceptor = axios.interceptors.response.use(undefined, errorHandler);

    return () => {
      setError(null);
      axios.interceptors.response.eject(interceptor);
    };
  }, [location]);

  return error ? (
    <ErrorSection code={error.code} title={error.title} message={error.message} onClick={() => navigate("/")} />
  ) : (
    children
  );
};
