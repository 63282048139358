import { useMemo } from "react";
import { Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";

import { useLoyaltyProgramApi, useProductSettings, useTenant } from "@ni/common/hooks";
import { Sidenav, SidenavEntitySelect } from "@ni/common/ui";
import { getLastRoute } from "@ni/common/utils";

import { EDIT_LYL_PROGRAMS_NAV_ITEMS } from "./constants";
import { PointAccrualSetupPage, PointExpiry, TransactionEligibility } from "./pages";

import styles from "./LoyaltyProgramEdit.module.scss";

export const LoyaltyProgramEdit = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    id: tenantId,
    productId,
    pctId,
    programId,
  } = useParams<{ id: string; productId: string; pctId: string; programId: string }>();

  const { tenant } = useTenant({ tenantId: parseInt(tenantId ?? "0", 10) });

  const { loyaltyProgram, editLoyaltyProgram } = useLoyaltyProgramApi({
    pctId: parseInt(pctId ?? "0", 10),
    loyaltyProgramId: parseInt(programId ?? "0", 10),
  });

  const { currentProduct, productCurrency } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: true,
  });

  const { selectedLoyaltyProgram, editNavBarList } = useMemo(
    () => ({
      selectedLoyaltyProgram:
        (currentProduct.shortLoyaltyPrograms ?? []).find(lt => lt.id === +(programId ?? "0")) ?? {},
      editNavBarList: EDIT_LYL_PROGRAMS_NAV_ITEMS,
    }),
    [currentProduct.shortLoyaltyPrograms, programId],
  );

  const handleChangeLoyaltyProgram = (programId: number): void => {
    navigate(
      `/tenant/${tenantId}/product/${productId}/pct/${pctId}/loyalty-program/${programId}/${getLastRoute(
        location.pathname,
      )}`,
    );
  };

  return (
    <div className={styles["ni-tenant"]}>
      <Sidenav items={editNavBarList} disabledItemsViewType="hidden">
        <SidenavEntitySelect
          value={selectedLoyaltyProgram?.id}
          entities={currentProduct.shortLoyaltyPrograms ?? []}
          onChange={handleChangeLoyaltyProgram}
        />
      </Sidenav>
      <div className={styles["ni-tenant-content"]}>
        <Routes>
          <Route
            path="transaction-eligibility"
            element={
              <TransactionEligibility
                loyaltyProgram={loyaltyProgram}
                productCurrency={productCurrency}
                editLoyaltyProgram={editLoyaltyProgram}
              />
            }
          />
          <Route
            path="point-accrual-setup"
            element={
              <PointAccrualSetupPage
                tenant={tenant ?? {}}
                currentProduct={currentProduct}
                loyaltyProgram={loyaltyProgram}
                editLoyaltyProgram={editLoyaltyProgram}
              />
            }
          />
          <Route
            path="point-expiry"
            element={<PointExpiry loyaltyProgram={loyaltyProgram} editLoyaltyProgram={editLoyaltyProgram} />}
          />
        </Routes>
      </div>
    </div>
  );
};
