import { FC, useCallback, useEffect, useState } from "react";
import { Form, Input, Space, Switch } from "antd";
import NumberFormat from "react-number-format";

import {
  INACT_FEE_BILLING_EN,
  INACT_FEE_ONCE_EN,
  NIC_CCY,
  NIC_INACT_FEE_BILLING_VAL,
  NIC_INACT_FEE_ONCE_VAL,
} from "@ni/common/constants";
import { useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, FormItemLabel, TooltipInfo } from "@ni/common/ui";
import { getFormValueFromProductValues } from "@ni/common/utils";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { ProductValue, TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

const wizardServicesApi = new ProductAndTenantWizardApi();

const feeOnceMessage = "Amount charged once is required";
const feeBillingMessage = "Amount charged on the regular base is required";

export const InactivityFeesPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [, setIsLoading] = useReduxState<boolean>("isLoading");
  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [isContinueAvailable, setIsContinueAvailable] = useState<boolean>(true);

  const [form] = Form.useForm<FormValues>();
  const isFeeOnceEnabled = Form.useWatch<boolean>(INACT_FEE_ONCE_EN, form);
  const feeOnceValue = Form.useWatch<string>(NIC_INACT_FEE_ONCE_VAL, form);
  const isFeeBillingEnabled = Form.useWatch<boolean>(INACT_FEE_BILLING_EN, form);
  const feeBillingValue = Form.useWatch<string>(NIC_INACT_FEE_BILLING_VAL, form);

  const currency = (getFormValueFromProductValues(wizardResponse.product?.productValues, NIC_CCY) as string) || "";

  const formDisabledFn = useCallback(
    (field: string, number: string | number) =>
      formDisabled
        ? (getFormValueFromProductValues(
            wizardResponse.product?.parameterTables?.[0].pctProductValues as ProductValue[],
            field,
            "string",
          ) as string) || ""
        : number,
    [formDisabled, wizardResponse.product?.parameterTables],
  );

  useEffect(() => {
    form.setFieldsValue({
      [INACT_FEE_ONCE_EN]: formDisabled
        ? getFormValueFromProductValues(
            wizardResponse.product?.parameterTables?.[0].pctProductValues as ProductValue[],
            INACT_FEE_ONCE_EN,
            "boolean",
          )
        : true,
      [NIC_INACT_FEE_ONCE_VAL]: formDisabledFn(NIC_INACT_FEE_ONCE_VAL, currency === "USD" ? "10" : "35"),
      [INACT_FEE_BILLING_EN]: formDisabled
        ? getFormValueFromProductValues(
            wizardResponse.product?.parameterTables?.[0].pctProductValues as ProductValue[],
            INACT_FEE_BILLING_EN,
            "boolean",
          )
        : true,
      [NIC_INACT_FEE_BILLING_VAL]: formDisabledFn(NIC_INACT_FEE_BILLING_VAL, currency === "USD" ? "3" : "10"),
    });
  }, [currency, form, formDisabled, formDisabledFn, wizardResponse.product?.parameterTables]);

  useEffect(() => {
    if (isFeeOnceEnabled) {
      if (feeOnceValue.length === 0) {
        setIsContinueAvailable(false);
        return;
      }
      setIsContinueAvailable(true);
    }

    if (isFeeBillingEnabled) {
      if (feeBillingValue.length === 0) {
        setIsContinueAvailable(false);
        return;
      }
      setIsContinueAvailable(true);
    }
  }, [feeBillingValue, feeOnceValue, isFeeBillingEnabled, isFeeOnceEnabled]);

  const onSwitchOnceClick = () => {
    if (isFeeOnceEnabled && feeOnceValue.length === 0) {
      setIsContinueAvailable(true);
      form.setFields([
        {
          name: NIC_INACT_FEE_ONCE_VAL,
          errors: [],
        },
      ]);
    }

    if (!isFeeOnceEnabled && feeOnceValue.length === 0) {
      setIsContinueAvailable(false);
      form.setFields([
        {
          name: NIC_INACT_FEE_ONCE_VAL,
          errors: [feeOnceMessage],
        },
      ]);
    }
  };

  const onSwitchBillingClick = () => {
    if (isFeeBillingEnabled && feeBillingValue.length === 0) {
      setIsContinueAvailable(true);
      form.setFields([
        {
          name: NIC_INACT_FEE_BILLING_VAL,
          errors: [],
        },
      ]);
    }

    if (!isFeeBillingEnabled && feeBillingValue.length === 0) {
      setIsContinueAvailable(false);
      form.setFields([
        {
          name: NIC_INACT_FEE_BILLING_VAL,
          errors: [feeBillingMessage],
        },
      ]);
    }
  };

  const onFinish = () => {
    setIsLoading(true);

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: {
        [INACT_FEE_ONCE_EN]: String(isFeeOnceEnabled),
        [NIC_INACT_FEE_ONCE_VAL]: isFeeOnceEnabled ? String(feeOnceValue).replace(/ /g, "") : "",
        [INACT_FEE_BILLING_EN]: String(isFeeBillingEnabled),
        [NIC_INACT_FEE_BILLING_VAL]: isFeeBillingEnabled ? String(feeBillingValue).replace(/ /g, "") : "",
      },
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      buttonDisabled={!isContinueAvailable}
      pageTitle="Inactivity Fees"
      pageSubtitle="Inactivity fee is the fee charged when there is no activity on the account for specific period (3 months
        by default). The inactivity state is defined by absence of financial activities on card excluding fees and
        charges transactions."
      size="sm"
      formSize="sm"
      gap={24}
      level="root"
      submitHandler={onFinish}
    >
      <Space direction="horizontal" align="center">
        <Form.Item valuePropName="checked" name={INACT_FEE_ONCE_EN} className="col-switch">
          <Switch onClick={onSwitchOnceClick} />
        </Form.Item>
        <TooltipInfo label="Enable one-time inactivity fee" code={INACT_FEE_ONCE_EN} tooltipProps={{}} />
      </Space>
      <Form.Item
        name={NIC_INACT_FEE_ONCE_VAL}
        label={
          <FormItemLabel label="Amount charged once (on exceeding inactivity period)" code={NIC_INACT_FEE_ONCE_VAL} />
        }
        rules={[{ required: isFeeOnceEnabled, message: feeOnceMessage }]}
      >
        <NumberFormat
          customInput={Input}
          addonAfter={currency}
          allowNegative={false}
          thousandSeparator=" "
          disabled={!isFeeOnceEnabled || formDisabled}
          decimalScale={2}
          decimalSeparator="."
          fixedDecimalScale={true}
        />
      </Form.Item>

      <Space direction="horizontal" align="center">
        <Form.Item valuePropName="checked" name={INACT_FEE_BILLING_EN} className="col-switch">
          <Switch onClick={onSwitchBillingClick} />
        </Form.Item>
        <TooltipInfo
          label="Enable regular inactivity fee"
          code={INACT_FEE_BILLING_EN}
          tooltipProps={{
            title: "This charge will be collected on each statement day for as long as inactive",
          }}
        />
      </Space>

      <Form.Item
        name={NIC_INACT_FEE_BILLING_VAL}
        label={
          <FormItemLabel
            label="Amount charged on the regular base (on statement day)"
            code={NIC_INACT_FEE_BILLING_VAL}
          />
        }
        rules={[{ required: isFeeBillingEnabled, message: feeBillingMessage }]}
      >
        <NumberFormat
          customInput={Input}
          addonAfter={currency}
          allowNegative={false}
          thousandSeparator=" "
          disabled={!isFeeBillingEnabled || formDisabled}
          decimalScale={2}
          decimalSeparator="."
          fixedDecimalScale={true}
        />
      </Form.Item>
    </CustomFormWrapper>
  );
};
