import { FC, useEffect } from "react";
import { Form } from "antd";

import { LTY_PROGRAM_ACCR_FREQ, LTY_PROGRAM_ACCR_MODE, LTY_PROGRAM_ACCR_TYPE } from "@ni/common/constants";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, RadioGroup } from "@ni/common/ui";
import { LoyaltyWizardRequest, LoyaltyWizardResponse } from "@ni/sdk/models";

import { useLoyaltyWizardApi } from "../../hooks";

import { RADIO_GROUP_ACCRUAL_FREQ, RADIO_GROUP_ACCRUAL_MODE, RADIO_GROUP_ACCRUAL_TYPE } from "./constants";

export const PointAccrualSetup: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const shouldShowAccrualFrequency = Form.useWatch<string>(LTY_PROGRAM_ACCR_TYPE, form) === "C";
  const shouldShowAccrualMode = !!Form.useWatch<string>(LTY_PROGRAM_ACCR_FREQ, form);
  const shouldContinue = !shouldShowAccrualFrequency || shouldShowAccrualMode;

  const [, setIsLoading] = useReduxState("isLoading", false);
  const [wizardResponse] = useReduxState<LoyaltyWizardResponse>("loyaltyWizard", {} as LoyaltyWizardResponse);
  const { processWizardRequest } = useLoyaltyWizardApi();

  useHydrateForm({
    form,
    entityFields: wizardResponse?.loyaltyProgramTemplate?.programValues ?? [],
    keys: {
      strings: [LTY_PROGRAM_ACCR_FREQ, LTY_PROGRAM_ACCR_MODE],
    },
  });

  useEffect(() => {
    if (wizardResponse.loyaltyProgramTemplate?.type) {
      form.setFieldValue(LTY_PROGRAM_ACCR_TYPE, wizardResponse.loyaltyProgramTemplate.type?.charAt(0));
    }
  }, [form, wizardResponse.loyaltyProgramTemplate]);

  const onFinish = async (values: FormValues) => {
    setIsLoading(true);
    try {
      await processWizardRequest({
        pageId: wizardResponse.pageId as number,
        tenantId: wizardResponse?.tenant?.id,
        loyaltyTemplateId: wizardResponse.loyaltyProgramTemplate?.id,
        collectedValues: {
          ...values,
        } as LoyaltyWizardRequest["collectedValues"],
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      buttonDisabled={!shouldContinue}
      pageTitle="Point Accrual Setup"
      pageSubtitle="Loyalty points are earned for eligible transactions. Please define the basic accrual setup for the loyalty program."
      size="sm"
      level="tenant"
      submitHandler={onFinish}
      additionalRoute="loyalty-program-templates"
    >
      <Form.Item
        name={LTY_PROGRAM_ACCR_TYPE}
        label="Choose loyalty program accrual type:"
        initialValue={RADIO_GROUP_ACCRUAL_TYPE.default}
        rules={[{ required: true, message: "Loyalty program accrual type is required" }]}
      >
        <RadioGroup radioList={RADIO_GROUP_ACCRUAL_TYPE.options} initialValue={RADIO_GROUP_ACCRUAL_TYPE.default} />
      </Form.Item>

      {shouldShowAccrualFrequency && (
        <>
          <Form.Item name={LTY_PROGRAM_ACCR_FREQ} label="Choose point accrual frequency:">
            <RadioGroup radioList={RADIO_GROUP_ACCRUAL_FREQ.options} />
          </Form.Item>

          {shouldShowAccrualMode && (
            <Form.Item
              name={LTY_PROGRAM_ACCR_MODE}
              label="Choose cumulative program accrual mode:"
              initialValue={RADIO_GROUP_ACCRUAL_MODE.default}
            >
              <RadioGroup
                radioList={RADIO_GROUP_ACCRUAL_MODE.options}
                initialValue={RADIO_GROUP_ACCRUAL_MODE.default}
              />
            </Form.Item>
          )}
        </>
      )}
    </CustomFormWrapper>
  );
};
