import { FC } from "react";
import { Form } from "antd";

import { QuestionCircleFilled } from "@ant-design/icons";
import { useHydrateForm } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, RadioGroup } from "@ni/common/ui";

import { useInsuranceWizardApi } from "../../hooks/useInsuranceWizardApi";

import { enrollmentModeOption } from "./constants";

export const ProductEnrollmentSettingsPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const { wizardResponse, processWizardRequest } = useInsuranceWizardApi();

  useHydrateForm({
    form,
    entityFields: (wizardResponse.insuranceTemplate?.values as { code: string; value: string }[]) ?? [],
    keys: {
      strings: [enrollmentModeOption.code],
    },
  });

  const onFinish = (values: FormValues) => {
    void processWizardRequest({
      pageId: wizardResponse?.pageId as number,
      tenantId: wizardResponse.tenant?.id,
      insuranceTemplateId: wizardResponse.insuranceTemplate?.id,
      collectedValues: values as {
        [key: string]: string;
      },
    });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Product Enrollment Settings"
      pageSubtitle="Program auto enrollment mode allows to automatically opt product accounts under this program, in two scenarios: by either auto enrolling all contracts during onboarding process only or in case of account transfer as well"
      level="tenant"
      additionalRoute="insurance-program-templates"
      size="md"
      formSize="md"
      gap={40}
      submitHandler={onFinish}
    >
      <Form.Item
        name={enrollmentModeOption.code}
        label={enrollmentModeOption.name}
        initialValue={enrollmentModeOption.default}
        tooltip={{
          title: enrollmentModeOption.tooltip,
          icon: <QuestionCircleFilled />,
        }}
      >
        <RadioGroup radioList={enrollmentModeOption.options} initialValue={enrollmentModeOption.default as string} />
      </Form.Item>
    </CustomFormWrapper>
  );
};
