import { ReactNode } from "react";
import { Form, InputNumber, Space, Switch } from "antd";

import { QuestionCircleFilled } from "@ant-design/icons";
import { SelectOption } from "@ni/common/types";
import { RadioGroup, TooltipInfo } from "@ni/common/ui";
import { numberFormatter, numberParser } from "@ni/common/utils";

export type KeyTab = "setting" | "waiving";

interface Options {
  name: string;
  code: string;
  default?: boolean | string | number;
  tooltip?: ReactNode;
}

interface NumericOptions extends Options {
  decimalsNumber?: number;
  min?: string | number;
  max?: string | number;
}

interface RadioOptions extends Options {
  options: SelectOption[];
}
// tab 1
const fixedOption: NumericOptions = {
  code: "ins-prem-fixed",
  name: "Insurance fixed premium amount",
  decimalsNumber: 2,
  min: 0,
  tooltip: "You can customize this parameter later on applied product level  or for each  PCT profile settings",
};
const percentageOption: NumericOptions = {
  code: "ins-prem-rate",
  name: "Insurance premium percentage",
  decimalsNumber: 2,
  min: 0,
  max: 100,
  default: 0.89,
  tooltip: (
    <Space direction="vertical">
      <div>
        You can customize this parameter later on applied product level or for each pricing control table profile
        settings
      </div>
      <div>
        This percentage will be charged on customer outstanding liability in addition to the insurance fixed premium
        amount.
      </div>
    </Space>
  ),
};
const capOption: NumericOptions = {
  code: "ins-prem-min",
  name: "Minimum insurance premium cap",
  decimalsNumber: 2,
  min: 0,
  tooltip: (
    <Space direction="vertical">
      <div>
        You can customize this parameter later on applied product level or for each pricing control table profile
        settings
      </div>
      <div>
        This specifies the minimum amount that will be charged to customer, if the Total Insurance Premium (output of
        the Insurance fixed amount + Insurance premium percentage formula amount) is less
      </div>
    </Space>
  ),
};
const periodOption: NumericOptions = {
  code: "ins-prem-period",
  name: "Specify period before charging the first insurance premium",
  decimalsNumber: 0,
  min: 0,
  tooltip:
    "This parameter specifies the number of months or billing cycles to auto reverse the insurance premium. this depends on selected period type",
};
const periodTypeOption: RadioOptions = {
  code: "ins-prem-period-type",
  name: "Period type",
  default: "M",
  options: [
    {
      label: "Calendar Months",
      value: "M",
    },
    {
      label: "Full Billing cycles",
      value: "B",
      tooltip: "Cycle of boarding is not counted",
    },
  ],
};
export const tab1 = {
  fixedOption,
  percentageOption,
  capOption,
  periodOption,
  periodTypeOption,
  page: ({ currency }: { currency: string }) => (
    <Space direction="vertical" size="large">
      <Form.Item
        name={fixedOption.code}
        label={fixedOption.name}
        tooltip={{
          title: fixedOption.tooltip,
          icon: <QuestionCircleFilled />,
        }}
      >
        <InputNumber
          addonAfter={currency}
          min={fixedOption.min}
          max={fixedOption.max}
          precision={fixedOption.decimalsNumber}
          formatter={numberFormatter}
          parser={numberParser}
        />
      </Form.Item>

      <Form.Item
        name={percentageOption.code}
        label={percentageOption.name}
        tooltip={{
          title: percentageOption.tooltip,
          icon: <QuestionCircleFilled />,
        }}
        initialValue={percentageOption.default}
      >
        <InputNumber
          addonAfter="%"
          min={percentageOption.min}
          max={percentageOption.max}
          precision={percentageOption.decimalsNumber}
        />
      </Form.Item>

      <Form.Item
        name={capOption.code}
        label={capOption.name}
        tooltip={{
          title: capOption.tooltip,
          icon: <QuestionCircleFilled />,
        }}
      >
        <InputNumber
          addonAfter={currency}
          min={capOption.min}
          max={capOption.max}
          precision={capOption.decimalsNumber}
          formatter={numberFormatter}
          parser={numberParser}
        />
      </Form.Item>

      <Form.Item
        name={periodOption.code}
        label={periodOption.name}
        tooltip={{
          title: periodOption.tooltip,
          icon: <QuestionCircleFilled />,
        }}
      >
        <InputNumber
          min={periodOption.min}
          max={periodOption.max}
          precision={periodOption.decimalsNumber}
          formatter={numberFormatter}
          parser={numberParser}
        />
      </Form.Item>

      <Form.Item name={periodTypeOption.code} label={periodTypeOption.name} initialValue={periodTypeOption.default}>
        <RadioGroup radioList={periodTypeOption.options} initialValue={periodTypeOption.default as string} />
      </Form.Item>
    </Space>
  ),
} as const;

// tab 2
const coverageOption: Options = {
  code: "ins-prem-waive-zero",
  name: "Waive premium on zero coverage amount",
  default: true,
  tooltip:
    "Premium will be waived if customer has paid the full outstanding balance that is used for premium computation",
};
const transactorOption: Options = {
  code: "ins-prem-waive",
  name: "Waive premium for transactor customer",
  default: false,
  tooltip: "Premium will be waived if customer has paid the previous cycle statement balance",
};
export const tab2 = {
  coverageOption,
  transactorOption,
  page: () => (
    <Space direction="vertical" size={16}>
      <Space direction="horizontal">
        <Form.Item name={coverageOption.code} valuePropName="checked" initialValue={coverageOption.default}>
          <Switch />
        </Form.Item>
        <TooltipInfo
          label={coverageOption.name}
          tooltipProps={{
            title: coverageOption.tooltip,
          }}
        />
      </Space>

      <Space direction="horizontal">
        <Form.Item name={transactorOption.code} valuePropName="checked" initialValue={transactorOption.default}>
          <Switch />
        </Form.Item>
        <TooltipInfo
          label={transactorOption.name}
          tooltipProps={{
            title: transactorOption.tooltip,
          }}
        />
      </Space>
    </Space>
  ),
} as const;
