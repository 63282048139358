import { InputNumberProps, SwitchProps } from "antd";

import { FormField, Options } from "@ni/common/types";

export const tawarruqProfitBase: Options = {
  code: "tawarruq-profit-type",
  label: "Tawarruq profit base",
  default: "P",
  options: [
    {
      value: "P",
      label: "Percentage",
      tooltip: "On Full Payment Date Charge rate used in the Tawarruq profit calculation to remaining balance",
    },
    {
      value: "F",
      label: "Fixed amount",
      tooltip: "On Full Payment Date, Charge fixed amount used in the Tawarruq profit calculation on remaining balance",
    },
    {
      value: "B",
      label: "Both",
    },
  ],
};

export const tawarruqProfitPercentage: FormField<InputNumberProps> = {
  code: "tawarruq-profit-percentage",
  label: "Tawarruq profit percentage",
  props: {
    min: 0,
    precision: 2,
    max: 100,
    controls: false,
    addonAfter: "%",
  },
};

export const tawarruqProfitAmount: FormField<InputNumberProps> = {
  code: "tawarruq-profit-amount",
  label: "Tawarruq profit amount",
  props: {
    min: 0,
    precision: 2,
    controls: false,
  },
};

export const tawarruqSalesFee: FormField<SwitchProps> = {
  code: "tawarruq-sales-fee",
  label: "Apply Tawarruq sales fee",
  tooltip: "You can select to apply a Tawarruq sales fee to customer if Tawarruq profit was charged",
};

export const tawarruqSalesFeeType: Options = {
  code: "tawarruq-sales-fee-type",
  label: "Tawarruq sales fee base",
  default: "F",
  options: [
    {
      value: "P",
      label: "Percentage",
      tooltip: "Sales fee percentage is charged to balance when Tawarruq Profit is charged",
    },
    {
      value: "F",
      label: "Fixed amount",
      tooltip: "Fixed sales fee amount is charged when Tawarruq Profit is charged",
    },
  ],
};

export const tawarruqSalesFeePercentage: FormField<InputNumberProps> = {
  code: "tawarruq-sales-fee-percentage ",
  label: "Tawarruq sales fee percentage",
  props: {
    min: 0,
    precision: 2,
    controls: false,
    addonAfter: "%",
  },
};

export const tawarruqSalesFeeAmount: FormField<InputNumberProps> = {
  code: "tawarruq-sales-fee-amount",
  label: "Tawarruq sales fee amount",
  props: {
    min: 0,
    precision: 2,
    controls: false,
  },
};

export const tawarruqPaymentDue: FormField<InputNumberProps> = {
  code: "nic-fp-date-days",
  label: "Full payment date (number of days from payment due date)",
  tooltip:
    "The full payment date is the deadline by which the entire payment must be made to meet grace option conditions.",
  props: {
    min: 0,
    precision: 0,
    controls: false,
  },
};
