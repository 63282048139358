import { FC } from "react";
import { Form, Input } from "antd";
import NumberFormat from "react-number-format";

import { NIC_INT_RATE_CASH, NIC_INT_RATE_RETAIL } from "@ni/common/constants";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, FormItemLabel } from "@ni/common/ui";
import { isNumeric, removeGapsFromStringNumeric } from "@ni/common/utils";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const InterestCalculation: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();

  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  useHydrateForm({
    form,
    entityFields: wizardResponse.product?.parameterTables![0].pctProductValues ?? [],
    keys: {
      strings: [NIC_INT_RATE_RETAIL, NIC_INT_RATE_CASH],
    },
    allowParse: false,
  });

  const onFinish = (values: FormValues) => {
    setIsLoading(true);

    const formValues: FormValues = Object.keys(values).reduce((newValues, fieldName) => {
      const value = values[fieldName];
      newValues[fieldName] = isNumeric(value as string) ? removeGapsFromStringNumeric(value as string) : value;
      return newValues;
    }, {} as FormValues);

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: formValues as { [key: string]: string },
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Interest on Retail/Cash Balance"
      pageSubtitle="The interest calculation is done based on Daily Balance algorithm which calculates the interest on daily balances and post the interest at the end of billing cycle."
      size="full"
      formSize="full"
      gap={40}
      level="root"
      submitHandler={onFinish}
    >
      <Form.Item
        name={NIC_INT_RATE_RETAIL}
        label={<FormItemLabel label="Annual interest rate on retail balance" code={NIC_INT_RATE_RETAIL} />}
        initialValue={40}
      >
        <NumberFormat
          customInput={Input}
          addonAfter="%"
          allowNegative={false}
          isNumericString={true}
          thousandSeparator=" "
          fixedDecimalScale={true}
          decimalScale={2}
        />
      </Form.Item>
      <Form.Item
        name={NIC_INT_RATE_CASH}
        label={<FormItemLabel label="Annual interest rate on cash balance" code={NIC_INT_RATE_CASH} />}
        initialValue={40}
      >
        <NumberFormat
          customInput={Input}
          addonAfter="%"
          allowNegative={false}
          isNumericString={true}
          thousandSeparator=" "
          fixedDecimalScale={true}
          decimalScale={2}
        />
      </Form.Item>
    </CustomFormWrapper>
  );
};
