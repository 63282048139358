import { useMemo } from "react";
import { Form, Input, notification, Space, Switch, Typography } from "antd";
import NumberFormat from "react-number-format";
import { useParams } from "react-router-dom";

import { NIC_STMT_FEE_VAL, SERVICE_UNAVAILABLE, STMT_FEE_ENABLED } from "@ni/common/constants";
import { useHydrateForm, useProductSettings } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, TooltipInfo } from "@ni/common/ui";
import { getErrorInstance } from "@ni/common/utils";

import { usePct } from "../../../../hooks";

export const StatementFeePage = () => {
  const { id: tenantId, productId, pctId } = useParams<{ id: string; productId: string; pctId: string }>();
  const { currentProduct, productCurrency } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });

  const { pct, onSavePct } = usePct({
    pctId: parseInt(pctId ?? "0", 10),
  });

  const [form] = Form.useForm();
  const isEnabled = Form.useWatch<boolean>(STMT_FEE_ENABLED, form);
  const feeValue = Form.useWatch<string>(NIC_STMT_FEE_VAL, form);

  const relatedLinks = useMemo(
    () => [
      {
        href: `/tenant/${tenantId}/product/${currentProduct.id || ""}/fees-settings`,
        label: `Fee Settings - ${currentProduct?.displayName || ""}`,
      },
      {
        href: `/tenant/${tenantId}/fees-tenant-configuration`,
        label: "Fee Settings - Global",
      },
    ],
    [currentProduct?.displayName, currentProduct.id, tenantId],
  );

  useHydrateForm({
    form,
    entityFields: pct?.pctProductValues ?? [],
    keys: {
      strings: [STMT_FEE_ENABLED, NIC_STMT_FEE_VAL],
    },
  });

  const onFinish = async (): Promise<void> => {
    const values = isEnabled
      ? {
          [STMT_FEE_ENABLED]: String(isEnabled),
          [NIC_STMT_FEE_VAL]: feeValue.replace(/ /g, ""),
        }
      : { [STMT_FEE_ENABLED]: String(isEnabled), [NIC_STMT_FEE_VAL]: null };

    try {
      await onSavePct(values as FormValues);
    } catch (error) {
      const errorInstance = getErrorInstance(error);
      notification.error({
        placement: "topRight",
        duration: 3,
        message: (
          <div>
            {errorInstance?.response?.status ?? 400} <br />
            {SERVICE_UNAVAILABLE}
          </div>
        ),
      });
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Statement Fee"
      pageSubtitle={
        <div>
          <TooltipInfo
            largeLabel="Statement fee is the fixed amount to charge to the customer with the following statement flags: E-mail
        statement, Physical statement, or Both."
            tooltipProps={{
              title:
                "You can configure client statement flags which are applicable for the fee charging. For this purpose, visit product settings (Fees Product Configuration page)",
            }}
          />
          The statement fee will be posted on billing date.
        </div>
      }
      submitHandler={onFinish}
      size="md"
      formSize="md"
      level="pct"
      submitLabel="Save"
      relatedLinks={relatedLinks}
    >
      <Space direction="horizontal">
        <Form.Item name={STMT_FEE_ENABLED} initialValue={false} valuePropName="checked">
          <Switch />
        </Form.Item>
        <Typography.Text strong={true}>Enable Statement fee for your product</Typography.Text>
      </Space>

      {isEnabled && (
        <Form.Item
          name={NIC_STMT_FEE_VAL}
          label="Statement fee amount"
          rules={[{ required: isEnabled, message: "Statement fee amount is required" }]}
        >
          <NumberFormat
            customInput={Input}
            addonAfter={productCurrency}
            allowNegative={false}
            thousandSeparator=" "
            disabled={!isEnabled}
            decimalScale={2}
            decimalSeparator="."
            fixedDecimalScale={true}
          />
        </Form.Item>
      )}
    </CustomFormWrapper>
  );
};
