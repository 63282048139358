import { FC, useCallback } from "react";
import { Form, Input, InputNumber, Space, Switch } from "antd";
import { useParams } from "react-router-dom";

import { QuestionCircleFilled } from "@ant-design/icons";
import { useHydrateForm, useProductSettings } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { Checkbox, CustomFormWrapper, FormulaTextArea, RadioGroup, TooltipInfo } from "@ni/common/ui";

import {
  ActivateOption,
  amountFormulaOption,
  calculationModeOption,
  directDebitDayBaseOption,
  directDebitDayOption,
  directMemberIdOption,
  formulaOption,
  getContributingBalances,
  getFullyContributingBalances,
  overlimitParticipationOption,
  recoveryOption,
  requestOccurrencesOption,
  skipDirectDebitOption,
  SmsPeriodOption,
} from "./constants";

export const DirectDebitConfigurationPage: FC = () => {
  const [form] = Form.useForm<FormValues>();
  const { productId } = useParams<{ id: string; productId: string }>();
  const { currentProduct, onUpdateProduct } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });

  const directDebitDayBase = Form.useWatch<string>(directDebitDayBaseOption.code, form);
  const amountFormula = Form.useWatch<string>(amountFormulaOption.code, form);
  const overlimitMode = Form.useWatch<string>(overlimitParticipationOption.code, form);
  const isDueAmountRequestedAsMode = Form.useWatch<string>(calculationModeOption.code, form) === "DUE";
  const isRequestOnDirectDebitDate = Form.useWatch<string>(requestOccurrencesOption.code, form) === "D";
  useHydrateForm({
    form,
    entityFields: currentProduct.productValues ?? [],
    keys: {
      strings: [
        directDebitDayBaseOption.code,
        directDebitDayOption.code,
        skipDirectDebitOption.code,
        SmsPeriodOption.code,
        calculationModeOption.code,
        amountFormulaOption.code,
        overlimitParticipationOption.code,
        requestOccurrencesOption.code,
        directMemberIdOption.code,
        ActivateOption.code,
      ],
      lists: [recoveryOption.code],
    },
    allowParse: true,
  });

  const generateFormula = useCallback(() => {
    if (isDueAmountRequestedAsMode) {
      return "Direct debit Amount = Due Amount";
    }

    if (!form.getFieldValue(amountFormulaOption.code) && !form.getFieldValue(amountFormulaOption.code))
      form.resetFields([amountFormulaOption.code, overlimitParticipationOption.code]);

    if (amountFormula)
      return `${getContributingBalances(overlimitMode, amountFormula)} DD percentage % ${getFullyContributingBalances(
        overlimitMode,
        amountFormula,
      )}`;
    return "";
  }, [amountFormula, form, isDueAmountRequestedAsMode, overlimitMode]);

  const onFinish = (values: FormValues) => {
    void onUpdateProduct({
      [amountFormulaOption.code]: "",
      [overlimitParticipationOption.code]: "",
      ...values,
      [recoveryOption.code]: values[recoveryOption.code] ? (values[recoveryOption.code] as string[])?.join(",") : "",
    });
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Direct Debit"
      pageSubtitle="Direct Debit (DD) is an arrangement made between a customer's bank and the card-issuing bank. It grants authorization for the automatic transfer of funds from the customer's bank account to their credit card account, facilitating the payment of outstanding bills."
      size="md"
      formSize="md"
      gap={24}
      level="root"
      submitLabel="Save"
      submitHandler={onFinish}
    >
      <Form.Item
        name={directDebitDayBaseOption.code}
        label={directDebitDayBaseOption.name}
        initialValue={directDebitDayBaseOption.default}
      >
        <RadioGroup
          radioList={directDebitDayBaseOption.options}
          initialValue={directDebitDayBaseOption.default as string}
        />
      </Form.Item>

      {directDebitDayBase && (
        <Form.Item
          name={directDebitDayOption.code}
          label={directDebitDayOption.name[directDebitDayBase]}
          tooltip={{
            title: directDebitDayOption.tooltip[directDebitDayBase],
            icon: <QuestionCircleFilled />,
          }}
          initialValue={directDebitDayOption.default}
          rules={[
            {
              type: "number",
              max: 30,
              message: "Number of days cannot exceed cycle length (30 days)",
            },
          ]}
        >
          <InputNumber min={0} precision={0} />
        </Form.Item>
      )}

      <Space direction="horizontal">
        <Form.Item
          name={skipDirectDebitOption.code}
          valuePropName="checked"
          initialValue={skipDirectDebitOption.default}
        >
          <Switch />
        </Form.Item>
        <TooltipInfo label={skipDirectDebitOption.name} tooltipProps={{}} />
      </Space>

      <Form.Item
        name={SmsPeriodOption.code}
        label={SmsPeriodOption.name}
        rules={[
          {
            type: "number",
            max: 30,
            message: "Number of days cannot exceed cycle length (30 days)",
          },
        ]}
      >
        <InputNumber min={0} precision={0} />
      </Form.Item>

      <Form.Item
        name={calculationModeOption.code}
        label={calculationModeOption.name}
        initialValue={calculationModeOption.default}
      >
        <RadioGroup radioList={calculationModeOption.options} initialValue={calculationModeOption.default as string} />
      </Form.Item>

      {!isDueAmountRequestedAsMode && (
        <>
          <Form.Item
            name={amountFormulaOption.code}
            label={amountFormulaOption.name}
            initialValue={amountFormulaOption.default}
            tooltip={{
              title: amountFormulaOption.tooltip,
              icon: <QuestionCircleFilled />,
            }}
          >
            <RadioGroup radioList={amountFormulaOption.options} initialValue={amountFormulaOption.default as string} />
          </Form.Item>

          <Form.Item
            name={overlimitParticipationOption.code}
            label={overlimitParticipationOption.name}
            initialValue={overlimitParticipationOption.default}
          >
            <RadioGroup
              radioList={overlimitParticipationOption.options}
              initialValue={overlimitParticipationOption.default as string}
            />
          </Form.Item>
        </>
      )}

      <Form.Item name={formulaOption.code} label={formulaOption.name}>
        <FormulaTextArea stringGenerator={generateFormula} />
      </Form.Item>

      <Form.Item
        name={requestOccurrencesOption.code}
        label={requestOccurrencesOption.name}
        initialValue={requestOccurrencesOption.default}
      >
        <RadioGroup
          radioList={requestOccurrencesOption.options}
          initialValue={requestOccurrencesOption.default as string}
        />
      </Form.Item>

      {!isRequestOnDirectDebitDate && (
        <Form.Item
          name={recoveryOption.code}
          label={recoveryOption.name}
          rules={[
            {
              required: true,
              type: "array",
              validator: (_, value) => {
                if (value) {
                  if (
                    (value as string[])?.filter(
                      x => x === recoveryOption.options[0].value || x === recoveryOption.options[1].value,
                    )?.length
                  )
                    return Promise.resolve();
                }
                return Promise.reject();
              },
              message: "Direct debit recovery mode is required!",
            },
          ]}
        >
          <Checkbox checkboxes={recoveryOption.options} />
        </Form.Item>
      )}

      <Form.Item
        name={directMemberIdOption.code}
        label={directMemberIdOption.name}
        tooltip={{
          title: directMemberIdOption.tooltip,
          icon: <QuestionCircleFilled />,
        }}
      >
        <Input />
      </Form.Item>

      <Space direction="horizontal">
        <Form.Item name={ActivateOption.code} valuePropName="checked" initialValue={ActivateOption.default}>
          <Switch />
        </Form.Item>
        <TooltipInfo label={ActivateOption.name} tooltipProps={{ title: ActivateOption.tooltip }} />
      </Space>
    </CustomFormWrapper>
  );
};
