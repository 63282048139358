import { FC, useEffect, useState } from "react";
import { notification, Select, SelectProps } from "antd";

import { SERVICE_UNAVAILABLE } from "@ni/common/constants";
import { useConfigurationApi } from "@ni/common/hooks";
import { getErrorInstance } from "@ni/common/utils";

import { FormItemLabel } from "../Labels";

export interface DictionarySelectProps extends SelectProps {
  code: string;
}

export const DictionarySelect: FC<DictionarySelectProps> = ({ code, ...props }) => {
  const [data, setData] = useState<SelectProps["options"]>([]);
  const { getDictionariesByCode } = useConfigurationApi();

  useEffect(() => {
    getDictionariesByCode(code)
      .then(response => {
        setData(response.dictionaries?.map(option => ({ value: option.value, label: option.displayValue })));
      })
      .catch(error => {
        const errorInstance = getErrorInstance(error);
        notification.error({
          placement: "topRight",
          duration: 3,
          message: (
            <div>
              {errorInstance?.response.status ?? 400} <br />
              {SERVICE_UNAVAILABLE}
            </div>
          ),
        });
      });
  }, [code, getDictionariesByCode]);

  const { options, ...restProps } = props;

  return (
    <Select {...restProps}>
      {[...(options ?? []), ...(data ?? [])].map(option => (
        <Select.Option key={option.value} value={option.value}>
          <FormItemLabel label={option.label} code={option.value as string} />
        </Select.Option>
      ))}
    </Select>
  );
};
