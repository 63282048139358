import { SelectOption } from "@ni/common/types";

import { PERSO, VIRT_PHYS } from "../../constants";

interface Options {
  name: string;
  code: string;
}

interface RadioOptions extends Options {
  options: SelectOption[];
}

export const cardTypeOptions: RadioOptions = {
  code: VIRT_PHYS,
  name: "What type of card do you want to issue?",
  options: [
    {
      label: "Virtual",
      value: "V",
    },
    {
      label: "Both virtual and physical",
      value: "B",
    },
  ],
};

export const cardPersonalizationOptions: RadioOptions = {
  code: PERSO,
  name: "Card personalization partners",
  options: [
    {
      label: "Network International existing partner (IDEMIA)",
      value: "I",
    },
    {
      label: "Own partner",
      value: "O",
    },
  ],
};

export const OTHER_INFO_TEXT =
  "Please reach out to your Network International representative to discuss new integrations for your third party personalization partners.";

export const REISS_PHYS_LABEL = "Allow re-issuing of virtual cards into physical cards";

export const SDE_LABEL = "Allow same day embossing";
export const SDE_TOOLTIP = "Card is produced at the day of ordering (urgent issuing).";
export const SDE_INFO =
  "Please reach out to your Network International representative to setup the process of urgent personalization.";
