import { NavbarItem } from "@ni/common/types";

export const EDIT_LYL_PROGRAMS_NAV_ITEMS: NavbarItem[] = [
  {
    name: "Transaction Eligibility",
    route: "transaction-eligibility",
  },
  {
    name: "Point Accrual Setup",
    route: "point-accrual-setup",
  },
  {
    name: "Point Expiry",
    route: "point-expiry",
  },
];
