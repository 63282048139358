import { FC, useMemo } from "react";
import { Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";

import { useLoyaltyTemplates, useTenant } from "@ni/common/hooks";
import { Sidenav, SidenavEntitySelect } from "@ni/common/ui";
import { getLastRoute } from "@ni/common/utils";

import { EDIT_LYL_TEMPL_NAV_ITEMS } from "./constants";
import { useLoyaltyProgramTemplateApi } from "./hooks";
import {
  LoyaltyApplyingToProducts,
  PointAccrualSetup,
  PointExpiry,
  PointRedemptionSetup,
  TransactionEligibility,
} from "./pages";

import styles from "./LoyaltyProgramTemplatesEdit.module.scss";

export const LoyaltyProgramTemplatesEdit: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { id: tenantId, templateId } = useParams<{ id: string; templateId: string }>();

  const { loyaltyProgramTemplate, applyToProductsApi, editLoyaltyProgramTemplate } = useLoyaltyProgramTemplateApi({
    loyaltyTemplateId: parseInt(templateId ?? "0", 10),
  });

  const { tenant } = useTenant({ tenantId: parseInt(tenantId ?? "0", 10) });

  const [loyaltyTemplates] = useLoyaltyTemplates({
    tenantId: parseInt(tenantId ?? "0", 10),
  });

  const { selectedLoyaltyProgramTemplate, editNavBarList } = useMemo(
    () => ({
      selectedLoyaltyProgramTemplate: loyaltyTemplates.find(lt => lt.id === parseInt(templateId ?? "0", 10)),
      editNavBarList: EDIT_LYL_TEMPL_NAV_ITEMS,
    }),
    [templateId, loyaltyTemplates],
  );

  const handleLoyaltyProgramTemplateChange = (loylatyProgramId: number): void => {
    navigate(`/tenant/${tenantId}/edit-template/${loylatyProgramId}/${getLastRoute(location.pathname)}`);
  };

  return (
    <div className={styles["ni-tenant"]}>
      <Sidenav items={editNavBarList} disabledItemsViewType="hidden">
        <SidenavEntitySelect
          value={selectedLoyaltyProgramTemplate?.id}
          entities={loyaltyTemplates}
          onChange={handleLoyaltyProgramTemplateChange}
        />
      </Sidenav>
      <div className={styles["ni-tenant-content"]}>
        <Routes>
          <Route
            path="transaction-eligibility"
            element={
              <TransactionEligibility
                loyaltyProgramTemplate={loyaltyProgramTemplate}
                editLoyaltyProgramTemplate={editLoyaltyProgramTemplate}
              />
            }
          />
          <Route
            path="redemption-setup"
            element={
              <PointRedemptionSetup
                tenantId={tenant?.id ?? 0}
                loyaltyProgramTemplate={loyaltyProgramTemplate}
                editLoyaltyProgramTemplate={editLoyaltyProgramTemplate}
              />
            }
          />
          <Route
            path="point-accrual-setup"
            element={
              <PointAccrualSetup
                tenant={tenant ?? {}}
                loyaltyProgramTemplate={loyaltyProgramTemplate}
                editLoyaltyProgramTemplate={editLoyaltyProgramTemplate}
              />
            }
          />
          <Route
            path="point-expiry"
            element={
              <PointExpiry
                loyaltyProgramTemplate={loyaltyProgramTemplate}
                editLoyaltyProgramTemplate={editLoyaltyProgramTemplate}
              />
            }
          />
          <Route
            path="applying-to-products"
            element={
              <LoyaltyApplyingToProducts
                tenantId={tenant?.id ?? 0}
                applyToProductsApi={applyToProductsApi}
                loyaltyProgramTemplate={loyaltyProgramTemplate}
                editLoyaltyProgramTemplate={editLoyaltyProgramTemplate}
              />
            }
          />
        </Routes>
      </div>
    </div>
  );
};
