import { createRoot } from "react-dom/client";

import { UserService } from "@ni/common/utils";

import App from "./App";

import "antd/dist/reset.css";
import "@ni/common/scss";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

UserService.initKeycloak(() => root.render(<App />));
