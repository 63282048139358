import { SelectOption } from "@ni/common/types";

export const VIRT_PHYS_LIST: SelectOption[] = [
  {
    label: "Just virtual cards",
    value: "V",
  },
  { label: "Both physical and virtual cards", value: "B" },
];

export const REISS_PHYS_LIST: SelectOption[] = [
  { label: "Yes", value: "true" },
  { label: "No", value: "false" },
];

export const PERSO_LIST: SelectOption[] = [
  {
    label: "We will use Network International existing partner (IDEMIA)",
    value: "I",
  },
  { label: "We will bring our own personalization partner and integrate with Network International", value: "O" },
];
