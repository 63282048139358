import { FC, useMemo, useState } from "react";
import { Button, Checkbox, Form, Modal, notification, Space, Typography } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { CheckboxProps } from "antd/lib";
import { Link, useParams } from "react-router-dom";

import { SERVICE_UNAVAILABLE } from "@ni/common/constants";
import { useReduxState, useUATDisableForm } from "@ni/common/hooks";
import { FormValues, Value } from "@ni/common/types";
import { CollapsedLinks, LoyaltyProgramListItem, PageItemLayoutGeneral, SettingsPageLayout } from "@ni/common/ui";
import { getErrorInstance } from "@ni/common/utils";
import { InsuranceProgramTemplate, Product } from "@ni/sdk/models";

import { InsuranceTable } from "../../components";
import { useInsurance } from "../../hooks";

import styles from "../Loyalty/styles.module.scss";

export const InsurancePrograms: FC = () => {
  const [form] = Form.useForm<FormValues>();

  const { id: tenantId, productId } = useParams<{ id: string; productId: string }>();

  const {
    insurancePrograms,
    insuranceTemplates,
    applyProductInsurancePrograms,
    duplicateInsuranceProgram,
    renameInsuranceProgram,
    toggleInsuranceProgram,
    productCurrency,
  } = useInsurance(parseInt(tenantId ?? "0", 10), parseInt(productId ?? "0", 10));

  const [currentProduct] = useReduxState<Product>("currentProduct", {});
  const [isLoading, setIsLoading] = useReduxState<boolean>("isLoading", false);
  const [modalOpen, setModalOpen] = useState(false);

  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);

  const isDisabled = useUATDisableForm();

  const checkAll = insuranceTemplates.length === checkedList.length;
  const indeterminate = checkedList.length > 0 && checkedList.length < insuranceTemplates.length;

  const onCheckAllChange: CheckboxProps["onChange"] = (e: CheckboxChangeEvent) => {
    const ids = (
      e.target.checked
        ? insuranceTemplates?.filter(x => x.currency === productCurrency)?.map(template => template.id!)
        : []
    ) as Value;

    setCheckedList(ids! as CheckboxValueType[]);
    form.setFieldsValue({ ids });
  };

  const onChange = (e: CheckboxChangeEvent) => {
    setCheckedList(prev => {
      const index = prev.findIndex(item => item === e.target.value);

      if (index !== -1) return [...prev.slice(0, index), ...prev.slice(index + 1)];

      return [...prev, e.target.value] as CheckboxValueType[];
    });
  };

  const { description, relatedLinks } = useMemo(() => {
    const isProgramsEmpty = insurancePrograms.length === 0;
    const isTemplatesEmpty = insuranceTemplates.length === 0;
    const pageInformation = {
      description: "",
      relatedLinks: [
        {
          href: `/tenant/${tenantId}/insurance-program-templates`,
          label: "Tenant Insurance Templates",
        },
      ],
    };
    if (isProgramsEmpty && isTemplatesEmpty)
      pageInformation.description =
        "The product is currently not associated with any insurance program. You can add insurance to your product by choosing from the insurance program templates. However it seems that you haven’t created any template yet. Please visit the Insurance Program Templates section in Tenant settings.";
    if (isProgramsEmpty && !isTemplatesEmpty)
      pageInformation.description =
        "The product is currently not associated with any insurance program. You can add insurance to your product by choosing from the insurance program templates.";
    if (!isProgramsEmpty) {
      currentProduct?.parameterTables?.forEach(table => {
        pageInformation.relatedLinks.push({
          href: `/tenant/${tenantId}/product/${productId}/pct/${table?.id}/insurance-programs`,
          label: `Insurance PCT Level - ${table?.displayName}`,
        });
      });

      pageInformation.description =
        "Insurance programs on this product can be managed from below dashboard. Additional programs can be created -Up to 12 programs- using templates. All program parameters, can be edited from below settings, also some parameters can be changed on specific Pricing Control Tables under related screens.";
    }

    return pageInformation;
  }, [currentProduct?.parameterTables, insurancePrograms.length, insuranceTemplates.length, productId, tenantId]);

  const onFormFinish = async (values: FormValues) => {
    setIsLoading(true);

    try {
      await applyProductInsurancePrograms(
        values["ids"] as number[],
        <Space direction="vertical">
          <Typography.Text strong={true}>Error</Typography.Text>
          <Typography.Text>A maximum of 12 insurance programs can be applied to each product</Typography.Text>
        </Space>,
      );
      setModalOpen(false);
      setCheckedList([]);

      form.setFieldsValue({ ids: [] });
    } catch (e) {
      const errorInstance = getErrorInstance(e);
      notification.error({
        placement: "topRight",
        duration: 3,
        message: (
          <div>
            {errorInstance?.response.status ?? 400} <br />
            {errorInstance?.response.data.errorMessage ?? SERVICE_UNAVAILABLE}
          </div>
        ),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const hasAvailbleTemplatesToApply = useMemo(
    () => insuranceTemplates?.filter(x => x.currency === productCurrency)?.length > 0,
    [insuranceTemplates, productCurrency],
  );

  return (
    <SettingsPageLayout pageTitle="Insurance Programs" pageSubtitle={!isLoading && description}>
      <PageItemLayoutGeneral>
        {insurancePrograms.length > 0 && (
          <InsuranceTable
            insurancePrograms={insurancePrograms}
            insuranceTemplates={insuranceTemplates}
            insuranceActions={{ duplicateInsuranceProgram, renameInsuranceProgram, toggleInsuranceProgram }}
          />
        )}

        <Modal
          title={
            <div>
              You can select to use an existing template to create new programs on this product. Or{" "}
              <Link to={`/tenant/${tenantId}/insurance-program-templates`}>visit template management</Link> on tenant
              settings if you need to create a new template
            </div>
          }
          open={modalOpen}
          width="568px"
          onCancel={() => setModalOpen(false)}
          footer={[
            <Button key="back" disabled={isLoading} onClick={() => setModalOpen(false)}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={isLoading}
              onClick={hasAvailbleTemplatesToApply ? form.submit : () => setModalOpen(false)}
              disabled={checkedList.length === 0}
            >
              Confirm
            </Button>,
          ]}
        >
          {hasAvailbleTemplatesToApply ? (
            <Form form={form} layout="vertical" onFinish={onFormFinish} disabled={isLoading}>
              <Form.Item name="selectAll" className="m-b-8">
                <Checkbox
                  indeterminate={indeterminate}
                  checked={checkAll}
                  onChange={onCheckAllChange}
                  className="text-primary"
                >
                  Select all
                </Checkbox>
              </Form.Item>

              <Form.Item name="ids" className="w-p-100">
                <Checkbox.Group className={styles["lty-modal-group"]}>
                  {insuranceTemplates
                    .filter(x => x.currency === productCurrency)
                    .map((template: InsuranceProgramTemplate) => (
                      <LoyaltyProgramListItem
                        key={`${template.id}-${template.name}`}
                        name={template.name ?? "Draft"}
                        value={template.id ?? "0"}
                        sections={[
                          {
                            title: "Insurance company",
                            value: template?.insuranceCompanyName ?? "-",
                          },
                          { title: "Currency", value: template?.currency ?? "-" },
                        ]}
                        onChange={onChange}
                      />
                    ))}
                </Checkbox.Group>
              </Form.Item>
            </Form>
          ) : (
            <Typography.Text>There are currently no available insurance templates in product currency</Typography.Text>
          )}
        </Modal>
        <Button
          size="large"
          type="primary"
          htmlType="button"
          disabled={isDisabled || insuranceTemplates.length === 0}
          loading={isLoading}
          onClick={() => {
            setModalOpen(true);
          }}
        >
          Add insurance program
        </Button>
      </PageItemLayoutGeneral>
      <CollapsedLinks list={relatedLinks} />
    </SettingsPageLayout>
  );
};
