import { FC, useMemo } from "react";
import { Flex, Form, InputNumber } from "antd";

import { QuestionCircleFilled } from "@ant-design/icons";
import { NIC_CASH_LIMIT_ACC_RATE, NIC_CCY, NIC_CRLIM_MAX, NIC_CRLIM_MIN, NIC_OVL_AUTH_VAR } from "@ni/common/constants";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, FormItemLabel } from "@ni/common/ui";
import {
  isNumeric,
  minMaxValidator,
  numberFormatter,
  numberParser,
  removeGapsFromStringNumeric,
} from "@ni/common/utils";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const CreditLimit: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();

  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  useHydrateForm({
    form,
    entityFields: wizardResponse.product?.productValues ?? [],
    keys: {
      strings: [NIC_CRLIM_MIN, NIC_CRLIM_MAX, NIC_CASH_LIMIT_ACC_RATE, NIC_OVL_AUTH_VAR],
    },
    allowParse: false,
  });

  const { currencyType } = useMemo(
    () => ({
      currencyType: wizardResponse.product?.productValues?.find(x => x.fieldCode === NIC_CCY)?.value,
    }),
    [wizardResponse.product?.productValues],
  );

  const onFinish = (values: FormValues) => {
    setIsLoading(true);

    const formValues: FormValues = Object.keys(values).reduce((newValues, fieldName) => {
      const value = values[fieldName];
      newValues[fieldName] = isNumeric(value as string) ? removeGapsFromStringNumeric(value as string) : value;
      return newValues;
    }, {} as FormValues);

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: formValues as { [key: string]: string },
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Credit Limit"
      pageSubtitle="Credit limit refers to the total line of credit that can be used by an Account to make purchases.  Fees and interests billed to the Account also make up for the total limit available to be used (Open-To-Buy).  Credit limit can be set at Account or Card level.  Card level Credit Limit cannot exceed the limit of account level."
      size="md"
      formSize="md"
      gap={40}
      level="root"
      submitHandler={onFinish}
      onValuesChange={form.validateFields}
    >
      <Form.Item
        name={NIC_CRLIM_MIN}
        label={<FormItemLabel label="Minimum credit limit" code={NIC_CRLIM_MIN} />}
        initialValue={0}
        rules={[
          {
            validator: (_, value) =>
              value >= form.getFieldValue(NIC_CRLIM_MAX) ? Promise.reject() : Promise.resolve(),
            message: "Minimum credit limit can not be more than or equal to maximum credit limit",
          },
        ]}
      >
        <InputNumber
          min={0}
          precision={2}
          controls={false}
          addonAfter={currencyType}
          formatter={numberFormatter}
          parser={numberParser}
        />
      </Form.Item>

      <Form.Item
        name={NIC_CRLIM_MAX}
        label={<FormItemLabel label="Maximum credit limit" code={NIC_CRLIM_MAX} />}
        initialValue={999999.99}
        rules={[
          {
            validator: (_, value) =>
              value <= form.getFieldValue(NIC_CRLIM_MIN) ? Promise.reject() : Promise.resolve(),
            message: "Maximum credit limit can not be less than or equal to minimum credit limit",
          },
        ]}
      >
        <InputNumber
          min={0}
          precision={2}
          controls={false}
          addonAfter={currencyType}
          formatter={numberFormatter}
          parser={numberParser}
        />
      </Form.Item>

      <Form.Item
        name={NIC_CASH_LIMIT_ACC_RATE}
        label={
          <FormItemLabel label="Cash limit (as a percentage of allowed account limit)" code={NIC_CASH_LIMIT_ACC_RATE} />
        }
        initialValue={50}
        rules={[
          () => ({
            validator(_, value: number) {
              return minMaxValidator(value, 0, 100);
            },
          }),
        ]}
      >
        <InputNumber precision={2} controls={false} addonAfter="%" formatter={numberFormatter} parser={numberParser} />
      </Form.Item>
      <Form.Item
        name={NIC_OVL_AUTH_VAR}
        label={
          <FormItemLabel label="Shadow limit (as a percentage of allowed account limit)" code={NIC_OVL_AUTH_VAR} />
        }
        initialValue={5}
        tooltip={{
          icon: <QuestionCircleFilled />,
          title: (
            <Flex vertical={true} gap={8}>
              <div>
                System provides the feasibility of giving certain percentage of extra credit limit on top of credit
                limit called as shadow limit.
              </div>
              <div>It is possible to charge overlimit fee in case of transactions amount exceeds credit limit.</div>
              <div>
                E.g. Credit limit is 1000 AED and shadow limit is 5%, then customer is allowed to do authorization of
                1050 AED amount.
              </div>
            </Flex>
          ),
        }}
        rules={[
          () => ({
            validator(_, value: number) {
              return minMaxValidator(value, 0, 100);
            },
          }),
        ]}
      >
        <InputNumber precision={2} controls={false} addonAfter="%" formatter={numberFormatter} parser={numberParser} />
      </Form.Item>
    </CustomFormWrapper>
  );
};
