import { FC, useEffect, useState } from "react";
import { Button, Col, Form, Input, Modal, notification, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";

import { BlockOutlined, CreditCardOutlined, DeleteOutlined, TableOutlined } from "@ant-design/icons";
import { useReduxState } from "@ni/common/hooks";
import { paymentMethodKeyToValueMapper } from "@ni/common/mocks";
import { DashboardTenantUI } from "@ni/common/types";
import { ActionsCell, CardView, MenuItemModel } from "@ni/common/ui";
import { checkIfProductCreatedSuccessfully, getErrorInstance } from "@ni/common/utils";
import { ProductApi } from "@ni/sdk/apis";
import { DashboardProduct, ProductState } from "@ni/sdk/models";

import { useDashboard } from "../../hooks";

import styles from "./styles.module.scss";

interface ProductBlockProperties {
  product: DashboardProduct;
  tenantId: string;
  onDuplicateSuccess: () => void;
}

interface DuplicateProductForm {
  newDisplayName: string;
}

const productServicesApi = new ProductApi();

export const CardBlock: FC<ProductBlockProperties> = props => {
  const { product, tenantId, onDuplicateSuccess } = props;
  const navigate = useNavigate();
  const [, setDashboardCards] = useReduxState<DashboardTenantUI[]>("dashboardCards", []);
  const [readyToUat, setReadyToUAT] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [creationCompleted, setCreationCompleted] = useState<boolean>();
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [sandboxModalOpened, setSandboxModalOpened] = useState<boolean>(false);
  const [modalId, setModalId] = useState<string | undefined>();
  const [modalForm] = Form.useForm();
  const [duplicateProductLoading, setDuplicateProductLoading] = useState<boolean>(false);
  const paymentMethodsMapper = paymentMethodKeyToValueMapper as { [key: string]: string };

  const { getCardTenants } = useDashboard();

  const onDuplicateProduct = (formData: DuplicateProductForm): void => {
    setDuplicateProductLoading(true);
    productServicesApi
      .copyProduct(formData, parseInt(modalId as string, 10))
      .then(() => {
        setDuplicateProductLoading(false);
        setModalOpened(false);
        onDuplicateSuccess();
      })
      .catch(() => {
        setDuplicateProductLoading(false);
      });
  };

  const onCancelModal = (): void => {
    setModalOpened(false);
  };

  useEffect(() => {
    if (product.productState === ProductState.DRAFT) {
      setReadyToUAT(true);
    }

    setCreationCompleted(checkIfProductCreatedSuccessfully(product?.lastProcessedPage?.code as string));
  }, [product]);

  const actionsItems: MenuItemModel[] = [
    {
      label: "Product settings",
      icon: <CreditCardOutlined />,
      disabled: !creationCompleted,
      link: `/tenant/${tenantId}/product/${product.id}/product-details`,
    },
    {
      label: "Pricing control tables",
      icon: <TableOutlined />,
      disabled: !creationCompleted,
      link: `/tenant/${tenantId}/product/${product.id}/pct/`,
    },
    {
      label: "Duplicate product",
      icon: <BlockOutlined />,
      disabled: !creationCompleted,
      actionCallBack: (data: { id: string; tenantId: string }) => {
        const { id } = data;

        setModalId(id);
        modalForm.setFieldsValue({
          newDisplayName: `Copy of ${product.name || ""}`,
        });

        setModalOpened(true);
      },
    },
    ...(product.productState === "DRAFT"
      ? [
          {
            label: "Delete product",
            icon: <DeleteOutlined />,
            actionCallBack: ({ id }: { id: string }) => {
              setModalId(id);
              setDeleteModalOpen(true);
            },
          },
        ]
      : []),
  ];

  const onDeleteDraftProduct = () => {
    setIsLoading(true);

    productServicesApi
      .deleteProductById(Number(modalId))
      .then(() => {
        setDashboardCards(prev =>
          prev.map(tenant =>
            tenant.id === Number(tenantId)
              ? { ...tenant, products: tenant.products.filter(product => product.id !== Number(modalId)) }
              : tenant,
          ),
        );

        notification.success({
          placement: "topRight",
          duration: 5,
          message: "Product has been deleted successfully!",
        });
      })
      .catch(response => {
        const errorInstance = getErrorInstance(response);
        notification.error({
          placement: "topRight",
          duration: 5,
          message: (
            <div>
              {errorInstance?.response.status} <br />
              {errorInstance?.response.data.errorMessage}
            </div>
          ),
        });
      })
      .finally(() => {
        setDeleteModalOpen(false);
        setIsLoading(false);
        setModalId(undefined);
      });
  };

  const continueProductCreation = (): void => {
    const queries = new URLSearchParams({
      tenantId,
      productId: String(product.id),
    });

    navigate({
      pathname: `/create-product`,
      search: queries.toString(),
    });
  };

  const goToUATHandle = (): void => {
    setIsLoading(true);
    const id = product?.id;
    productServicesApi
      .confirmUat(id)
      .then(response => {
        if (Number.isInteger(response?.data?.id)) {
          setIsLoading(false);
          setReadyToUAT(false);
          setSandboxModalOpened(false);
          void getCardTenants({});
        } else {
          throw new Error();
        }
      })
      .catch(response => {
        const errorInstance = getErrorInstance(response);
        notification.error({
          placement: "topRight",
          duration: 5,
          message: (
            <div>
              {errorInstance?.response.status} <br />
              {errorInstance?.response.data.errorMessage}
            </div>
          ),
        });
        setIsLoading(false);
        setSandboxModalOpened(false);
      });
  };

  const getBalanceOwner = (balanceOwner?: string): string => {
    if (!balanceOwner) {
      return "-";
    }

    return balanceOwner === "CMS" ? "Network" : "Tenant";
  };

  return (
    <Col sm={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 8, offset: "16px" }} xxl={{ span: 6, offset: "16px" }}>
      <div className={styles["tenant-product-card"]}>
        <div className={styles["tenant-product-card-heading"]}>
          <div className={styles["tenant-product-card-name"]}>{product.name}</div>
          <div className={styles["tenant-product-card-options"]}>
            <ActionsCell items={actionsItems} rowData={{ id: product.id?.toString(), tenantId }} />
          </div>
        </div>
        <div className={styles["tenant-product-card-body"]}>
          <div className={styles["tenant-product-card-image"]}>
            {creationCompleted ? (
              <Link to={`/tenant/${tenantId}/product/${product.id || ""}/product-details`}>
                <CardView
                  className={creationCompleted ? "" : "card-disabled"}
                  cardImage={product.cardImage}
                  prodIps={product.paymentScheme}
                />
              </Link>
            ) : (
              <CardView
                className={creationCompleted ? "" : "card-disabled"}
                cardImage={product.cardImage}
                prodIps={product.paymentScheme}
              />
            )}
            <div hidden={!readyToUat}>
              <Button
                loading={isLoading}
                onClick={() => (creationCompleted ? setSandboxModalOpened(true) : continueProductCreation())}
              >
                {creationCompleted ? "Test in sandbox" : "Continue creating"}
              </Button>
            </div>
          </div>

          <div className={styles["tenant-product-card-data"]}>
            <div className={styles["tenant-product-card-data-row"]}>
              <div className={styles["tenant-product-card-data-column"]}>
                <div className={styles["tenant-product-card-data-label"]}>State</div>
              </div>
              <div className={styles["tenant-product-card-data-column-val"]}>
                <div className={styles["grayed-div"]}>{product.productState}</div>
              </div>
            </div>
            <div className={styles["tenant-product-card-data-row"]}>
              <div className={styles["tenant-product-card-data-column"]}>
                <div className={styles["tenant-product-card-data-label"]}>Product</div>
              </div>
              <div className={styles["tenant-product-card-data-column-val"]}>
                <div className={styles["tenant-product-card-data-value"]}>{product.productType || "-"}</div>
              </div>
            </div>
            <div className={styles["tenant-product-card-data-row"]}>
              <div className={styles["tenant-product-card-data-column"]}>
                <div className={styles["tenant-product-card-data-label"]}>Payment Scheme</div>
              </div>
              <div className={styles["tenant-product-card-data-column-val"]}>
                <div className={styles["tenant-product-card-data-value"]}>
                  {paymentMethodsMapper[product.paymentScheme as string] || "-"}
                </div>
              </div>
            </div>
            <div className={styles["tenant-product-card-data-row"]}>
              <div className={styles["tenant-product-card-data-column"]}>
                <div className={styles["tenant-product-card-data-label"]}>Co-badge scheme</div>
              </div>
              <div className={styles["tenant-product-card-data-column-val"]}>
                <div className={styles["tenant-product-card-data-value"]}>{product.coBadgeScheme || "-"}</div>
              </div>
            </div>
            <div className={styles["tenant-product-card-data-row"]}>
              <div className={styles["tenant-product-card-data-column"]}>
                <div className={styles["tenant-product-card-data-label"]}>BIN</div>
              </div>
              <div className={styles["tenant-product-card-data-column-val"]}>
                <div className={styles["tenant-product-card-data-value"]}>{product.bin || "-"}</div>
              </div>
            </div>
            <div className={styles["tenant-product-card-data-row"]}>
              <div className={styles["tenant-product-card-data-column"]}>
                <div className={styles["tenant-product-card-data-label"]}>Currency</div>
              </div>
              <div className={styles["tenant-product-card-data-column-val"]}>
                <div className={styles["tenant-product-card-data-value"]}>{product.currency || "-"}</div>
              </div>
            </div>
            <div className={styles["tenant-product-card-data-row"]}>
              <div className={styles["tenant-product-card-data-column"]}>
                <div className={styles["tenant-product-card-data-label"]}>Balance</div>
              </div>
              <div className={styles["tenant-product-card-data-column-val"]}>
                <div className={styles["tenant-product-card-data-value"]}>{getBalanceOwner(product.balanceOwner)}</div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          title="Confirmation"
          open={deleteModalOpen}
          width="600px"
          onCancel={() => setDeleteModalOpen(false)}
          footer={[
            <Button key="back" onClick={() => setDeleteModalOpen(false)}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={isLoading} onClick={onDeleteDraftProduct}>
              Confirm
            </Button>,
          ]}
        >
          <Typography.Text>Are you sure you want to delete this product? This action is irreversible.</Typography.Text>
        </Modal>

        <Modal
          title="Confirmation"
          open={sandboxModalOpened}
          width="500px"
          onCancel={() => setSandboxModalOpened(false)}
          footer={[
            <Button key="back" onClick={() => setSandboxModalOpened(false)}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={isLoading} onClick={goToUATHandle}>
              Confirm
            </Button>,
          ]}
        >
          <Typography.Text>Are you sure you want to send this product to UAT?</Typography.Text>
        </Modal>

        <Modal
          title="Copy of product will be created"
          open={modalOpened}
          width="600px"
          onCancel={onCancelModal}
          footer={[
            <Button key="back" onClick={onCancelModal}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={duplicateProductLoading} onClick={modalForm.submit}>
              Confirm
            </Button>,
          ]}
        >
          <Form form={modalForm} layout="vertical" onFinish={onDuplicateProduct}>
            <Form.Item
              name="newDisplayName"
              label="Provide the name for the new product"
              rules={[{ type: "string", max: 256, message: " Cannot be empty. Max length is 256 letters" }]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </Col>
  );
};
