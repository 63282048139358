import { Form, notification, Space, Switch, Typography } from "antd";
import { useParams } from "react-router-dom";

import {
  BAL_INQ_ENABLED,
  INQ_ALLOW_DOM,
  INQ_ALLOW_ONUS,
  NIC_BAL_INQ_FEE_DOM_MAX_NR,
  NIC_BAL_INQ_FEE_DOM_VAL,
  NIC_BAL_INQ_FEE_ONUS_MAX_NR,
  NIC_BAL_INQ_FEE_OTHER_MAX_NRR,
  NIC_BAL_INQ_FEE_OTHER_ONUS_VAL,
  NIC_BAL_INQ_FEE_OTHER_VAL,
  SERVICE_UNAVAILABLE,
} from "@ni/common/constants";
import { useHydrateForm, useProductSettings } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, Tabs } from "@ni/common/ui";
import { getErrorInstance, mergeObjects, parseBooleanOrNumber } from "@ni/common/utils";

import { usePct } from "../../../../hooks";

import { CustomizedInquiryFees } from "./CustomizedInquiryFees";
import { DefaultInquiryFee } from "./DefaultInquiryFee";

export const BalanceInquiryFeesPage = () => {
  const [form] = Form.useForm<FormValues>();
  const isEnabled = Form.useWatch<boolean>(BAL_INQ_ENABLED, form);

  const { id: tenantId, productId, pctId } = useParams<{ id: string; productId: string; pctId: string }>();
  const { productCurrency } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });

  const { pct, onSavePct } = usePct({
    pctId: parseInt(pctId ?? "0", 10),
  });

  const relatedLinks = [
    {
      href: `/tenant/${tenantId}/fees-tenant-configuration`,
      label: "Fee Settings - Global",
    },
  ];

  const initialValues = useHydrateForm(
    {
      form,
      entityFields: pct.pctProductValues ?? [],
      keys: {
        strings: pct.pctProductValues
          ?.map(pctValue => {
            if (pctValue.fieldCode.includes("inq")) {
              return pctValue.fieldCode;
            }

            return null;
          })
          .filter(Boolean) as string[],
      },
    },
    [form, pct.pctProductValues],
  );

  const onFinish = async (values: FormValues): Promise<void> => {
    try {
      const data = isEnabled
        ? (mergeObjects(
            Object.entries(values).map(([key, value]) => ({ [key]: parseBooleanOrNumber(value?.toString() ?? "") })),
          ) as FormValues)
        : {
            [BAL_INQ_ENABLED]: false,
            [NIC_BAL_INQ_FEE_OTHER_VAL]: "",
            [NIC_BAL_INQ_FEE_OTHER_MAX_NRR]: "",
            [INQ_ALLOW_ONUS]: false,
            [NIC_BAL_INQ_FEE_OTHER_ONUS_VAL]: "",
            [NIC_BAL_INQ_FEE_ONUS_MAX_NR]: "",
            [INQ_ALLOW_DOM]: false,
            [NIC_BAL_INQ_FEE_DOM_VAL]: "",
            [NIC_BAL_INQ_FEE_DOM_MAX_NR]: "",
          };

      await onSavePct(data);
    } catch (error) {
      const errorInstance = getErrorInstance(error);
      notification.error({
        placement: "topRight",
        duration: 3,
        message: (
          <div>
            {errorInstance?.response?.status ?? 400} <br />
            {SERVICE_UNAVAILABLE}
          </div>
        ),
      });
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Balance Inquiry Fees"
      pageSubtitle="Balance inquiry fee is a fixed amount that can be charged to your customer in case of successful balance inquiry through ATMs or POS. You can choose to specify a default fee for all transactions, or you may specify different ones for OnUs and Domestic areas."
      submitHandler={onFinish}
      size="md"
      formSize="md"
      level="pct"
      submitLabel="Save"
      relatedLinks={relatedLinks}
    >
      <Space direction="horizontal" size={8}>
        <Form.Item name={BAL_INQ_ENABLED} initialValue={false} valuePropName="checked">
          <Switch />
        </Form.Item>
        <Typography.Text strong={true}>Enable balance inquire fee for your product</Typography.Text>
      </Space>

      {isEnabled && (
        <Tabs<"" | "defaultInquiryFee" | "customizedInquiryFees">
          pagesList={[
            {
              key: "defaultInquiryFee",
              label: <div>Default Inquiry Fee</div>,
              children: <DefaultInquiryFee productCurrency={productCurrency} isEnabled={isEnabled} form={form} />,
            },
            {
              key: "customizedInquiryFees",
              label: <div>Customized Inquiry Fees</div>,
              children: <CustomizedInquiryFees productCurrency={productCurrency} isEnabled={isEnabled} form={form} />,
            },
          ]}
          isCheckEnabled={true}
          form={form}
          excludedFieldsListFromCheck={[BAL_INQ_ENABLED]}
          initialValues={initialValues}
          discardAfterChangeTab={false}
          onSave={onFinish}
        />
      )}
    </CustomFormWrapper>
  );
};
