import { FC } from "react";
import { Dropdown } from "antd";
import { Link } from "react-router-dom";

import { MoreOutlined } from "@ant-design/icons";

import { MenuItemModel } from "./MenuItemModel";

import styles from "./style.module.scss";

interface ActionsCellProps {
  items: MenuItemModel[];
  rowData: unknown;
}

export const ActionsCell: FC<ActionsCellProps> = ({ items, rowData }) => (
  <div className={styles["actions-cell"]}>
    <Dropdown.Button
      type="text"
      trigger={["click"]}
      icon={<MoreOutlined />}
      menu={{
        items: items.map(item => ({
          label: <Link to={item.link ?? ""}>{item.label}</Link>,
          value: item.link,
          disabled: item.disabled,
          onClick: () => {
            if (typeof item.actionCallBack === "function") {
              item.actionCallBack(rowData);
            }
          },
          key: Math.random(),
          icon: item.icon,
        })),
        style: {
          width: 205,
        },
        mode: "vertical",
      }}
    />
  </div>
);
