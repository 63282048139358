import { FC, ReactNode, useMemo } from "react";
import cn from "classnames";
import { useParams } from "react-router-dom";

import { useParamsList } from "@ni/common/hooks";

import { AfterWizardEditPageTitle } from "../AfterWizardEditPageTitle";

import styles from "./styles.module.scss";

type Level = "root" | "tenant" | "product" | "pct" | "pct-loyalty";
type Size = "sm" | "md" | "lg" | "full";

export interface SettingsPageLayoutProps {
  level?: Level;
  additionalRoute?: string;
  pageTitle: string;
  pageSubtitle?: ReactNode;
  size?: Size;
  link?: string;
  linkTitle?: string;
  children: ReactNode;
}

export const SettingsPageLayout: FC<SettingsPageLayoutProps> = ({
  level = "root",
  additionalRoute = "",
  pageTitle,
  pageSubtitle,
  size = "md",
  link,
  linkTitle,
  children,
}) => {
  const {
    params: { tenantId: tenantIdQuery, productId: productIdQuery },
  } = useParamsList(["tenantId", "productId"]);

  const {
    id: tenantIdParam,
    productId: productIdParam,
    pctId: pctIdParam,
  } = useParams<{
    id: string;
    productId: string;
    pctId: string;
    programId: string;
  }>();

  const tenantId = useMemo(() => tenantIdParam ?? tenantIdQuery ?? "", [tenantIdParam, tenantIdQuery]);
  const productId = useMemo(() => productIdParam ?? productIdQuery ?? "", [productIdParam, productIdQuery]);
  const pctId = useMemo(() => pctIdParam ?? pctIdParam ?? "", [pctIdParam]);

  const generateLink = (
    level: SettingsPageLayoutProps["level"],
    tenantId: string,
    productId: string,
    additionalRoute: string,
  ) => {
    if (level === "pct-loyalty") {
      return `/tenant/${tenantId}/product/${productId}/pct/${pctId}/loyalty-programs`;
    }

    if (level === "pct") {
      return `/tenant/${tenantId}/product/${productId}/pct${additionalRoute}/`;
    }

    if (level === "product") {
      return `/tenant/${tenantId}/product/${productId}${additionalRoute}`;
    }

    if (level === "tenant") {
      return `/tenant/${tenantId}/${additionalRoute}`;
    }

    return `/${additionalRoute}`;
  };

  const generatePrevPageName = (level: SettingsPageLayoutProps["level"]) => {
    if (level === "pct-loyalty") {
      return "PCT Loyalty Programs";
    }

    if (level === "pct") {
      return "Product Settings";
    }

    if (level === "product") {
      return "Tenant Settings";
    }

    if (level === "tenant") {
      return "Tenant Settings";
    }

    return "Dashboard";
  };

  return (
    <div className={styles["layout"]}>
      <AfterWizardEditPageTitle
        title={pageTitle}
        link={link || generateLink(level, tenantId, productId, additionalRoute)}
        page={linkTitle || generatePrevPageName(level)}
      />
      {pageSubtitle && <div className={cn(styles["subtitle"], styles[size])}>{pageSubtitle}</div>}
      <div className={cn(styles["wrapper"], styles[size])}>{children}</div>
    </div>
  );
};
