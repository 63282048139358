import { FC } from "react";
import { Form, Space, Switch, Typography } from "antd";

import {
  LTY_CASHBACK_AUTO_REDEEM,
  LTY_CASHBACK_INTERVAL,
  LTY_CASHBACK_INTERVAL_RADIO_GROUP,
  LTY_EXT_AUTO_REDEEM,
  LTY_REDEEM_TYPE,
  RADIO_GROUP_LTY_REDEEM_TYPE,
} from "@ni/common/constants";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, RadioGroup, TooltipInfo } from "@ni/common/ui";
import { LoyaltyWizardResponse } from "@ni/sdk/models";

import { useLoyaltyWizardApi } from "../../hooks";

import { LTY_EXT_AUTO_REDEEM_TOOLTIP } from "./constants";

export const RedemptionSetup: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const isRedemptionTypeCashback = Form.useWatch<string>(LTY_REDEEM_TYPE, form) === "C";
  const isCashbackAutoRedemption = Form.useWatch<boolean>(LTY_CASHBACK_AUTO_REDEEM, form);
  const isExternalAutoRedemption = Form.useWatch<boolean>(LTY_EXT_AUTO_REDEEM, form);

  const { processWizardRequest } = useLoyaltyWizardApi();

  const [, setIsLoading] = useReduxState("isLoading", false);
  const [wizardResponse] = useReduxState<LoyaltyWizardResponse>("loyaltyWizard", {} as LoyaltyWizardResponse);

  useHydrateForm({
    form,
    entityFields: wizardResponse?.loyaltyProgramTemplate?.programValues ?? [],
    keys: { strings: [LTY_REDEEM_TYPE, LTY_CASHBACK_AUTO_REDEEM, LTY_CASHBACK_INTERVAL, LTY_EXT_AUTO_REDEEM] },
  });

  const onFinish = async (values: FormValues) => {
    setIsLoading(true);
    try {
      await processWizardRequest({
        pageId: wizardResponse.pageId as number,
        tenantId: wizardResponse?.tenant?.id,
        loyaltyTemplateId: wizardResponse.loyaltyProgramTemplate?.id,
        collectedValues: values as { [key: string]: string },
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Redemption Setup"
      pageSubtitle="Loyalty points redemption is the process of utilizing accumulated loyalty points to obtain rewards, benefits, or discounts offered by a loyalty program. Please define basic redemption setup for the loyalty program."
      size="sm"
      level="tenant"
      submitHandler={onFinish}
      additionalRoute="loyalty-program-templates"
    >
      <Form.Item name={LTY_REDEEM_TYPE} label="Choose redemption type" initialValue="C">
        <RadioGroup radioList={RADIO_GROUP_LTY_REDEEM_TYPE} initialValue="C" />
      </Form.Item>
      {isRedemptionTypeCashback && (
        <>
          <Space direction="horizontal">
            <Form.Item name={LTY_CASHBACK_AUTO_REDEEM} initialValue={false}>
              <Switch checked={isCashbackAutoRedemption} />
            </Form.Item>
            <Typography.Text strong={true}>Enable cashback auto-redemption</Typography.Text>
          </Space>
          {isCashbackAutoRedemption && (
            <Form.Item name={LTY_CASHBACK_INTERVAL} label="Choose cashback auto-redemption interval" initialValue="B">
              <RadioGroup radioList={LTY_CASHBACK_INTERVAL_RADIO_GROUP} initialValue="B" />
            </Form.Item>
          )}
        </>
      )}
      {!isRedemptionTypeCashback && (
        <Space>
          <Form.Item name={LTY_EXT_AUTO_REDEEM} initialValue={false}>
            <Switch checked={isExternalAutoRedemption} />
          </Form.Item>
          <Typography.Text strong={true}>
            <TooltipInfo
              label="Enable external auto-redemption"
              tooltipProps={{ title: LTY_EXT_AUTO_REDEEM_TOOLTIP }}
            />
          </Typography.Text>
        </Space>
      )}
    </CustomFormWrapper>
  );
};
