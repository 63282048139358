import { FC, useMemo } from "react";
import { Alert, Form, Space, Switch } from "antd";
import { useParams } from "react-router-dom";

import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, TooltipInfo } from "@ni/common/ui";
import { Product } from "@ni/sdk/models";

import { useInsuranceProgramApi } from "../../../../hooks";

import { tab2 } from "./constants";

export const ProductWaivingSettings: FC = () => {
  const { id: tenantId, insuranceId, productId } = useParams<{ id: string; insuranceId: string; productId: string }>();
  const [form] = Form.useForm<FormValues>();
  const { currentInsuranceProgram: currentInsurance, editInsurance, formValuesConverter } = useInsuranceProgramApi();

  const [currentProduct] = useReduxState<Product>("currentProduct", {});

  useHydrateForm({
    form,
    entityFields: currentInsurance?.values ?? [],
    keys: {
      strings: [tab2.coverageOption.code, tab2.transactorOption.code],
    },
    allowParse: true,
  });

  const onFinish = async (values: FormValues) => {
    await editInsurance({ values: formValuesConverter(values) });
  };

  const relatedLinks = useMemo(
    () => [
      {
        label: "Insurance Program Templates",
        href: `/tenant/${tenantId}/insurance-program-templates`,
      },
      ...(currentProduct.parameterTables?.flatMap(table => ({
        href: `/tenant/${tenantId}/product/${productId}/pct/${table.id}/insurance-edit/${insuranceId}/insurance-premium-settings?tab=waiving`,
        label: `${currentProduct.displayName} - ${table.displayName} PCT - ${currentInsurance?.name} - Waiving and Cancellation`,
      })) || []),
    ],
    [currentInsurance, currentProduct, tenantId, productId, insuranceId],
  );

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Premium Waiving Settings"
      pageSubtitle="Insurance premium can be waived in case customer has paid his statement cycle in full and computation base
            is zero. You can also specify to waive premium for transactor customers who paid in full, the previous
            cycle&#39;s statement balance."
      size="md"
      formSize="full"
      gap={16}
      submitLabel="Save"
      submitHandler={onFinish}
      level="product"
      linkTitle="Product Settings"
      additionalRoute="/insurance-programs"
      relatedLinks={relatedLinks}
    >
      <Space direction="horizontal">
        <Form.Item name={tab2.coverageOption.code} valuePropName="checked" initialValue={tab2.coverageOption.default}>
          <Switch />
        </Form.Item>
        <TooltipInfo
          label={tab2.coverageOption.name}
          tooltipProps={{
            title: tab2.coverageOption.tooltip,
          }}
        />
      </Space>

      <Alert
        message="Below preferences will be applied to all contracts under this product, you can override below parameter(s) value for a specific pricing control table in related screens."
        type="info"
        showIcon={true}
      />

      <Space direction="horizontal">
        <Form.Item
          name={tab2.transactorOption.code}
          valuePropName="checked"
          initialValue={tab2.transactorOption.default}
        >
          <Switch />
        </Form.Item>
        <TooltipInfo
          label={tab2.transactorOption.name}
          tooltipProps={{
            title: tab2.transactorOption.tooltip,
          }}
        />
      </Space>
    </CustomFormWrapper>
  );
};
