import { FC } from "react";
import { Form, Input, Space, Switch } from "antd";
import { parseInt } from "lodash";
import { useParams } from "react-router-dom";

import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, FormContent, RadioGroup, TooltipInfo } from "@ni/common/ui";
import { checkIfOneProductHasFieldCodeValue } from "@ni/common/utils";
import { TenantApi } from "@ni/sdk/apis";
import { ChangeTenantRequest, Tenant, TenantValue } from "@ni/sdk/models";

import {
  ABU_CLOSE_STAND_RULES,
  NIC_ABU_OPTOUT_UNEXP,
  NIC_ABU_VISA_ISS_ID,
  NIC_TOKEN_CLOSE_FOR_CLOSED,
  NIC_TOKEN_SUSPEND_UNEXP,
  RADIO_GROUP_OUTPUT_UNEXP,
  RADIO_GROUP_SUSPEND_UNEXP,
} from "./PaymentSchemasServices.constants";

export interface PaymentSchemasServicesForm {
  [NIC_TOKEN_SUSPEND_UNEXP]: string;
  [NIC_TOKEN_CLOSE_FOR_CLOSED]: boolean;
  [ABU_CLOSE_STAND_RULES]: boolean;
  [NIC_ABU_OPTOUT_UNEXP]: string;
  [NIC_ABU_VISA_ISS_ID]: string;
}

const tenantsServiceApi = new TenantApi();

export const PaymentSchemasServices: FC = () => {
  const [form] = Form.useForm<FormValues>();
  const { id } = useParams<{ id: string }>();
  const [tenant, setTenant] = useReduxState<Tenant>("tenant", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  const shouldFieldsBeVisible =
    checkIfOneProductHasFieldCodeValue(tenant, "nic-card-subtype-token-used", "true") ||
    checkIfOneProductHasFieldCodeValue(tenant, "nic-card-subtype-token-mada-used", "true");

  const shouldVisaISSBeVisible = checkIfOneProductHasFieldCodeValue(tenant, "nic-prod-ips", "Visa");

  useHydrateForm({
    form,
    entityFields: tenant.tenantValues ?? [],
    keys: {
      strings: [
        NIC_TOKEN_SUSPEND_UNEXP,
        NIC_TOKEN_CLOSE_FOR_CLOSED,
        ABU_CLOSE_STAND_RULES,
        NIC_ABU_OPTOUT_UNEXP,
        NIC_ABU_VISA_ISS_ID,
      ],
    },
  });

  const onFinish = (value: FormValues) => {
    setIsLoading(true);

    const tenantId = parseInt(id ?? "0", 10);
    const requestBody: ChangeTenantRequest = {
      tenantValues: Object.keys(value).map((val: string) => {
        return {
          fieldCode: val,
          value: value[val as keyof PaymentSchemasServicesForm],
        };
      }) as TenantValue[],
    };

    tenantsServiceApi
      .editTenant(requestBody, tenantId)
      .then(res => {
        setIsLoading(false);
        setTenant(res.data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      pageTitle="Payment Schemas Services"
      form={form}
      submitHandler={onFinish}
      level="tenant"
      formSize="md"
      submitLabel="Save"
      additionalRoute="details"
    >
      {shouldFieldsBeVisible && (
        <FormContent
          title={
            <TooltipInfo
              largeLabel="Tokenization (token lifecycle management)"
              tooltipProps={{
                title:
                  "Tokenization involves a process in which a unique token masks sensitive card details. In place of actual card details, this token is used to perform card transactions in contactless mode at Point Of Sale (POS) terminals, Quick Response (QR) code payments, and so on. With tokenization, your online payments and transactions using cards become more secure than they are at present.",
              }}
            />
          }
        >
          <Form.Item
            label="Token suspension options for expired/inactive cards:"
            name={NIC_TOKEN_SUSPEND_UNEXP}
            initialValue={RADIO_GROUP_SUSPEND_UNEXP[2].value}
          >
            <RadioGroup radioList={RADIO_GROUP_SUSPEND_UNEXP} initialValue={RADIO_GROUP_SUSPEND_UNEXP[2].value} />
          </Form.Item>

          <Space direction="horizontal">
            <Form.Item valuePropName="checked" name={NIC_TOKEN_CLOSE_FOR_CLOSED} initialValue={true}>
              <Switch />
            </Form.Item>
            Close the tokens if the account or card is closed
          </Space>
        </FormContent>
      )}
      <FormContent
        title={
          <TooltipInfo
            largeLabel="ABU (Automatic Billing Updater) functionality"
            tooltipProps={{
              title:
                "Service of the international payment system that automatically updates card data (PAN, Expire Date) when replacing or reissuing a card stored in online stores or services (recurrent transaction)",
            }}
          />
        }
      >
        <Space direction="horizontal">
          <Form.Item valuePropName="checked" name={ABU_CLOSE_STAND_RULES} initialValue={true}>
            <Switch />
          </Form.Item>
          <TooltipInfo
            largeLabel="Apply standard set of rules for ABU messaging about card Closure/Replacement"
            tooltipProps={{
              title: (
                <>
                  <div>Notify ABU about:</div>
                  <ul>
                    <li>
                      ‘Closed account’ in case of closed, purged and charged-off accounts or cancelled and purged cards
                    </li>
                    <li>‘Replacement Card’ in case of replaced or transferred cards. </li>
                  </ul>
                  <div>
                    You can switch it off and configure customized rules with the help of Block Codes functionality.
                  </div>
                </>
              ),
            }}
          />
        </Space>
        <Form.Item
          label="ABU opt-out options for expired/inactive cards:"
          name={NIC_ABU_OPTOUT_UNEXP}
          initialValue={RADIO_GROUP_OUTPUT_UNEXP[2].value}
        >
          <RadioGroup radioList={RADIO_GROUP_OUTPUT_UNEXP} initialValue={RADIO_GROUP_OUTPUT_UNEXP[2].value} />
        </Form.Item>
        {shouldVisaISSBeVisible && (
          <Form.Item
            name={NIC_ABU_VISA_ISS_ID}
            label={
              <TooltipInfo
                largeLabel="ABU Visa Issuer identifier (Visa Segment ID)"
                tooltipProps={{
                  title: "The value of the identifier given by Visa",
                }}
              />
            }
          >
            <Input />
          </Form.Item>
        )}
      </FormContent>
    </CustomFormWrapper>
  );
};
