import { NavbarItem } from "@ni/common/types";

export const EDIT_LYL_TEMPL_NAV_ITEMS: NavbarItem[] = [
  {
    name: "Transaction Eligibility",
    route: "transaction-eligibility",
  },
  {
    name: "Point Accrual Setup",
    route: "point-accrual-setup",
  },
  {
    name: "Point Redemption Setup",
    route: "redemption-setup",
  },
  {
    name: "Point Expiry",
    route: "point-expiry",
  },
  {
    name: "Applying To Products",
    route: "applying-to-products",
  },
];
