import React, { FC } from "react";

import styles from "./styles.module.scss";

interface FileCardListWrapperProps {
  children?: JSX.Element;
}

export const FileCardListWrapper: FC<FileCardListWrapperProps> = props => {
  const { children } = props;

  return (
    <div className={styles["file-card-list-wrapper-page"]}>
      <div className={styles["file-card-list-wrapper-header"]}>{children}</div>
    </div>
  );
};

FileCardListWrapper.defaultProps = {
  children: undefined,
};
