import { FC, useCallback, useEffect, useState } from "react";
import { Button, ConfigProvider, Form } from "antd";
import Modal from "antd/es/modal/Modal";
import Search from "antd/lib/transfer/search";

import { useReduxState } from "@ni/common/hooks";
import { PageHeading } from "@ni/common/ui";
import { FeatureApi } from "@ni/sdk/apis";
import { ChangeFeatureRequest, Feature } from "@ni/sdk/models";

import { FeatureDrawer } from "./FeatureDrawer";
import { FeatureElement } from "./FeatureElement";

import styles from "./styles.module.scss";

const featureApi = new FeatureApi();

export const DictionariesFeatures: FC = () => {
  const [isLoading, setIsLoading] = useReduxState<boolean>("isLoading");
  const [features, setFeatures] = useReduxState<Feature[]>("features");
  const [isDrawerOpened, setIsDrawerOpened] = useState<boolean>(false);
  const [selectedFeature, setSelectedFeature] = useState<Feature>();
  const [deleteModalOpened, setDeleteModalOpened] = useState<boolean>();
  const [featureToDelete, setFeatureToDelete] = useState<Feature>();

  const loadFeatureList = useCallback(() => {
    setIsLoading(true);
    setFeatures([]);
    featureApi
      .getFeatures({ pageLimits: { number: 0, size: 1000 } })
      .then(response => {
        setFeatures(response.data.content as Feature[]);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [setFeatures, setIsLoading]);

  useEffect(() => {
    loadFeatureList();
  }, [loadFeatureList]);

  const openDrawer = (feature?: Feature) => {
    if (feature) {
      setSelectedFeature(feature);
    }

    setIsDrawerOpened(true);
  };

  const closeDrawer = (update: boolean, changeFeatureRequest?: ChangeFeatureRequest) => {
    if (update && changeFeatureRequest) {
      setIsLoading(true);
      if (selectedFeature) {
        featureApi
          .updateFeature(changeFeatureRequest, Number(selectedFeature?.id))
          .then(() => {
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
          });
      } else {
        featureApi
          .createFeature(changeFeatureRequest)
          .then(() => {
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
          });
      }
      loadFeatureList();
    }
    setIsDrawerOpened(false);
    setSelectedFeature(undefined);
  };

  const onOpenDeleteModal = (feature: Feature) => {
    setFeatureToDelete(feature);
    setDeleteModalOpened(true);
  };

  const onCloseDeleteModal = (deleteConfirmed?: boolean) => {
    if (deleteConfirmed) {
      setIsLoading(true);
      featureApi
        .deleteFeature(Number(featureToDelete?.id))
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        })
        .finally(() => loadFeatureList());
    }

    setDeleteModalOpened(false);
  };

  const onToggleFeature = (state: boolean, feature: Feature) => {
    featureApi
      .updateFeature({ state: state ? "ENABLED" : "DISABLED" }, feature.id)
      .then(data => {
        setFeatures(
          features.map(item => {
            return item.id === data.data.id ? data.data : item;
          }),
        );
      })
      .catch(() => {});
  };

  const extra = (
    <div className={styles["extra-container"]} key={Math.random()}>
      <div className={styles["top-section"]}>
        <Button
          disabled={isLoading}
          className={styles["new-button"]}
          type="primary"
          size="middle"
          onClick={() => openDrawer()}
        >
          New feature
        </Button>
        <Form>
          <Form.Item name="searchValue">
            <Search placeholder="Search" />
          </Form.Item>
        </Form>
      </div>
    </div>
  );

  return (
    <ConfigProvider componentSize="middle">
      <div className={styles["container"]}>
        <PageHeading text="Features" extraElements={[extra]} />

        <div className={styles["features-container"]}>
          {features
            ? features.map(feature => (
                <FeatureElement
                  key={feature.id}
                  feature={feature}
                  onDeleteFeature={onOpenDeleteModal}
                  onOpenDrawer={openDrawer}
                  onToggleFeature={onToggleFeature}
                />
              ))
            : null}
        </div>
      </div>

      <Modal
        title="Do you really want to delete this feature?"
        open={deleteModalOpened}
        width="800px"
        onCancel={() => onCloseDeleteModal(false)}
        footer={[
          <div key={Math.random()}>
            <Button disabled={isLoading} onClick={() => onCloseDeleteModal(false)}>
              Cancel
            </Button>
            <Button loading={isLoading} type="primary" onClick={() => onCloseDeleteModal(true)}>
              Confirm
            </Button>
          </div>,
        ]}
      />

      {isDrawerOpened && (
        <FeatureDrawer closeDrawer={closeDrawer} selectedFeature={selectedFeature} isOpen={isDrawerOpened} />
      )}
    </ConfigProvider>
  );
};
