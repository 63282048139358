import { FC } from "react";
import { Collapse } from "antd";
import cn from "classnames";

import { RightOutlined } from "@ant-design/icons";
import { useReduxState } from "@ni/common/hooks";
import { ListPanel, SwitchItem } from "@ni/common/ui";
import { Event, EventSubgroup, Sms, Tenant } from "@ni/sdk/models";

import { SmsPanelHeader } from "../SmsPanelHeader";

import styles from "./styles.module.scss";

const { Panel } = Collapse;

interface CardViewProps {
  items?: EventSubgroup[];
  onEditClick: (event: Event, _groupId: number, smsDetails: Sms) => void;
  smsList?: Sms[];
  onSwitchClick: (event: Event, smsDetails: Sms) => void;
}

export const EventSubgroupsList: FC<CardViewProps> = props => {
  const { items, onEditClick, onSwitchClick, smsList } = props;
  const [tenant] = useReduxState<Tenant>("tenant", {});
  const eventsList = items ? [...items] : [];

  const getEventName = (item: EventSubgroup, eventName: string) => {
    if (item.name?.startsWith("Block Code")) {
      const bcCode = eventName.replace(/.*'(.)'.*/, "$1");
      const bcName = tenant.blockCodes?.find(bc => {
        return bc.code === bcCode && bc.fieldCode === "nic-bc-name";
      });
      return eventName.replace(/\(.*?\)/, `(${bcName?.value ?? ""})`);
    }
    return eventName;
  };

  return (
    <Collapse
      bordered={false}
      className={cn(styles["collapsible-list"], styles["collapsible-list--normal"])}
      // eslint-disable-next-line react/no-unstable-nested-components
      expandIcon={({ isActive }) => <RightOutlined style={{ color: "#0060A8" }} rotate={isActive ? 90 : 0} />}
    >
      {eventsList
        ?.sort((subgroup1, subgroup2) => {
          return subgroup1.order - subgroup2.order;
        })
        ?.map(item => (
          <Panel
            header={item?.name}
            extra={
              <SmsPanelHeader
                smsEvent={item.events?.find(smsEvent => !!smsEvent.isGeneral)}
                isGeneral={!!item.events?.find(smsEvent => !!smsEvent.isGeneral)}
                smsDetails={smsList?.find(
                  smsDetails =>
                    smsDetails?.smsEvent?.id === item.events?.find(smsEvent => !!smsEvent.isGeneral)?.id || 0,
                )}
                groupId={item.eventGroupId}
                key={item.id}
                onEditClick={onEditClick}
                onSwitchClick={onSwitchClick}
              />
            }
            collapsible="header"
            key={item.id}
          >
            <ListPanel
              title="Individual templates"
              titleTooltip="Turning the switch on allows setting the text template for each SMS event individually."
            >
              {item.events?.map(
                smsEvent =>
                  !smsEvent.isGeneral && (
                    <SwitchItem
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                      value={smsList?.find(smsDetails => smsDetails?.smsEvent?.id === smsEvent.id)?.isActive || false}
                      smsEvent={smsEvent}
                      key={smsEvent.id}
                      smsDetails={smsList?.find(smsDetails => smsDetails?.smsEvent?.id === smsEvent.id)}
                      title={getEventName(item, smsEvent.eventName || "")}
                      groupId={item.eventGroupId}
                      onEditClick={onEditClick}
                      onSwitchClick={onSwitchClick}
                      layout="listItem"
                    />
                  ),
              )}
            </ListPanel>
          </Panel>
        ))}
    </Collapse>
  );
};

EventSubgroupsList.defaultProps = {
  items: [],
  smsList: [],
};
