import { FC, useMemo } from "react";
import { Form, Input, Select, Space, Switch, Typography } from "antd";
import { useParams } from "react-router-dom";

import { QuestionCircleFilled } from "@ant-design/icons";
import { PRODUCT_CURRENCY } from "@ni/common/constants";
import { useHydrateForm, useProductSettings } from "@ni/common/hooks";
import { paymentMethodKeyToValueMapper, paymentMethodsOptions, productTypeOptionsWithKey } from "@ni/common/mocks";
import { FormValues } from "@ni/common/types";
import { CardView, CustomFormWrapper, DictionarySelect, RadioGroup } from "@ni/common/ui";
import { filterByDisplayValue, getFormValueFromProductValues } from "@ni/common/utils";

import { creditInterestType, creditProfitStructure } from "./constants";

import styles from "./styles.module.scss";

interface NicCobadgeItem {
  value: string;
  key: string;
}

const stringsKeys = [
  "nic-prod-ips",
  "nic-prod-core-name",
  "nic-ccy",
  "product-type",
  "nic-card-subtype-pan-bin",
  "use-test-bin",
  "nic-card-subtype-pan-full-min",
  "nic-card-subtype-pan-full-max",
  "is-co-badged-product",
  "nic-mada-use",
  "co-badged-name",
  creditInterestType.code,
  creditProfitStructure.code,
];

export const ProductDetails: FC = () => {
  const paymentMethodsMapper = paymentMethodKeyToValueMapper;
  const nicCobadgeList: NicCobadgeItem[] = [{ value: "MADA", key: "MADA" }];

  const { productId } = useParams<{ id: string; productId: string }>();

  const [form] = Form.useForm<FormValues>();
  const { currentProduct, onUpdateProduct } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });

  const isIslamicCredit = Form.useWatch(creditInterestType.code, form) === "Islamic";

  useHydrateForm(
    {
      form,
      entityFields: currentProduct.productValues ?? [],
      keys: {
        strings: stringsKeys,
      },
    },
    [currentProduct.productValues],
  );

  const { productType, currencyLabel, productCurrency } = useMemo(() => {
    return {
      productType: getFormValueFromProductValues(currentProduct.productValues, "product-type"),
      currencyLabel: getFormValueFromProductValues(currentProduct.productValues, "curr-setup"),
      productCurrency: getFormValueFromProductValues(currentProduct.productValues, "nic-ccy"),
    };
  }, [currentProduct.productValues]);

  const setDisplayNameValue = () => {
    const values: FormValues = form.getFieldsValue();
    values["nic-mada-use"] = String(values["co-badged-name"] === "MADA");
    const coBadgedName = values["co-badged-name"] ? ` - ${values["co-badged-name"] as string}` : "";

    form.setFieldsValue({
      displayName: `${paymentMethodsMapper[values["nic-prod-ips"] as string] || ""}${
        values["is-co-badged-product"] ? `${coBadgedName}` : ""
      } ${(values["nic-prod-core-name"] as string) || ""} ${productCurrency || ""}${
        currencyLabel === "mult" ? " Multicurrency" : ""
      }`,
    });
  };

  const clearMadaSelect = () => {
    form.setFieldsValue({
      "nic-mada-use": "",
      "co-badged-name": "",
    });

    setDisplayNameValue();
  };

  const onSubmit = (values: FormValues) => {
    values["nic-mada-use"] = !!values["is-co-badged-product"] && values["co-badged-name"] === "MADA";
    void onUpdateProduct(values);
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Product Details"
      submitHandler={onSubmit}
      onValuesChange={form.validateFields}
      size="md"
      formSize="md"
      submitLabel="Save"
      gap={24}
    >
      <div className={styles["product-details-card-image"]}>
        <div className={styles["product-details-card-image-title"]}>Product image</div>
        <CardView
          cardSize="large"
          cardImage={currentProduct.cardImage}
          prodIps={(getFormValueFromProductValues(currentProduct.productValues, "nic-prod-ips") as string) || "-"}
        />
      </div>

      <Form.Item name="nic-prod-ips" label="Payment scheme">
        <Select onChange={setDisplayNameValue}>
          {paymentMethodsOptions.map(paymentMethod => (
            <Select.Option key={paymentMethod.key} value={paymentMethod.key}>
              {paymentMethod.value}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Space direction="horizontal">
        <Form.Item name="is-co-badged-product" valuePropName="checked">
          <Switch onChange={clearMadaSelect} disabled={true} />
        </Form.Item>
        <Typography.Text>Co-badged product</Typography.Text>
        <div className="alert-div m-l-16">Coming soon</div>
      </Space>

      <Form.Item noStyle={true} dependencies={["is-co-badged-product"]}>
        {() => (
          <Form.Item
            name="co-badged-name"
            label="Co-badged scheme"
            rules={[
              {
                required: form.getFieldValue("is-co-badged-product") as boolean,
                message: "Co-badged scheme is required.",
              },
            ]}
          >
            <Select onChange={setDisplayNameValue} disabled={!form.getFieldValue("is-co-badged-product")}>
              {nicCobadgeList.map(nicCobadge => (
                <Select.Option key={nicCobadge.key} value={nicCobadge.key}>
                  {nicCobadge.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </Form.Item>

      <Form.Item
        name="nic-prod-core-name"
        label="Product name"
        tooltip={{
          title:
            "This parameter specifies the name of the card product. No need to repeat Payment schema name in this " +
            "field. e.g.: Platinum, Gold, Titanium",
          icon: <QuestionCircleFilled />,
        }}
        rules={[
          { required: true, message: "Product core name is required." },
          { max: 255, message: "Product core name must have maximum 255 characters." },
          {
            pattern: /^[^\t\r{}]*$/,
            message: "Product Name contains not permitted characters as: “TAB”, `{', '}` ”.",
          },
        ]}
      >
        <Input placeholder="Product name" onChange={setDisplayNameValue} />
      </Form.Item>

      {getFormValueFromProductValues(currentProduct.productValues, "balance-owner") !== "CMS" && (
        <Form.Item name="nic-ccy" label="Product currency">
          <DictionarySelect
            placeholder="Product currency"
            filterOption={filterByDisplayValue}
            onChange={setDisplayNameValue}
            code={PRODUCT_CURRENCY}
            showSearch={true}
          />
        </Form.Item>
      )}

      <Form.Item
        name="product-type"
        label={
          (getFormValueFromProductValues(currentProduct.productValues, "balance-owner") as string) === "CMS"
            ? "Product group"
            : "Product group for reports"
        }
        tooltip={
          (getFormValueFromProductValues(currentProduct.productValues, "balance-owner") as string) === "CMS"
            ? {
                title:
                  "Product group parameter defines category of product for displaying in reports it could be different from product type.",
                icon: <QuestionCircleFilled />,
              }
            : null
        }
      >
        <Select
          disabled={(getFormValueFromProductValues(currentProduct.productValues, "balance-owner") as string) === "CMS"}
        >
          {productTypeOptionsWithKey.map(productType => (
            <Select.Option key={productType.key} value={productType.value}>
              {productType.value}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      {productType === "Credit" && (
        <>
          <Form.Item
            name={creditInterestType.code}
            label={creditInterestType.label}
            initialValue={creditInterestType.default}
          >
            <RadioGroup radioList={creditInterestType.options} gap={8} disabled={true} />
          </Form.Item>

          {isIslamicCredit && (
            <Form.Item
              name={creditProfitStructure.code}
              label={creditProfitStructure.label}
              initialValue={creditProfitStructure.default}
            >
              <RadioGroup radioList={creditProfitStructure.options} gap={8} disabled={true} />
            </Form.Item>
          )}
        </>
      )}

      <Form.Item name="displayName" label="Product displayed name" initialValue={currentProduct.displayName}>
        <Input placeholder="Product displayed name" disabled={true} />
      </Form.Item>
    </CustomFormWrapper>
  );
};
