import { FC, useEffect } from "react";
import { Col, Form, Input, InputNumber, Row, Switch } from "antd";
import { FormInstance } from "antd/lib/form/hooks/useForm";
import NumberFormat from "react-number-format";

import { FormValues } from "@ni/common/types";
import { TooltipInfo } from "@ni/common/ui";
import {
  CardBillingFormNames,
  CardBillingSwitchHeaders,
  checkboxFieldsPage35,
  checkHeader,
  headerSwitchHandler,
  switchHeader35,
} from "@ni/tenant-portal/core";

import { thousandSeparatorFormatter } from "../../../../utils";
import { prepareFieldsToRender } from "../../../../utils/prepareFieldsToRender";

import styles from "../../styles.module.scss";

interface PctCardBillingProps {
  currency: string;
  form: FormInstance<FormValues>;
}

export const PctCardBilling: FC<PctCardBillingProps> = ({ currency, form }) => {
  useEffect(() => {
    Object.keys(checkboxFieldsPage35).forEach(key => {
      form.setFieldsValue({
        [key]: false,
      });
    });

    prepareFieldsToRender(checkboxFieldsPage35, (fieldName: string, key?: string) => {
      if (form.getFieldValue(fieldName)) {
        form.setFieldsValue({
          [key || ""]: true,
        });
      }
    });

    checkHeader(CardBillingSwitchHeaders.allCashAtmEnable, switchHeader35, form);
    checkHeader(CardBillingSwitchHeaders.allRetailEnable, switchHeader35, form);
  }, [form]);

  return (
    <>
      <div className="limits-block">
        <div className="limits-block-header">
          <Form.Item valuePropName="checked" name={CardBillingSwitchHeaders.allCashAtmEnable} className="header-switch">
            <Switch
              onClick={value =>
                headerSwitchHandler(CardBillingSwitchHeaders.allCashAtmEnable, switchHeader35, value, form)
              }
            />
          </Form.Item>
          <div className="col-switch-desc">
            <TooltipInfo label="Cash" tooltipProps={{ title: "Turns off all cash ATM limits." }} />
          </div>
        </div>
        <div className="limits-block-body">
          <Row>
            <Col span={6} />
            <Col span={6} className="col-header">
              <TooltipInfo
                label="Max number"
                tooltipProps={{
                  title:
                    "This counter restricts maximal number of transactions per period. Leave it empty, if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                }}
              />
            </Col>
            <Col span={6} className="col-header">
              <TooltipInfo
                label="Max amount"
                tooltipProps={{
                  title:
                    "This counter restricts maximal amount of transactions per period. Leave it empty if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                }}
              />
            </Col>
            <Col span={6} className="col-header">
              <TooltipInfo
                label="Max single amount"
                tooltipProps={{
                  title:
                    "This counter restricts maximal amount of single transaction. Leave it empty if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item valuePropName="checked" name={CardBillingFormNames.cashAtmEnable} className="col-switch" />
              <div className="col-switch-desc">
                <TooltipInfo
                  label="ATM"
                  tooltipProps={{
                    title: "This limiter counts all ATM transactions. Turn it off, if restrictions are not intended.",
                  }}
                />
              </div>
            </Col>
            <Col span={6}>
              <Form.Item dependencies={[CardBillingFormNames.cashAtmEnable]} shouldUpdate={true}>
                {() => {
                  return (
                    <Form.Item name={CardBillingFormNames.cashAtmMaxNr}>
                      <InputNumber
                        className={styles["pct-number-input"]}
                        disabled={!form.getFieldValue(CardBillingFormNames.cashAtmEnable)}
                        formatter={thousandSeparatorFormatter}
                        min={0}
                        stringMode={true}
                        precision={0}
                        step={1}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item dependencies={[CardBillingFormNames.cashAtmEnable]} shouldUpdate={true}>
                {() => {
                  return (
                    <Form.Item name={CardBillingFormNames.cashAtmMaxAm}>
                      <NumberFormat
                        customInput={Input}
                        addonAfter={currency}
                        allowNegative={false}
                        disabled={!form.getFieldValue(CardBillingFormNames.cashAtmEnable)}
                        thousandSeparator=" "
                        decimalScale={0}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item dependencies={[CardBillingFormNames.cashAtmEnable]} shouldUpdate={true}>
                {() => {
                  return (
                    <Form.Item name={CardBillingFormNames.cashAtmMaxSingle}>
                      <NumberFormat
                        customInput={Input}
                        addonAfter={currency}
                        allowNegative={false}
                        disabled={!form.getFieldValue(CardBillingFormNames.cashAtmEnable)}
                        thousandSeparator=" "
                        decimalScale={0}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>
      <div className="limits-block">
        <div className="limits-block-header">
          <Form.Item valuePropName="checked" name={CardBillingSwitchHeaders.allRetailEnable} className="header-switch">
            <Switch
              onClick={value =>
                headerSwitchHandler(CardBillingSwitchHeaders.allRetailEnable, switchHeader35, value, form)
              }
            />
          </Form.Item>
          <div className="col-switch-desc">
            <TooltipInfo label="Retail" tooltipProps={{ title: "Turns off all retail transactions." }} />
          </div>
        </div>
        <div className="limits-block-body">
          <Row>
            <Col span={6} />
            <Col span={6} className="col-header">
              <TooltipInfo
                label="Max number"
                tooltipProps={{
                  title:
                    "This counter restricts maximal number of transactions per period. Leave it empty, if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                }}
              />
            </Col>
            <Col span={6} className="col-header">
              <TooltipInfo
                label="Max amount"
                tooltipProps={{
                  title:
                    "This counter restricts maximal amount of transactions per period. Leave it empty if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                }}
              />
            </Col>
            <Col span={6} className="col-header">
              <TooltipInfo
                label="Max single amount"
                tooltipProps={{
                  title:
                    "This counter restricts maximal amount of single transaction. Leave it empty if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item valuePropName="checked" name={CardBillingFormNames.retailEnable} className="col-switch" />
              <div className="col-switch-desc">
                <TooltipInfo
                  label="All"
                  tooltipProps={{
                    title:
                      "This limiter counts all retail transactions. Turn it off, if restrictions are not intended.",
                  }}
                />
              </div>
            </Col>
            <Col span={6}>
              <Form.Item dependencies={[CardBillingFormNames.retailEnable]} shouldUpdate={true}>
                {() => {
                  return (
                    <Form.Item name={CardBillingFormNames.retailMaxNr}>
                      <InputNumber
                        className={styles["pct-number-input"]}
                        disabled={!form.getFieldValue(CardBillingFormNames.retailEnable)}
                        formatter={thousandSeparatorFormatter}
                        min={0}
                        stringMode={true}
                        precision={0}
                        step={1}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item dependencies={[CardBillingFormNames.retailEnable]} shouldUpdate={true}>
                {() => {
                  return (
                    <Form.Item name={CardBillingFormNames.retailMaxAm}>
                      <NumberFormat
                        customInput={Input}
                        addonAfter={currency}
                        allowNegative={false}
                        disabled={!form.getFieldValue(CardBillingFormNames.retailEnable)}
                        thousandSeparator=" "
                        decimalScale={0}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item dependencies={[CardBillingFormNames.retailEnable]} shouldUpdate={true}>
                {() => {
                  return (
                    <Form.Item name={CardBillingFormNames.retailMaxSingle}>
                      <NumberFormat
                        customInput={Input}
                        addonAfter={currency}
                        allowNegative={false}
                        disabled={!form.getFieldValue(CardBillingFormNames.retailEnable)}
                        thousandSeparator=" "
                        decimalScale={0}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
