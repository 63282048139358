import { FC, ReactNode, useCallback } from "react";
import { Tooltip, TooltipProps } from "antd";

import { QuestionCircleFilled } from "@ant-design/icons";

import { FormItemLabel } from "../Labels";

import styles from "./style.module.scss";

interface TooltipInfoProps {
  label?: string;
  code?: string;
  largeLabel?: string;
  tooltipProps: TooltipProps;
  children?: ReactNode;
}

export const TooltipInfo: FC<TooltipInfoProps> = ({ label, code, largeLabel, tooltipProps, children }) => {
  const largeLabelWithoutLastWord = largeLabel && `${largeLabel.split(" ").slice(0, -1).join(" ")} `;
  const largeLabelLastWord = largeLabel && ` ${largeLabel.split(" ").slice(-1)[0]}`;

  const getTooltipChildren = useCallback(() => {
    if (tooltipProps?.title) {
      return children ? <div className={styles["tooltip-icon-wrapper"]}> {children}</div> : <QuestionCircleFilled />;
    }
    return null;
  }, [children, tooltipProps?.title]);

  const tooltipContent = Object.keys(tooltipProps).length > 0 && (
    <Tooltip {...tooltipProps}>{getTooltipChildren()}</Tooltip>
  );

  return (
    <div className={styles[largeLabel ? "large-label-tooltip-text-wrapper" : "tooltip-text-wrapper"]}>
      {label && (
        <div title={label} className={styles["tooltip-text"]}>
          {code ? <FormItemLabel label={label} code={code} /> : label}
        </div>
      )}
      {!largeLabel && tooltipContent}
      {largeLabel && (
        <div title={largeLabel} className={styles["tooltip-text"]}>
          {largeLabelWithoutLastWord}
          <div className={styles["tooltip-text-icon-text-wrapper"]}>
            {largeLabelLastWord}
            {tooltipContent}
          </div>
        </div>
      )}
    </div>
  );
};
