import { FC } from "react";
import { Space, Typography } from "antd";
import _ from "lodash";

import styles from "./styles.module.scss";

interface SimpleCardProps {
  contentList: { title?: string; description?: string }[];
}

export const SimpleCardList: FC<SimpleCardProps> = ({ contentList }) => {
  return (
    <Space direction="horizontal" size={40} wrap={true}>
      {contentList?.map(({ title = "_", description = "_" }) => (
        <div key={title + description + _.random(10)} className={styles["wizard-success-details"]}>
          <Typography.Title level={3}>{title}</Typography.Title>
          <Typography.Text>{description}</Typography.Text>
        </div>
      ))}
    </Space>
  );
};
