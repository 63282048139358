import { FC, useEffect, useMemo } from "react";
import { Col, Form, Input, Row, Switch } from "antd";
import NumberFormat from "react-number-format";

import {
  B_PAYM_A_TOTAL_ENABLED,
  D_PAYM_A_TOTAL_ENABLED,
  D365_PAYM_A_TOTAL_ENABLED,
  E_PAYM_A_TOTAL_ENABLED,
  NIC_B_PAYM_A_TOTAL_AM,
  NIC_B_PAYM_A_TOTAL_NR,
  NIC_B_PAYM_A_TOTAL_SINGLE,
  NIC_D_PAYM_A_TOTAL_AM,
  NIC_D_PAYM_A_TOTAL_NR,
  NIC_D_PAYM_A_TOTAL_SINGLE,
  NIC_D365_PAYM_A_TOTAL_AM,
  NIC_D365_PAYM_A_TOTAL_NR,
  NIC_D365_PAYM_A_TOTAL_SINGLE,
  NIC_E_PAYM_A_TOTAL_AM,
  NIC_E_PAYM_A_TOTAL_NR,
  NIC_E_PAYM_A_TOTAL_SINGLE,
  PAYM_A_TOTAL_ENABLED,
  PAYM_LIM_CONTR_LEVEL,
  PAYM_LIM_PERIODS,
  TOTAL_PAYMENTS_ENABLED,
} from "@ni/common/constants";
import { useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, FormItemLabel, TooltipInfo } from "@ni/common/ui";
import { getFormValueFromProductValues, getSwitchesCount } from "@ni/common/utils";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { ProductValue, TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";
import { checkHeader, headerSwitchHandler } from "@ni/tenant-portal/core";

const wizardServicesApi = new ProductAndTenantWizardApi();

interface Record {
  [key: string]: string;
}

export const TopupAccounLimitsPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const [, setIsLoading] = useReduxState<boolean>("isLoading");
  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});

  const currency = (getFormValueFromProductValues(wizardResponse.product?.productValues, "nic-ccy") as string) || "";

  const isShowAccountLimits = useMemo(() => {
    return (
      (wizardResponse.product?.parameterTables &&
        wizardResponse.product?.parameterTables[0]?.pctProductValues?.find(
          productValue => productValue.fieldCode === PAYM_LIM_CONTR_LEVEL,
        )?.value === "A") ||
      (wizardResponse.product?.parameterTables &&
        wizardResponse.product?.parameterTables[0]?.pctProductValues?.find(
          productValue => productValue.fieldCode === PAYM_LIM_CONTR_LEVEL,
        )?.value === "B")
    );
  }, [wizardResponse.product?.parameterTables]);

  const isShowPerDay = useMemo(() => {
    return (
      isShowAccountLimits &&
      wizardResponse.product?.parameterTables &&
      wizardResponse.product?.parameterTables[0]?.pctProductValues
        ?.find(productValue => productValue.fieldCode === PAYM_LIM_PERIODS)
        ?.value?.includes("D")
    );
  }, [isShowAccountLimits, wizardResponse.product?.parameterTables]);

  const isShowPerBilling = useMemo(() => {
    return (
      isShowAccountLimits &&
      wizardResponse.product?.parameterTables &&
      wizardResponse.product?.parameterTables[0]?.pctProductValues
        ?.find(productValue => productValue.fieldCode === PAYM_LIM_PERIODS)
        ?.value?.includes("B")
    );
  }, [isShowAccountLimits, wizardResponse.product?.parameterTables]);

  const isShowPer365Days = useMemo(() => {
    return (
      isShowAccountLimits &&
      wizardResponse.product?.parameterTables &&
      wizardResponse.product?.parameterTables[0]?.pctProductValues
        ?.find(productValue => productValue.fieldCode === PAYM_LIM_PERIODS)
        ?.value?.includes("Y")
    );
  }, [isShowAccountLimits, wizardResponse.product?.parameterTables]);

  const isShowForever = useMemo(() => {
    return (
      isShowAccountLimits &&
      wizardResponse.product?.parameterTables &&
      wizardResponse.product?.parameterTables[0]?.pctProductValues
        ?.find(productValue => productValue.fieldCode === PAYM_LIM_PERIODS)
        ?.value?.includes("F")
    );
  }, [isShowAccountLimits, wizardResponse.product?.parameterTables]);

  const switchShownAccount = {
    perDay: isShowPerDay,
    perBilling: isShowPerBilling,
    per365: isShowPer365Days,
    forever: isShowForever,
  };

  const accountSwitchesNotOne = getSwitchesCount(switchShownAccount) !== 1;

  useEffect(() => {
    const formDisabledFn = (field: string, number: string | number) =>
      formDisabled
        ? (getFormValueFromProductValues(
            wizardResponse.product?.parameterTables?.[0].pctProductValues as ProductValue[],
            field,
            "string",
          ) as string) || ""
        : number;

    form.setFieldsValue({
      [D_PAYM_A_TOTAL_ENABLED]: formDisabled
        ? getFormValueFromProductValues(
            wizardResponse.product?.parameterTables?.[0].pctProductValues as ProductValue[],
            D_PAYM_A_TOTAL_ENABLED,
            "boolean",
          )
        : true,
      [NIC_D_PAYM_A_TOTAL_AM]: formDisabledFn(NIC_D_PAYM_A_TOTAL_AM, "500000"),
      [NIC_D_PAYM_A_TOTAL_NR]: formDisabledFn(NIC_D_PAYM_A_TOTAL_NR, "20"),
      [NIC_D_PAYM_A_TOTAL_SINGLE]: formDisabledFn(NIC_D_PAYM_A_TOTAL_SINGLE, "500000"),

      [B_PAYM_A_TOTAL_ENABLED]: formDisabled
        ? getFormValueFromProductValues(
            wizardResponse.product?.parameterTables?.[0].pctProductValues as ProductValue[],
            B_PAYM_A_TOTAL_ENABLED,
            "boolean",
          )
        : true,
      [NIC_B_PAYM_A_TOTAL_AM]: formDisabledFn(NIC_B_PAYM_A_TOTAL_AM, "500000"),
      [NIC_B_PAYM_A_TOTAL_NR]: formDisabledFn(NIC_B_PAYM_A_TOTAL_NR, "20"),
      [NIC_B_PAYM_A_TOTAL_SINGLE]: formDisabledFn(NIC_B_PAYM_A_TOTAL_SINGLE, "500000"),

      [D365_PAYM_A_TOTAL_ENABLED]: formDisabled
        ? getFormValueFromProductValues(
            wizardResponse.product?.parameterTables?.[0].pctProductValues as ProductValue[],
            D365_PAYM_A_TOTAL_ENABLED,
            "boolean",
          )
        : true,
      [NIC_D365_PAYM_A_TOTAL_AM]: formDisabledFn(NIC_D365_PAYM_A_TOTAL_AM, "500000"),
      [NIC_D365_PAYM_A_TOTAL_NR]: formDisabledFn(NIC_D365_PAYM_A_TOTAL_NR, "20"),
      [NIC_D365_PAYM_A_TOTAL_SINGLE]: formDisabledFn(NIC_D365_PAYM_A_TOTAL_SINGLE, "500000"),

      [E_PAYM_A_TOTAL_ENABLED]: formDisabled
        ? getFormValueFromProductValues(
            wizardResponse.product?.parameterTables?.[0].pctProductValues as ProductValue[],
            E_PAYM_A_TOTAL_ENABLED,
            "boolean",
          )
        : true,
      [NIC_E_PAYM_A_TOTAL_AM]: formDisabledFn(NIC_E_PAYM_A_TOTAL_AM, "500000"),
      [NIC_E_PAYM_A_TOTAL_NR]: formDisabledFn(NIC_E_PAYM_A_TOTAL_NR, "20"),
      [NIC_E_PAYM_A_TOTAL_SINGLE]: formDisabledFn(NIC_E_PAYM_A_TOTAL_SINGLE, "500000"),
    });

    checkHeader(PAYM_A_TOTAL_ENABLED, TOTAL_PAYMENTS_ENABLED, form);
  }, [form, formDisabled, wizardResponse.product?.parameterTables]);

  const onFinish = () => {
    setIsLoading(true);

    const isPerDayAvailable =
      String(form.getFieldValue(PAYM_A_TOTAL_ENABLED)) === "true" &&
      String(form.getFieldValue(D_PAYM_A_TOTAL_ENABLED)) === "true";

    const isPerBillingAvailable =
      String(form.getFieldValue(PAYM_A_TOTAL_ENABLED)) === "true" &&
      String(form.getFieldValue(B_PAYM_A_TOTAL_ENABLED)) === "true";

    const isPer365Available =
      String(form.getFieldValue(PAYM_A_TOTAL_ENABLED)) === "true" &&
      String(form.getFieldValue(D365_PAYM_A_TOTAL_ENABLED)) === "true";

    const isForeverAvailable =
      String(form.getFieldValue(PAYM_A_TOTAL_ENABLED)) === "true" &&
      String(form.getFieldValue(E_PAYM_A_TOTAL_ENABLED)) === "true";

    const perDayFieldValues = isShowPerDay
      ? {
          [D_PAYM_A_TOTAL_ENABLED]: String(form.getFieldValue(D_PAYM_A_TOTAL_ENABLED)),
          [NIC_D_PAYM_A_TOTAL_NR]: isPerDayAvailable
            ? String(form.getFieldValue(NIC_D_PAYM_A_TOTAL_NR)).replace(/ /g, "")
            : null,
          [NIC_D_PAYM_A_TOTAL_AM]: isPerDayAvailable
            ? String(form.getFieldValue(NIC_D_PAYM_A_TOTAL_AM)).replace(/ /g, "")
            : null,
          [NIC_D_PAYM_A_TOTAL_SINGLE]: isPerDayAvailable
            ? String(form.getFieldValue(NIC_D_PAYM_A_TOTAL_SINGLE)).replace(/ /g, "")
            : null,
        }
      : {};

    const perBillingFieldValues = isShowPerBilling
      ? {
          [B_PAYM_A_TOTAL_ENABLED]: String(form.getFieldValue(B_PAYM_A_TOTAL_ENABLED)),
          [NIC_B_PAYM_A_TOTAL_NR]: isPerBillingAvailable
            ? String(form.getFieldValue(NIC_B_PAYM_A_TOTAL_NR)).replace(/ /g, "")
            : null,
          [NIC_B_PAYM_A_TOTAL_AM]: isPerBillingAvailable
            ? String(form.getFieldValue(NIC_B_PAYM_A_TOTAL_AM)).replace(/ /g, "")
            : null,
          [NIC_B_PAYM_A_TOTAL_SINGLE]: isPerBillingAvailable
            ? String(form.getFieldValue(NIC_B_PAYM_A_TOTAL_SINGLE)).replace(/ /g, "")
            : null,
        }
      : {};

    const per365DaysFieldValues = isShowPer365Days
      ? {
          [D365_PAYM_A_TOTAL_ENABLED]: String(form.getFieldValue(D365_PAYM_A_TOTAL_ENABLED)),
          [NIC_D365_PAYM_A_TOTAL_NR]: isPer365Available
            ? String(form.getFieldValue(NIC_D365_PAYM_A_TOTAL_NR)).replace(/ /g, "")
            : null,
          [NIC_D365_PAYM_A_TOTAL_AM]: isPer365Available
            ? String(form.getFieldValue(NIC_D365_PAYM_A_TOTAL_AM)).replace(/ /g, "")
            : null,
          [NIC_D365_PAYM_A_TOTAL_SINGLE]: isPer365Available
            ? String(form.getFieldValue(NIC_D365_PAYM_A_TOTAL_SINGLE)).replace(/ /g, "")
            : null,
        }
      : {};

    const foreverFieldValues = isShowForever
      ? {
          [E_PAYM_A_TOTAL_ENABLED]: String(form.getFieldValue(E_PAYM_A_TOTAL_ENABLED)),
          [NIC_E_PAYM_A_TOTAL_NR]: isForeverAvailable
            ? String(form.getFieldValue(NIC_E_PAYM_A_TOTAL_NR)).replace(/ /g, "")
            : null,
          [NIC_E_PAYM_A_TOTAL_AM]: isForeverAvailable
            ? String(form.getFieldValue(NIC_E_PAYM_A_TOTAL_AM)).replace(/ /g, "")
            : null,
          [NIC_E_PAYM_A_TOTAL_SINGLE]: isForeverAvailable
            ? String(form.getFieldValue(NIC_E_PAYM_A_TOTAL_SINGLE)).replace(/ /g, "")
            : null,
        }
      : {};

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues:
        String(form.getFieldValue(PAYM_A_TOTAL_ENABLED)) === "true"
          ? ({
              [PAYM_A_TOTAL_ENABLED]: String(form.getFieldValue(PAYM_A_TOTAL_ENABLED)),
              ...perDayFieldValues,
              ...perBillingFieldValues,
              ...per365DaysFieldValues,
              ...foreverFieldValues,
            } as Record)
          : ({ [PAYM_A_TOTAL_ENABLED]: String(form.getFieldValue(PAYM_A_TOTAL_ENABLED)) } as Record),
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Account Limits - Top-up"
      pageSubtitle="Account limits govern payments to cards related to the account and payments to the account itself. Both
      primary and supplementary card loads contribute towards the account limits."
      size="md"
      formSize="full"
      gap={24}
      level="root"
      submitHandler={onFinish}
    >
      <div className="limits-block">
        <div className="limits-block-header">
          <Form.Item valuePropName="checked" name={PAYM_A_TOTAL_ENABLED} className="header-switch">
            <Switch
              onClick={value => {
                headerSwitchHandler(PAYM_A_TOTAL_ENABLED, TOTAL_PAYMENTS_ENABLED, value, form);
              }}
            />
          </Form.Item>
          <div className="col-switch-desc">
            <TooltipInfo label="Account Limits" code="PAYM_A_TOTAL_ENABLED" tooltipProps={{}} />
          </div>
        </div>
        <div className="limits-block-body">
          <Row>
            <Col span={6} />
            <Col span={6} className="col-header">
              <TooltipInfo
                label="Max number"
                tooltipProps={{
                  title:
                    "This counter restricts maximal number of payments per period. Leave it empty if restrictions are not intended. All zeros prohibit payments at all.",
                }}
              />
            </Col>
            <Col span={6} className="col-header">
              <TooltipInfo
                label="Max amount"
                tooltipProps={{
                  title:
                    "This counter restricts maximal amount of payments per period. Leave it empty if restrictions are not intended. All zeros prohibit payments at all.",
                }}
              />
            </Col>
            <Col span={6} className="col-header">
              <TooltipInfo
                label="Max single amount"
                tooltipProps={{
                  title:
                    "This counter restricts maximal amount of single payment. Leave it empty if restrictions are not intended. All zeros prohibit payments at all.",
                }}
              />
            </Col>
          </Row>
          {isShowPerDay && (
            <Row>
              <Col span={6}>
                <Form.Item valuePropName="checked" name={D_PAYM_A_TOTAL_ENABLED} className="col-switch">
                  {accountSwitchesNotOne && (
                    <Switch onClick={() => checkHeader(PAYM_A_TOTAL_ENABLED, TOTAL_PAYMENTS_ENABLED, form)} />
                  )}
                </Form.Item>
                <div className="col-switch-desc">
                  <TooltipInfo
                    label="Per Day"
                    code="D_PAYM_A_TOTAL_ENABLED"
                    tooltipProps={{
                      title:
                        "Switch it off if restrictions are not intended per calendar day (from 00:00:00 to 23:59:59).",
                    }}
                  />
                </div>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={[D_PAYM_A_TOTAL_ENABLED]}>
                  {() => {
                    return (
                      <FormItemLabel code={NIC_D_PAYM_A_TOTAL_NR}>
                        <Form.Item name={NIC_D_PAYM_A_TOTAL_NR}>
                          <NumberFormat
                            customInput={Input}
                            allowNegative={false}
                            disabled={!form.getFieldValue(D_PAYM_A_TOTAL_ENABLED) || formDisabled}
                            thousandSeparator=" "
                            decimalScale={0}
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={[D_PAYM_A_TOTAL_ENABLED]}>
                  {() => {
                    return (
                      <FormItemLabel code={NIC_D_PAYM_A_TOTAL_AM}>
                        <Form.Item name={NIC_D_PAYM_A_TOTAL_AM}>
                          <NumberFormat
                            customInput={Input}
                            addonAfter={currency}
                            allowNegative={false}
                            disabled={!form.getFieldValue(D_PAYM_A_TOTAL_ENABLED) || formDisabled}
                            thousandSeparator=" "
                            decimalScale={0}
                            fixedDecimalScale={true}
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={[D_PAYM_A_TOTAL_ENABLED]}>
                  {() => {
                    return (
                      <FormItemLabel code={NIC_D_PAYM_A_TOTAL_SINGLE}>
                        <Form.Item name={NIC_D_PAYM_A_TOTAL_SINGLE}>
                          <NumberFormat
                            customInput={Input}
                            addonAfter={currency}
                            allowNegative={false}
                            disabled={!form.getFieldValue(D_PAYM_A_TOTAL_ENABLED) || formDisabled}
                            thousandSeparator=" "
                            decimalScale={0}
                            fixedDecimalScale={true}
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
          )}
          {isShowPerBilling && (
            <Row>
              <Col span={6}>
                <Form.Item valuePropName="checked" name={B_PAYM_A_TOTAL_ENABLED} className="col-switch">
                  {accountSwitchesNotOne && (
                    <Switch onClick={() => checkHeader(PAYM_A_TOTAL_ENABLED, TOTAL_PAYMENTS_ENABLED, form)} />
                  )}
                </Form.Item>
                <div className="col-switch-desc">
                  <TooltipInfo
                    label="Per billing"
                    code={B_PAYM_A_TOTAL_ENABLED}
                    tooltipProps={{ title: "Switch it off if restrictions are not intended per billing cycle." }}
                  />
                </div>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={[B_PAYM_A_TOTAL_ENABLED]}>
                  {() => {
                    return (
                      <FormItemLabel code={NIC_B_PAYM_A_TOTAL_NR}>
                        <Form.Item name={NIC_B_PAYM_A_TOTAL_NR}>
                          <NumberFormat
                            customInput={Input}
                            allowNegative={false}
                            disabled={!form.getFieldValue(B_PAYM_A_TOTAL_ENABLED) || formDisabled}
                            thousandSeparator=" "
                            decimalScale={0}
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={[B_PAYM_A_TOTAL_ENABLED]}>
                  {() => {
                    return (
                      <FormItemLabel code={NIC_B_PAYM_A_TOTAL_AM}>
                        <Form.Item name={NIC_B_PAYM_A_TOTAL_AM}>
                          <NumberFormat
                            customInput={Input}
                            addonAfter={currency}
                            allowNegative={false}
                            disabled={!form.getFieldValue(B_PAYM_A_TOTAL_ENABLED) || formDisabled}
                            thousandSeparator=" "
                            decimalScale={0}
                            fixedDecimalScale={true}
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={[B_PAYM_A_TOTAL_ENABLED]}>
                  {() => {
                    return (
                      <FormItemLabel code={NIC_B_PAYM_A_TOTAL_SINGLE}>
                        <Form.Item name={NIC_B_PAYM_A_TOTAL_SINGLE}>
                          <NumberFormat
                            customInput={Input}
                            addonAfter={currency}
                            allowNegative={false}
                            disabled={!form.getFieldValue(B_PAYM_A_TOTAL_ENABLED) || formDisabled}
                            thousandSeparator=" "
                            decimalScale={0}
                            fixedDecimalScale={true}
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
          )}
          {isShowPer365Days && (
            <Row>
              <Col span={6}>
                <Form.Item valuePropName="checked" name={D365_PAYM_A_TOTAL_ENABLED} className="col-switch">
                  {accountSwitchesNotOne && (
                    <Switch onClick={() => checkHeader(PAYM_A_TOTAL_ENABLED, TOTAL_PAYMENTS_ENABLED, form)} />
                  )}
                </Form.Item>
                <div className="col-switch-desc">
                  <TooltipInfo
                    label="Per 365 days"
                    code={D365_PAYM_A_TOTAL_ENABLED}
                    tooltipProps={{
                      title:
                        "365 sliding days, means that the limiter calculates transactions for 365 days before the current transaction was made. Switch it off if restrictions are not intended.",
                    }}
                  />
                </div>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={[D365_PAYM_A_TOTAL_ENABLED]}>
                  {() => {
                    return (
                      <FormItemLabel code={NIC_D365_PAYM_A_TOTAL_NR}>
                        <Form.Item name={NIC_D365_PAYM_A_TOTAL_NR}>
                          <NumberFormat
                            customInput={Input}
                            allowNegative={false}
                            disabled={!form.getFieldValue(D365_PAYM_A_TOTAL_ENABLED) || formDisabled}
                            thousandSeparator=" "
                            decimalScale={0}
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={[D365_PAYM_A_TOTAL_ENABLED]}>
                  {() => {
                    return (
                      <FormItemLabel code={NIC_D365_PAYM_A_TOTAL_AM}>
                        <Form.Item name={NIC_D365_PAYM_A_TOTAL_AM}>
                          <NumberFormat
                            customInput={Input}
                            addonAfter={currency}
                            allowNegative={false}
                            disabled={!form.getFieldValue(D365_PAYM_A_TOTAL_ENABLED) || formDisabled}
                            thousandSeparator=" "
                            decimalScale={0}
                            fixedDecimalScale={true}
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={[D365_PAYM_A_TOTAL_ENABLED]}>
                  {() => {
                    return (
                      <FormItemLabel code={NIC_D365_PAYM_A_TOTAL_SINGLE}>
                        <Form.Item name={NIC_D365_PAYM_A_TOTAL_SINGLE}>
                          <NumberFormat
                            customInput={Input}
                            addonAfter={currency}
                            allowNegative={false}
                            disabled={!form.getFieldValue(D365_PAYM_A_TOTAL_ENABLED) || formDisabled}
                            thousandSeparator=" "
                            decimalScale={0}
                            fixedDecimalScale={true}
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
          )}
          {isShowForever && (
            <Row>
              <Col span={6}>
                <Form.Item valuePropName="checked" name={E_PAYM_A_TOTAL_ENABLED} className="col-switch">
                  {accountSwitchesNotOne && (
                    <Switch onClick={() => checkHeader(PAYM_A_TOTAL_ENABLED, TOTAL_PAYMENTS_ENABLED, form)} />
                  )}
                </Form.Item>
                <div className="col-switch-desc">
                  <TooltipInfo
                    label="Forever"
                    code={E_PAYM_A_TOTAL_ENABLED}
                    tooltipProps={{
                      title:
                        "No frequency cycle is set for the limiter; counter contents accumulate constantly, and counters do not reset to zero. Switch it off if restrictions are not intended.",
                    }}
                  />
                </div>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={[E_PAYM_A_TOTAL_ENABLED]}>
                  {() => {
                    return (
                      <FormItemLabel code={NIC_E_PAYM_A_TOTAL_NR}>
                        <Form.Item name={NIC_E_PAYM_A_TOTAL_NR}>
                          <NumberFormat
                            customInput={Input}
                            allowNegative={false}
                            disabled={!form.getFieldValue(E_PAYM_A_TOTAL_ENABLED) || formDisabled}
                            thousandSeparator=" "
                            decimalScale={0}
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={[E_PAYM_A_TOTAL_ENABLED]}>
                  {() => {
                    return (
                      <FormItemLabel code={NIC_E_PAYM_A_TOTAL_AM}>
                        <Form.Item name={NIC_E_PAYM_A_TOTAL_AM}>
                          <NumberFormat
                            customInput={Input}
                            addonAfter={currency}
                            allowNegative={false}
                            disabled={!form.getFieldValue(E_PAYM_A_TOTAL_ENABLED) || formDisabled}
                            thousandSeparator=" "
                            decimalScale={0}
                            fixedDecimalScale={true}
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={[E_PAYM_A_TOTAL_ENABLED]}>
                  {() => {
                    return (
                      <FormItemLabel code={NIC_E_PAYM_A_TOTAL_SINGLE}>
                        <Form.Item name={NIC_E_PAYM_A_TOTAL_SINGLE}>
                          <NumberFormat
                            customInput={Input}
                            addonAfter={currency}
                            allowNegative={false}
                            disabled={!form.getFieldValue(E_PAYM_A_TOTAL_ENABLED) || formDisabled}
                            thousandSeparator=" "
                            decimalScale={0}
                            fixedDecimalScale={true}
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </CustomFormWrapper>
  );
};
