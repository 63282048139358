import { FC, useEffect } from "react";
import { Form, InputNumber, Space, Switch } from "antd";

import {
  LTY_WELC_BONUS_ENABLE,
  NIC_LTY_BONUS_W_TAR_BASE,
  NIC_LTY_BONUS_W_TIMER_FROM,
  NIC_LTY_BONUS_W_TIMER_PER,
  NIC_LTY_BONUS_W_TIMER_PER_TYPE,
} from "@ni/common/constants";
import { FormContent, RadioGroup } from "@ni/common/ui";
import { validateNumberInput } from "@ni/common/utils";

import { GeneralSettingsPageProps } from "../../../types";

import { RADIO_GROUP_TIMER_FROM, RADIO_GROUP_TIMER_PER_TYPE } from "./WelcomeBonus.constants";

export const WelcomeBonus: FC<GeneralSettingsPageProps> = ({ form }) => {
  const isWelcomeBonusOn = Form.useWatch(LTY_WELC_BONUS_ENABLE, form);

  useEffect(() => {
    if (isWelcomeBonusOn) {
      if (!form.getFieldValue(NIC_LTY_BONUS_W_TAR_BASE)) {
        form.setFieldValue(NIC_LTY_BONUS_W_TAR_BASE, 0);
      }

      if (!form.getFieldValue(NIC_LTY_BONUS_W_TIMER_FROM)) {
        form.setFieldValue(NIC_LTY_BONUS_W_TIMER_FROM, RADIO_GROUP_TIMER_FROM[0].value);
      }
      if (!form.getFieldValue(NIC_LTY_BONUS_W_TIMER_PER_TYPE)) {
        form.setFieldValue(NIC_LTY_BONUS_W_TIMER_PER_TYPE, RADIO_GROUP_TIMER_PER_TYPE[0].value);
      }
    }
  }, [form, isWelcomeBonusOn]);

  return (
    <FormContent gap={40}>
      <div>
        New customers could receive a certain number of bonus points upon signing up for the loyalty program or after a
        fixed period of time.
      </div>

      <Space direction="horizontal" size={8}>
        <Form.Item valuePropName="checked" name={LTY_WELC_BONUS_ENABLE}>
          <Switch />
        </Form.Item>
        <div>Enable welcome bonus on your loyalty program</div>
      </Space>
      {isWelcomeBonusOn && (
        <Form.Item
          name={NIC_LTY_BONUS_W_TAR_BASE}
          label="Welcome bonus amount (number of points)"
          rules={[
            () => ({
              validator(_, value) {
                return validateNumberInput(value as string | number | undefined);
              },
            }),
          ]}
        >
          <InputNumber min={0} precision={0} />
        </Form.Item>
      )}

      {isWelcomeBonusOn && (
        <Form.Item name={NIC_LTY_BONUS_W_TIMER_FROM} label="Welcome bonus timer trigger (count down starts from):">
          <RadioGroup radioList={RADIO_GROUP_TIMER_FROM} />
        </Form.Item>
      )}

      {isWelcomeBonusOn && (
        <Form.Item name={NIC_LTY_BONUS_W_TIMER_PER_TYPE} label="Welcome bonus count down timer period">
          <RadioGroup radioList={RADIO_GROUP_TIMER_PER_TYPE} />
        </Form.Item>
      )}

      {isWelcomeBonusOn && (
        <Form.Item name={NIC_LTY_BONUS_W_TIMER_PER} label="Welcome bonus count down timer (number of periods)">
          <InputNumber min={0} precision={0} />
        </Form.Item>
      )}
    </FormContent>
  );
};
