import { FC, useEffect } from "react";
import { Form } from "antd";

import { useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper } from "@ni/common/ui";
import { getFormValueFromProductValues } from "@ni/common/utils";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { ProductValue, TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";
import {
  AccountBillingFormNames,
  AccountBillingSwitchHeaders,
  checkboxFieldsPage34,
  checkHeader,
  getAccountBillingLimitsPreparedValues,
  PctAccountBilling,
  switchHeader34,
} from "@ni/tenant-portal/core";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const TransactionAccountLimitsBillingPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  const currency = (getFormValueFromProductValues(wizardResponse.product?.productValues, "nic-ccy") as string) || "";

  useEffect(() => {
    const formDisabledFn = (field: string, number: string | number) =>
      formDisabled
        ? (getFormValueFromProductValues(
            wizardResponse.product?.parameterTables?.[0].pctProductValues as ProductValue[],
            field,
            "string",
          ) as string) || ""
        : number;

    form.setFieldsValue({
      [AccountBillingFormNames.cashEnable]: true,
      [AccountBillingFormNames.cashMaxNr]: formDisabledFn(AccountBillingFormNames.cashMaxNr, "20"),
      [AccountBillingFormNames.cashMaxAm]: formDisabledFn(AccountBillingFormNames.cashMaxAm, "1000000"),
      [AccountBillingFormNames.cashMaxSingle]: formDisabledFn(AccountBillingFormNames.cashMaxSingle, "1000000"),

      [AccountBillingFormNames.cashForEnable]: true,
      [AccountBillingFormNames.cashForMaxNr]: formDisabledFn(AccountBillingFormNames.cashForMaxNr, "20"),
      [AccountBillingFormNames.cashForMaxAm]: formDisabledFn(AccountBillingFormNames.cashForMaxAm, "1000000"),
      [AccountBillingFormNames.cashForMaxSingle]: formDisabledFn(AccountBillingFormNames.cashForMaxSingle, "1000000"),

      [AccountBillingFormNames.retailEnable]: true,
      [AccountBillingFormNames.retailMaxNr]: formDisabledFn(AccountBillingFormNames.retailMaxNr, "20"),
      [AccountBillingFormNames.retailMaxAm]: formDisabledFn(AccountBillingFormNames.retailMaxAm, "1000000"),
      [AccountBillingFormNames.retailMaxSingle]: formDisabledFn(AccountBillingFormNames.retailMaxSingle, "1000000"),

      [AccountBillingFormNames.retailForEnable]: true,
      [AccountBillingFormNames.retailForMaxNr]: formDisabledFn(AccountBillingFormNames.retailForMaxNr, "20"),
      [AccountBillingFormNames.retailForMaxAm]: formDisabledFn(AccountBillingFormNames.retailForMaxAm, "1000000"),
      [AccountBillingFormNames.retailForMaxSingle]: formDisabledFn(
        AccountBillingFormNames.retailForMaxSingle,
        "1000000",
      ),

      [AccountBillingFormNames.totalEnable]: true,
      [AccountBillingFormNames.totalMaxNr]: formDisabledFn(AccountBillingFormNames.totalMaxNr, "21"),
      [AccountBillingFormNames.totalMaxAm]: formDisabledFn(AccountBillingFormNames.totalMaxAm, "1000000"),
      [AccountBillingFormNames.totalMaxSingle]: formDisabledFn(AccountBillingFormNames.totalMaxSingle, "1000000"),
    });

    Object.keys(checkboxFieldsPage34).forEach(key => {
      checkHeader(key, checkboxFieldsPage34, form);
    });

    checkHeader(AccountBillingSwitchHeaders.allCashEnable, switchHeader34, form);
    checkHeader(AccountBillingSwitchHeaders.allRetailEnable, switchHeader34, form);
    checkHeader(AccountBillingSwitchHeaders.allTotalEnable, switchHeader34, form);
  }, [form, formDisabled, wizardResponse.product?.parameterTables]);

  const onFinish = (value: FormValues): void => {
    let actualValues: { [key: string]: string } = {};

    actualValues = {
      ...actualValues,
      ...getAccountBillingLimitsPreparedValues(value as { [key: string]: string | boolean }),
    };

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: actualValues,
    };

    setIsLoading(true);
    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Account Limits - Billing Period"
      pageSubtitle="Account limits govern the spending velocity for the cards related to the account. Both primary and
      supplementary card transactions contribute towards the account limits"
      size="md"
      formSize="full"
      gap={24}
      level="root"
      submitHandler={onFinish}
    >
      <PctAccountBilling currency={currency} form={form} formDisabled={formDisabled} />
    </CustomFormWrapper>
  );
};
