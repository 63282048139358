import { useMemo } from "react";

import { useReduxState } from "@ni/common/hooks";
import { GetProductWithId } from "@ni/common/types";
import { CollapsedLinks, SettingsPageLayout } from "@ni/common/ui";
import { sortLoyaltyPrograms } from "@ni/common/utils";

import { LoyaltyProgramTemplatesTable } from "../../../../components";

export const PctLoyaltyPrograms = () => {
  const [currentProduct] = useReduxState<GetProductWithId>("currentProduct", {} as GetProductWithId);

  const programsList = useMemo(
    () =>
      currentProduct?.shortLoyaltyPrograms
        ? sortLoyaltyPrograms(currentProduct?.shortLoyaltyPrograms?.filter(x => x.state === "ACTIVE"))
        : currentProduct?.shortLoyaltyPrograms,
    [currentProduct?.shortLoyaltyPrograms],
  );

  const relatedLinks = [
    {
      label: `${currentProduct.displayName} - Loyalty Programs`,
      href: "../loyalty-programs",
    },
  ];

  return (
    <SettingsPageLayout
      pageTitle="Loyalty Programs"
      pageSubtitle="The product can be associated with multiple loyalty programs, including up to 5 normal and up to 5 cumulative programs. Below is the list of applicable loyalty programs, arranged in descending order of priority. Click the “Edit” icon to view and update details."
      level="pct"
    >
      <LoyaltyProgramTemplatesTable loyaltyPrograms={programsList} />

      <CollapsedLinks list={relatedLinks} />
    </SettingsPageLayout>
  );
};
