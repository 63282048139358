import { FC, useEffect, useState } from "react";
import { Col, Form, Input, Row, Switch } from "antd";
import NumberFormat from "react-number-format";

import { useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, FormItemLabel, TooltipInfo } from "@ni/common/ui";
import { getFormValueFromProductValues } from "@ni/common/utils";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { ProductValue, TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";
import { checkHeader, headerSwitchHandler } from "@ni/tenant-portal/core";

const wizardServicesApi = new ProductAndTenantWizardApi();

const checkboxFieldsPage29: { [key: string]: string[] } = {
  "paym-fee-enabled": [
    "nic-paym-online-first-base",
    "nic-paym-online-first-fee-rate",
    "nic-paym-online-first-min",
    "nic-paym-online-first-max",
    "nic-paym-online-subseq-base",
    "nic-paym-online-subseq-fee-rate",
    "nic-paym-online-subseq-min",
    "nic-paym-online-subseq-max",
  ],
};

const switchHeader29: { [key: string]: string[] } = {
  "paym-fee-enabled": ["paym-fee-enabled"],
};

export const TopupFeesPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const [, setIsLoading] = useReduxState<boolean>("isLoading");
  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [isMinMaxFeeFirstCashEnabled, setIsMinMaxFeeFirstCashEnabled] = useState<boolean>(true);
  const [isMinMaxFeeSubCashEnabled, setIsMinMaxFeeSubCashEnabled] = useState<boolean>(true);

  useEffect(() => {
    const formDisabledFn = (field: string, number: string | number) =>
      formDisabled
        ? (getFormValueFromProductValues(
            wizardResponse.product?.parameterTables![0].pctProductValues as ProductValue[],
            field,
            "string",
          ) as string) || ""
        : number;

    form.setFieldsValue({
      "paym-fee-enabled": formDisabled
        ? getFormValueFromProductValues(
            wizardResponse.product?.parameterTables![0].pctProductValues as ProductValue[],
            "paym-fee-enabled",
            "boolean",
          )
        : true,
      "nic-paym-online-first-base": formDisabledFn("nic-paym-online-first-base", ""),
      "nic-paym-online-first-fee-rate": formDisabledFn("nic-paym-online-first-fee-rate", "0.5"),
      "nic-paym-online-first-min": formDisabledFn("nic-paym-online-first-min", ""),
      "nic-paym-online-first-max": formDisabledFn("nic-paym-online-first-max", ""),
      "nic-paym-online-subseq-base": formDisabledFn("nic-paym-online-subseq-base", ""),
      "nic-paym-online-subseq-fee-rate": formDisabledFn("nic-paym-online-subseq-fee-rate", "0.5"),
      "nic-paym-online-subseq-min": formDisabledFn("nic-paym-online-subseq-min", ""),
      "nic-paym-online-subseq-max": formDisabledFn("nic-paym-online-subseq-max", ""),
    });

    Object.keys(checkboxFieldsPage29).forEach(key => {
      form.setFieldsValue({
        [key]: false,
      });

      checkboxFieldsPage29[key].forEach(item => {
        if (form.getFieldValue(item)) {
          form.setFieldsValue({
            [key]: true,
          });
        }
      });
    });

    checkHeader("paym-fee-enabled", switchHeader29, form);
  }, [form, formDisabled, wizardResponse.product?.parameterTables]);

  const currency = (getFormValueFromProductValues(wizardResponse.product?.productValues, "nic-ccy") as string) || "";

  const onFormChange = () => {
    setIsMinMaxFeeFirstCashEnabled(!!form.getFieldValue("nic-paym-online-first-fee-rate")?.length);
    setIsMinMaxFeeSubCashEnabled(!!form.getFieldValue("nic-paym-online-subseq-fee-rate")?.length);
  };

  const onFinish = () => {
    setIsLoading(true);

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: form.getFieldValue("paym-fee-enabled")
        ? ({
            "paym-fee-enabled": String(form.getFieldValue("paym-fee-enabled")),
            "nic-paym-online-first-base": form.getFieldValue("nic-paym-online-first-base")
              ? String(form.getFieldValue("nic-paym-online-first-base")).replace(/ /g, "")
              : null,
            "nic-paym-online-first-fee-rate": form.getFieldValue("nic-paym-online-first-fee-rate")
              ? String(form.getFieldValue("nic-paym-online-first-fee-rate")).replace(/ /g, "")
              : null,
            "nic-paym-online-first-min": form.getFieldValue("nic-paym-online-first-min")
              ? String(form.getFieldValue("nic-paym-online-first-min")).replace(/ /g, "")
              : null,
            "nic-paym-online-first-max": form.getFieldValue("nic-paym-online-first-max")
              ? String(form.getFieldValue("nic-paym-online-first-max")).replace(/ /g, "")
              : null,
            "nic-paym-online-subseq-base": form.getFieldValue("nic-paym-online-subseq-base")
              ? String(form.getFieldValue("nic-paym-online-subseq-base")).replace(/ /g, "")
              : null,
            "nic-paym-online-subseq-fee-rate": form.getFieldValue("nic-paym-online-subseq-fee-rate")
              ? String(form.getFieldValue("nic-paym-online-subseq-fee-rate")).replace(/ /g, "")
              : null,
            "nic-paym-online-subseq-min": form.getFieldValue("nic-paym-online-subseq-min")
              ? String(form.getFieldValue("nic-paym-online-subseq-min")).replace(/ /g, "")
              : null,
            "nic-paym-online-subseq-max": form.getFieldValue("nic-paym-online-subseq-max")
              ? String(form.getFieldValue("nic-paym-online-subseq-max")).replace(/ /g, "")
              : null,
          } as { [key: string]: string })
        : ({ "paym-fee-enabled": String(form.getFieldValue("paym-fee-enabled")) } as { [key: string]: string }),
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Top-up Fees"
      pageSubtitle="Top-ups fees are charged when funds are credited to an account (payment that increases the available
        amount)"
      size="md"
      formSize="full"
      gap={24}
      level="root"
      onValuesChange={onFormChange}
      submitHandler={onFinish}
    >
      <div className="limits-block">
        <div className="limits-block-header">
          <Form.Item valuePropName="checked" name="paym-fee-enabled" className="header-switch">
            <Switch
              onClick={value => {
                headerSwitchHandler("paym-fee-enabled", switchHeader29, value, form);
              }}
            />
          </Form.Item>
          <TooltipInfo
            label="Internal top-up channels. Online"
            code="paym-fee-enabled"
            tooltipProps={{
              title:
                "Special API allows you to top-up cards and accounts. You can configure other channel fees (in boarding file, P2P, external channels) after wizard completion from Pricing Control Tables menu on Product settings.",
            }}
          />
        </div>
        <div className="limits-block-body">
          <Row>
            <Col span={4} />
            <Col span={5} className="col-header">
              <TooltipInfo
                label="Fee fixed"
                tooltipProps={{
                  title: "The parameter specifies the fixed fee amount to be charged for the transaction.",
                }}
              />
            </Col>
            <Col span={5} className="col-header">
              <TooltipInfo
                label="Fee %"
                tooltipProps={{
                  title:
                    "The parameter specifies the percentage of fee amount that needs to be calculated on original transaction. Total Fee = Fee fixed + Fee %",
                }}
              />
            </Col>
            <Col span={5} className="col-header">
              <TooltipInfo
                label="Min Fee"
                tooltipProps={{
                  title:
                    "The parameter specifies the minimum fee amount that needs to be charged to the customer. Incase of calculated `Total Fee` is lesser than the Min fee defined then Min fee will be charged to the customer. If Min Fee > Fee fixed + Fee % then Min Fee will be charged to customer.",
                }}
              />
            </Col>
            <Col span={5} className="col-header">
              <TooltipInfo
                label="Max Fee"
                tooltipProps={{
                  title:
                    "The parameter specifies the maximum fee amount that needs to be charged to the customer. Incase of calculated `Total Fee` is greater than the Max fee defined then Max fee will be charged to the customer. If Max Fee < Fee fixed + Fee % then Max Fee will be charged to customer. ",
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <TooltipInfo
                label="First top-up"
                tooltipProps={{
                  title: "This fee is charged in case first top up or first payment is done on an account/card.",
                }}
              />
            </Col>
            <Col span={5}>
              <Form.Item dependencies={["paym-fee-enabled"]}>
                {() => {
                  return (
                    <FormItemLabel code="nic-paym-online-first-base">
                      <Form.Item name="nic-paym-online-first-base">
                        <NumberFormat
                          customInput={Input}
                          addonAfter={currency}
                          allowNegative={false}
                          disabled={!form.getFieldValue("paym-fee-enabled") || formDisabled}
                          thousandSeparator=" "
                          decimalScale={2}
                          decimalSeparator="."
                          fixedDecimalScale={true}
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item dependencies={["paym-fee-enabled"]}>
                {() => {
                  return (
                    <FormItemLabel code="nic-paym-online-first-fee-rate">
                      <Form.Item name="nic-paym-online-first-fee-rate">
                        <NumberFormat
                          customInput={Input}
                          addonAfter="%"
                          allowNegative={false}
                          disabled={!form.getFieldValue("paym-fee-enabled") || formDisabled}
                          thousandSeparator=" "
                          decimalScale={2}
                          decimalSeparator="."
                          fixedDecimalScale={true}
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item dependencies={["paym-fee-enabled"]}>
                {() => {
                  return (
                    <FormItemLabel code="nic-paym-online-first-min">
                      <Form.Item name="nic-paym-online-first-min">
                        <NumberFormat
                          customInput={Input}
                          addonAfter={currency}
                          allowNegative={false}
                          disabled={
                            !form.getFieldValue("paym-fee-enabled") || !isMinMaxFeeFirstCashEnabled || formDisabled
                          }
                          thousandSeparator=" "
                          decimalScale={2}
                          decimalSeparator="."
                          fixedDecimalScale={true}
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item dependencies={["paym-fee-enabled"]}>
                {() => {
                  return (
                    <FormItemLabel code="nic-paym-online-first-max">
                      <Form.Item name="nic-paym-online-first-max">
                        <NumberFormat
                          customInput={Input}
                          addonAfter={currency}
                          allowNegative={false}
                          disabled={
                            !form.getFieldValue("paym-fee-enabled") || !isMinMaxFeeFirstCashEnabled || formDisabled
                          }
                          thousandSeparator=" "
                          decimalScale={2}
                          decimalSeparator="."
                          fixedDecimalScale={true}
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <TooltipInfo
                label="Subsequent top-ups"
                tooltipProps={{
                  title: "This fee is charged in case of subsequent payment made on an account/card.",
                }}
              />
            </Col>
            <Col span={5}>
              <Form.Item dependencies={["paym-fee-enabled"]}>
                {() => {
                  return (
                    <FormItemLabel code="nic-paym-online-subseq-base">
                      <Form.Item name="nic-paym-online-subseq-base">
                        <NumberFormat
                          customInput={Input}
                          addonAfter={currency}
                          allowNegative={false}
                          disabled={!form.getFieldValue("paym-fee-enabled") || formDisabled}
                          thousandSeparator=" "
                          decimalScale={2}
                          decimalSeparator="."
                          fixedDecimalScale={true}
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item dependencies={["paym-fee-enabled"]}>
                {() => {
                  return (
                    <FormItemLabel code="nic-paym-online-subseq-fee-rate">
                      <Form.Item name="nic-paym-online-subseq-fee-rate">
                        <NumberFormat
                          customInput={Input}
                          addonAfter="%"
                          allowNegative={false}
                          disabled={!form.getFieldValue("paym-fee-enabled") || formDisabled}
                          thousandSeparator=" "
                          decimalScale={2}
                          decimalSeparator="."
                          fixedDecimalScale={true}
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item dependencies={["paym-fee-enabled"]}>
                {() => {
                  return (
                    <FormItemLabel code="nic-paym-online-subseq-min">
                      <Form.Item name="nic-paym-online-subseq-min">
                        <NumberFormat
                          customInput={Input}
                          addonAfter={currency}
                          allowNegative={false}
                          disabled={
                            !form.getFieldValue("paym-fee-enabled") || !isMinMaxFeeSubCashEnabled || formDisabled
                          }
                          thousandSeparator=" "
                          decimalScale={2}
                          decimalSeparator="."
                          fixedDecimalScale={true}
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item dependencies={["paym-fee-enabled"]}>
                {() => {
                  return (
                    <FormItemLabel code="nic-paym-online-subseq-max">
                      <Form.Item name="nic-paym-online-subseq-max">
                        <NumberFormat
                          customInput={Input}
                          addonAfter={currency}
                          allowNegative={false}
                          disabled={
                            !form.getFieldValue("paym-fee-enabled") || !isMinMaxFeeSubCashEnabled || formDisabled
                          }
                          thousandSeparator=" "
                          decimalScale={2}
                          decimalSeparator="."
                          fixedDecimalScale={true}
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>
    </CustomFormWrapper>
  );
};
