import { FeatureGuard, RoutesTreeItem } from "@ni/common/types";

import { PCT_ID_KEY } from "../components/Sidebar/Sidebar.constants";

import {
  AccountLimitsBillingPage,
  AccountLimitsDailyPage,
  AccountLimitsPage,
  AdditionalRulesPage,
  AutoClosure,
  BalanceInquiryFeesPage,
  CardAndPinMaintenance,
  CardLimitPage,
  CardLimitsBillingPage,
  CardLimitsDailyPage,
  CardLimitsMonthlyPage,
  CardNumbering,
  CardProductionFeesPage,
  CardTransactionFeesPage,
  ContractPurge,
  CreditLimit,
  CreditLimitModificationFeesPage,
  CreditLimitPage,
  CurrencyConversionFeesPage,
  DeclineFeePage,
  DirectDebitConfigurationPage,
  FeeSettings,
  GeneralLoyaltySettings,
  InactivityFeePage,
  InstallmentPaymentPlans,
  InsurancePrograms,
  InterestCalculationPage,
  InterestGracePage,
  JoiningFeePage,
  LatePaymentFeePage,
  LoyaltyPctGeneralSettings,
  LoyaltyPrograms,
  ManualClosure,
  MembershipFeesPage,
  MinimumToPayFormulaPage,
  MultiCurrency,
  MurabahaProfitCalculationPage,
  MurabahaProfitPage,
  OverlimitFeePage,
  PaymentAndDelinquencySettings,
  PaymentDuePage,
  PaymentSchemasServices,
  PctInsurancePrograms,
  PctLoyaltyPrograms,
  PctPage,
  ProductDetails,
  SettingsOverviewPage,
  SmsNotification,
  StatementFeePage,
  TawarruqProfitCalculationPage,
  TawarruqProfitPage,
  TopUpFeesPage,
  TransactionRestrictionsPage,
} from ".";

export const getProductPagesRoutes = (guard: FeatureGuard): RoutesTreeItem[] => [
  {
    name: "Product Identification",
    children: [
      {
        name: "Product Details",
        route: "product-details",
        element: <ProductDetails />,
      },
      {
        name: "Currency Setup",
        route: "currency-setup",
        element: <MultiCurrency />,
        dependency: "$..productValues[?(@.fieldCode == 'balance-owner' && @.value == 'CMS')]",
        dependencyType: "PRODUCT",
      },
      {
        name: "Card Numbering",
        route: "card-numbering",
        element: <CardNumbering />,
      },
    ],
  },
  {
    name: "Credit Product Settings",
    children: [
      {
        name: "Credit Limit",
        route: "credit-limit",
        element: <CreditLimit />,
      },
      {
        name: "Payment and Delinquency",
        route: "payment-and-delinquency-settings",
        element: <PaymentAndDelinquencySettings />,
      },
      {
        name: "Minimum To Pay Formula",
        route: "minimum-to-pay-formula",
        element: <MinimumToPayFormulaPage />,
      },
      {
        name: "Interest Grace Options",
        route: "interest-grace-options",
        element: <InterestGracePage />,
        dependency: "!$..productValues[?(@.fieldCode == 'credit-type' && @.value == 'Islamic')]",
        dependencyType: "PRODUCT",
      },
      {
        name: "Murabaha Profit Settings",
        route: "murabaha-profit-settings",
        element: <MurabahaProfitPage />,
        dependency:
          "$..productValues[?(@.fieldCode == 'credit-type' && @.value == 'Islamic')] AND $..productValues[?(@.fieldCode == 'credit-type-islamic' && @.value == 'Murabaha')]",
        dependencyType: "PRODUCT",
      },
      {
        name: "Tawarruq Profit Settings",
        route: "tawarruq-profit-settings",
        element: <TawarruqProfitPage />,
        dependency:
          "$..productValues[?(@.fieldCode == 'credit-type' && @.value == 'Islamic')] AND $..productValues[?(@.fieldCode == 'credit-type-islamic' && @.value == 'Tawarruq')]",
        dependencyType: "PRODUCT",
      },
      {
        name: "Direct Debit Configuration",
        route: "direct-debit-configuration",
        element: <DirectDebitConfigurationPage />,
      },
    ],
    dependency:
      "$..productValues[?(@.fieldCode == 'balance-owner' && @.value == 'CMS')] AND $..productValues[?(@.fieldCode == 'product-type' && @.value == 'Credit')]",
    dependencyType: "PRODUCT",
  },
  {
    name: "Insurance Programs",
    route: "insurance-programs",
    element: <InsurancePrograms />,
    dependency: "$..productValues[?(@.fieldCode == 'product-type' && @.value == 'Credit')]",
    dependencyType: "PRODUCT",
  },
  {
    name: "Card and PIN Maintenance",
    route: "card-and-pin-maintenance",
    element: <CardAndPinMaintenance />,
  },
  {
    name: "SMS Notification & Statement Generation",
    route: "sms-notification",
    element: <SmsNotification />,
  },
  {
    name: "Payment Schemas Services",
    route: "payment-schemas-services",
    element: <PaymentSchemasServices />,
  },
  {
    name: "Contract Closure",
    children: [
      {
        name: "Manual Closure",
        route: "manual-closure",
        element: <ManualClosure />,
      },
      {
        name: "Auto-closure",
        route: "auto-closure",
        element: <AutoClosure />,
      },
      {
        name: "Purge",
        route: "purge",
        element: <ContractPurge />,
      },
    ],
  },
  {
    name: "Fee Settings",
    route: "fees-settings",
    element: <FeeSettings />,
  },
  {
    name: "Loyalty",
    guard: guard("LTY"),
    children: [
      {
        name: "General Settings",
        route: "loyalty-settings",
        element: <GeneralLoyaltySettings />,
        dependency: "$..shortLoyaltyPrograms[?(@.state== 'ACTIVE')]",
        dependencyType: "PRODUCT",
      },
      {
        name: "Loyalty Programs",
        route: "loyalty-programs",
        element: <LoyaltyPrograms />,
      },
    ],
  },
  {
    name: "Pricing Control Tables",
    route: "pct/",
    element: <PctPage />,
  },
];

export const getPctPagesRoutes = (guard: FeatureGuard, isCredit: boolean): RoutesTreeItem[] => [
  {
    name: "Settings Overview",
    route: `pct/${PCT_ID_KEY}/settings-overview`,
    element: <SettingsOverviewPage />,
  },
  {
    name: "Transaction Restrictions",
    route: `pct/${PCT_ID_KEY}/transaction-restrictions`,
    element: <TransactionRestrictionsPage />,
  },
  {
    name: "Transaction Limits",
    children: [
      {
        name: "Account Limits - Daily",
        route: `pct/${PCT_ID_KEY}/account-limits-daily`,
        element: <AccountLimitsDailyPage />,
      },
      {
        name: "Account Limits - Billing Period",
        route: `pct/${PCT_ID_KEY}/account-limits-billing-period`,
        element: <AccountLimitsBillingPage />,
        dependency: "$..productValues[?(@.fieldCode == 'balance-owner' && @.value == 'CMS')]",
        dependencyType: "PRODUCT",
      },
      {
        name: "Card Limits - Daily",
        route: `pct/${PCT_ID_KEY}/card-limits-daily`,
        element: <CardLimitsDailyPage />,
      },
      {
        name: "Card Limits - Monthly",
        route: `pct/${PCT_ID_KEY}/card-limits-monthly`,
        element: <CardLimitsMonthlyPage />,
      },
      {
        name: "Card Limits - Billing Period",
        route: `pct/${PCT_ID_KEY}/card-limits-billing-period`,
        element: <CardLimitsBillingPage />,
        dependency: "$..productValues[?(@.fieldCode == 'balance-owner' && @.value == 'CMS')]",
        dependencyType: "PRODUCT",
      },
    ],
  },
  {
    name: `${isCredit ? "Payment" : "Top-up"} Limits`,
    children: [
      {
        name: "Additional Rules",
        route: `pct/${PCT_ID_KEY}/additional-rules`,
        element: <AdditionalRulesPage isCredit={isCredit} />,
      },
      {
        name: "Account Limits",
        route: `pct/${PCT_ID_KEY}/account-limits`,
        element: <AccountLimitsPage isCredit={isCredit} />,
      },
      {
        name: "Card Limits",
        route: `pct/${PCT_ID_KEY}/card-limits`,
        element: <CardLimitPage isCredit={isCredit} />,
      },
    ],
    dependency: "$..productValues[?(@.fieldCode == 'balance-owner' && @.value == 'CMS')]",
    dependencyType: "PRODUCT",
  },
  {
    name: "General Fees",
    children: [
      {
        name: "Membership Fees",
        route: `pct/${PCT_ID_KEY}/membership-fees`,
        element: <MembershipFeesPage />,
      },
      {
        name: "Joining Fee",
        route: `pct/${PCT_ID_KEY}/joining-fee`,
        element: <JoiningFeePage />,
      },
      {
        name: "Card Production Fees",
        route: `pct/${PCT_ID_KEY}/card-production-fees`,
        element: <CardProductionFeesPage />,
      },
      {
        name: "Statement Fee",
        route: `pct/${PCT_ID_KEY}/statement-fee`,
        element: <StatementFeePage />,
      },
      {
        name: "Inactivity Fees",
        route: `pct/${PCT_ID_KEY}/inactivity-fees`,
        element: <InactivityFeePage />,
        dependency: "$..productValues[?(!(@.fieldCode == 'curr-setup' && @.value == 'mult'))]",
        dependencyType: "PRODUCT",
      },
      {
        name: "Overlimit Fee",
        route: `pct/${PCT_ID_KEY}/overlimit-fee`,
        element: <OverlimitFeePage />,
        dependency: "$..productValues[?(@.fieldCode == 'product-type' && @.value == 'Credit')]",
        dependencyType: "PRODUCT",
      },
      {
        name: "Late Payment Fee",
        route: `pct/${PCT_ID_KEY}/late-payment-fee`,
        element: <LatePaymentFeePage />,
        dependency:
          "$..productValues[?(@.fieldCode == 'balance-owner' && @.value == 'CMS')] AND $..productValues[?(@.fieldCode == 'product-type' && @.value == 'Credit')]",
        dependencyType: "PRODUCT",
      },
      {
        name: "Credit Limit Modification Fees",
        route: `pct/${PCT_ID_KEY}/credit-limit-modification-fees`,
        element: <CreditLimitModificationFeesPage />,
        dependency:
          "$..productValues[?(@.fieldCode == 'balance-owner' && @.value == 'CMS')] AND $..productValues[?(@.fieldCode == 'product-type' && @.value == 'Credit')]",
        dependencyType: "PRODUCT",
      },
    ],
  },
  {
    name: "Transaction Fees",
    children: [
      {
        name: "Card Transaction Fees",
        route: `pct/${PCT_ID_KEY}/card-transaction-fees`,
        element: <CardTransactionFeesPage />,
      },
      {
        name: "Top-up Fees",
        route: `pct/${PCT_ID_KEY}/top-up-fees`,
        element: <TopUpFeesPage />,
        dependency:
          "$..productValues[?(@.fieldCode == 'balance-owner' && @.value == 'CMS')] AND $..productValues[?(@.fieldCode == 'product-type' && @.value == 'Prepaid')]",
        dependencyType: "PRODUCT",
      },
      {
        name: "Payment Fees",
        route: `pct/${PCT_ID_KEY}/payment-fees`,
        element: <TopUpFeesPage />,
        dependency:
          "$..productValues[?(@.fieldCode == 'balance-owner' && @.value == 'CMS')] AND $..productValues[?(@.fieldCode == 'product-type' && @.value == 'Credit')]",
        dependencyType: "PRODUCT",
      },
      {
        name: "Currency Conversion Fees",
        route: `pct/${PCT_ID_KEY}/currency-conversion-fees`,
        element: <CurrencyConversionFeesPage />,
      },
      {
        name: "Balance Inquiry Fees",
        route: `pct/${PCT_ID_KEY}/balance-inquiry-fees`,
        element: <BalanceInquiryFeesPage />,
      },
      {
        name: "Decline Fee",
        route: `pct/${PCT_ID_KEY}/decline-fee`,
        element: <DeclineFeePage />,
      },
    ],
    dependency: "$..productValues[?(@.fieldCode == 'balance-owner' && @.value == 'CMS')]",
    dependencyType: "PRODUCT",
  },
  {
    name: "Loyalty",
    guard: guard("LTY"),
    children: [
      {
        name: "General Settings",
        route: `pct/${PCT_ID_KEY}/loyalty-settings`,
        element: <LoyaltyPctGeneralSettings />,
      },
      {
        name: "Loyalty Programs",
        route: `pct/${PCT_ID_KEY}/loyalty-programs`,
        element: <PctLoyaltyPrograms />,
      },
    ],
    dependency: "$..shortLoyaltyPrograms[?(@.state == 'ACTIVE')]",
    dependencyType: "PRODUCT",
  },
  {
    name: "Credit Product Settings",
    children: [
      {
        name: "Credit Limit",
        route: `pct/${PCT_ID_KEY}/credit-limit`,
        element: <CreditLimitPage />,
      },
      {
        name: "Payment Due",
        route: `pct/${PCT_ID_KEY}/payment-due`,
        element: <PaymentDuePage />,
      },
      {
        name: "Interest Calculation",
        route: `pct/${PCT_ID_KEY}/interest-calculation`,
        element: <InterestCalculationPage />,
        dependency: "!$..productValues[?(@.fieldCode == 'credit-type' && @.value == 'Islamic')]",
        dependencyType: "PRODUCT",
      },
      {
        name: "Murabaha Profit Calculation",
        route: `pct/${PCT_ID_KEY}/murabaha-profit-calculation`,
        element: <MurabahaProfitCalculationPage />,
        dependency:
          "$..productValues[?(@.fieldCode == 'credit-type' && @.value == 'Islamic')] AND $..productValues[?(@.fieldCode == 'credit-type-islamic' && @.value == 'Murabaha')]",
        dependencyType: "PRODUCT",
      },
      {
        name: "Tawarruq Profit Calculation",
        route: `pct/${PCT_ID_KEY}/tawarruq-profit-calculation`,
        element: <TawarruqProfitCalculationPage />,
        dependency:
          "$..productValues[?(@.fieldCode == 'credit-type' && @.value == 'Islamic')] AND $..productValues[?(@.fieldCode == 'credit-type-islamic' && @.value == 'Tawarruq')]",
        dependencyType: "PRODUCT",
      },
    ],
    dependency:
      "$..productValues[?(@.fieldCode == 'balance-owner' && @.value == 'CMS')] AND $..productValues[?(@.fieldCode == 'product-type' && @.value == 'Credit')]",
    dependencyType: "PRODUCT",
  },
  {
    name: "Installment Payment Plans",
    route: `pct/${PCT_ID_KEY}/installment-payment-plans`,
    element: <InstallmentPaymentPlans />,
    dependency: "$..plans[?(@.state== 'ACTIVE')]",
    dependencyType: "TENANT",
  },
  {
    name: "Insurance Programs",
    route: `pct/${PCT_ID_KEY}/insurance-programs`,
    element: <PctInsurancePrograms />,
    dependency:
      "$..productValues[?(@.fieldCode == 'product-type' && @.value == 'Credit')] AND $..shortInsurancePrograms[?(@.state== 'ACTIVE')]",
    dependencyType: "PRODUCT",
  },
];
