import { FC, useEffect, useMemo, useState } from "react";
import { Button, ConfigProvider, Empty, Form, Modal, Select } from "antd";
import Search from "antd/lib/transfer/search";
import { Link, useNavigate } from "react-router-dom";

import { dashboardStart } from "@ni/common/assets";
import { colors, PRODUCT_CURRENCY } from "@ni/common/constants";
import { useDebaunce, useImport, useReduxState } from "@ni/common/hooks";
import { ListViewIcon, TableViewIcon } from "@ni/common/icons";
import {
  balanceOwners,
  paymentSchemeOptions,
  productStateOptions,
  productTypeOptions,
  tenantOptions,
} from "@ni/common/mocks";
import { DashboardFilters, DashboardTenantUI, FormValues, SelectOption } from "@ni/common/types";
import { DictionarySelect, PageHeading, Spin } from "@ni/common/ui";
import { filterByDisplayValue } from "@ni/common/utils";
import { RoleRestrictionsAccessLevel, RoleRestrictionsObjectLevel, UserFull } from "@ni/sdk/models";

import { ModalWarning, TableView, TenantBlock } from "./components";
import { DashboardContentType } from "./constants";
import { useDashboard } from "./hooks";

import styles from "./styles.module.scss";

const { Option } = Select;

const balanceSourcesOptions: SelectOption[] = [{ value: "ALL", label: "All Balances" }, ...balanceOwners];

export const DashboardLayout: FC = () => {
  const {
    id,
    dashboardContentType,
    dashboardCards,
    viewType,
    filters,
    isLoading,
    getFIDashboard,
    getCardTenants,
    setFilters,
    setViewType,
  } = useDashboard();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [importForm] = Form.useForm();
  const [showImportModal, setShowImportModal] = useState(false);
  const [user] = useReduxState<UserFull>("user");

  const searchQuery = Form.useWatch<string>("searchValue", form);
  const externalCode = Form.useWatch<string>("code", importForm);

  const hasImportPermission = useMemo(
    () =>
      !!user?.userRoles
        ?.flatMap(x => x?.roleRestrictions)
        ?.find(
          x =>
            x?.restrictionsObjectLevel === RoleRestrictionsObjectLevel.APPLICATION &&
            x?.restrictionsAccessLevel === RoleRestrictionsAccessLevel.MANAGE,
        ),
    [user?.userRoles],
  );
  const internalCodeSet = useMemo(() => {
    return new Set(dashboardCards.map(x => x.externalCode?.toString() ?? ""));
  }, [dashboardCards]);

  const { importFiApi, tenants, isLoading: isImporting } = useImport(hasImportPermission);
  const debauncedQuery = useDebaunce({ value: searchQuery, delay: 1000 });

  useEffect(() => {
    if (debauncedQuery !== undefined) {
      setFilters(filters => ({ ...filters, search: debauncedQuery }));
    }
  }, [debauncedQuery, setFilters]);

  useEffect(() => {
    if (viewType === "cards" && id) {
      void getFIDashboard(id, filters);
    }

    if (viewType === "cards" && !id) {
      void getCardTenants(filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, viewType, id]);

  const onFormChange = (currentValue: { [key: string]: string }, value: { [key: string]: string }) => {
    if (value["searchValue"] || typeof value["searchValue"] === "string") {
      return;
    }

    const filter: DashboardFilters = Object.keys(value).reduce((acc, key) => {
      if (value[key] && value[key] !== "ALL") {
        return {
          ...acc,
          filter: {
            ...acc.filter,
            [key]: value[key],
          },
        };
      }

      return acc;
    }, {} as DashboardFilters);

    setFilters(filter);
  };

  const getTenantOptions = (data?: DashboardTenantUI[]) => {
    let options = tenantOptions.map(option => (
      <Option key={option.value + option.label} value={option.value}>
        {option.label}
      </Option>
    ));

    if (data && data.length) {
      options = [
        ...options,
        ...data.map(item => (
          <Option key={item.id} value={item.name}>
            {item.name}
          </Option>
        )),
      ];
    }
    return options;
  };

  const onCreateProduct = (id: number) => {
    if (id) {
      const queries = new URLSearchParams({
        tenantId: String(id),
      });

      navigate({
        pathname: "/create-product",
        search: queries.toString(),
      });
    }
  };

  const handleFiImport = (formValue: FormValues) => {
    if (formValue["code"])
      void importFiApi((formValue["code"] as unknown as string).toString()).finally(() => {
        setShowImportModal(false);
        setFilters({ ...filters });
      });
  };

  const handleTenantCreate = () => {
    navigate({
      pathname: "/create-product",
    });
  };

  const extra = (
    <div className={styles["extra"]} key="extradiv">
      <Button
        className={styles["new-product-button"]}
        type="primary"
        htmlType="button"
        loading={isLoading}
        onClick={handleTenantCreate}
      >
        New Tenant
      </Button>

      {hasImportPermission && (
        <Button
          className={styles["new-product-button"]}
          type="primary"
          htmlType="button"
          loading={isLoading}
          onClick={() => setShowImportModal(true)}
        >
          Import Tenant
        </Button>
      )}

      <Form.Item name="searchValue">
        <Search placeholder="Search" />
      </Form.Item>
      <div className={styles["dashboard-view-switch"]}>
        <Button
          type="default"
          className={styles["view-switch"]}
          icon={<ListViewIcon fill={viewType === "cards" ? colors.primaryColor : colors.lightColor} />}
          onClick={() => setViewType("cards")}
        />
        <Button
          type="default"
          className={styles["view-switch"]}
          icon={<TableViewIcon fill={viewType === "table" ? colors.primaryColor : colors.lightColor} />}
          onClick={() => setViewType("table")}
        />
      </div>
    </div>
  );

  const renderWelcomePage = () => {
    return (
      <div className={styles["DashboardLayout"]}>
        <div className={styles["empty-tenants"]}>
          <img src={dashboardStart} alt="start" />
          <div className={styles["empty-tenants-title"]}>Welcome to our issuer self-service portal</div>
          <div className={styles["empty-tenants-description"]}>
            Here you will be able to setup and configure your products from our rich feature sets, our smart setup
            wizards will guide you through the process with recommended business parameters included.
          </div>
          <Link to="/create-product">
            <Button type="primary" size="large" loading={isLoading}>
              Let&#39;s start!
            </Button>
          </Link>
        </div>
      </div>
    );
  };

  return (
    <ConfigProvider componentSize="middle">
      {dashboardContentType === DashboardContentType.initial && <Spin />}

      {dashboardContentType !== DashboardContentType.initial &&
        dashboardContentType !== DashboardContentType.welcome && (
          <Form form={form} className={styles["DashboardLayout"]} onValuesChange={onFormChange}>
            <PageHeading text="Dashboard" extraElements={[extra]} />
            <div className={styles["filters-bar"]}>
              <Form.Item name="tenant.name">
                <Select placeholder="Tenants">{getTenantOptions(dashboardCards)}</Select>
              </Form.Item>
              <Form.Item name="product.productType">
                <Select placeholder="Product Type">
                  {productTypeOptions.map(option => (
                    <Option key={option.value + option.label} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="product.paymentScheme">
                <Select placeholder="Payment Scheme">
                  {paymentSchemeOptions.map(option => (
                    <Option key={option.value + option.label} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="product.currency">
                <DictionarySelect
                  placeholder="Currency"
                  filterOption={filterByDisplayValue}
                  code={PRODUCT_CURRENCY}
                  showSearch={true}
                  options={[{ value: "ALL", label: "All Currencies" }]}
                />
              </Form.Item>
              <Form.Item name="product.balanceOwner">
                <Select placeholder="Balance Source">
                  {balanceSourcesOptions.map(option => (
                    <Option key={option.value + option.label} value={option.value}>
                      {option.aliasLabel || option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="product.productState">
                <Select placeholder="Product State">
                  {productStateOptions.map(option => (
                    <Option key={option.value + option.label} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <Modal
              title="Select tenant to import"
              open={showImportModal}
              width="568px"
              onCancel={() => setShowImportModal(false)}
              footer={[
                <Button key="back" disabled={isImporting} onClick={() => setShowImportModal(false)}>
                  Cancel
                </Button>,
                <Button key="submit" type="primary" loading={isImporting} onClick={importForm.submit}>
                  Confirm
                </Button>,
              ]}
            >
              <Form form={importForm} onFinish={handleFiImport} disabled={isImporting}>
                <Form.Item name="code">
                  <Select placeholder="Tenant" showSearch={true} allowClear={true} filterOption={filterByDisplayValue}>
                    {tenants.map(tenant => (
                      <Option key={tenant.value} value={tenant.value}>
                        {tenant.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form>
              <ModalWarning externalCode={externalCode} tenantsSet={internalCodeSet} />
            </Modal>

            {isLoading ? (
              <Spin />
            ) : (
              <>
                {viewType === "cards" &&
                  dashboardCards.map(tenant => (
                    <TenantBlock
                      tenant={tenant}
                      key={tenant.id}
                      onDuplicateSuccess={() => getCardTenants(filters)}
                      onCreateProduct={onCreateProduct}
                    />
                  ))}

                {viewType === "cards" && dashboardCards.length === 0 && <Empty />}

                {viewType === "table" && <TableView filters={filters} isFISelected={!!id} selectedFIId={id ?? ""} />}
              </>
            )}
          </Form>
        )}
      {dashboardContentType === DashboardContentType.welcome && renderWelcomePage()}
    </ConfigProvider>
  );
};
