import { ReactNode, useState } from "react";
import { Modal, Space } from "antd";

import { SettingOutlined } from "@ant-design/icons";

import { PrintModeToggle } from "../../../Form";
import { LabelCodeToggle } from "../../../Labels";
import { WizardShortcutToggle } from "../../../WizardShortcut";

import styles from "./styles.module.scss";

export const Settings = ({ children }: { children: ReactNode }) => {
  const [isVisiable, setIsVisiable] = useState(false);

  return (
    <>
      <Space onClick={() => setIsVisiable(true)} className={styles["settings-icon"]}>
        {children || <SettingOutlined />}
      </Space>
      <Modal
        title="Settings"
        open={isVisiable}
        cancelText="Cancel"
        width="570px"
        onCancel={() => setIsVisiable(false)}
        closable={true}
        onOk={undefined}
        footer={[]}
      >
        <Space direction="vertical" className={styles["settings-modal-options-container"]} size={16}>
          <Space direction="horizontal">
            <LabelCodeToggle />
            Field code tooltip
          </Space>
          <Space direction="horizontal">
            <WizardShortcutToggle />
            Wizard shortcut options
          </Space>
          <Space direction="horizontal">
            <PrintModeToggle />
            Print Mode
          </Space>
        </Space>
      </Modal>
    </>
  );
};
