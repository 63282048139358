import { FC, useCallback, useEffect, useMemo } from "react";
import { Form, Input, Select, Space, Switch } from "antd";

import { PRODUCT_CURRENCY } from "@ni/common/constants";
import { useFormButtonDisabled, useHydrateForm, useReduxState } from "@ni/common/hooks";
import { paymentMethodKeyToValueMapper, paymentMethodsOptions } from "@ni/common/mocks";
import { BaseWizardPageProps, FormValues, SelectOption } from "@ni/common/types";
import { CustomFormWrapper, DictionarySelect, FormItemLabel, TooltipInfo } from "@ni/common/ui";
import { filterByDisplayValue } from "@ni/common/utils";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

export interface Page4Props {
  formDisabled: boolean;
}

const wizardServicesApi = new ProductAndTenantWizardApi();

export const ProductDetailsPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  const nicProdIpsList = paymentMethodsOptions;
  const paymentMethodsMapper = paymentMethodKeyToValueMapper as { [key: string]: string };
  const nicCobadgeList: SelectOption[] = [{ value: "MADA", key: "MADA" }];

  const [isButtonDisabled, onFormChange] = useFormButtonDisabled({ form });

  const currencyLabel = useMemo(() => {
    return wizardResponse.product?.productValues?.find(item => item.fieldCode === "curr-setup")?.value;
  }, [wizardResponse.product?.productValues]);

  const setDisplayNameValue = useCallback(() => {
    const values: FormValues = form.getFieldsValue();
    const coBadgedName = ` ${values["co-badged-name"] ? ` - ${values["co-badged-name"] as string}` : ""} `;

    const paymentMethodValue = `${paymentMethodsMapper[values["nic-prod-ips"] as string] || ""}`;
    const coBadgedNameValue = values["is-co-badged-product"] ? ` ${coBadgedName}` : "";
    const coreNameValue = values["nic-prod-core-name"] ? ` ${values["nic-prod-core-name"] as string}` : "";
    const niccyValue = values["nic-ccy"] ? ` ${values["nic-ccy"] as string}` : "";
    const multiCurrencyValue = currencyLabel === "mult" ? " Multi-currency" : "";

    form.setFieldsValue({
      displayName: `${paymentMethodValue}${coBadgedNameValue}${coreNameValue}${niccyValue}${multiCurrencyValue}`,
    });
  }, [currencyLabel, form, paymentMethodsMapper]);

  useHydrateForm({
    form,
    entityFields: wizardResponse.product?.productValues ?? [],
    keys: {
      strings: [
        "is-co-badged-product",
        "nic-mada-use",
        "co-badged-name",
        "nic-ccy",
        "nic-prod-core-name",
        "nic-prod-ips",
      ],
    },
  });

  useEffect(() => {
    form.setFieldValue("displayName", wizardResponse.product?.displayName);
  }, [form, wizardResponse]);

  const validateForm = () => {
    form.validateFields().finally(null);
  };

  const clearMadaSelect = () => {
    if (!form.getFieldValue("is-co-badged-product")) {
      form.setFieldsValue({
        "co-badged-name": "",
      });

      setDisplayNameValue();
    }

    validateForm();
  };

  const onFinish = (values: FormValues) => {
    setIsLoading(true);

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: {
        ...values,
        "nic-mada-use": String(values["co-badged-name"] === "MADA"),
      },
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setIsLoading(false);
        setWizardResponse(response.data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      buttonDisabled={isButtonDisabled}
      pageTitle="Product Details"
      size="md"
      formSize="sm"
      gap={16}
      level="root"
      onValuesChange={onFormChange}
      submitHandler={onFinish}
    >
      <Form.Item
        name="nic-prod-ips"
        label={<FormItemLabel label="Payment scheme" code="nic-prod-ips" />}
        className="wizard-input-pct"
        rules={[{ required: true, message: "Payment scheme is required!" }]}
      >
        <Select onChange={setDisplayNameValue}>
          {nicProdIpsList.map(listEntry => (
            <Select.Option key={listEntry.key} value={listEntry.key}>
              <FormItemLabel label={listEntry.value} code={listEntry.key} />
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Space direction="horizontal">
        <Form.Item name="is-co-badged-product" valuePropName="checked">
          <Switch onChange={clearMadaSelect} disabled={true} />
        </Form.Item>
        <FormItemLabel label="Co-badged product" code="is-co-badged-product" />
      </Space>

      <Form.Item dependencies={["is-co-badged-product"]}>
        {() => (
          <Form.Item
            name="co-badged-name"
            label={<FormItemLabel label="Co-badged scheme" code="co-badged-name" />}
            className="wizard-input-pct"
            rules={[
              {
                required: form.getFieldValue("is-co-badged-product") as boolean,
                message: "Co-badged Scheme is required.",
              },
            ]}
          >
            <Select
              onChange={setDisplayNameValue}
              disabled={formDisabled || !form.getFieldValue("is-co-badged-product")}
            >
              {nicCobadgeList.map(listEntry => (
                <Select.Option key={listEntry.key} value={listEntry.value}>
                  <FormItemLabel label={listEntry.value} code={listEntry.value} />
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </Form.Item>

      <Form.Item
        name="nic-prod-core-name"
        label={<FormItemLabel label="Product name" code="nic-prod-core-name" />}
        className="wizard-input-pct"
        rules={[
          { required: true, message: "Product Name is required!" },
          { max: 255, message: "Product Name must have maximum 255 characters." },
          {
            pattern: /^[^\t\r{}]*$/,
            message: "Product Name contains not permitted characters as: “TAB”, `{', '}` ”.",
          },
        ]}
      >
        <Input placeholder="Product Name" onChange={setDisplayNameValue} />
      </Form.Item>

      <Form.Item
        name="nic-ccy"
        label={
          <div>
            <TooltipInfo
              label="Product base Currency"
              code="nic-ccy"
              tooltipProps={{
                title:
                  "Set the base product currency. Additional currency setup options will be available in the upcoming screens.",
              }}
            />
          </div>
        }
        className="wizard-input-pct"
        rules={[{ required: true, message: "Product Currency is required!" }]}
      >
        <DictionarySelect
          placeholder="Select base currency"
          disabled={formDisabled}
          onChange={setDisplayNameValue}
          filterOption={filterByDisplayValue}
          code={PRODUCT_CURRENCY}
          showSearch={true}
        />
      </Form.Item>

      <Form.Item
        name="displayName"
        label={<FormItemLabel label="Product display name" code="displayName-pw" />}
        className="wizard-input-pct"
      >
        <Input placeholder="Product Displayed Name" disabled={true} />
      </Form.Item>
    </CustomFormWrapper>
  );
};
