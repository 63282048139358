export const LIMITS_HEADINGS = [
  {
    label: "Max number",
    tooltip:
      "This counter restricts maximal number of payments per period. Leave it empty if restrictions are not intended. All zeros prohibit payments at all.",
  },
  {
    label: "Max amount",
    tooltip:
      "This counter restricts maximal amount of payments per period. Leave it empty if restrictions are not intended. All zeros prohibit payments at all.",
  },
  {
    label: "Max single amount",
    tooltip:
      "This counter restricts maximal amount of single payment. Leave it empty if restrictions are not intended. All zeros prohibit payments at all.",
  },
];
