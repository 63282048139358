import { FC, useEffect, useState } from "react";
import { Col, Form, Input, Row, Space, Switch } from "antd";
import NumberFormat from "react-number-format";

import { useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, FormItemLabel, TooltipInfo } from "@ni/common/ui";
import { getFormValueFromProductValues } from "@ni/common/utils";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { ProductValue, TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";
import { checkHeader, headerSwitchHandler } from "@ni/tenant-portal/core";

const wizardServicesApi = new ProductAndTenantWizardApi();

const switchHeader30: { [key: string]: string[] } = {
  "fx-fee-enabled": ["ips-markup-fee-enabled", "ips-dcc-cash-fee-enabled"],
};

export const CurrencyConversionsFeesPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const [, setIsLoading] = useReduxState<boolean>("isLoading");
  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [isMinMaxFeeEnabled, setIsMinMaxFeeEnabled] = useState<boolean>(true);
  const [isMinMaxFeeEnabledCash, setIsMinMaxFeeEnabledCash] = useState<boolean>(true);

  useEffect(() => {
    const formDisabledFn = (field: string, number: string | number) =>
      formDisabled
        ? (getFormValueFromProductValues(
            wizardResponse.product?.parameterTables![0].pctProductValues as ProductValue[],
            field,
            "string",
          ) as string) || ""
        : number;

    form.setFieldsValue({
      "fx-fee-enabled": formDisabled
        ? getFormValueFromProductValues(
            wizardResponse.product?.parameterTables![0].pctProductValues as ProductValue[],
            "fx-fee-enabled",
            "boolean",
          )
        : true,
      "ips-markup-fee-enabled": formDisabled
        ? getFormValueFromProductValues(
            wizardResponse.product?.parameterTables![0].pctProductValues as ProductValue[],
            "ips-markup-fee-enabled",
            "boolean",
          )
        : true,
      "nic-ips-markup-cash-fee-rate": formDisabledFn("nic-ips-markup-cash-fee-rate", "2.5"),
      "nic-ips-markup-retail-fee-rate": formDisabledFn("nic-ips-markup-retail-fee-rate", "2.5"),
      "ips-dcc-cash-fee-enabled": formDisabled
        ? getFormValueFromProductValues(
            wizardResponse.product?.parameterTables![0].pctProductValues as ProductValue[],
            "ips-dcc-cash-fee-enabled",
            "boolean",
          )
        : true,
      "nic-ips-dcc-cash-fee-rate": formDisabledFn("nic-ips-dcc-cash-fee-rate", "2.5"),
      "nic-ips-dcc-retail-fee-rate": formDisabledFn("nic-ips-dcc-retail-fee-rate", "2.5"),
      "nic-ips-dcc-cash-base": formDisabledFn("nic-ips-dcc-cash-base", ""),
      "nic-ips-dcc-cash-min": formDisabledFn("nic-ips-dcc-cash-min", ""),
      "nic-ips-dcc-cash-max": formDisabledFn("nic-ips-dcc-cash-max", ""),
      "nic-ips-dcc-retail-base": formDisabledFn("nic-ips-dcc-retail-base", ""),
      "nic-ips-dcc-retail-min": formDisabledFn("nic-ips-dcc-retail-min", ""),
      "nic-ips-dcc-retail-max": formDisabledFn("nic-ips-dcc-retail-max", ""),
    });

    checkHeader("fx-fee-enabled", switchHeader30, form);
  }, [form, formDisabled, wizardResponse.product?.parameterTables]);

  const currency = (getFormValueFromProductValues(wizardResponse.product?.productValues, "nic-ccy") as string) || "";

  const onFormChange = () => {
    setIsMinMaxFeeEnabled(!!form.getFieldValue("nic-ips-dcc-retail-fee-rate")?.length);
    setIsMinMaxFeeEnabledCash(!!form.getFieldValue("nic-ips-dcc-cash-fee-rate")?.length);
    if (form.getFieldValue("nic-ips-dcc-cash-fee-rate") === "") {
      form.setFieldValue("nic-ips-dcc-cash-min", "");
      form.setFieldValue("nic-ips-dcc-cash-max", "");
    }
    if (form.getFieldValue("nic-ips-dcc-retail-fee-rate") === "") {
      form.setFieldValue("nic-ips-dcc-retail-min", "");
      form.setFieldValue("nic-ips-dcc-retail-max", "");
    }
  };

  const onFinish = () => {
    setIsLoading(true);

    const isFxMarkupAvailable = String(form.getFieldValue("ips-markup-fee-enabled")) === "true";
    const isDccTransactionAvailable = String(form.getFieldValue("ips-dcc-cash-fee-enabled")) === "true";

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues:
        String(form.getFieldValue("fx-fee-enabled")) === "true"
          ? ({
              "fx-fee-enabled": String(form.getFieldValue("fx-fee-enabled")),

              // FX markup
              "ips-markup-fee-enabled": String(form.getFieldValue("ips-markup-fee-enabled")),
              "nic-ips-markup-cash-fee-rate": isFxMarkupAvailable
                ? String(form.getFieldValue("nic-ips-markup-cash-fee-rate")).replace(/ /g, "")
                : null,
              "nic-ips-markup-retail-fee-rate": isFxMarkupAvailable
                ? String(form.getFieldValue("nic-ips-markup-retail-fee-rate")).replace(/ /g, "")
                : null,

              // DCC transaction
              "ips-dcc-cash-fee-enabled": String(form.getFieldValue("ips-dcc-cash-fee-enabled")),
              "nic-ips-dcc-cash-fee-rate": isDccTransactionAvailable
                ? String(form.getFieldValue("nic-ips-dcc-cash-fee-rate")).replace(/ /g, "")
                : null,
              "nic-ips-dcc-retail-fee-rate": isDccTransactionAvailable
                ? String(form.getFieldValue("nic-ips-dcc-retail-fee-rate")).replace(/ /g, "")
                : null,
              "nic-ips-dcc-cash-base": isDccTransactionAvailable
                ? String(form.getFieldValue("nic-ips-dcc-cash-base")).replace(/ /g, "")
                : null,
              "nic-ips-dcc-cash-min":
                String(form.getFieldValue("nic-ips-dcc-cash-fee-rate")) === "" || !isDccTransactionAvailable
                  ? null
                  : String(form.getFieldValue("nic-ips-dcc-cash-min")).replace(/ /g, ""),
              "nic-ips-dcc-cash-max":
                String(form.getFieldValue("nic-ips-dcc-cash-fee-rate")) === "" || !isDccTransactionAvailable
                  ? null
                  : String(form.getFieldValue("nic-ips-dcc-cash-max")).replace(/ /g, ""),
              "nic-ips-dcc-retail-base": isDccTransactionAvailable
                ? String(form.getFieldValue("nic-ips-dcc-retail-base")).replace(/ /g, "")
                : null,
              "nic-ips-dcc-retail-min":
                String(form.getFieldValue("nic-ips-dcc-retail-fee-rate")) === "" || !isDccTransactionAvailable
                  ? null
                  : String(form.getFieldValue("nic-ips-dcc-retail-min")).replace(/ /g, ""),
              "nic-ips-dcc-retail-max":
                String(form.getFieldValue("nic-ips-dcc-retail-fee-rate")) === "" || !isDccTransactionAvailable
                  ? null
                  : String(form.getFieldValue("nic-ips-dcc-retail-max")).replace(/ /g, ""),
            } as { [key: string]: string })
          : ({ "fx-fee-enabled": String(form.getFieldValue("fx-fee-enabled")) } as { [key: string]: string }),
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Currency Conversions Fees"
      pageSubtitle="Currency conversion occurs when contract currency and transaction currency are not the same."
      size="md"
      formSize="full"
      gap={24}
      level="root"
      onValuesChange={onFormChange}
      submitHandler={onFinish}
    >
      <Space direction="horizontal" align="center">
        <Form.Item valuePropName="checked" name="fx-fee-enabled" className="col-switch">
          <Switch
            onClick={value => {
              headerSwitchHandler("fx-fee-enabled", switchHeader30, value, form);
            }}
          />
        </Form.Item>

        <TooltipInfo label="Enable Currency conversions fees on your product" code="fx-fee-enabled" tooltipProps={{}} />
      </Space>
      <div className="limits-block small">
        <div className="limits-block-header">
          <Form.Item valuePropName="checked" name="ips-markup-fee-enabled" className="header-switch">
            <Switch onClick={() => checkHeader("fx-fee-enabled", switchHeader30, form)} />
          </Form.Item>
          <div className="col-switch-desc">
            <TooltipInfo
              label="FX markup"
              code="ips-markup-fee-enabled"
              tooltipProps={{
                title:
                  "FX markup fee is defined as the fee charged to the customer for the transactions where contract currency is different from transaction currency.",
              }}
            />
          </div>
        </div>
        <div className="limits-block-body">
          <Row>
            <Col span={4} />

            <Col span={20} className="col-header">
              <TooltipInfo
                label="Fee %"
                tooltipProps={{
                  title:
                    "The parameter specifies the percentage of fee amount that needs to be calculated on original transaction. Total Fee = Fee fixed + Fee %",
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <div className="col-switch-desc">
                <TooltipInfo
                  label="Cash"
                  tooltipProps={{ title: "Includes cash withdrawal in ATM and POS machines" }}
                />
              </div>
            </Col>
            <Col span={20} className="limits-input">
              <Form.Item dependencies={["ips-markup-fee-enabled", "ips-markup-fee-enabled"]}>
                {() => {
                  return (
                    <FormItemLabel code="nic-ips-markup-cash-fee-rate">
                      <Form.Item name="nic-ips-markup-cash-fee-rate">
                        <NumberFormat
                          customInput={Input}
                          addonAfter="%"
                          allowNegative={false}
                          disabled={
                            !form.getFieldValue("ips-markup-fee-enabled") ||
                            !form.getFieldValue("ips-markup-fee-enabled") ||
                            formDisabled
                          }
                          thousandSeparator=" "
                          decimalScale={2}
                          decimalSeparator="."
                          fixedDecimalScale={true}
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <div className="col-switch-desc">Retail</div>
            </Col>
            <Col span={20} className="limits-input">
              <Form.Item dependencies={["ips-markup-fee-enabled", "ips-markup-fee-enabled"]}>
                {() => {
                  return (
                    <FormItemLabel code="nic-ips-markup-retail-fee-rate">
                      <Form.Item name="nic-ips-markup-retail-fee-rate">
                        <NumberFormat
                          customInput={Input}
                          addonAfter="%"
                          allowNegative={false}
                          disabled={
                            !form.getFieldValue("ips-markup-fee-enabled") ||
                            !form.getFieldValue("ips-markup-fee-enabled") ||
                            formDisabled
                          }
                          thousandSeparator=" "
                          decimalScale={2}
                          decimalSeparator="."
                          fixedDecimalScale={true}
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>
      <div className="limits-block small">
        <div className="limits-block-header">
          <Form.Item valuePropName="checked" name="ips-dcc-cash-fee-enabled" className="header-switch">
            <Switch onClick={() => checkHeader("fx-fee-enabled", switchHeader30, form)} />
          </Form.Item>
          <div className="col-switch-desc">
            <TooltipInfo
              label="DCC transaction"
              code="ips-dcc-cash-fee-enabled"
              tooltipProps={{
                title:
                  "Dynamic currency conversion is a process whereby the amount of a transaction is converted at the point of sale, ATM or internet to the currency of the card's country of issue. DCC is generally provided by third party operators in association with the merchant, and not by a card issuer. DCC fee is not charged for transactions where FX markup fee is charged.",
              }}
            />
          </div>
        </div>
        <div className="limits-block-body">
          <Row>
            <Col span={4} />
            <Col span={5} className="col-header">
              <TooltipInfo
                label="  Fee fixed"
                tooltipProps={{
                  title: "The parameter specifies the fixed fee amount to be charged for the transaction.",
                }}
              />
            </Col>
            <Col span={5} className="col-header">
              <TooltipInfo
                label="  Fee %"
                tooltipProps={{
                  title:
                    "The parameter specifies the percentage of fee amount that needs to be calculated on original transaction. Total Fee = Fee fixed + Fee %",
                }}
              />
            </Col>
            <Col span={5} className="col-header">
              <TooltipInfo
                label="Min Fee"
                tooltipProps={{
                  title:
                    "The parameter specifies the minimum fee amount that needs to be charged to the customer. Incase of calculated `Total Fee` is lesser than the Min fee defined then Min fee will be charged to the customer. If Min Fee > Fee fixed + Fee % then Min Fee will be charged to customer.",
                }}
              />
            </Col>
            <Col span={5} className="col-header">
              <TooltipInfo
                label="Max Fee"
                tooltipProps={{
                  title:
                    "The parameter specifies the maximum fee amount that needs to be charged to the customer. Incase of calculated `Total Fee` is greater than the Max fee defined then Max fee will be charged to the customer. If Max Fee < Fee fixed + Fee % then Max Fee will be charged to customer. ",
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <div className="col-switch-desc">
                <TooltipInfo
                  label="Cash"
                  tooltipProps={{ title: "Includes cash withdrawal in ATM and POS machines" }}
                />
              </div>
            </Col>
            <Col span={5}>
              <Form.Item dependencies={["ips-dcc-cash-fee-enabled"]}>
                {() => {
                  return (
                    <FormItemLabel code="nic-ips-dcc-cash-base">
                      <Form.Item name="nic-ips-dcc-cash-base">
                        <NumberFormat
                          customInput={Input}
                          addonAfter={currency}
                          allowNegative={false}
                          disabled={!form.getFieldValue("ips-dcc-cash-fee-enabled") || formDisabled}
                          thousandSeparator=" "
                          decimalScale={2}
                          decimalSeparator="."
                          fixedDecimalScale={true}
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item dependencies={["ips-dcc-cash-fee-enabled"]}>
                {() => {
                  return (
                    <FormItemLabel code="nic-ips-dcc-cash-fee-rate">
                      <Form.Item name="nic-ips-dcc-cash-fee-rate">
                        <NumberFormat
                          customInput={Input}
                          addonAfter="%"
                          allowNegative={false}
                          disabled={!form.getFieldValue("ips-dcc-cash-fee-enabled") || formDisabled}
                          thousandSeparator=" "
                          decimalScale={2}
                          decimalSeparator="."
                          fixedDecimalScale={true}
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item dependencies={["ips-dcc-cash-fee-enabled"]}>
                {() => {
                  return (
                    <FormItemLabel code="nic-ips-dcc-cash-min">
                      <Form.Item name="nic-ips-dcc-cash-min">
                        <NumberFormat
                          customInput={Input}
                          addonAfter={currency}
                          allowNegative={false}
                          disabled={
                            !form.getFieldValue("ips-dcc-cash-fee-enabled") || !isMinMaxFeeEnabledCash || formDisabled
                          }
                          thousandSeparator=" "
                          decimalScale={2}
                          decimalSeparator="."
                          fixedDecimalScale={true}
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item dependencies={["ips-dcc-cash-fee-enabled"]}>
                {() => {
                  return (
                    <FormItemLabel code="nic-ips-dcc-cash-max">
                      <Form.Item name="nic-ips-dcc-cash-max">
                        <NumberFormat
                          customInput={Input}
                          addonAfter={currency}
                          allowNegative={false}
                          disabled={
                            !form.getFieldValue("ips-dcc-cash-fee-enabled") || !isMinMaxFeeEnabledCash || formDisabled
                          }
                          thousandSeparator=" "
                          decimalScale={2}
                          decimalSeparator="."
                          fixedDecimalScale={true}
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <div className="col-switch-desc">Retail</div>
            </Col>
            <Col span={5}>
              <Form.Item dependencies={["ips-dcc-cash-fee-enabled"]}>
                {() => {
                  return (
                    <FormItemLabel code="nic-ips-dcc-retail-base">
                      <Form.Item name="nic-ips-dcc-retail-base">
                        <NumberFormat
                          customInput={Input}
                          addonAfter={currency}
                          allowNegative={false}
                          disabled={!form.getFieldValue("ips-dcc-cash-fee-enabled") || formDisabled}
                          thousandSeparator=" "
                          decimalScale={2}
                          decimalSeparator="."
                          fixedDecimalScale={true}
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item dependencies={["ips-dcc-cash-fee-enabled"]}>
                {() => {
                  return (
                    <FormItemLabel code="nic-ips-dcc-retail-fee-rate">
                      <Form.Item name="nic-ips-dcc-retail-fee-rate">
                        <NumberFormat
                          customInput={Input}
                          addonAfter="%"
                          allowNegative={false}
                          disabled={!form.getFieldValue("ips-dcc-cash-fee-enabled") || formDisabled}
                          thousandSeparator=" "
                          decimalScale={2}
                          decimalSeparator="."
                          fixedDecimalScale={true}
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item dependencies={["ips-dcc-cash-fee-enabled"]}>
                {() => {
                  return (
                    <FormItemLabel code="nic-ips-dcc-retail-min">
                      <Form.Item name="nic-ips-dcc-retail-min">
                        <NumberFormat
                          customInput={Input}
                          addonAfter={currency}
                          allowNegative={false}
                          disabled={
                            !form.getFieldValue("ips-dcc-cash-fee-enabled") || !isMinMaxFeeEnabled || formDisabled
                          }
                          thousandSeparator=" "
                          decimalScale={2}
                          decimalSeparator="."
                          fixedDecimalScale={true}
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item dependencies={["ips-dcc-cash-fee-enabled"]}>
                {() => {
                  return (
                    <FormItemLabel code="nic-ips-dcc-retail-max">
                      <Form.Item name="nic-ips-dcc-retail-max">
                        <NumberFormat
                          customInput={Input}
                          addonAfter={currency}
                          allowNegative={false}
                          disabled={
                            !form.getFieldValue("ips-dcc-cash-fee-enabled") || !isMinMaxFeeEnabled || formDisabled
                          }
                          thousandSeparator=" "
                          decimalScale={2}
                          decimalSeparator="."
                          fixedDecimalScale={true}
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>
    </CustomFormWrapper>
  );
};
