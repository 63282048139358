import { FC } from "react";
import { Form, Space, Switch } from "antd";
import { useParams } from "react-router-dom";

import { useHydrateForm, useProductSettings } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, TooltipInfo } from "@ni/common/ui";
import { getFormValueFromProductValues } from "@ni/common/utils";

import styles from "./styles.module.scss";

const stringsKeys = ["nic-card-subtype-abu-active", "nic-card-subtype-token-used", "nic-card-subtype-token-mada-used"];

export const PaymentSchemasServices: FC = () => {
  const [form] = Form.useForm<FormValues>();
  const { productId } = useParams<{ id: string; productId: string }>();

  const { currentProduct, onUpdateProduct } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });

  useHydrateForm(
    {
      form,
      entityFields: currentProduct.productValues ?? [],
      keys: {
        strings: stringsKeys,
      },
    },
    [currentProduct.productValues],
  );

  const signedUpForMada =
    ((getFormValueFromProductValues(currentProduct.productValues, "nic-mada-use") as string) || "") === "true";

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Payment Schemas Services"
      submitHandler={onUpdateProduct}
      size="md"
      formSize="md"
      submitLabel="Save"
    >
      <Space direction="horizontal">
        <Form.Item
          name="nic-card-subtype-abu-active"
          valuePropName="checked"
          rules={[{ required: true, message: "Automated Updating Set is required!" }]}
        >
          <Switch />
        </Form.Item>
        Automated updating “card-on-file“ information for recurring and non-recurring payments (ABU service from
        Payments Schemas)
      </Space>

      <Space direction="horizontal">
        <Form.Item
          name="nic-card-subtype-token-used"
          valuePropName="checked"
          rules={[{ required: true, message: "Tokenization Set is required!" }]}
        >
          <Switch />
        </Form.Item>
        <Space>
          <TooltipInfo
            label="Tokenization (Visa / MasterCard)"
            tooltipProps={{ title: "Tokenization (Visa/ MasterCard)" }}
          />
        </Space>
      </Space>

      {signedUpForMada && (
        <div className={styles["product-details-switch"]}>
          <Form.Item
            name="nic-card-subtype-token-mada-used"
            valuePropName="checked"
            rules={[{ required: true, message: "Tokenization Set is required!" }]}
          >
            <Switch />
          </Form.Item>
          <Space>
            <TooltipInfo label="Tokenization (MADA)" tooltipProps={{ title: "Tokenization (MADA)" }} />
          </Space>
        </div>
      )}

      <Form.Item noStyle={true} shouldUpdate={true}>
        {({ getFieldValue }) =>
          getFieldValue("nic-card-subtype-token-used") === true ||
          getFieldValue("nic-card-subtype-token-mada-used") === true ? (
            <div className="alert-div">
              For some token providers like ApplePay a live BIN needs to be certified with them.
              <br />
              Please make sure that this process is initiated in parallel.
            </div>
          ) : null
        }
      </Form.Item>
    </CustomFormWrapper>
  );
};
