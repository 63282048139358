import { FC, useEffect } from "react";
import { Form, Input, Select } from "antd";

import { QuestionCircleFilled } from "@ant-design/icons";
import { useGetCurrencyList, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues, Value } from "@ni/common/types";
import { CustomFormWrapper, RadioGroup } from "@ni/common/ui";
import { InstallmentPaymentPlanWizardRequest, InstallmentPaymentPlanWizardResponse } from "@ni/sdk/models";

import { useIPPWizardApi } from "../../hooks";

import { PLANS_RADIO_GROUP } from "./constants";

const INITIAL_NAME = "Installment Plan";

export const IPPInitiationPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const planName = Form.useWatch("displayName", form) as string;

  const { processWizardRequest } = useIPPWizardApi();

  const { currencyList: planCurrencies } = useGetCurrencyList("plan-currency");

  const [, setIsLoading] = useReduxState("isLoading", false);
  const [wizardResponse] = useReduxState<InstallmentPaymentPlanWizardResponse>(
    "installmentPaymentPlanWizard",
    {} as InstallmentPaymentPlanWizardResponse,
  );

  useEffect(() => {
    const tenantCurrency = wizardResponse.tenant?.defaultCurrency;

    form.setFieldsValue({
      displayName: wizardResponse?.plan?.name ?? INITIAL_NAME,
      group: wizardResponse?.plan?.group ?? PLANS_RADIO_GROUP[0].value,
      currency: (wizardResponse?.plan?.currency as Value) ?? tenantCurrency,
    });
  }, [form, wizardResponse]);

  const onFinish = async (values: FormValues) => {
    setIsLoading(true);

    try {
      await processWizardRequest({
        pageId: wizardResponse.pageId as number,
        tenantId: wizardResponse?.tenant?.id,
        planId: undefined,
        collectedValues: values as InstallmentPaymentPlanWizardRequest["collectedValues"],
      });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      buttonDisabled={!planName || planName === INITIAL_NAME}
      pageTitle="Installment Payment Plan Initiation"
      pageSubtitle="Please follow the steps to configure a specific Installment Payment Plan (IPP). Once completed, it can be edited and overridden for a particular Pricing Control Table. Multiple plans can be set up, each with different conditions."
      level="tenant"
      size="md"
      formSize="md"
      gap={40}
      submitHandler={onFinish}
      additionalRoute="./installment-payment-plans"
    >
      <Form.Item
        name="displayName"
        label="Plan name"
        tooltip={{
          title: "Specify the short plan name to have a semantic meaning for it.",
          icon: <QuestionCircleFilled />,
        }}
        rules={[{ required: true, message: "Plan name is required" }]}
        initialValue={INITIAL_NAME}
      >
        <Input maxLength={24} />
      </Form.Item>

      <Form.Item name="group" label="Select plan group" initialValue={PLANS_RADIO_GROUP[0].value}>
        <RadioGroup radioList={PLANS_RADIO_GROUP} />
      </Form.Item>

      <Form.Item
        name="currency"
        label="Plan currency"
        tooltip={{
          title: "All currency-related fields for the plan will be specified in this particular currency.",
          icon: <QuestionCircleFilled />,
        }}
        initialValue={wizardResponse.tenant?.defaultCurrency}
      >
        <Select>
          {planCurrencies.map(currency => (
            <Select.Option key={currency.value} value={currency.value}>
              {currency.displayValue}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </CustomFormWrapper>
  );
};
