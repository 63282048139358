import { FC, useEffect } from "react";
import { Form, Space, Switch } from "antd";
import cn from "classnames";
import { useParams } from "react-router-dom";

import { ArrowRightOutlined } from "@ant-design/icons";
import { useReduxState } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, PageItemLayoutElements, PageItemLayoutGroup } from "@ni/common/ui";
import { TenantApi } from "@ni/sdk/apis";
import { ChangeTenantRequest, Tenant } from "@ni/sdk/models";

import styles from "./styles.module.scss";

const tenantsServiceApi = new TenantApi();

interface AdditionServiceDetailsForm {
  "nic-ncontrol-flag-def-new": boolean;
  "nic-ncontrol-flag-editable": boolean;
  "nic-falcon-enable-notif": boolean;
  isCardControlSelected: boolean;
  isFalconSelected: boolean;
}

export const AdditionalServicesDetails: FC = () => {
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const [tenant, setTenant] = useReduxState<Tenant>("tenant", {});
  const [isLoading, setIsLoading] = useReduxState<boolean>("isLoading");

  const setFalcon = (val: boolean) => {
    form.setFieldsValue({
      "nic-falcon-enable-notif": val,
      isFalconSelected: val,
    });
  };

  useEffect(() => {
    if (!isLoading) {
      let nicNControlFlagDefNew = tenant.isCardControlSelected;
      let nicNControlFlagEditable = true;
      let nicFalconEnableNotif = tenant.isFalconSelected;

      tenant?.tenantValues?.forEach(val => {
        if (val.fieldCode === "nic-ncontrol-flag-def-new") {
          nicNControlFlagDefNew = val.value === "true";
        }
        if (val.fieldCode === "nic-ncontrol-flag-editable") {
          nicNControlFlagEditable = val.value === "true";
        }
        if (val.fieldCode === "nic-falcon-enable-notif") {
          nicFalconEnableNotif = val.value === "true";
        }
      });

      form.setFieldsValue({
        "nic-ncontrol-flag-def-new": nicNControlFlagDefNew,
        "nic-ncontrol-flag-editable": nicNControlFlagEditable,
        "nic-falcon-enable-notif": nicFalconEnableNotif,
        isCardControlSelected: tenant.isCardControlSelected,
        isFalconSelected: tenant.isFalconSelected,
      });
    }
  }, [form, isLoading, tenant]);

  const onFinish = (value: AdditionServiceDetailsForm) => {
    setIsLoading(true);
    const tenantId = parseInt(id ?? "0", 10);

    const requestBody: ChangeTenantRequest = {
      isCardControlSelected: value.isCardControlSelected,
      isFalconSelected: value.isFalconSelected,
      tenantValues: Object.keys(value).map(val => {
        if (val === "isCardControlSelected" || val === "isFalconSelected") {
          return {};
        }

        return {
          fieldCode: val,
          value: value[val as keyof AdditionServiceDetailsForm] ? "true" : "false",
        };
      }),
    };
    tenantsServiceApi
      .editTenant(requestBody, tenantId)
      .then(res => {
        setIsLoading(false);
        setTenant(res.data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      pageTitle="Additional services details"
      form={form}
      submitHandler={onFinish as unknown as (values: FormValues) => void}
      formSize="lg"
      size="full"
      submitLabel="Save"
    >
      <div
        className={cn(
          styles["additional-services-details-container"],
          styles["additional-services-details-card-section"],
        )}
      >
        <div className={styles["additional-services-details-card-header"]}>
          <Space direction="horizontal" size={12}>
            <Form.Item valuePropName="checked" name="isFalconSelected">
              <Switch />
            </Form.Item>
            Falcon fraud monitoring
          </Space>
        </div>
        <div className={styles["additional-services-details-card-body"]}>
          <PageItemLayoutGroup>
            <div>
              Falcon is Network International’s peripheral system which provides fraud monitoring facilities. {"  "}
              <a
                href="https://www.network.ae/en/contents/listing/fraud-solutions#book-1"
                target="_blank"
                rel="noreferrer"
              >
                <span>Read more </span>
                <ArrowRightOutlined />
              </a>
            </div>
            <Space direction="horizontal" size={8}>
              <Form.Item dependencies={["isFalconSelected"]}>
                {() => (
                  <Form.Item
                    className={styles["additional-services-details-form-item"]}
                    valuePropName="checked"
                    name="nic-falcon-enable-notif"
                  >
                    <Switch disabled={!form.getFieldValue("isFalconSelected")} onChange={setFalcon} />
                  </Form.Item>
                )}
              </Form.Item>
              Enable Notifications
            </Space>
          </PageItemLayoutGroup>
        </div>
      </div>
      <div
        className={cn(
          styles["additional-services-details-container"],
          styles["additional-services-details-card-section"],
        )}
      >
        <div className={styles["additional-services-details-card-header"]}>
          <Space direction="horizontal" size={12}>
            <Form.Item
              className={styles["additional-services-details-form-item"]}
              valuePropName="checked"
              name="isCardControlSelected"
            >
              <Switch />
            </Form.Item>
            Card Control
          </Space>
        </div>
        <div className={styles["additional-services-details-card-body"]}>
          <PageItemLayoutGroup>
            <div>
              Card control is Network International’s peripheral system which provides certain transactional level
              controls to the client and as well as to the customer. {"  "}
              <a
                href="https://www.network.ae/en/contents/listing/addonsolutions#mbook-1"
                target="_blank"
                rel="noreferrer"
              >
                <span>Read more </span>
                <ArrowRightOutlined />
              </a>
            </div>
            <PageItemLayoutElements>
              <Space direction="horizontal" size={8}>
                <Form.Item dependencies={["isCardControlSelected"]}>
                  {() => (
                    <Form.Item valuePropName="checked" name="nic-ncontrol-flag-def-new">
                      <Switch disabled={!form.getFieldValue("isCardControlSelected")} />
                    </Form.Item>
                  )}
                </Form.Item>
                Set default Card control enrollment flag as enabled for the product
              </Space>
              <Space direction="horizontal" size={8}>
                <Form.Item dependencies={["isCardControlSelected"]}>
                  {() => (
                    <Form.Item
                      className={styles["additional-services-details-form-item"]}
                      valuePropName="checked"
                      name="nic-ncontrol-flag-editable"
                    >
                      <Switch disabled={!form.getFieldValue("isCardControlSelected")} />
                    </Form.Item>
                  )}
                </Form.Item>
                Card control enrollment flag is editable for contracts
              </Space>
            </PageItemLayoutElements>
          </PageItemLayoutGroup>
        </div>
      </div>
    </CustomFormWrapper>
  );
};
