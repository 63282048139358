import { ReactNode } from "react";
import { Rule } from "rc-field-form/lib/interface";

interface Options {
  name: string;
  code: string;
  default?: boolean | string | number;
  tooltip?: ReactNode;
  type?: "switch" | "integer" | "number";
}

interface NumericOptions extends Options {
  decimalsNumber?: number;
  min?: string | number;
  max?: string | number;
  rules?: Rule[];
  addonAfter?: ReactNode;
  type?: "switch" | "integer" | "number";
}

// Total switch

export const enableCTotal: Options = {
  code: "paym-c-total-enabled",
  name: "Card Limits",
  default: true,
};

// Per Day

export const enableDTotalOption: Options = {
  code: "d-paym-c-total-enabled",
  name: "Per Day",
  default: true,
  type: "switch",
  tooltip: "Switch it off if restrictions are not intended per calendar day (from 00:00:00 to 23:59:59)",
};

export const dTotalNrOption: NumericOptions = {
  code: "nic-d-paym-c-total-nr",
  name: "Max Number",
  default: 20,
  min: 0,
  type: "number",
  tooltip:
    "This counter restricts maximal number of payments per period. Leave it empty if restrictions are not intended. All zeros prohibit payments at all.",
};

export const dTotalAmOption: NumericOptions = {
  code: "nic-d-paym-c-total-am",
  name: "Max Amount",
  default: 500000,
  decimalsNumber: 2,
  type: "number",
  tooltip:
    "This counter restricts maximal amount of payments per period. Leave it empty if restrictions are not intended. All zeros prohibit payments at all.",
};

export const dTotalSingleOption: NumericOptions = {
  code: "nic-d-paym-c-total-single",
  name: "Max Single Amount",
  default: 500000,
  decimalsNumber: 2,
  type: "number",
  tooltip:
    "This counter restricts maximal amount of single payment. Leave it empty if restrictions are not intended. All zeros prohibit payments at all.",
};

// Per month days

export const enableMTotalOption: Options = {
  code: "m-paym-c-total-enabled",
  name: "Per Month",
  default: true,
  type: "switch",
  tooltip:
    "Switch it off if restrictions are not intended per calendar month (from the 1st till the last day of the month)",
};

export const mTotalNrOption: NumericOptions = {
  code: "nic-m-paym-c-total-nr",
  name: "Max Number",
  default: 20,
  min: 0,
  type: "integer",
  tooltip:
    "This counter restricts maximal number of payments per period. Leave it empty if restrictions are not intended. All zeros prohibit payments at all.",
};

export const mTotalAmOption: NumericOptions = {
  code: "nic-m-paym-c-total-am",
  name: "Max Amount",
  default: 500000,
  decimalsNumber: 2,
  type: "number",
  tooltip:
    "This counter restricts maximal amount of payments per period. Leave it empty if restrictions are not intended. All zeros prohibit payments at all.",
};

export const mTotalSingleOption: NumericOptions = {
  code: "nic-m-paym-c-total-single",
  name: "Max Single Amount",
  default: 500000,
  decimalsNumber: 2,
  type: "number",
  tooltip:
    "This counter restricts maximal amount of single payment. Leave it empty if restrictions are not intended. All zeros prohibit payments at all.",
};

// Per 365 days

export const enableD365TotalOption: Options = {
  code: "d365-paym-c-total-enabled",
  name: "Per 365 Days",
  default: true,
  type: "switch",
  tooltip:
    "365 sliding days, means that the limiter calculates transactions for 365 days before the current transaction was made. Switch it off if restrictions are not intended.",
};

export const d365TotalNrOption: NumericOptions = {
  code: "nic-d365-paym-c-total-nr",
  name: "Max Number",
  default: 20,
  min: 0,
  type: "integer",
  tooltip:
    "This counter restricts maximal number of payments per period. Leave it empty if restrictions are not intended. All zeros prohibit payments at all.",
};

export const d365TotalAmOption: NumericOptions = {
  code: "nic-d365-paym-c-total-am",
  name: "Max Amount",
  default: 500000,
  decimalsNumber: 2,
  type: "number",
  tooltip:
    "This counter restricts maximal amount of payments per period. Leave it empty if restrictions are not intended. All zeros prohibit payments at all.",
};

export const d365TotalSingleOption: NumericOptions = {
  code: "nic-d365-paym-c-total-single",
  name: "Max Single Amount",
  default: 500000,
  decimalsNumber: 2,
  type: "number",
  tooltip:
    "This counter restricts maximal amount of single payment. Leave it empty if restrictions are not intended. All zeros prohibit payments at all.",
};
