import { FC } from "react";
import { Form, FormInstance, Space, Switch } from "antd";

import { QuestionCircleFilled } from "@ant-design/icons";
import {
  LTY_CASHBACK_INTERVAL_RADIO_GROUP,
  LTY_RED_CASH_A_TOT,
  LTY_REDEEM_TYPE,
  NIC_LTY_RED_CASH_A_TOT,
  NIC_LTY_RED_EXT_A_TOT_MBR,
} from "@ni/common/constants";
import { FormValues } from "@ni/common/types";
import { FormContent, RadioGroup } from "@ni/common/ui";
import { LoyaltyProgramTemplate } from "@ni/sdk/models";

interface PointRedemptionProps {
  form: FormInstance<FormValues>;
  loyaltyPrograms: LoyaltyProgramTemplate[];
}

export const PointRedemption: FC<PointRedemptionProps> = ({ form, loyaltyPrograms }) => {
  const isSharedCashbackOn = Form.useWatch(LTY_RED_CASH_A_TOT, form);

  const doesCashbackShown = loyaltyPrograms.some(
    program => program.programValues?.find(value => value?.code === LTY_REDEEM_TYPE)?.value === "C",
  );
  const doesVoucherShown = loyaltyPrograms.some(
    program => program.programValues?.find(value => value?.code === LTY_REDEEM_TYPE)?.value === "E",
  );

  return (
    <FormContent gap={24}>
      <div>
        Loyalty points redemption is the process of utilizing accumulated loyalty points to obtain rewards, benefits, or
        discounts offered by a loyalty program.
      </div>

      {doesCashbackShown && (
        <Space direction="horizontal" size={8}>
          <Form.Item valuePropName="checked" name={LTY_RED_CASH_A_TOT}>
            <Switch />
          </Form.Item>
          Enable shared (cross-program) cashback auto-redemption
        </Space>
      )}

      {isSharedCashbackOn && (
        <Form.Item
          name={NIC_LTY_RED_CASH_A_TOT}
          initialValue={LTY_CASHBACK_INTERVAL_RADIO_GROUP[0].value}
          tooltip={{ title: "Choose yearly accrual auto-start mode", icon: <QuestionCircleFilled /> }}
        >
          <RadioGroup
            radioList={LTY_CASHBACK_INTERVAL_RADIO_GROUP}
            initialValue={LTY_CASHBACK_INTERVAL_RADIO_GROUP[0].value}
          />
        </Form.Item>
      )}

      {doesVoucherShown && (
        <Space direction="horizontal" size={8}>
          <Form.Item valuePropName="checked" name={NIC_LTY_RED_EXT_A_TOT_MBR}>
            <Switch />
          </Form.Item>
          Enable shared (cross-program) external voucher auto-redemption
        </Space>
      )}
    </FormContent>
  );
};
