import { FC } from "react";
import { Form, Space } from "antd";
import { useParams } from "react-router-dom";

import { useHydrateForm, useProductSettings } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, FormulaTextArea, RadioGroup } from "@ni/common/ui";
import { getFormValueFromProductValues } from "@ni/common/utils";

import { balanceComponentsOptions, mtpModeOptions, mtpResultOptions } from "./constants";

export const MinimumToPayFormulaPage: FC = () => {
  const [form] = Form.useForm<FormValues>();
  const { productId } = useParams<{ id: string; productId: string }>();
  const { currentProduct, onUpdateProduct } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });

  const isIslamicCredit = getFormValueFromProductValues(currentProduct?.productValues, "credit-type") === "Islamic";

  const balance = Form.useWatch<string>(balanceComponentsOptions.code, form);
  const mode = Form.useWatch<string>(mtpModeOptions.code, form);
  useHydrateForm({
    form,
    entityFields: currentProduct.productValues ?? [],
    keys: {
      strings: [balanceComponentsOptions.code, mtpModeOptions.code],
    },
    allowParse: false,
  });

  const onFinish = onUpdateProduct;

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Minimum to Pay Formula"
      pageSubtitle={
        <Space direction="vertical">
          <div>
            At the closing of each billing cycle, Minimum to Pay (MTP) amount is calculated. The customer is expected to
            make a payment equal to or greater than the minimum to pay amount by the due date, to avoid late payment
            fees and being delinquent.
          </div>
          <div>
            MTP amount is calculated from the balance components, which are included as MTP percentage % or fully
            (100%).
          </div>
        </Space>
      }
      size="md"
      formSize="md"
      gap={24}
      level="root"
      submitLabel="Save"
      submitHandler={onFinish}
    >
      <Form.Item
        name={balanceComponentsOptions.code}
        label={balanceComponentsOptions.name}
        initialValue={balanceComponentsOptions.default}
      >
        <RadioGroup
          radioList={balanceComponentsOptions.options}
          initialValue={balanceComponentsOptions.default as string}
        />
      </Form.Item>

      <Form.Item name={mtpModeOptions.code} label={mtpModeOptions.name} initialValue={mtpModeOptions.default}>
        <RadioGroup radioList={mtpModeOptions.options} initialValue={mtpModeOptions.default as string} />
      </Form.Item>

      <Form.Item name={mtpResultOptions.code} label={mtpResultOptions.name}>
        <FormulaTextArea balance={balance} mode={mode} percentage="MTP percentage " isIslamicCredit={isIslamicCredit} />
      </Form.Item>
    </CustomFormWrapper>
  );
};
