import { FC } from "react";
import { Form, InputNumber, Space, Switch } from "antd";

import { QuestionCircleFilled } from "@ant-design/icons";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, FormItemLabel, TooltipInfo } from "@ni/common/ui";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

import { useProductCurrencyType } from "../../hooks";

import { enableLatePaymentFeeOptions, latePaymentAmountVariance, latePaymentFeeAmountOptions } from "./constants";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const LatePaymentFeePage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");
  useHydrateForm({
    form,
    entityFields: wizardResponse.product?.parameterTables![0].pctProductValues ?? [],
    keys: {
      strings: [enableLatePaymentFeeOptions.code, latePaymentAmountVariance.code, latePaymentFeeAmountOptions.code],
    },
    allowParse: true,
  });

  const isLatePaymentFeeEnabled = Form.useWatch<boolean>(enableLatePaymentFeeOptions.code, form);
  const currencyType = useProductCurrencyType(wizardResponse.product?.productValues);

  const onFinish = (values: FormValues) => {
    setIsLoading(true);
    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: values as { [key: string]: string },
    };
    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Late Payment Fee"
      pageSubtitle="Late Payment fee is charged to cardholder when the minimum payment is not made towards due amount until the configured late payment date."
      size="md"
      formSize="full"
      gap={30}
      level="root"
      submitHandler={onFinish}
    >
      <Space direction="horizontal">
        <Form.Item
          name={enableLatePaymentFeeOptions.code}
          valuePropName="checked"
          initialValue={enableLatePaymentFeeOptions.default}
        >
          <Switch />
        </Form.Item>
        <TooltipInfo
          label={enableLatePaymentFeeOptions.name}
          code={enableLatePaymentFeeOptions.code}
          tooltipProps={{}}
        />
      </Space>
      {isLatePaymentFeeEnabled && (
        <>
          <Form.Item
            name={latePaymentFeeAmountOptions.code}
            label={<FormItemLabel label={latePaymentFeeAmountOptions.name} code={latePaymentFeeAmountOptions.code} />}
            tooltip={{
              icon: <QuestionCircleFilled />,
              title: latePaymentFeeAmountOptions.tooltip,
            }}
            rules={[{ required: true, message: "Late payment fee amount is required!" }]}
          >
            <InputNumber
              addonAfter={currencyType}
              min={latePaymentFeeAmountOptions.min}
              precision={latePaymentFeeAmountOptions.decimalsNumber}
            />
          </Form.Item>
          <Form.Item
            name={latePaymentAmountVariance.code}
            label={<FormItemLabel label={latePaymentAmountVariance.name} code={latePaymentAmountVariance.code} />}
            tooltip={{
              icon: <QuestionCircleFilled />,
              title: latePaymentAmountVariance.tooltip,
            }}
          >
            <InputNumber
              addonAfter={currencyType}
              min={latePaymentAmountVariance.min}
              precision={latePaymentAmountVariance.decimalsNumber}
            />
          </Form.Item>
        </>
      )}
    </CustomFormWrapper>
  );
};
