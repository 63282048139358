import { FC, useEffect, useState } from "react";
import { Button, Drawer, Form, Input } from "antd";
import { AxiosResponse } from "axios";

import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useReduxState } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { ConfigurationApi } from "@ni/sdk/apis";
import { CreateDictionaries, Dictionaries, Dictionary, PutDictionaries } from "@ni/sdk/models";

import styles from "./styles.module.scss";

interface FormOptions extends Dictionary {
  formKey: string;
}

const configurationApi = new ConfigurationApi();

interface DictionariesValueDrawerProps {
  isOpen: boolean;
  closeDrawer: () => void;
  code?: string;
  selectedDictionary: Dictionaries;
}

export const DictionariesValueDrawer: FC<DictionariesValueDrawerProps> = ({
  isOpen,
  closeDrawer,
  code,
  selectedDictionary,
}: DictionariesValueDrawerProps) => {
  const [form] = Form.useForm<FormValues>();
  const [options, setOptions] = useState<FormOptions[]>([]);
  const [lastIndex, setLastIndex] = useState<number>(0);
  const [isLoading, setIsLoading] = useReduxState<boolean>("isLoading");
  const [isEnabled, setIsEnabled] = useState<boolean>(false);

  const [, setSelectedDictionary] = useReduxState<Dictionaries>("selectedDictionary", {
    code: "",
    dictionaries: [],
  });

  const [dictionaries, setDictionaries] = useReduxState<Dictionaries[]>("dictionaries");

  useEffect(() => {
    if (selectedDictionary) {
      const tempOptions = selectedDictionary.dictionaries.map((option, index) => ({
        ...option,
        formKey: index.toString(),
      }));

      setOptions(tempOptions);
      setLastIndex(tempOptions.length);

      const tempFormValues = tempOptions.reduce(
        (acc, key) => ({
          ...acc,
          [`${key.formKey}_value`]: key.value,
          [`${key.formKey}_displayValue`]: key.displayValue || "",
        }),
        {} as { [key: string]: string },
      );

      form.setFieldsValue({
        displayValue: selectedDictionary.code,
        ...tempFormValues,
      });
    }
  }, [form, selectedDictionary]);

  const onAddNewOption = () => {
    setOptions([...options, { id: lastIndex, displayValue: "", value: "", formKey: (lastIndex + 1).toString() }]);
    setLastIndex(lastIndex + 1);
  };

  const onDeleteOption = (key: string): void => {
    const tempFormValue = form.getFieldsValue();
    delete tempFormValue[`${key}_value`];
    delete tempFormValue[`${key}_displayName`];
    form.setFieldsValue(tempFormValue);

    const tempOptions = options.filter(option => option.formKey !== key);
    setOptions(tempOptions);

    if (
      !form.getFieldValue("displayValue")?.length ||
      tempOptions.every(item => item.value === "") ||
      tempOptions.every(item => item.displayValue === "")
    ) {
      setIsEnabled(false);
    } else {
      setIsEnabled(true);
    }
  };

  const onSave = (): void => {
    form
      .validateFields()
      .then(() => {
        setIsLoading(true);
        const formValues = form.getFieldsValue();

        if (!selectedDictionary.code) {
          const tempElement: CreateDictionaries = { code: formValues["displayValue"] as string, dictionaries: [] };

          options.forEach(option => {
            tempElement.dictionaries.push({
              value: formValues[`${option.formKey}_value`] as string,
              displayValue: formValues[`${option.formKey}_displayValue`] as string,
            });
          });

          configurationApi
            .createDictionaries({ dictionaries: tempElement })
            .then(() => {
              closeDrawer();
              setIsLoading(false);
            })
            .catch(() => {
              setIsLoading(false);
            });
        } else {
          const tempElement: PutDictionaries = { newCode: formValues["displayValue"] as string, dictionaries: [] };
          options.forEach(option => {
            tempElement.dictionaries.push({
              value: formValues[`${option.formKey}_value`] as string,
              displayValue: formValues[`${option.formKey}_displayValue`] as string,
            });
          });

          configurationApi
            .putDictionaries({ dictionaries: tempElement }, code as string)
            .then(({ data }: AxiosResponse<Dictionaries>) => {
              setSelectedDictionary(data);
              const newDictionaryArray = dictionaries.map(item => {
                if (item === selectedDictionary) {
                  return data;
                }
                return item;
              });

              setDictionaries(newDictionaryArray);
              closeDrawer();
              setIsLoading(false);
            })
            .catch(() => {
              setIsLoading(false);
            });
        }
      })
      .catch(() => {});
  };

  const onValuesChange = (): void => {
    const isFieldsFilled = form.getFieldValue("displayValue")?.length > 0;

    const isValuesFilled = options.every(item => {
      return form.getFieldValue(`${item.formKey}_value`) !== "";
    });

    setIsEnabled(isFieldsFilled && isValuesFilled);
  };

  return (
    <Drawer
      title={`${selectedDictionary.code ? "Edit" : "New"} Dictionary`}
      placement="right"
      open={isOpen}
      width="99%"
      onClose={() => closeDrawer()}
      keyboard={false}
      maskClosable={false}
      extra={
        <Button type="primary" size="large" className={styles["save-button"]} onClick={onSave} disabled={!isEnabled}>
          Save
        </Button>
      }
    >
      <>
        <Form form={form} className={styles["form-container"]} layout="vertical" onChange={onValuesChange}>
          <Form.Item
            dependencies={["displayValue"]}
            name="displayValue"
            label="Dictionary value name"
            rules={[
              { required: true, message: "Dictionary value name is required" },
              () => ({
                validator(_, value) {
                  if (dictionaries.find(val => val.code === value && value !== code)) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject<string>("Dictionary value name already exists");
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input />
          </Form.Item>

          {options.map(option => (
            <div className={styles["option-group"]} key={Math.random()}>
              <Form.Item
                name={`${option.formKey}_value`}
                label="Value"
                rules={[{ required: true, message: "Value is required" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item name={`${option.formKey}_displayValue`} label="Displayed value">
                <Input />
              </Form.Item>

              {options.length > 1 && (
                <DeleteOutlined
                  onClick={e => {
                    e.stopPropagation();
                    onDeleteOption(option.formKey);
                  }}
                />
              )}
            </div>
          ))}
        </Form>
        <Button loading={isLoading} type="text" className={styles["add-value-button"]} onClick={onAddNewOption}>
          <PlusOutlined /> Add value
        </Button>
      </>
    </Drawer>
  );
};

DictionariesValueDrawer.defaultProps = {
  code: "",
};
