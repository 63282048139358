import { Form, Select, Space } from "antd";
import { useParams } from "react-router-dom";

import { QuestionCircleFilled } from "@ant-design/icons";
import {
  NIC_DD_WR_ASK100,
  NIC_DLQ_LEVEL_TO_FREEZE,
  NIC_INT_BASE,
  NIC_PAST_DUE_DAYS_CALC_MODE,
} from "@ni/common/constants";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, RadioGroup, Section } from "@ni/common/ui";
import { TenantApi } from "@ni/sdk/apis";
import { ChangeTenantRequest, Tenant } from "@ni/sdk/models";

import {
  NIC_DD_WR_ASK100_SELECT,
  NIC_DLQ_LEVEL_TO_FREEZE_SELECT,
  NIC_INT_BASE_RADIO,
  NIC_PAST_DUE_DAYS_CALC_MODE_SELECT,
} from "./constants";

const tenantsServiceApi = new TenantApi();

export const CreditProductsConfiguration = () => {
  const [form] = Form.useForm<FormValues>();
  const { id: tenantId } = useParams();

  const [, setIsLoading] = useReduxState<boolean>("isLoading");
  const [tenant, setTenant] = useReduxState<Tenant>("tenant", {});

  useHydrateForm(
    {
      form,
      entityFields: tenant?.tenantValues ?? [],
      keys: {
        strings: [NIC_INT_BASE, NIC_DD_WR_ASK100, NIC_DLQ_LEVEL_TO_FREEZE, NIC_PAST_DUE_DAYS_CALC_MODE],
      },
      allowParse: false,
    },
    [form, tenant?.tenantValues],
  );

  const onFinish = (values: FormValues) => {
    setIsLoading(true);

    const tenantValues = {
      tenantValues: Object.keys(values).map(key => ({
        fieldCode: key,
        value: values[key],
      })),
    };

    tenantsServiceApi
      .editTenant(tenantValues as ChangeTenantRequest, parseInt(tenantId ?? "0", 10))
      .then(res => {
        setIsLoading(false);
        setTenant(res.data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const relatedLinks = [
    {
      href: "../block-codes",
      label: "Block Codes",
    },
  ];

  return (
    <CustomFormWrapper
      pageTitle="Credit Product General Settings"
      pageSubtitle="While the tenant can have multiple credit products, the following settings apply universally to all of them."
      form={form}
      submitHandler={onFinish}
      formSize="full"
      submitLabel="Save"
      relatedLinks={relatedLinks}
    >
      <Section
        title="Interest Calculation"
        description="The interest calculation is done based on Daily Balance algorithm which calculates the interest on daily balances and post the interest at the end of billing cycle."
        gap={40}
      >
        <Form.Item
          name={NIC_INT_BASE}
          label="Interest base"
          tooltip={{
            title:
              "This parameter specifies the number of days considered in a year i.e. either 365 or 366. With respect to this per day interest is calculated. Interest rate defined in system is for per year, so interest rate divided by interest base gives the interest rate per day.",
            icon: <QuestionCircleFilled />,
          }}
          initialValue={NIC_INT_BASE_RADIO[0].value}
        >
          <RadioGroup radioList={NIC_INT_BASE_RADIO} />
        </Form.Item>
      </Section>

      <Section
        title="Delinquency"
        description={
          <>
            When the payment of minimum due amount is not made by the delinquency aging day, the account becomes
            delinquent and is set with a delinquency status. Based on number of past due days different delinquency
            buckets (levels) are available in the system.
            <br /> <br />
            Delinquency could result into status change and restriction of certain functionalities. This can be
            configured using Block Codes.
          </>
        }
      >
        <Space direction="vertical" size={24}>
          <Form.Item
            name={NIC_DD_WR_ASK100}
            label="Contract delinquency level to request 100% of balance by direct debit"
            tooltip={{
              title:
                "E.g. if this parameter is set to Level 8, when an account reaches contract delinquency level 8, then accounts complete balance will be requested as Direct Debit amount.",
              icon: <QuestionCircleFilled />,
            }}
            initialValue={NIC_DD_WR_ASK100_SELECT[0].value}
          >
            <Select>
              {NIC_DD_WR_ASK100_SELECT.map(option => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name={NIC_DLQ_LEVEL_TO_FREEZE}
            label="Contract delinquency level to freeze"
            tooltip={{
              title: "When an account reaches this or higher level, it will not be reverted on payment.",
              icon: <QuestionCircleFilled />,
            }}
            initialValue={NIC_DLQ_LEVEL_TO_FREEZE_SELECT[0].value}
          >
            <Select>
              {NIC_DLQ_LEVEL_TO_FREEZE_SELECT.map(option => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name={NIC_PAST_DUE_DAYS_CALC_MODE}
            label="Past due days calculation mode"
            tooltip={{
              title:
                "In case of “Trimmed” mode contract delinquency level 9 always returns 210 days past due regardless of when it was last reached.",
              icon: <QuestionCircleFilled />,
            }}
            initialValue={NIC_PAST_DUE_DAYS_CALC_MODE_SELECT[0].value}
          >
            <Select>
              {NIC_PAST_DUE_DAYS_CALC_MODE_SELECT.map(option => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Space>
      </Section>
    </CustomFormWrapper>
  );
};
