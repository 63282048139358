import React, { FC, useEffect, useMemo, useState } from "react";
import { Form, FormInstance, Input, Space, Switch } from "antd";
import NumberFormat from "react-number-format";

import { QuestionCircleFilled } from "@ant-design/icons";
import { useProductSettings } from "@ni/common/hooks";
import { FeeField, FeeRow, FormValues } from "@ni/common/types";
import { TooltipInfo } from "@ni/common/ui";
import { extractNumberFromString, removeElementByIndex } from "@ni/common/utils";

import { TIERS_HEADINGS_AMOUNT, TIERS_HEADINGS_COUNT } from "../../constants";

import { PointAccrualTable } from "./PointAccrualTable";

export type FrequencyKey = "Y" | "B";

interface TierField extends Pick<FeeField, "type"> {
  name?: string;
  disabled?: boolean;
  initialValue?: string;
}

interface TierRow extends Pick<FeeRow, "rowLabel"> {
  fields: TierField[];
}

interface PointAccrualFrequencyConfigurationProps {
  form: FormInstance<FormValues>;
  amountCoundMode: "AMOUNT" | "COUNT";
  currentFrequencyKey: FrequencyKey;
  codes: string[];
}

const getFieldKey = (key: Extract<FrequencyKey, "Y" | "B">) => key.toLocaleLowerCase();
export const getSpecificRateSwitchFieldName = (key: Extract<FrequencyKey, "Y" | "B">) =>
  `lty-accr-${getFieldKey(key)}-rate-tiers-en`;

export const getSpecificRateFieldName = (key: Extract<FrequencyKey, "Y" | "B">) => `lty-accr-${getFieldKey(key)}-rate`;

const getDefaultTier = (
  key: string,
  amountCoundMode: PointAccrualFrequencyConfigurationProps["amountCoundMode"],
): TierRow => {
  return {
    rowLabel: "Default tier",
    fields: [
      {
        name: `lty-accr-${key}-def-tier-min`,
        type: "default",
        disabled: true,
        initialValue: "0.00",
      },
      {
        name: `lty-accr-${key}-def-tier-max`,
        type: "default",
        disabled: true,
        initialValue: amountCoundMode === "AMOUNT" ? "99999999999.99" : "99999999999",
      },
      {
        name: `lty-accr-${key}-rate`,
        type: "percentage",
      },
      {
        type: "button",
      },
    ],
  };
};

const getAdditionalTier = (
  key: string,
  currentOrder: number,
  amountCoundMode: PointAccrualFrequencyConfigurationProps["amountCoundMode"],
): TierRow => {
  return {
    rowLabel: `Additional tier ${currentOrder}`,
    fields: [
      {
        name: `lty-accr-${key}-tier-t${currentOrder}-min`,
        type: "default",
        disabled: true,
        initialValue: "0.00",
      },
      {
        name: `lty-accr-${key}-tier-t${currentOrder}-max`,
        type: "default",
        disabled: false,
        initialValue: amountCoundMode === "AMOUNT" ? "99999999999.99" : "99999999999",
      },
      {
        name: `lty-accr-${key}-rate-tier-t${currentOrder}`,
        type: "percentage",
      },
      {
        name: `button-${currentOrder}`,
        type: "button",
      },
    ],
  };
};

export const PointAccrualFrequencyConfiguration: FC<PointAccrualFrequencyConfigurationProps> = ({
  form,
  amountCoundMode,
  currentFrequencyKey,
  codes,
}) => {
  const [isInitiated, setIsInitiated] = useState(false);
  const isSpecificRateOn = Form.useWatch(getSpecificRateSwitchFieldName(currentFrequencyKey), form);

  const defaultTier = useMemo(
    () => getDefaultTier(getFieldKey(currentFrequencyKey), amountCoundMode),
    [amountCoundMode, currentFrequencyKey],
  );

  const [tierRows, setTierRows] = useState<TierRow[]>([]);

  const { productCurrency } = useProductSettings({
    isFetchEnabled: false,
  });

  useEffect(() => {
    if (codes.length > 0 && amountCoundMode && codes && currentFrequencyKey && !isInitiated && isSpecificRateOn) {
      const additionalTiers: TierRow[] = [];
      const highestTierNumber = codes
        .map(code => extractNumberFromString(code))
        .filter(number => number !== null)
        .reduce((max, num) => Math.max(max!, num!), 0)!;

      for (let i = 1; i <= highestTierNumber; i++) {
        additionalTiers.push(getAdditionalTier(getFieldKey(currentFrequencyKey), i, amountCoundMode));
      }

      setTierRows([...additionalTiers]);
      setIsInitiated(true);
    }
  }, [amountCoundMode, codes, currentFrequencyKey, isInitiated, isSpecificRateOn]);

  const onAddTier = (key: string, currentOrder: number) => {
    setTierRows(tiers => [...tiers, getAdditionalTier(key, currentOrder + 1, amountCoundMode)]);
  };

  const onRemoveTier = (index: number) => {
    tierRows[tierRows.length - 1].fields.forEach(field => {
      form.setFieldValue(field.name, "");
    });
    setTierRows(prevTiers => {
      const newTiers = removeElementByIndex([...prevTiers], index);
      const newTiersWithUpdatedOrdinals = newTiers.map((tier, index) => {
        const regex = /\d+/;
        const currentOrdinal = index + 1;
        return {
          rowLabel: tier.rowLabel.replace(regex, currentOrdinal.toString()),
          fields: tier.fields.map(tierField => ({
            ...tierField,
            name: (tierField?.name ?? "").replace(regex, currentOrdinal.toString()),
          })),
        };
      });

      return newTiersWithUpdatedOrdinals;
    });
  };

  return (
    <Space size={24} direction="vertical">
      <Space direction="horizontal">
        <Form.Item name={getSpecificRateSwitchFieldName(currentFrequencyKey)} valuePropName="checked">
          <Switch
            onChange={isChecked => {
              if (!isChecked) {
                tierRows.forEach(row => {
                  row.fields.forEach(field => form.resetFields([field.name]));
                });
                setTierRows([]);
              }
            }}
          />
        </Form.Item>
        <TooltipInfo
          label={
            amountCoundMode === "COUNT"
              ? "Differentiate accrual rate by transaction count tiers"
              : "Differentiate accrual rate by net amount tiers"
          }
          tooltipProps={{
            title:
              "Loyalty program with tiered structure may offer higher accrual rates for customers who reach higher tiers.",
          }}
        />
      </Space>

      {isSpecificRateOn ? (
        <PointAccrualTable
          form={form}
          heading={amountCoundMode === "AMOUNT" ? TIERS_HEADINGS_AMOUNT : TIERS_HEADINGS_COUNT}
          amountCoundMode={amountCoundMode}
          productCurrency={productCurrency}
          tierRows={tierRows}
          defaultTier={defaultTier}
          onAddTier={() => onAddTier(getFieldKey(currentFrequencyKey), tierRows.length)}
          onRemoveTier={onRemoveTier}
        />
      ) : (
        <Form.Item
          name={getSpecificRateFieldName(currentFrequencyKey)}
          required={true}
          rules={[{ required: true }]}
          label="Points accrual percentage rate"
          tooltip={{
            title:
              "This parameter specifies the rate at which points are accrued for the eligible transactions in the cycle.",
            icon: <QuestionCircleFilled />,
          }}
        >
          <NumberFormat
            customInput={Input}
            addonAfter="%"
            allowNegative={false}
            isNumericString={true}
            thousandSeparator=" "
            decimalScale={2}
            decimalSeparator="."
            fixedDecimalScale={true}
          />
        </Form.Item>
      )}
    </Space>
  );
};
