import { FC, useState } from "react";
import { Button, Checkbox, Dropdown, Form, Select } from "antd";

import { DeleteOutlined, DownOutlined } from "@ant-design/icons";
import { extractNumberFromString } from "@ni/common/utils";

import { NIC_USAGE_PAYM_U01_RULES, NIC_USAGE_PAYM_U02_RULES, NIC_USAGE_PAYM_U03_RULES } from "../../../../constants";

import {
  P2P_PAYMENT_ENABLED_OPTIONS,
  P2P_PAYMENT_OPTIONS,
  PRIMARY_SUPPLEMENTARY_OPTIONS,
  RULE_STATEMENTS_OPTIONS,
} from "./AdditionalRules.constants";

import styles from "../styles.module.scss";

export type RuleKeys =
  | typeof NIC_USAGE_PAYM_U01_RULES
  | typeof NIC_USAGE_PAYM_U02_RULES
  | typeof NIC_USAGE_PAYM_U03_RULES;

interface P2p {
  isEnabled: boolean;
  prefix: string;
  value: string;
}

interface PrimarySupplementary {
  isEnabled: boolean;
  value: string;
}

export interface Rule<K extends string> {
  key: K;
  value: string;
  p2p: P2p;
  primarySupplementary: PrimarySupplementary;
}

interface OnChangeRuleArguments {
  type: "checkbox" | "input";
  ruleKey: RuleKeys;
  value?: Partial<Pick<Rule<RuleKeys>, "p2p" | "primarySupplementary">>;
}

export interface RuleListItemProps {
  rule: Rule<RuleKeys>;
  onChangeRule: (args: OnChangeRuleArguments) => void;
  onRemoveRule: (key: RuleKeys) => void;
}

export const RuleListItem: FC<RuleListItemProps> = ({ rule, onChangeRule, onRemoveRule }) => {
  const [open, setOpen] = useState(false);

  return (
    <li className={styles["rules-list-item"]}>
      <div className={styles["rules-list-item__header"]}>
        <h5 className={styles["rules-list-item__header-title"]}>Additional rule {extractNumberFromString(rule.key)}</h5>

        <Button
          type="text"
          danger={true}
          onClick={() => {
            onRemoveRule(rule.key);
          }}
        >
          <DeleteOutlined />
        </Button>
      </div>

      <div className={styles["rules-list-item__body"]}>
        <Dropdown
          trigger={["click"]}
          menu={{
            items: RULE_STATEMENTS_OPTIONS.map(statement => {
              return {
                key: statement.label,
                label: (
                  <Form.Item className={styles["pct-rules-ant-form-item"]}>
                    <Checkbox
                      key={statement.label}
                      checked={rule[statement.name].isEnabled}
                      onChange={e => {
                        onChangeRule({
                          type: "checkbox",
                          ruleKey: rule.key,
                          value: {
                            [statement.name]:
                              statement.name === "p2p"
                                ? {
                                    isEnabled: e.target.checked,
                                    prefix: e.target.checked ? P2P_PAYMENT_ENABLED_OPTIONS[0].value : "",
                                    value: e.target.checked ? P2P_PAYMENT_OPTIONS[0].value : "",
                                  }
                                : statement.name === "primarySupplementary"
                                ? {
                                    isEnabled: e.target.checked,
                                    value: e.target.checked ? PRIMARY_SUPPLEMENTARY_OPTIONS[1].value : "",
                                  }
                                : {},
                          },
                        });
                        setOpen(false);
                      }}
                    >
                      <div className={styles["pct-rules-ant-checkbox-label"]}>{statement.label}</div>
                    </Checkbox>
                  </Form.Item>
                ),
              };
            }),
            selectable: true,
          }}
          open={open}
          onOpenChange={setOpen}
        >
          <Button className={styles["pct-rules-statement-button"]}>
            Statement <DownOutlined />
          </Button>
        </Dropdown>

        {rule.p2p.isEnabled && (
          <Form.Item className={styles["pct-rules-select-group"]}>
            <Select
              defaultValue={rule.p2p.prefix ?? P2P_PAYMENT_ENABLED_OPTIONS[1].value}
              options={P2P_PAYMENT_ENABLED_OPTIONS}
              onChange={value =>
                onChangeRule({
                  type: "input",
                  ruleKey: rule.key,
                  value: { p2p: { ...rule.p2p, prefix: value } },
                })
              }
            />
            <Select
              defaultValue={rule.p2p.value ?? P2P_PAYMENT_OPTIONS[0].value}
              options={P2P_PAYMENT_OPTIONS}
              onChange={value =>
                onChangeRule({
                  type: "input",
                  ruleKey: rule.key,
                  value: { p2p: { ...rule.p2p, value } },
                })
              }
            />
          </Form.Item>
        )}

        {rule.primarySupplementary.isEnabled && (
          <Form.Item className={styles["pct-rules-select"]}>
            <Select
              defaultValue={rule.primarySupplementary.value ?? PRIMARY_SUPPLEMENTARY_OPTIONS[1].value}
              options={PRIMARY_SUPPLEMENTARY_OPTIONS}
              onChange={value =>
                onChangeRule({
                  type: "input",
                  ruleKey: rule.key,
                  value: { primarySupplementary: { ...rule.primarySupplementary, value } },
                })
              }
            />
          </Form.Item>
        )}
      </div>
    </li>
  );
};
