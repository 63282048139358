import { FC } from "react";
import { Form, Select } from "antd";

import {
  CHECKBOX_GROUP_ELIGIBILITY_CRITERIA,
  LTY_RULE_MERCH,
  LTY_RULE_MERCH_LIST,
  LTY_RULES_CRITERIA,
  RADIO_GROUP,
} from "@ni/common/constants";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, RadioGroup } from "@ni/common/ui";
import { LoyaltyWizardRequest, LoyaltyWizardResponse } from "@ni/sdk/models";

import { useFieldMandatory, useLoyaltyWizardApi } from "../../hooks";

export const TransactionMerchantId: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();

  const { processWizardRequest } = useLoyaltyWizardApi();

  const [, setIsLoading] = useReduxState("isLoading", false);
  const [wizardResponse] = useReduxState<LoyaltyWizardResponse>("loyaltyWizard", {} as LoyaltyWizardResponse);

  const isIDSFilled = Form.useWatch<string>(LTY_RULE_MERCH_LIST, form);
  const isIDSMandatory = useFieldMandatory({
    values: wizardResponse?.loyaltyProgramTemplate?.programPctValues ?? [],
    checkAgainst: LTY_RULES_CRITERIA,
    keyToInclude: CHECKBOX_GROUP_ELIGIBILITY_CRITERIA[2].value,
  });

  useHydrateForm({
    form,
    entityFields: wizardResponse?.loyaltyProgramTemplate?.programPctValues ?? [],
    keys: {
      lists: [LTY_RULE_MERCH_LIST],
      strings: [LTY_RULE_MERCH],
    },
  });

  const onFinish = async (values: FormValues) => {
    setIsLoading(true);
    try {
      await processWizardRequest({
        pageId: wizardResponse.pageId as number,
        tenantId: wizardResponse?.tenant?.id,
        loyaltyTemplateId: wizardResponse.loyaltyProgramTemplate?.id,
        collectedValues: {
          ...values,
          [LTY_RULE_MERCH_LIST]: (values[LTY_RULE_MERCH_LIST] as string[]).join(","),
        } as LoyaltyWizardRequest["collectedValues"],
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      buttonDisabled={!isIDSFilled}
      pageTitle="Transaction Merchant ID"
      pageSubtitle="Points can be earned for transactions made at specific merchants or some merchants can be excluded from earning points. Kindly specify which merchants are included or excluded to determine your loyalty eligibility."
      size="sm"
      level="tenant"
      submitHandler={onFinish}
      additionalRoute="loyalty-program-templates"
    >
      <Form.Item name={LTY_RULE_MERCH} label="Select merchants" initialValue={RADIO_GROUP[0].value}>
        <RadioGroup radioList={RADIO_GROUP} initialValue={RADIO_GROUP[0].value} />
      </Form.Item>

      <Form.Item
        name={LTY_RULE_MERCH_LIST}
        label="Specify merchant ID list"
        rules={[
          {
            required: isIDSMandatory,
            validator: (_, value: string[]) => {
              if (!isIDSMandatory || (value && value.length > 0)) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Please fill out Id list."));
            },
          },
        ]}
      >
        <Select mode="tags" />
      </Form.Item>
    </CustomFormWrapper>
  );
};
