import { FC } from "react";
import { Form, Input, InputNumber, Select } from "antd";

import { QuestionCircleFilled } from "@ant-design/icons";
import { FieldValue } from "@ni/common/constants";
import { useGetCurrencyList, useHydrateForm } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper } from "@ni/common/ui";
import { numberFormatter, numberParser } from "@ni/common/utils";
import { ChangePlanRequestCurrency, Dictionary } from "@ni/sdk/models";

import { usePlanApi } from "../../../../hooks";

import {
  currencyOption,
  descriptionOption,
  groupOption,
  nameOption,
  rolloverPlanOption,
  tenorOption,
} from "./constants";

export const IppDetailsPage: FC = () => {
  const [form] = Form.useForm<FormValues>();
  const { currentPlan, editPlan } = usePlanApi();
  const { currencyList } = useGetCurrencyList("plan-currency");

  const hydratedValues = useHydrateForm({
    form,
    entityFields: currentPlan?.planValues
      ? [
          ...(currentPlan?.planValues ?? []),
          ...[
            { fieldCode: nameOption.code, value: currentPlan?.name },
            { fieldCode: groupOption.code, value: currentPlan?.group },
            { fieldCode: currencyOption.code, value: currentPlan?.currency?.toString() },
            { fieldCode: descriptionOption.code, value: currentPlan?.description },
          ],
        ]
      : [],
    keys: {
      strings: [
        nameOption.code,
        groupOption.code,
        currencyOption.code,
        tenorOption.code,
        descriptionOption.code,
        rolloverPlanOption.code,
      ],
    },
    allowParse: false,
  });

  const renderSelectOptions = (options: FieldValue[] | undefined) => {
    return options?.map(({ value, displayed }) => (
      <Select.Option key={value} value={value}>
        {displayed}
      </Select.Option>
    ));
  };

  const renderSelectCurrencyOptions = (options: Dictionary[]) => {
    return options?.map(({ value, displayValue }) => (
      <Select.Option key={value} value={value}>
        {displayValue}
      </Select.Option>
    ));
  };

  const onFinish = async (values: FormValues) => {
    if (
      values[descriptionOption.code] === hydratedValues[descriptionOption.code] &&
      values[descriptionOption.code]?.toString()?.includes("- Interest")
    ) {
      const planGroup =
        groupOption?.options?.find(x => x.value === values[groupOption.code])?.displayed ?? "No plan group";
      const planTenor = values[tenorOption.code]?.toString() ?? "0";
      const planInterest =
        currentPlan?.planValues?.find(x => x.fieldCode === "nic-ipp-inst-int-plan-rate")?.value ?? "0";

      values[descriptionOption.code] = `${planGroup} - ${planTenor} Months - Interest ${planInterest}%`;
    }
    await editPlan({
      name: values[nameOption.code]?.toString(),
      group: values[groupOption.code]?.toString(),
      currency: values[currencyOption.code]?.toString() as ChangePlanRequestCurrency,
      description: values[descriptionOption.code]?.toString(),
      planValues: [
        { fieldCode: rolloverPlanOption.code, value: values[rolloverPlanOption.code]?.toString() },
        { fieldCode: tenorOption.code, value: values[tenorOption.code]?.toString() },
      ],
    });
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Installment Payment Plan Details"
      pageSubtitle=""
      level="tenant"
      size="md"
      formSize="md"
      gap={40}
      submitLabel="Save"
      linkTitle="Plan List"
      submitHandler={onFinish}
      additionalRoute="./installment-payment-plans"
    >
      <Form.Item
        name={nameOption.code}
        label={nameOption.name}
        tooltip={{
          title: nameOption.tooltip,
          icon: <QuestionCircleFilled />,
        }}
        rules={[{ required: true, message: `${nameOption.name} is required` }]}
      >
        <Input maxLength={24} />
      </Form.Item>

      <Form.Item
        name={groupOption.code}
        label={groupOption.name}
        tooltip={{
          title: groupOption.tooltip,
          icon: <QuestionCircleFilled />,
        }}
        rules={[{ required: true, message: `${groupOption.name} is required` }]}
      >
        <Select>{renderSelectOptions(groupOption.options)}</Select>
      </Form.Item>

      <Form.Item
        name={currencyOption.code}
        label={currencyOption.name}
        tooltip={{
          title: currencyOption.tooltip,
          icon: <QuestionCircleFilled />,
        }}
        rules={[{ required: true, message: `${currencyOption.name} is required` }]}
      >
        <Select>{renderSelectCurrencyOptions(currencyList)}</Select>
      </Form.Item>

      <Form.Item
        name={tenorOption.code}
        label={tenorOption.name}
        tooltip={{
          title: tenorOption.tooltip,
          icon: <QuestionCircleFilled />,
        }}
        rules={[{ required: true, message: `${tenorOption.name} is required` }]}
      >
        <InputNumber
          min={tenorOption.min}
          max={tenorOption.max}
          precision={tenorOption.decimalsNumber}
          formatter={numberFormatter}
          parser={numberParser}
        />
      </Form.Item>

      <Form.Item
        name={descriptionOption.code}
        label={descriptionOption.name}
        tooltip={{
          title: descriptionOption.tooltip,
          icon: <QuestionCircleFilled />,
        }}
      >
        <Input maxLength={100} />
      </Form.Item>

      <Form.Item
        name={rolloverPlanOption.code}
        label={rolloverPlanOption.name}
        tooltip={{
          title: rolloverPlanOption.tooltip,
          icon: <QuestionCircleFilled />,
        }}
      >
        <Select>{renderSelectOptions(rolloverPlanOption.options)}</Select>
      </Form.Item>
    </CustomFormWrapper>
  );
};
