import { useEffect } from "react";
import { Form, notification } from "antd";
import { useParams } from "react-router-dom";

import { SERVICE_UNAVAILABLE } from "@ni/common/constants";
import { useProductSettings } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper } from "@ni/common/ui";
import { getErrorInstance } from "@ni/common/utils";
import {
  AccountBillingSwitchHeaders,
  checkboxFieldsPage34,
  checkHeader,
  getLimitsPreparedValues,
  switchHeader34,
} from "@ni/tenant-portal/core";

import { usePct } from "../../../../hooks";
import { prepareFieldsToRender } from "../../../../utils";

import { PctAccountBilling } from "./PctAccountBilling";

export const AccountLimitsBillingPage = () => {
  const { productId, pctId } = useParams<{ productId: string; pctId: string }>();
  const { productCurrency } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });

  const { pct, getValueFromPct, onSavePct } = usePct({
    pctId: parseInt(pctId ?? "0", 10),
  });
  const [form] = Form.useForm<FormValues>();

  useEffect(() => {
    prepareFieldsToRender(checkboxFieldsPage34, (fieldName: string) => {
      form.setFieldValue(fieldName, getValueFromPct(fieldName));
    });

    Object.keys(checkboxFieldsPage34).forEach(key => {
      checkHeader(key, checkboxFieldsPage34, form);
    });

    checkHeader(AccountBillingSwitchHeaders.allCashEnable, switchHeader34, form);
    checkHeader(AccountBillingSwitchHeaders.allRetailEnable, switchHeader34, form);
    checkHeader(AccountBillingSwitchHeaders.allTotalEnable, switchHeader34, form);
  }, [form, getValueFromPct, pct]);

  const onFinish = async (values: FormValues): Promise<void> => {
    try {
      let actualValues = {};
      actualValues = {
        ...actualValues,
        ...getLimitsPreparedValues(values as { [key: string]: string | boolean }, checkboxFieldsPage34),
      };

      await onSavePct(actualValues as unknown as FormValues);
    } catch (error) {
      const errorInstance = getErrorInstance(error);
      notification.error({
        placement: "topRight",
        duration: 3,
        message: (
          <div>
            {errorInstance?.response?.status ?? 400} <br />
            {SERVICE_UNAVAILABLE}
          </div>
        ),
      });
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Account Limits - Billing Period"
      pageSubtitle="Account limits govern the spending velocity for the cards related to the account. Both primary and supplementary card transactions contribute towards the account limits"
      submitHandler={onFinish}
      size="md"
      formSize="full"
      level="pct"
      submitLabel="Save"
    >
      <PctAccountBilling currency={productCurrency} form={form} />
    </CustomFormWrapper>
  );
};
