import { FC, useMemo } from "react";
import { Alert, Form, InputNumber } from "antd";
import { useParams } from "react-router-dom";

import { QuestionCircleFilled } from "@ant-design/icons";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, RadioGroup } from "@ni/common/ui";
import { numberFormatter, numberParser } from "@ni/common/utils";
import { Product } from "@ni/sdk/models";

import { useInsuranceProgramApi } from "../../../../hooks";

import { actionOption, coverageOption, insuranceCoverOption } from "./constants";

export const ProductCoverageSettings: FC = () => {
  const { id: tenantId, insuranceId, productId } = useParams<{ id: string; insuranceId: string; productId: string }>();
  const [form] = Form.useForm<FormValues>();
  const {
    currentInsuranceProgram: currentInsurance,
    editInsurance,
    formValuesConverter,
    currency,
  } = useInsuranceProgramApi();

  const [currentProduct] = useReduxState<Product>("currentProduct", {});

  useHydrateForm({
    form,
    entityFields: currentInsurance?.values ?? [],
    keys: {
      strings: [actionOption.code, coverageOption.code, insuranceCoverOption.code],
    },
    allowParse: false,
  });

  const onFinish = async (values: FormValues) => {
    await editInsurance({ values: formValuesConverter(values) });
  };

  const relatedLinks = useMemo(
    () => [
      {
        label: "Insurance Program Templates",
        href: `/tenant/${tenantId}/insurance-program-templates`,
      },
      ...(currentProduct.parameterTables?.flatMap(table => ({
        href: `/tenant/${tenantId}/product/${productId}/pct/${table.id}/insurance-edit/${insuranceId}/insurance-premium-settings?tab=coverage`,
        label: `${currentProduct.displayName} - ${table.displayName} PCT - ${currentInsurance?.name} - Insurance Coverage`,
      })) || []),
    ],
    [currentInsurance, currentProduct, tenantId, productId, insuranceId],
  );

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Insurance Coverage Settings"
      pageSubtitle="Specify the insured balances' eligible components, you can also identify maximum coverage amount which will impact the truncation indicator and the premium amount calculation."
      formSize="full"
      gap={24}
      submitLabel="Save"
      submitHandler={onFinish}
      level="product"
      size="md"
      linkTitle="Product Settings"
      additionalRoute="/insurance-programs"
      relatedLinks={relatedLinks}
    >
      <Form.Item
        name={insuranceCoverOption.code}
        label={insuranceCoverOption.name}
        initialValue={insuranceCoverOption.default}
      >
        <RadioGroup radioList={insuranceCoverOption.options} initialValue={insuranceCoverOption.default as string} />
      </Form.Item>

      <Form.Item
        name={actionOption.code}
        label={actionOption.name}
        initialValue={actionOption.default}
        tooltip={{
          title: actionOption.tooltip,
          icon: <QuestionCircleFilled />,
        }}
      >
        <RadioGroup radioList={actionOption.options} initialValue={actionOption.default as string} />
      </Form.Item>

      <Alert
        message="Below preferences will be applied to all contracts under this product, you can override below parameter(s) value for a specific pricing control table in related screens"
        type="info"
        showIcon={true}
      />

      <Form.Item
        name={coverageOption.code}
        label={coverageOption.name}
        tooltip={{
          title: coverageOption.tooltip,
          icon: <QuestionCircleFilled />,
        }}
      >
        <InputNumber
          addonAfter={currency as string}
          min={coverageOption.min}
          precision={coverageOption.decimalsNumber}
          formatter={numberFormatter}
          parser={numberParser}
        />
      </Form.Item>
    </CustomFormWrapper>
  );
};
