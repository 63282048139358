import React, { FC, useEffect } from "react";
import { Form, InputNumber } from "antd";

import { QuestionCircleFilled } from "@ant-design/icons";
import { useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, FormItemLabel } from "@ni/common/ui";
import { checkValueBetweenRangeValidator, getFormValueFromProductValues } from "@ni/common/utils";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { ProductValue, TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";
import { ClosureTriggering } from "@ni/tenant-portal/core";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const ManualClosurePage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm();

  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  useEffect(() => {
    form.setFieldsValue({
      "nic-acc-st-man-close-days":
        getFormValueFromProductValues(
          wizardResponse.product?.productValues as ProductValue[],
          "nic-acc-st-man-close-days",
          "number",
        ) || 33,
      "nic-card-st-man-close-days":
        getFormValueFromProductValues(
          wizardResponse.product?.productValues as ProductValue[],
          "nic-card-st-man-close-days",
          "number",
        ) || 33,
    });
  }, [form, wizardResponse.product?.productValues]);

  const onFinish = (values: FormValues) => {
    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: values as { [key: string]: string },
    };

    setIsLoading(true);
    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Manual Closure"
      formTitle="Manual closure (cancellation) settings"
      pageSubtitle={<ClosureTriggering showLinks={false} />}
      size="sm"
      formSize="md"
      gap={24}
      level="root"
      submitHandler={onFinish}
    >
      <Form.Item
        name="nic-acc-st-man-close-days"
        label={
          <FormItemLabel label="Pre-closure period for account (number of days)" code="nic-acc-st-man-close-days" />
        }
        className="wizard-form-input"
        rules={[
          () => ({
            validator(_, value) {
              return checkValueBetweenRangeValidator(value, "Number of days", 0, 365);
            },
          }),
        ]}
      >
        <InputNumber precision={0} />
      </Form.Item>

      <Form.Item
        name="nic-card-st-man-close-days"
        label={
          <FormItemLabel label="Pre-closure period for cards (number of days)" code="nic-card-st-man-close-days" />
        }
        className="wizard-form-input"
        tooltip={{
          title:
            "Cancelling the card is needed mostly for the supplementary card or for the cases when more than one primary card is allowed. It is used for canceling one specific card without cancelling the account.",
          icon: <QuestionCircleFilled />,
        }}
        rules={[
          () => ({
            validator(_, value) {
              return checkValueBetweenRangeValidator(value, "Number of days", 0, 365);
            },
          }),
        ]}
      >
        <InputNumber precision={0} />
      </Form.Item>
    </CustomFormWrapper>
  );
};
