import { FC } from "react";
import { Form, Input } from "antd";
import NumberFormat from "react-number-format";

import { QuestionCircleFilled } from "@ant-design/icons";
import { LTY_REDEEM_TYPE, NIC_LTY_RED_RATE_CASH_RATE, NIC_LTY_RED_RATE_EXT_RATE } from "@ni/common/constants";
import { FormContent } from "@ni/common/ui";
import { LoyaltyProgramTemplate } from "@ni/sdk/models";

interface PointRedemptionProps {
  loyaltyPrograms: LoyaltyProgramTemplate[];
}

export const PointRedemption: FC<PointRedemptionProps> = ({ loyaltyPrograms }) => {
  const doesCashbackShown = loyaltyPrograms.some(
    program => program.programValues?.find(value => value?.code === LTY_REDEEM_TYPE)?.value === "C",
  );
  const doesVoucherShown = loyaltyPrograms.some(
    program => program.programValues?.find(value => value?.code === LTY_REDEEM_TYPE)?.value === "E",
  );

  return (
    <FormContent gap={40}>
      <div>
        Loyalty points redemption is the process of utilizing accumulated loyalty points to obtain rewards, benefits, or
        discounts offered by a loyalty program.
      </div>

      {doesCashbackShown && (
        <Form.Item
          name={NIC_LTY_RED_RATE_CASH_RATE}
          label="Cashback redemption rate"
          tooltip={{
            title:
              "Points can be redeemed and credit (cashback) transaction is posted to customer account based upon the rate defined for cashback redemption",
            icon: <QuestionCircleFilled />,
          }}
        >
          <NumberFormat
            customInput={Input}
            addonAfter="%"
            allowNegative={false}
            isNumericString={true}
            thousandSeparator=" "
            decimalScale={2}
            decimalSeparator="."
            fixedDecimalScale={true}
          />
        </Form.Item>
      )}

      {doesVoucherShown && (
        <Form.Item
          name={NIC_LTY_RED_RATE_EXT_RATE}
          label="External voucher redemption rate"
          tooltip={{
            title:
              "Rewards points can be redeemed against voucher amount based upon the rate defined for external redemption",
            icon: <QuestionCircleFilled />,
          }}
        >
          <NumberFormat
            customInput={Input}
            addonAfter="%"
            allowNegative={false}
            isNumericString={true}
            thousandSeparator=" "
            decimalScale={2}
            decimalSeparator="."
            fixedDecimalScale={true}
          />
        </Form.Item>
      )}
    </FormContent>
  );
};
