import { FC, useEffect } from "react";
import { Form } from "antd";

import { useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper } from "@ni/common/ui";
import { getFormValueFromProductValues } from "@ni/common/utils";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { ProductValue, TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";
import {
  checkboxFieldsPage10,
  checkHeader,
  getCardDailyLimitsPreparedValues,
  PctCardDaily,
  switchHeader10,
} from "@ni/tenant-portal/core";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const TransactionCardLimitsPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  const currency = (getFormValueFromProductValues(wizardResponse.product?.productValues, "nic-ccy") as string) || "";

  useEffect(() => {
    const formDisabledFn = (field: string, number: string | number) =>
      formDisabled
        ? (getFormValueFromProductValues(
            wizardResponse.product?.parameterTables?.[0].pctProductValues as ProductValue[],
            field,
            "string",
          ) as string) || ""
        : number;

    form.setFieldsValue({
      "nic-c-d-cash-atm-enable": true,
      "nic-c-d-cash-atm-max-am": formDisabledFn("nic-c-d-cash-atm-max-am", "500000"),
      "nic-c-d-cash-atm-max-nr": formDisabledFn("nic-c-d-cash-atm-max-nr", "20"),
      "nic-c-d-cash-atm-max-single": formDisabledFn("nic-c-d-cash-atm-max-single", "500000"),
      "nic-c-d-cash-enable": true,
      "nic-c-d-cash-max-am": formDisabledFn("nic-c-d-cash-max-am", "500000"),
      "nic-c-d-cash-max-nr": formDisabledFn("nic-c-d-cash-max-nr", "20"),
      "nic-c-d-cash-max-single": formDisabledFn("nic-c-d-cash-max-single", "500000"),
      "nic-c-d-cash-pos-enable": true,
      "nic-c-d-cash-pos-max-am": formDisabledFn("nic-c-d-cash-pos-max-am", "500000"),
      "nic-c-d-cash-pos-max-nr": formDisabledFn("nic-c-d-cash-pos-max-nr", "20"),
      "nic-c-d-cash-pos-max-single": formDisabledFn("nic-c-d-cash-pos-max-single", "500000"),
      "nic-c-d-ecomm-enable": true,
      "nic-c-d-ecomm-max-am": formDisabledFn("nic-c-d-ecomm-max-am", "500000"),
      "nic-c-d-ecomm-max-nr": formDisabledFn("nic-c-d-ecomm-max-nr", "20"),
      "nic-c-d-ecomm-max-single": formDisabledFn("nic-c-d-ecomm-max-single", "500000"),
      "nic-c-d-retail-enable": true,
      "nic-c-d-retail-max-am": formDisabledFn("nic-c-d-retail-max-am", "500000"),
      "nic-c-d-retail-max-nr": formDisabledFn("nic-c-d-retail-max-nr", "20"),
      "nic-c-d-retail-max-single": formDisabledFn("nic-c-d-retail-max-single", "500000"),
      "nic-c-d-total-enable": true,
      "nic-c-d-total-max-am": formDisabledFn("nic-c-d-total-max-am", "500000"),
      "nic-c-d-total-max-nr": formDisabledFn("nic-c-d-total-max-nr", 21),
      "nic-c-d-total-max-single": formDisabledFn("nic-c-d-total-max-single", "500000"),
    });

    Object.keys(checkboxFieldsPage10).forEach(key => {
      checkHeader(key, checkboxFieldsPage10, form);
    });

    checkHeader("card-daily-all-cash-enable", switchHeader10, form);
    checkHeader("card-daily-all-retail-enable", switchHeader10, form);
    checkHeader("card-daily-all-total-enable", switchHeader10, form);
  }, [form, formDisabled, wizardResponse.product?.parameterTables]);

  const onFinish = (value: FormValues) => {
    let actualValues: { [key: string]: string } = {};

    actualValues = {
      ...actualValues,
      ...getCardDailyLimitsPreparedValues(value as { [key: string]: string | boolean }),
    };

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: actualValues,
    };

    setIsLoading(true);
    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Card Limits - Daily"
      pageSubtitle="Card daily limits control spending on the card level independently from account limits, which even limit
      is reached first."
      size="md"
      formSize="full"
      gap={24}
      level="root"
      submitHandler={onFinish}
    >
      <PctCardDaily currency={currency} form={form} formDisabled={formDisabled} />
    </CustomFormWrapper>
  );
};
