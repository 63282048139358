import { FC, useMemo } from "react";
import { Form, InputNumber, Space, Switch } from "antd";

import { QuestionCircleFilled } from "@ant-design/icons";
import {
  MEM_FEE_TYPE,
  NIC_AMF_ACC_EX_MONTHS,
  NIC_AMF_ACC_VAL,
  NIC_CCY,
  NIC_MMF_ACC_VAL,
  WAIVE_AMF_SPEND,
  WAIVE_AMF_SPEND_AMOUNT,
} from "@ni/common/constants";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { feeIntervalList } from "@ni/common/mocks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, FormContent, FormItemLabel, RadioGroup, TooltipInfo } from "@ni/common/ui";
import { getFormValueFromProductValues, numberFormatter, numberParser } from "@ni/common/utils";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

const wizardServicesApi = new ProductAndTenantWizardApi();

const stringsKeys = [
  MEM_FEE_TYPE,
  NIC_AMF_ACC_VAL,
  NIC_AMF_ACC_EX_MONTHS,
  NIC_MMF_ACC_VAL,
  WAIVE_AMF_SPEND,
  WAIVE_AMF_SPEND_AMOUNT,
];

export const MembershipFeesPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const [, setIsLoading] = useReduxState<boolean>("isLoading");
  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});

  const selectedFeeInterval = Form.useWatch(MEM_FEE_TYPE, form);
  const isAnnual = Form.useWatch(MEM_FEE_TYPE, form) === "Y";
  const waiveFeeEnabled = Form.useWatch(WAIVE_AMF_SPEND, form);

  const productCurrency = useMemo(
    () => getFormValueFromProductValues(wizardResponse.product?.productValues, NIC_CCY),
    [wizardResponse.product?.productValues],
  );

  useHydrateForm({
    form,
    entityFields: wizardResponse.product?.parameterTables?.[0].pctProductValues ?? [],
    keys: {
      strings: stringsKeys,
    },
    allowParse: false,
  });

  const onFinish = (values: FormValues) => {
    setIsLoading(true);

    [...stringsKeys].forEach(key => {
      if (
        !values[key]?.toString() &&
        wizardResponse?.product?.parameterTables?.[0].pctProductValues?.find(item => item.fieldCode === key)
      ) {
        values[key] = "";
      }
    });

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: values as { [key: string]: string },
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Membership Fees"
      pageSubtitle="Membership fees are charged to the account holder over the life cycle of cards, when account is initially
        boarded an annual membership fee can be charged based upon the product set up. Similarly, you can choose
        to charge your membership fees on monthly basis."
      size="sm"
      formSize="md"
      gap={40}
      level="root"
      submitHandler={onFinish}
    >
      <Form.Item
        name={MEM_FEE_TYPE}
        label={<FormItemLabel label="Membership fee interval" code={MEM_FEE_TYPE} />}
        initialValue="Y"
      >
        <RadioGroup radioList={feeIntervalList} gap={8} disabled={formDisabled} />
      </Form.Item>

      {selectedFeeInterval !== "N" && (
        <FormContent gap={24} title={`account ${isAnnual ? "annual" : "monthly"}  membership parameters`}>
          {isAnnual && (
            <TooltipInfo
              largeLabel="Annual Membership fee is a flat amount levied to the customer on the first billing day and each anniversary or on another day based on setup"
              tooltipProps={{
                title:
                  "You can access your product full fees configurations, after wizard completion, by visiting `Product setting` menu option on dashboard",
              }}
            />
          )}

          {selectedFeeInterval === "Y" && (
            <>
              <Form.Item
                name={NIC_AMF_ACC_VAL}
                label={<FormItemLabel label="Annual membership fee amount" code={NIC_AMF_ACC_VAL} />}
                rules={[{ required: true, message: "Annual membership fee amount is required" }]}
                initialValue={productCurrency === "USD" ? "100.00" : "350.00"}
              >
                <InputNumber
                  min={0}
                  precision={2}
                  controls={false}
                  addonAfter={productCurrency}
                  parser={numberParser}
                  formatter={numberFormatter}
                />
              </Form.Item>

              <Form.Item
                name={NIC_AMF_ACC_EX_MONTHS}
                label={<FormItemLabel label="Delay first charge by (months)" code={NIC_AMF_ACC_EX_MONTHS} />}
                initialValue="12"
              >
                <InputNumber min={0} max={120} precision={0} step={1} />
              </Form.Item>

              <Space direction="horizontal" size={8}>
                <Form.Item valuePropName="checked" name={WAIVE_AMF_SPEND} initialValue={false}>
                  <Switch disabled={formDisabled} />
                </Form.Item>
                <TooltipInfo
                  label="Waive fee on eligible net spend"
                  tooltipProps={{
                    title:
                      "Specify a net spend limit to waive annual membership fee based on amount spent on the year between charging occurrences",
                  }}
                />
              </Space>

              {waiveFeeEnabled && (
                <Form.Item
                  name={WAIVE_AMF_SPEND_AMOUNT}
                  label="Net spend amount"
                  tooltip={{
                    icon: <QuestionCircleFilled />,
                    title: "If net spend is equal to or higher than this amount, fee will be waived",
                  }}
                  rules={[{ required: true, message: "Net spend amount is required" }]}
                >
                  <InputNumber
                    min={0}
                    precision={2}
                    controls={false}
                    addonAfter={productCurrency}
                    parser={numberParser}
                    formatter={numberFormatter}
                  />
                </Form.Item>
              )}
            </>
          )}

          {selectedFeeInterval === "M" && (
            <Form.Item
              name={NIC_MMF_ACC_VAL}
              label={<FormItemLabel label="Monthly fee amount" code={NIC_MMF_ACC_VAL} />}
              rules={[{ required: true, message: "Monthly fee amount is required" }]}
              initialValue={productCurrency === "USD" ? "10.00" : "35.00"}
            >
              <InputNumber
                min={0}
                precision={2}
                controls={false}
                addonAfter={productCurrency}
                parser={numberParser}
                formatter={numberFormatter}
              />
            </Form.Item>
          )}
        </FormContent>
      )}
    </CustomFormWrapper>
  );
};
