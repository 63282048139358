import { FC, ReactNode, useMemo } from "react";
import { Form, Space } from "antd";

import { useHydrateForm } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, Tabs } from "@ni/common/ui";

import { useInsuranceApi } from "../../../../hooks";

import { KeyTab, tab1, tab2 } from "./constants";

export const PremiumSettings: FC = () => {
  const [form] = Form.useForm<FormValues>();
  const { currentInsurance, editInsurance, formValuesConverter } = useInsuranceApi();

  const initialValues = useHydrateForm({
    form,
    entityFields: currentInsurance?.values ?? [],
    keys: {
      strings: [
        tab1.capOption.code,
        tab1.fixedOption.code,
        tab1.percentageOption.code,
        tab1.periodOption.code,
        tab1.periodTypeOption.code,
        tab2.coverageOption.code,
        tab2.transactorOption.code,
      ],
    },
    allowParse: true,
  });

  const tabList = useMemo(() => {
    const tabsContent: { [tab in KeyTab]: ReactNode } = {
      setting: tab1.page({ currency: currentInsurance?.currency ?? "" }),
      waiving: tab2.page(),
    } as const;

    return [
      {
        key: "setting",
        label: <div title="setting">Premuim Setting</div>,
        children: tabsContent.setting,
      },
      {
        key: "waiving",
        label: <div title="waiving">Premuim Waiving;</div>,
        children: tabsContent.waiving,
      },
    ];
  }, [currentInsurance?.currency]);

  const onFinish = async (values: FormValues) => {
    await editInsurance({ values: formValuesConverter(values) });
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Insurance Premium Settings"
      pageSubtitle={
        <Space direction="vertical">
          <div>
            Insurance is billed to the customer at the end of the billing cycle with the amount calculated based on
            multiple parameters. It can be either a percentage of the statement balance or a fixed amount, charged as an
            insurance premium. You can choose to reverse the premium for initial number of months or cycles, while
            reversing the insurance premium; system will take care of deducting that out of payment due amount and
            retail plan or full fee cash plan.
          </div>
          <div>
            Insurance premium can be waived in case customer has paid his statement cycle in full and computation base
            is zero. You can also specify to waive premium for transactor customers who paid in full, the previous
            cycle&#39;s statement balance.
          </div>
        </Space>
      }
      level="tenant"
      size="md"
      formSize="md"
      gap={40}
      submitLabel="Save"
      linkTitle="Insurance Templates"
      submitHandler={onFinish}
      additionalRoute="./insurance-program-templates"
    >
      <Tabs
        pagesList={tabList}
        isCheckEnabled={true}
        form={form}
        initialValues={initialValues}
        onSave={onFinish}
        discardAfterChangeTab={false}
      />
    </CustomFormWrapper>
  );
};
