import { FC } from "react";
import { Collapse } from "antd";
import cn from "classnames";

import { RightOutlined } from "@ant-design/icons";
import { Event, EventGroup, Sms } from "@ni/sdk/models";

import { EventSubgroupsList } from "../EventSubgroupsList";

import styles from "./styles.module.scss";

interface EventGroupListProps {
  items?: EventGroup[];
  onEditClick: (event: Event, _groupId: number, smsDetails: Sms) => void;
  smsList?: Sms[];
  onSwitchClick: (event: Event, smsDetails: Sms) => void;
}

const { Panel } = Collapse;

export const EventGroupList: FC<EventGroupListProps> = props => {
  const { items, onEditClick, smsList, onSwitchClick } = props;

  const extraHeader = (name: string | undefined) => <div className={styles["title"]}>{name ?? ""}</div>;

  return (
    <div>
      <Collapse
        bordered={false}
        className={cn(styles["collapsible-list"], styles["collapsible-list--bolded"])}
        // eslint-disable-next-line react/no-unstable-nested-components
        expandIcon={({ isActive }) => <RightOutlined style={{ color: "#0060A8" }} rotate={isActive ? 90 : 0} />}
      >
        {items?.map(item => (
          <Panel header={extraHeader(item.name)} key={item.id}>
            {item.eventSubgroups && (
              <EventSubgroupsList
                items={item.eventSubgroups}
                key={item.id}
                smsList={smsList}
                onEditClick={onEditClick}
                onSwitchClick={onSwitchClick}
              />
            )}
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

EventGroupList.defaultProps = {
  items: [],
  smsList: [],
};
