import { FC } from "react";
import { Link, useParams } from "react-router-dom";

import styles from "./styles.module.scss";

interface ClosureTriggeringProps {
  showLinks: boolean;
}

export const ClosureTriggering: FC<ClosureTriggeringProps> = ({ showLinks }) => {
  const { id } = useParams<{ id: string }>();

  return (
    <div className={showLinks ? styles["after-wizard-form-smaller-width"] : ""}>
      <div>
        Contract closure can be triggered by card holder side or by the issuer side, via placing a block code (for both
        cards and accounts). Please visit the Block Codes page from the dashboard to check the Block Codes settings.
        {showLinks && <Link to={`/tenant/${id}/block-codes`}> Open Block Codes settings &#10132; </Link>}
        Cardholder will receive a default SMS, you can customize SMS templates by visiting SMS Settings from the
        dashboard.
        {showLinks && <Link to={`/tenant/${id}/sms-templates`}> Open SMS Templates settings &#10132; </Link>}
      </div>
      <br />
      <div>
        Triggering closure will change certain features as an immediate action like, blocking the authorization, Card
        Control feature. However, the contract status will change to closed; only when the defined &quot;Pre-closure
        period&quot; closure concludes. The main role of the &quot;Pre-closure period&quot; is allowing presentments to
        come from the payment scheme. After closure process completion, accepting presentments by schemes will no longer
        be allowed, and the contract will be excluded from all reports, then purged as per the purge policy.
      </div>
    </div>
  );
};
