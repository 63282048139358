import React, { FC, useEffect } from "react";
import { Form, InputNumber } from "antd";

import { useReduxState } from "@ni/common/hooks";
import { periodRadioOptions } from "@ni/common/mocks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, FormItemLabel, RadioGroup } from "@ni/common/ui";
import { checkValueBetweenRangeValidator, getFormValueFromProductValues } from "@ni/common/utils";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { ProductValue, TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";
import { Purge } from "@ni/tenant-portal/core";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const ContractPurgePage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const periodOptions = periodRadioOptions;

  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  useEffect(() => {
    form.setFieldsValue({
      "nic-acc-st-auto-purge-per-type":
        getFormValueFromProductValues(
          wizardResponse.product?.productValues as ProductValue[],
          "nic-acc-st-auto-purge-per-type",
        ) || "M",
      "nic-acc-st-auto-purge-per":
        getFormValueFromProductValues(
          wizardResponse.product?.productValues as ProductValue[],
          "nic-acc-st-auto-purge-per",
          "number",
        ) || 3,
      "nic-card-st-auto-purge-per-type":
        getFormValueFromProductValues(
          wizardResponse.product?.productValues as ProductValue[],
          "nic-card-st-auto-purge-per-type",
        ) || "M",
      "nic-card-st-auto-purge-per":
        getFormValueFromProductValues(
          wizardResponse.product?.productValues as ProductValue[],
          "nic-card-st-auto-purge-per",
          "number",
        ) || 3,
    });
  }, [form, wizardResponse.product?.productValues]);

  const onFinish = (value: FormValues): void => {
    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: value as { [key: string]: string },
    };

    setIsLoading(true);
    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Contract Purge"
      formTitle="Pre-purge parameters"
      pageSubtitle={<Purge isAfterWizard={false} />}
      size="sm"
      formSize="md"
      gap={24}
      level="root"
      submitHandler={onFinish}
    >
      <Form.Item
        name="nic-acc-st-auto-purge-per-type"
        label={<FormItemLabel label="Pre-purge period type (for accounts)" code="nic-acc-st-auto-purge-per-type" />}
      >
        <RadioGroup radioList={periodOptions} />
      </Form.Item>

      <Form.Item noStyle={true} dependencies={["nic-acc-st-auto-purge-per-type"]}>
        {() => (
          <Form.Item
            name="nic-acc-st-auto-purge-per"
            label={<FormItemLabel label="Pre-purge period number (for accounts)" code="nic-acc-st-auto-purge-per" />}
            dependencies={["nic-acc-st-auto-purge-per-type"]}
            rules={[
              () => ({
                validator(_, value) {
                  return checkValueBetweenRangeValidator(
                    value,
                    form.getFieldValue("nic-acc-st-auto-purge-per-type") === "M"
                      ? "Number of months"
                      : "Number of days",
                    0,
                    form.getFieldValue("nic-acc-st-auto-purge-per-type") === "M" ? 12 : 365,
                  );
                },
              }),
            ]}
          >
            <InputNumber />
          </Form.Item>
        )}
      </Form.Item>

      <Form.Item
        name="nic-card-st-auto-purge-per-type"
        label={<FormItemLabel label="Pre-purge period type (for cards)" code="nic-card-st-auto-purge-per-type" />}
      >
        <RadioGroup radioList={periodOptions} />
      </Form.Item>

      <Form.Item noStyle={true} dependencies={["nic-card-st-auto-purge-per-type"]}>
        {() => (
          <Form.Item
            name="nic-card-st-auto-purge-per"
            label={<FormItemLabel label="Pre-purge period number (for cards)" code="nic-card-st-auto-purge-per" />}
            dependencies={["nic-card-st-auto-purge-per-type"]}
            rules={[
              () => ({
                validator(_, value) {
                  return checkValueBetweenRangeValidator(
                    value,
                    form.getFieldValue("nic-card-st-auto-purge-per-type") === "M"
                      ? "Number of months"
                      : "Number of days",
                    0,
                    form.getFieldValue("nic-card-st-auto-purge-per-type") === "M" ? 12 : 365,
                  );
                },
              }),
            ]}
          >
            <InputNumber />
          </Form.Item>
        )}
      </Form.Item>
    </CustomFormWrapper>
  );
};
