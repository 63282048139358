import { FC, useCallback, useEffect, useMemo } from "react";
import { Form, Input, Space, Switch } from "antd";
import NumberFormat from "react-number-format";

import { JF_ACC_ENABLED, NICE_JF_ACC_VAL } from "@ni/common/constants";
import { useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps } from "@ni/common/types";
import { CustomFormWrapper, FormItemLabel, TooltipInfo } from "@ni/common/ui";
import { getFormValueFromProductValues } from "@ni/common/utils";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { ProductValue, TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const JoiningFeePage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [, setIsLoading] = useReduxState<boolean>("isLoading");
  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});

  const [form] = Form.useForm();
  const isEnabled = Form.useWatch<boolean>(JF_ACC_ENABLED, form);
  const feeValue = Form.useWatch<string>(NICE_JF_ACC_VAL, form);

  const currency = useMemo(
    () => (getFormValueFromProductValues(wizardResponse.product?.productValues, "nic-ccy") as string) || "",
    [wizardResponse.product?.productValues],
  );

  const productValues = useMemo(
    () => wizardResponse.product?.parameterTables?.[0].pctProductValues as ProductValue[],
    [wizardResponse.product?.parameterTables],
  );

  const formDisabledFn = useCallback(
    (field: string, number: string | number) =>
      formDisabled ? (getFormValueFromProductValues(productValues, field, "string") as string) || "" : number,
    [formDisabled, productValues],
  );

  useEffect(() => {
    form.setFieldsValue({
      [JF_ACC_ENABLED]: formDisabled ? getFormValueFromProductValues(productValues, JF_ACC_ENABLED, "boolean") : true,

      [NICE_JF_ACC_VAL]: formDisabledFn(NICE_JF_ACC_VAL, currency === "USD" ? "85" : "300"),
    });
  }, [currency, form, formDisabled, formDisabledFn, productValues]);

  const switchClick = () => {
    if (isEnabled && feeValue.length === 0) {
      form.setFields([
        {
          name: NICE_JF_ACC_VAL,
          errors: [],
        },
      ]);
    }
  };

  const onFinish = () => {
    setIsLoading(true);

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: isEnabled
        ? {
            [JF_ACC_ENABLED]: String(isEnabled),
            [NICE_JF_ACC_VAL]: feeValue.replace(/ /g, ""),
          }
        : { [JF_ACC_ENABLED]: String(isEnabled) },
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      buttonDisabled={isEnabled && feeValue?.length <= 0}
      pageTitle="Joining Fee"
      pageSubtitle="Joining fee is a one-time fee that is charged to customer upon account opening regardless of card
        activation status or number of cards under account."
      size="sm"
      formSize="sm"
      gap={40}
      level="root"
      submitHandler={onFinish}
    >
      <Space direction="horizontal" align="center">
        <Form.Item name={JF_ACC_ENABLED} initialValue={true} valuePropName="checked">
          <Switch onChange={switchClick} />
        </Form.Item>
        <TooltipInfo
          label="Enable joining fee on your product"
          code={JF_ACC_ENABLED}
          tooltipProps={{
            title:
              "Joining fee will be charged on account once the account is boarded on system, regardless of number of cards",
          }}
        />
      </Space>
      <Form.Item
        name={NICE_JF_ACC_VAL}
        label={<FormItemLabel label="Joining fee amount" code={NICE_JF_ACC_VAL} />}
        rules={[
          {
            required: isEnabled,
            message: "Joining fee amount is required",
          },
        ]}
      >
        <NumberFormat
          customInput={Input}
          addonAfter={currency}
          allowNegative={false}
          thousandSeparator=" "
          disabled={!isEnabled || formDisabled}
          decimalScale={2}
          decimalSeparator="."
          fixedDecimalScale={true}
        />
      </Form.Item>
    </CustomFormWrapper>
  );
};
