import { FC, useEffect } from "react";
import { Form, FormInstance, Switch } from "antd";

import { FormValues } from "@ni/common/types";
import { HeadingsRow } from "@ni/common/ui";
import { extractNumberFromString } from "@ni/common/utils";
import { checkHeader, headerSwitchHandler } from "@ni/tenant-portal/core";

import { LIMITS_HEADINGS } from "../../../constants";

import { LimitsHeader } from "./LimitsHeader";
import { LimitsValuesRow } from "./LimitsValuesRow";

interface LimitsKeys {
  allFieldsEnabledKey: string;
  totalEnabledKey: string;
  totalNrKey: string;
  totalAmKey: string;
  totalSingleKey: string;
}

interface LimitsBlockProps {
  title: string;
  tooltipText: string;
  productCurrency: string;
  existedRules: string[];
  totalPaymentsEnabled: { [x: string]: string[] };
  keys: LimitsKeys;
  rulesKeyPrefix: "d" | "b" | "d365" | "e" | "m";
  // account or card
  limitType: "a" | "c";
  form: FormInstance<FormValues>;
}

type Postfixes = "nr" | "am" | "single";

export const LimitsBlock: FC<LimitsBlockProps> = ({
  title,
  tooltipText,
  productCurrency,
  existedRules,
  totalPaymentsEnabled,
  keys,
  rulesKeyPrefix,
  limitType,
  form,
}) => {
  const createRuleKey = (serialNumber: number, postfix: Postfixes): string =>
    `nic-${rulesKeyPrefix}-paym-${limitType}-u0${serialNumber}-${postfix}`;

  useEffect(() => {
    checkHeader(keys.allFieldsEnabledKey, totalPaymentsEnabled, form);
  }, [form, keys.allFieldsEnabledKey, totalPaymentsEnabled]);

  return (
    <div className="limits-block md">
      <LimitsHeader title={title} tooltipText={tooltipText}>
        <Form.Item valuePropName="checked" name={keys.allFieldsEnabledKey} className="header-switch">
          <Switch
            onClick={checked => {
              headerSwitchHandler(keys.allFieldsEnabledKey, totalPaymentsEnabled, checked, form);
            }}
          />
        </Form.Item>
      </LimitsHeader>
      <div className="limits-block-body">
        <HeadingsRow firstColSpan={6} headings={LIMITS_HEADINGS} />
        <LimitsValuesRow
          limitName="Total"
          limitKey={keys.totalEnabledKey}
          form={form}
          currency={productCurrency}
          inputsNames={{
            maxNumber: keys.totalNrKey,
            maxAmount: keys.totalAmKey,
            maxSingleAmount: keys.totalSingleKey,
          }}
        >
          <Form.Item valuePropName="checked" name={keys.totalEnabledKey} className="col-switch">
            <Switch onClick={() => checkHeader(keys.allFieldsEnabledKey, totalPaymentsEnabled, form)} />
          </Form.Item>
        </LimitsValuesRow>
        {existedRules.map((rule, index) => {
          const ruleName = `${rulesKeyPrefix}-paym-${limitType}-u0${
            extractNumberFromString(rule) ?? index + 1
          }-enabled`;
          const maxNumber = createRuleKey(extractNumberFromString(rule) ?? index + 1, "nr");
          const maxAmount = createRuleKey(extractNumberFromString(rule) ?? index + 1, "am");
          const maxSingleAmount = createRuleKey(extractNumberFromString(rule) ?? index + 1, "single");

          return (
            <LimitsValuesRow
              key={ruleName}
              limitName={`Additional rule ${extractNumberFromString(rule) ?? index + 1}`}
              limitKey={ruleName}
              form={form}
              currency={productCurrency}
              inputsNames={{
                maxNumber,
                maxAmount,
                maxSingleAmount,
              }}
            >
              <Form.Item valuePropName="checked" name={ruleName} className="col-switch" initialValue={false}>
                <Switch onClick={() => checkHeader(keys.allFieldsEnabledKey, totalPaymentsEnabled, form)} />
              </Form.Item>
            </LimitsValuesRow>
          );
        })}
      </div>
    </div>
  );
};
