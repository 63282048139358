import { FC, useCallback, useEffect, useMemo } from "react";
import { Form, Input, Space, Switch } from "antd";
import NumberFormat from "react-number-format";

import { NIC_CCY, NIC_STMT_FEE_VAL, STMT_FEE_ENABLED } from "@ni/common/constants";
import { useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, FormItemLabel, TooltipInfo } from "@ni/common/ui";
import { getFormValueFromProductValues } from "@ni/common/utils";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { ProductValue, TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const StatementFeePage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [, setIsLoading] = useReduxState<boolean>("isLoading");
  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});

  const [form] = Form.useForm<FormValues>();
  const isEnabled = Form.useWatch<boolean>(STMT_FEE_ENABLED, form);
  const feeValue = Form.useWatch<string>(NIC_STMT_FEE_VAL, form);

  const currency = useMemo(
    () => (getFormValueFromProductValues(wizardResponse.product?.productValues, NIC_CCY) as string) || "",
    [wizardResponse.product?.productValues],
  );

  const productValues = useMemo(
    () => wizardResponse.product?.parameterTables?.[0].pctProductValues as ProductValue[],
    [wizardResponse.product?.parameterTables],
  );

  const formDisabledFn = useCallback(
    (field: string, number: string | number) =>
      formDisabled ? (getFormValueFromProductValues(productValues, field, "string") as string) || "" : number,
    [formDisabled, productValues],
  );

  useEffect(() => {
    form.setFieldsValue({
      [STMT_FEE_ENABLED]: formDisabled
        ? getFormValueFromProductValues(productValues, STMT_FEE_ENABLED, "boolean")
        : true,
      [NIC_STMT_FEE_VAL]: formDisabledFn(NIC_STMT_FEE_VAL, currency === "USD" ? "1" : "5"),
    });
  }, [currency, form, formDisabled, formDisabledFn, productValues]);

  const switchClick = () => {
    if (isEnabled && feeValue.length === 0) {
      form.setFields([
        {
          name: NIC_STMT_FEE_VAL,
          errors: [],
        },
      ]);
    }
  };

  const onFinish = () => {
    setIsLoading(true);

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: isEnabled
        ? {
            [STMT_FEE_ENABLED]: String(isEnabled),
            [NIC_STMT_FEE_VAL]: feeValue.replace(/ /g, ""),
          }
        : { [STMT_FEE_ENABLED]: String(isEnabled) },
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      buttonDisabled={isEnabled && feeValue?.length <= 0}
      pageTitle="Statement Fee"
      pageSubtitle={
        <div>
          <TooltipInfo
            largeLabel="Statement fee is the fixed amount to charge to the customer with the following statement flags: E-mail
        statement, Physical statement, or Both."
            tooltipProps={{
              title:
                "After wizard completing you can configure client statement flags which are applicable for the fee charging. For this purpose visit product settings (Fees Product Configuration page)",
            }}
          />
          {"   "}
          The statement fee will be posted on billing date.
        </div>
      }
      size="sm"
      formSize="sm"
      gap={24}
      level="root"
      submitHandler={onFinish}
    >
      <Space direction="horizontal" align="center">
        <Form.Item name={STMT_FEE_ENABLED} initialValue={true} valuePropName="checked" className="col-switch">
          <Switch onChange={switchClick} />
        </Form.Item>
        <TooltipInfo label="Enable Statement fee for your product" code={STMT_FEE_ENABLED} tooltipProps={{}} />
      </Space>

      <Form.Item
        name={NIC_STMT_FEE_VAL}
        label={<FormItemLabel label="Statement fee amount" code={NIC_STMT_FEE_VAL} />}
        rules={[{ required: isEnabled, message: "Statement fee amount is required" }]}
      >
        <NumberFormat
          customInput={Input}
          addonAfter={currency}
          allowNegative={false}
          thousandSeparator=" "
          disabled={!isEnabled || formDisabled}
          decimalScale={2}
          decimalSeparator="."
          fixedDecimalScale={true}
        />
      </Form.Item>
    </CustomFormWrapper>
  );
};
