import { useMemo, useState } from "react";
import { FormInstance } from "rc-field-form";

import { useQpApi, useReduxState } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { FilterInputProps } from "@ni/common/ui";
import { filterByDisplayValue } from "@ni/common/utils";

import { transformRowkeysToOptions } from "../../utils";

export const useQpFilters = ({ form }: { form: FormInstance<FormValues> }) => {
  const [tenantRowkey, setTenantRowkey] = useReduxState<string | undefined>("tenantRowkey", undefined);
  const [productRowkey, setProductRowkey] = useState<string | undefined>(undefined);

  const { qpParamTypes, qpQuests, qpRowkeys } = useQpApi({ isFetchEnabled: false });

  const resetState = () => {
    setTenantRowkey(undefined);
    setProductRowkey(undefined);
    form.resetFields();
  };

  const qpInputFilters = useMemo(
    (): FilterInputProps[] => [
      {
        name: "rowkey.rowkey",
        type: "select",
        props: {
          placeholder: "Tenant",
          options: qpRowkeys.map(rowkey => ({
            value: rowkey.rowkey,
            label: `${rowkey.rowkey} - ${rowkey.name}`,
          })),
          onSelect: (value: string) => {
            setTenantRowkey(value);

            if (value !== tenantRowkey) {
              setProductRowkey(undefined);
              form.resetFields(["rowkey.product", "rowkey.pct"]);
            }
          },
          onClear: () => {
            setTenantRowkey(undefined);
            setProductRowkey(undefined);
            form.resetFields();
          },
          filterOption: filterByDisplayValue,
          showSearch: true,
          allowClear: true,
        },
      },
      {
        name: "rowkey.product",
        type: "select",
        props: {
          placeholder: "Product",
          options: transformRowkeysToOptions(qpRowkeys, tenantRowkey ?? "0", true),
          onSelect: (value: string) => setProductRowkey(value),
          onClear: () => {
            setProductRowkey(undefined);
            form.resetFields(["rowkey.pct"]);
          },
          disabled: tenantRowkey === undefined,
          filterOption: filterByDisplayValue,
          showSearch: true,
          allowClear: true,
          maxTagCount: "responsive",
        },
      },
      {
        name: "rowkey.pct",
        type: "select",
        props: {
          placeholder: "PCT",
          options: transformRowkeysToOptions(qpRowkeys, productRowkey ?? "0", false),
          disabled: productRowkey === undefined,
          filterOption: filterByDisplayValue,
          showSearch: true,
          allowClear: true,
          maxTagCount: "responsive",
        },
      },
      {
        name: "column.questionnaire",
        type: "select",
        props: {
          placeholder: "Questionnaire",
          options: qpQuests.map(i => ({ value: i.questCode, label: i.questName })),
          mode: "multiple",
          allowClear: true,
          maxTagCount: "responsive",
          disabled: !tenantRowkey,
        },
      },
      {
        name: "column.maxLevel",
        type: "select",
        props: {
          placeholder: "Apply Level",
          options: [
            { value: "1", label: "Tenant" },
            { value: "2", label: "Product" },
            { value: "3", label: "PCT" },
          ],
          mode: "multiple",
          showSearch: false,
          allowClear: true,
          maxTagCount: "responsive",
          disabled: !tenantRowkey,
        },
      },
      {
        name: "column.parmType",
        type: "select",
        props: {
          placeholder: "Parameter Type",
          options: qpParamTypes.map(i => ({ value: i.parmType, label: i.comments })),
          mode: "multiple",
          filterOption: filterByDisplayValue,
          showSearch: true,
          allowClear: true,
          maxTagCount: "responsive",
          disabled: !tenantRowkey,
        },
      },
      {
        name: "popularity",
        type: "select",
        props: {
          placeholder: "Popularity",
          options: [
            { value: "HIGH", label: "High popularity" },
            { value: "MEDIUM", label: "Medium popularity" },
            { value: "LOW", label: "Low popularity" },
          ],
          mode: "multiple",
          showSearch: false,
          allowClear: true,
          maxTagCount: "responsive",
          disabled: !tenantRowkey,
        },
      },
      {
        name: "recordsToShow",
        type: "select",
        props: {
          placeholder: "Display",
          options: [
            { value: "QP_DATA", label: "Display inputs" },
            { value: "QP_METADATA", label: "Display empty values" },
          ],
          disabled: !tenantRowkey,
          defaultValue: "QP_DATA",
        },
      },
    ],
    [form, productRowkey, qpParamTypes, qpQuests, qpRowkeys, setTenantRowkey, tenantRowkey],
  );

  return { qpInputFilters, qpRowkeys, currentRowkey: tenantRowkey, resetState };
};
