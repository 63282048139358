import { FEE_PERCENTAGE_LABEL, FEE_PERCENTAGE_TOOLTIP } from "@ni/common/constants";

export const cardTransactionFeesFields: { [key: string]: string[] } = {
  "nic-ips-fees-fin-only": [],
  "ips-cash-fee-enabled": [
    "nic-ips-cash-atm-def-bf-base",
    "nic-ips-cash-atm-def-bf-fee-rate",
    "nic-ips-cash-atm-def-bf-min",
    "nic-ips-cash-atm-def-bf-max",
    "nic-ips-cash-pos-def-bf-base",
    "nic-ips-cash-pos-def-bf-fee-rate",
    "nic-ips-cash-pos-def-bf-max",
    "nic-ips-cash-pos-def-bf-min",
  ],
  "ips-quasi-fee-enabled": [
    "nic-ips-retail-qsi-bf-base",
    "nic-ips-retail-qsi-bf-fee-rate",
    "nic-ips-retail-qsi-bf-min",
    "nic-ips-retail-qsi-bf-max",
  ],
};

export const cardTransactionFeesSwitches: { [key: string]: string[] } = {
  "ips-trans-fee-enabled": ["nic-ips-fees-fin-only", "ips-cash-fee-enabled", "ips-quasi-fee-enabled"],
};

// depended values

export const CTF_DEPENDED_VALUES = [
  {
    parent: "nic-ips-cash-atm-def-t1-max",
    child: "def-tier-min-atm",
  },
  {
    parent: "nic-ips-cash-atm-onus-t1-max",
    child: "def-tier-min-atm-onus",
  },
  {
    parent: "nic-ips-cash-atm-dom-t1-max",
    child: "def-tier-min-atm-dom",
  },

  {
    parent: "nic-ips-cash-pos-def-t1-max",
    child: "def-tier-min-cash",
  },
  {
    parent: "nic-ips-cash-pos-onus-t1-max",
    child: "def-tier-min-cash-onus",
  },
  {
    parent: "nic-ips-cash-pos-dom-t1-max",
    child: "def-tier-min-cash-dom",
  },
];

// empty values

export const emptyCashFeeFieldValues = {
  "nic-ips-cash-atm-def-bf-base": null,
  "nic-ips-cash-atm-def-bf-fee-rate": null,
  "nic-ips-cash-atm-def-bf-min": null,
  "nic-ips-cash-atm-def-bf-max": null,
  "nic-ips-cash-pos-def-bf-base": null,
  "nic-ips-cash-pos-def-bf-fee-rate": null,
  "nic-ips-cash-pos-def-bf-max": null,
  "nic-ips-cash-pos-def-bf-min": null,
};

export const emptyQuatiFeeFieldValues = {
  "nic-ips-retail-qsi-bf-base": null,
  "nic-ips-retail-qsi-bf-fee-rate": null,
  "nic-ips-retail-qsi-bf-min": null,
  "nic-ips-retail-qsi-bf-max": null,
};

export const emptyFieldValues = {
  "ips-trans-fee-enabled": false,
  "ips-cash-fee-enabled": false,
  "ips-quasi-fee-enabled": false,
  "nic-ips-fees-fin-only": false,
  ...emptyCashFeeFieldValues,
  ...emptyQuatiFeeFieldValues,
};

const CTF_ATM_DEFAULT_EMPTY_AMOUNT_VALUES = {
  "def-tier-min-atm": null,
  "def-tier-max-atm": null,
};

const CTF_ATM_DEFAULT_EMPTY_VALUES = {
  ...CTF_ATM_DEFAULT_EMPTY_AMOUNT_VALUES,
  "nic-ips-cash-atm-def-bf-base": null,
  "nic-ips-cash-atm-def-bf-fee-rate": null,
  "nic-ips-cash-atm-def-bf-min": null,
  "nic-ips-cash-atm-def-bf-max": null,
};

const CTF_ATM_ONUS_EMPTY_AMOUNT_VALUES = {
  "def-tier-min-atm-onus": null,
  "def-tier-max-atm-onus": null,
};

const CTF_ATM_ONUS_EMPTY_VALUES = {
  ...CTF_ATM_ONUS_EMPTY_AMOUNT_VALUES,
  "nic-ips-cash-atm-onus-bf-base": null,
  "nic-ips-cash-atm-onus-bf-fee-rate": null,
  "nic-ips-cash-atm-onus-bf-min": null,
  "nic-ips-cash-atm-onus-bf-max": null,
};

const CTF_ATM_DOMESTIC_EMPTY_AMOUNT_VALUES = {
  "def-tier-min-atm-dom": null,
  "def-tier-max-atm-dom": null,
};

const CTF_ATM_DOMESTIC_EMPTY_VALUES = {
  ...CTF_ATM_DOMESTIC_EMPTY_AMOUNT_VALUES,
  "nic-ips-cash-atm-dom-bf-base": null,
  "nic-ips-cash-atm-dom-bf-fee-rate": null,
  "nic-ips-cash-atm-dom-bf-min": null,
  "nic-ips-cash-atm-dom-bf-max": null,
};

const CTF_ATM_ADDITIONAL_TIER_EMPTY_AMOUNT_VALUES = {
  "nic-ips-cash-atm-def-t1-min": null,
  "nic-ips-cash-atm-def-t1-max": null,
};

const CTF_ATM_ADDITIONAL_TIER_EMPTY_VALUES = {
  ...CTF_ATM_ADDITIONAL_TIER_EMPTY_AMOUNT_VALUES,
  "nic-ips-cash-atm-def-bf-t1-base": null,
  "nic-ips-cash-atm-def-bf-t1-fee-rate": null,
  "nic-ips-cash-atm-def-bf-t1-min": null,
  "nic-ips-cash-atm-def-bf-t1-max": null,
};

const CTF_ATM_ONUS_ADDITIONAL_TIER_EMPTY_AMOUNT_VALUES = {
  "nic-ips-cash-atm-onus-t1-min": null,
  "nic-ips-cash-atm-onus-t1-max": null,
};

const CTF_ATM_ONUS_ADDITIONAL_TIER_EMPTY_VALUES = {
  ...CTF_ATM_ONUS_ADDITIONAL_TIER_EMPTY_AMOUNT_VALUES,
  "nic-ips-cash-atm-onus-bf-t1-base": null,
  "nic-ips-cash-atm-onus-bf-t1-fee-rate": null,
  "nic-ips-cash-atm-onus-bf-t1-min": null,
  "nic-ips-cash-atm-onus-bf-t1-max": null,
};

const CTF_ATM_DOMESTIC_ADDITIONAL_TIER_EMPTY_AMOUNT_VALUES = {
  "nic-ips-cash-atm-dom-t1-min": null,
  "nic-ips-cash-atm-dom-t1-max": null,
};

const CTF_ATM_DOMESTIC_ADDITIONAL_TIER_EMPTY_VALUES = {
  ...CTF_ATM_DOMESTIC_ADDITIONAL_TIER_EMPTY_AMOUNT_VALUES,
  "nic-ips-cash-atm-dom-bf-t1-base": null,
  "nic-ips-cash-atm-dom-bf-t1-fee-rate": null,
  "nic-ips-cash-atm-dom-bf-t1-min": null,
  "nic-ips-cash-atm-dom-bf-t1-max": null,
};

const CTF_CASH_DEFAULT_EMPTY_AMOUNT_VALUES = {
  "def-tier-min-cash": null,
  "def-tier-max-cash": null,
};

const CTF_CASH_DEFAULT_EMPTY_VALUES = {
  ...CTF_CASH_DEFAULT_EMPTY_AMOUNT_VALUES,
  "nic-ips-cash-pos-def-bf-base": null,
  "nic-ips-cash-pos-def-bf-fee-rate": null,
  "nic-ips-cash-pos-def-bf-min": null,
  "nic-ips-cash-pos-def-bf-max": null,
};

const CTF_CASH_ONUS_EMPTY_AMOUNT_VALUES = {
  "def-tier-min-cash-onus": null,
  "def-tier-max-cash-onus": null,
};

const CTF_CASH_ONUS_EMPTY_VALUES = {
  ...CTF_CASH_ONUS_EMPTY_AMOUNT_VALUES,
  "nic-ips-cash-pos-onus-bf-base": null,
  "nic-ips-cash-pos-onus-bf-fee-rate": null,
  "nic-ips-cash-pos-onus-bf-min": null,
  "nic-ips-cash-pos-onus-bf-max": null,
};

const CTF_CASH_DOMESTIC_EMPTY_AMOUNT_VALUES = {
  "def-tier-min-cash-dom": null,
  "def-tier-max-cash-dom": null,
};

const CTF_CASH_DOMESTIC_EMPTY_VALUES = {
  ...CTF_CASH_DOMESTIC_EMPTY_AMOUNT_VALUES,
  "nic-ips-cash-pos-dom-bf-base": null,
  "nic-ips-cash-pos-dom-bf-fee-rate": null,
  "nic-ips-cash-pos-dom-bf-min": null,
  "nic-ips-cash-pos-dom-bf-max": null,
};

const CTF_CASH_ADDITIONAL_TIER_EMPTY_AMOUNT_VALUES = {
  "nic-ips-cash-pos-def-t1-min": null,
  "nic-ips-cash-pos-def-t1-max": null,
};

const CTF_CASH_ADDITIONAL_TIER_EMPTY_VALUES = {
  ...CTF_CASH_ADDITIONAL_TIER_EMPTY_AMOUNT_VALUES,
  "nic-ips-cash-pos-def-bf-t1-base": null,
  "nic-ips-cash-pos-def-bf-t1-fee-rate": null,
  "nic-ips-cash-pos-def-bf-t1-min": null,
  "nic-ips-cash-pos-def-bf-t1-max": null,
};

const CTF_CASH_ONUS_ADDITIONAL_TIER_EMPTY_AMOUNT_VALUES = {
  "nic-ips-cash-pos-onus-t1-min": null,
  "nic-ips-cash-pos-onus-t1-max": null,
};

const CTF_CASH_ONUS_ADDITIONAL_TIER_EMPTY_VALUES = {
  ...CTF_CASH_ONUS_ADDITIONAL_TIER_EMPTY_AMOUNT_VALUES,
  "nic-ips-cash-pos-onus-bf-t1-base": null,
  "nic-ips-cash-pos-onus-bf-t1-fee-rate": null,
  "nic-ips-cash-pos-onus-bf-t1-min": null,
  "nic-ips-cash-pos-onus-bf-t1-max": null,
};

const CTF_CASH_DOMESTIC_ADDITIONAL_TIER_EMPTY_AMOUNT_VALUES = {
  "nic-ips-cash-pos-dom-t1-min": null,
  "nic-ips-cash-pos-dom-t1-max": null,
};

const CTF_CASH_DOMESTIC_ADDITIONAL_TIER_EMPTY_VALUES = {
  ...CTF_CASH_DOMESTIC_ADDITIONAL_TIER_EMPTY_AMOUNT_VALUES,
  "nic-ips-cash-pos-dom-bf-t1-base": null,
  "nic-ips-cash-pos-dom-bf-t1-fee-rate": null,
  "nic-ips-cash-pos-dom-bf-t1-min": null,
  "nic-ips-cash-pos-dom-bf-t1-max": null,
};

const CTF_RETAIL_DEFAULT_EMPTY_VALUES = {
  "nic-ips-retail-def-bf-base": null,
  "nic-ips-retail-def-bf-fee-rate": null,
  "nic-ips-retail-def-bf-min": null,
  "nic-ips-retail-def-bf-max": null,
};

const CTF_RETAIL_ONUS_EMPTY_VALUES = {
  "nic-ips-retail-onus-bf-base": null,
  "nic-ips-retail-onus-bf-fee-rate": null,
  "nic-ips-retail-onus-bf-min": null,
  "nic-ips-retail-onus-bf-max": null,
};

const CTF_RETAIL_DOMESTIC_EMPTY_VALUES = {
  "nic-ips-retail-dom-bf-base": null,
  "nic-ips-retail-dom-bf-fee-rate": null,
  "nic-ips-retail-dom-bf-min": null,
  "nic-ips-retail-dom-bf-max": null,
};

const CTF_REFUND_DEFAULT_EMPTY_VALUES = {
  "nic-ips-credit-def-bf-base": null,
  "nic-ips-credit-def-bf-fee-rate": null,
  "nic-ips-credit-def-bf-min": null,
  "nic-ips-credit-def-bf-max": null,
};

const CTF_REFUND_ONUS_EMPTY_VALUES = {
  "nic-ips-credit-onus-bf-base": null,
  "nic-ips-credit-onus-bf-fee-rate": null,
  "nic-ips-credit-onus-bf-min": null,
  "nic-ips-credit-onus-bf-max": null,
};

const CTF_REFUND_DOMESTIC_EMPTY_VALUES = {
  "nic-ips-credit-dom-bf-base": null,
  "nic-ips-credit-dom-bf-fee-rate": null,
  "nic-ips-credit-dom-bf-min": null,
  "nic-ips-credit-dom-bf-max": null,
};

const CTF_P2P_DEBIT_DEFAULT_EMPTY_VALUES = {
  "nic-ips-ch-debit-def-bf-base": null,
  "nic-ips-ch-debit-def-bf-fee-rate": null,
  "nic-ips-ch-debit-def-bf-min": null,
  "nic-ips-ch-debit-def-bf-max": null,
};

const CTF_P2P_DEBIT_ONUS_EMPTY_VALUES = {
  "nic-ips-ch-debit-onus-bf-base": null,
  "nic-ips-ch-debit-onus-bf-fee-rate": null,
  "nic-ips-ch-debit-onus-bf-min": null,
  "nic-ips-ch-debit-onus-bf-max": null,
};

const CTF_P2P_DEBIT_DOMESTIC_EMPTY_VALUES = {
  "nic-ips-ch-debit-dom-bf-base": null,
  "nic-ips-ch-debit-dom-bf-fee-rate": null,
  "nic-ips-ch-debit-dom-bf-min": null,
  "nic-ips-ch-debit-dom-bf-max": null,
};

const CTF_P2P_PAYM_DEFAULT_EMPTY_VALUES = {
  "nic-ips-ch-paym-def-bf-base": null,
  "nic-ips-ch-paym-def-bf-fee-rate": null,
  "nic-ips-ch-paym-def-bf-min": null,
  "nic-ips-ch-paym-def-bf-max": null,
};

const CTF_P2P_PAYM_ONUS_EMPTY_VALUES = {
  "nic-ips-ch-paym-onus-bf-base": null,
  "nic-ips-ch-paym-onus-bf-fee-rate": null,
  "nic-ips-ch-paym-onus-bf-min": null,
  "nic-ips-ch-paym-onus-bf-max": null,
};

const CTF_P2P_PAYM_DOMESTIC_EMPTY_VALUES = {
  "nic-ips-ch-paym-dom-bf-base": null,
  "nic-ips-ch-paym-dom-bf-fee-rate": null,
  "nic-ips-ch-paym-dom-bf-min": null,
  "nic-ips-ch-paym-dom-bf-max": null,
};

const CTF_QUASI_DEFAULT_EMPTY_VALUES = {
  "nic-ips-retail-qsi-bf-base": null,
  "nic-ips-retail-qsi-bf-fee-rate": null,
  "nic-ips-retail-qsi-bf-min": null,
  "nic-ips-retail-qsi-bf-max": null,
};

const CTF_QUASI_ONUS_EMPTY_VALUES = {
  "nic-ips-retail-qsi-onus-bf-base": null,
  "nic-ips-retail-qsi-onus-bf-fee-rate": null,
  "nic-ips-retail-qsi-onus-bf-min": null,
  "nic-ips-retail-qsi-onus-bf-max": null,
};

const CTF_QUASI_DOMESTIC_EMPTY_VALUES = {
  "nic-ips-retail-qsi-dom-bf-base": null,
  "nic-ips-retail-qsi-dom-bf-fee-rate": null,
  "nic-ips-retail-qsi-dom-bf-min": null,
  "nic-ips-retail-qsi-dom-bf-max": null,
};

export const CTF_ATM_EMPTY_REG_VALUES = {
  ...CTF_ATM_ONUS_EMPTY_VALUES,
  ...CTF_ATM_DOMESTIC_EMPTY_VALUES,
};

export const CTF_ATM_EMPTY_AMOUNT_VALUES = {
  ...CTF_ATM_DEFAULT_EMPTY_AMOUNT_VALUES,
  ...CTF_ATM_ONUS_EMPTY_AMOUNT_VALUES,
  ...CTF_ATM_DOMESTIC_EMPTY_AMOUNT_VALUES,
  ...CTF_ATM_ADDITIONAL_TIER_EMPTY_VALUES,
  ...CTF_ATM_ONUS_ADDITIONAL_TIER_EMPTY_VALUES,
  ...CTF_ATM_DOMESTIC_ADDITIONAL_TIER_EMPTY_VALUES,
};

export const CTF_CASH_EMPTY_REG_VALUES = {
  ...CTF_CASH_ONUS_EMPTY_VALUES,
  ...CTF_CASH_DOMESTIC_EMPTY_VALUES,
};

export const CTF_CASH_EMPTY_AMOUNT_VALUES = {
  ...CTF_CASH_DEFAULT_EMPTY_AMOUNT_VALUES,
  ...CTF_CASH_ONUS_EMPTY_AMOUNT_VALUES,
  ...CTF_CASH_DOMESTIC_EMPTY_AMOUNT_VALUES,
  ...CTF_CASH_ADDITIONAL_TIER_EMPTY_VALUES,
  ...CTF_CASH_ONUS_ADDITIONAL_TIER_EMPTY_VALUES,
  ...CTF_CASH_DOMESTIC_ADDITIONAL_TIER_EMPTY_VALUES,
};

export const CTF_RETAIL_EMPTY_REG_VALUES = {
  ...CTF_RETAIL_ONUS_EMPTY_VALUES,
  ...CTF_RETAIL_DOMESTIC_EMPTY_VALUES,
};

export const CTF_REFUND_EMPTY_REG_VALUES = {
  ...CTF_REFUND_ONUS_EMPTY_VALUES,
  ...CTF_REFUND_DOMESTIC_EMPTY_VALUES,
};

export const CTF_P2P_DEBIT_EMPTY_REG_VALUES = {
  ...CTF_P2P_DEBIT_ONUS_EMPTY_VALUES,
  ...CTF_P2P_DEBIT_DOMESTIC_EMPTY_VALUES,
};

export const CTF_P2P_PAYM_EMPTY_REG_VALUES = {
  ...CTF_P2P_PAYM_ONUS_EMPTY_VALUES,
  ...CTF_P2P_PAYM_DOMESTIC_EMPTY_VALUES,
};

export const CTF_QUASI_EMPTY_REG_VALUES = {
  ...CTF_QUASI_ONUS_EMPTY_VALUES,
  ...CTF_QUASI_DOMESTIC_EMPTY_VALUES,
};

export const EMTY_CTF_VALUES = {
  "ips-trans-fee-enabled": false,
  "atm-reg-enable": false,
  "atm-amount-enable": false,
  "cash-reg-enable": false,
  "cash-amount-enable": false,
  "retail-reg-enable": false,
  "refund-reg-enable": false,
  "p2p-deb-reg-enable": false,
  "p2p-paym-reg-enable": false,
  "quasi-reg-enable": false,
  ...CTF_ATM_DEFAULT_EMPTY_VALUES,
  ...CTF_ATM_ONUS_EMPTY_VALUES,
  ...CTF_ATM_DOMESTIC_EMPTY_VALUES,
  ...CTF_ATM_ADDITIONAL_TIER_EMPTY_VALUES,
  ...CTF_ATM_ONUS_ADDITIONAL_TIER_EMPTY_VALUES,
  ...CTF_ATM_DOMESTIC_ADDITIONAL_TIER_EMPTY_VALUES,
  ...CTF_CASH_DEFAULT_EMPTY_VALUES,
  ...CTF_CASH_ONUS_EMPTY_VALUES,
  ...CTF_CASH_DOMESTIC_EMPTY_VALUES,
  ...CTF_CASH_ADDITIONAL_TIER_EMPTY_VALUES,
  ...CTF_CASH_ONUS_ADDITIONAL_TIER_EMPTY_VALUES,
  ...CTF_CASH_DOMESTIC_ADDITIONAL_TIER_EMPTY_VALUES,
  ...CTF_RETAIL_DEFAULT_EMPTY_VALUES,
  ...CTF_RETAIL_ONUS_EMPTY_VALUES,
  ...CTF_RETAIL_DOMESTIC_EMPTY_VALUES,
  ...CTF_REFUND_DEFAULT_EMPTY_VALUES,
  ...CTF_REFUND_ONUS_EMPTY_VALUES,
  ...CTF_REFUND_DOMESTIC_EMPTY_VALUES,
  ...CTF_P2P_DEBIT_DEFAULT_EMPTY_VALUES,
  ...CTF_P2P_DEBIT_ONUS_EMPTY_VALUES,
  ...CTF_P2P_DEBIT_DOMESTIC_EMPTY_VALUES,
  ...CTF_P2P_PAYM_DEFAULT_EMPTY_VALUES,
  ...CTF_P2P_PAYM_ONUS_EMPTY_VALUES,
  ...CTF_P2P_PAYM_DOMESTIC_EMPTY_VALUES,
  ...CTF_QUASI_DEFAULT_EMPTY_VALUES,
  ...CTF_QUASI_ONUS_EMPTY_VALUES,
  ...CTF_QUASI_DOMESTIC_EMPTY_VALUES,
};

export const CARD_TRANSACTION_FEES_TABS = [
  {
    key: "atm" as const,
    children: "ATM withdrawal fee is charged to the customers for the ATM withdrawal transactions",
    label: <div title="ATM">ATM</div>,
  },
  {
    key: "pos" as const,
    children:
      "Cash POS fee is charged to the customers for the cash withdrawal transactions at the POS machines that are not considered as retail purchases",
    label: <div title="Cash POS">Cash POS</div>,
  },

  {
    key: "retail" as const,
    children:
      "Retail transaction fee is charged to a customer when a purchase is done on their card at a Point-Of-Sale terminal at a merchant location",
    label: <div title="Retail">Retail</div>,
  },
  {
    key: "credit" as const,
    children:
      "Refund fee is charged to a customer when a retail purchase made on the customer’s card is refunded via Point-Of-Sale terminal at a merchant location",
    label: <div title="Refund">Refund</div>,
  },
  {
    key: "ch-debit" as const,
    children: "P2P debit fee is charged to the sender for the P2P transfer transaction",
    label: <div title="P2P Debit">P2P Debit</div>,
  },
  {
    key: "ch-paym" as const,
    children: "P2P debit fee is charged to the receiver for the P2P transfer transaction",
    label: <div title="P2P Payment">P2P Payment</div>,
  },
  {
    key: "retail-qsi" as const,
    children:
      "System identifies a transaction as Quasi cash transaction based on the transaction Merchant category code (MCC). E.g. ‘6050 - Quasi Cash, fin Inst’, ‘6051 - Foreign Currency, Money, TC’s'. List of MCC is configurable, please follow ‘Fee settings - Global’ in Related links section",
    label: <div title="Quasi Cash">Quasi Cash</div>,
  },
];

export const CTF_HEADINGS = [
  {
    label: "Min transaction amount",
    tooltip: "Define min transaction amount to apply this fee ",
  },
  {
    label: "Max transaction amount",
    tooltip: "Define max transaction amount to apply this fee ",
  },
  {
    label: "Fee fixed",
    tooltip: "The parameter specifies the fixed fee amount to be charged for the transaction.",
  },
  {
    label: FEE_PERCENTAGE_LABEL,
    tooltip: FEE_PERCENTAGE_TOOLTIP,
  },
  {
    label: "Min fee",
    tooltip:
      'The parameter specifies the minimum fee amount that needs to be charged to the customer. In case of calculated "Total Fee" is lesser than the Min fee defined then Min fee will be charged to the customer. If Min Fee > Fee fixed + Fee % then Min Fee will be charged to the customer.',
  },
  {
    label: "Max fee",
    tooltip:
      'The parameter specifies the maximum fee amount that needs to be charged to the customer. In case of calculated "Total Fee" is greater than the Max fee defined then Max fee will be charged to the customer. If Max Fee < Fee fixed + Fee % then Max Fee will be charged to the customer.',
  },
];
