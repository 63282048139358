import React, { FC, useEffect, useState } from "react";
import { Input } from "antd";
import { escapeRegExp } from "lodash";

import { Variable } from "@ni/sdk/models";

import styles from "./styles.module.scss";

const { TextArea } = Input;

interface SmsVariablePreviewProps {
  text: string;
  variables: Variable[];
}

export const SmsVariablePreview: FC<SmsVariablePreviewProps> = ({ text, variables }) => {
  const [content, setContent] = useState(text);

  useEffect(() => {
    let currentText = text;

    variables.forEach(smsVariable => {
      if (currentText?.includes(smsVariable.code)) {
        currentText = currentText.replace(new RegExp(escapeRegExp(smsVariable.code), "g"), smsVariable.example || "");
      }
    });
    setContent(currentText);
  }, [text, variables]);

  return (
    <TextArea
      className={styles["sms-variable-preview"]}
      rows={4}
      bordered={false}
      showCount={{
        formatter: () => `${content?.length || 0}/150`,
      }}
      value={content}
      disabled={true}
    />
  );
};

SmsVariablePreview.defaultProps = {
  text: "",
  variables: [],
};
