import { useState } from "react";
import { ConfigProvider, Form, Input, notification } from "antd";

import { SearchOutlined } from "@ant-design/icons";
import { useReduxState } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { ConfirmModal, PageHeading } from "@ni/common/ui";
import { Dictionaries } from "@ni/sdk/models";

import { DictionairesHeader, DictionariesTable, DictionariesValueDrawer } from "./components";
import { useDictionaries } from "./hooks";

import styles from "./styles.module.scss";

export const DictionaryValues = () => {
  const [searchForm] = Form.useForm<FormValues>();

  const [selectedDictionary, setSelectedDictionary] = useReduxState<Dictionaries>("selectedDictionary", {
    code: "",
    dictionaries: [],
  });

  const searchValue = Form.useWatch("searchValue", searchForm) as string;

  const {
    tablePagination: [tablePagination, setTablePagination],
    filteredDictionaries,
    deleteDictionariesByCode,
    syncWithWay4,
  } = useDictionaries({
    searchValue,
  });

  const [isDrawerOpened, setIsDrawerOpened] = useState<boolean>(false);

  const [deleteElementOpened, setDeleteElementOpened] = useState(false);
  const [elementCodeToDelete, setElementCodeToDelete] = useState("");

  const closeDrawer = () => {
    setIsDrawerOpened(false);
    setSelectedDictionary({
      code: "",
      dictionaries: [],
    });
  };

  const openDrawer = (enumToEdit?: Dictionaries) => {
    if (enumToEdit) {
      setSelectedDictionary(enumToEdit);
    }
    setIsDrawerOpened(true);
  };

  const openDeleteModal = (elemToDelete: string) => {
    setElementCodeToDelete(elemToDelete);
    setDeleteElementOpened(true);
  };

  const closeDeleteModal = (deleteElement: boolean) => {
    if (deleteElement) {
      deleteDictionariesByCode(elementCodeToDelete)
        .then(() => {
          setDeleteElementOpened(false);
        })
        .catch(error => {
          notification.error({
            placement: "topRight",
            duration: 5,
            message: (
              <div>
                {error.response.status}
                <br />
                {error.notificationMessage}
              </div>
            ),
          });
        });
    }

    setElementCodeToDelete("");
    setDeleteElementOpened(false);
  };

  return (
    <ConfigProvider componentSize="middle">
      <div className={styles["container"]}>
        <PageHeading
          text="Dictionaries"
          extraElements={[
            <DictionairesHeader syncWithWay4={syncWithWay4} openDrawer={openDrawer}>
              <Form form={searchForm}>
                <Form.Item name="searchValue">
                  <Input placeholder="Search" prefix={<SearchOutlined />} autoFocus={true} />
                </Form.Item>
              </Form>
            </DictionairesHeader>,
          ]}
        />
        <div className={styles["body"]} key={Math.random()}>
          <DictionariesTable
            dataSource={filteredDictionaries}
            pagination={tablePagination}
            onChange={setTablePagination}
            openDrawer={openDrawer}
            openDeleteModal={openDeleteModal}
          />
          <ConfirmModal
            title="Are you sure delete this dictionary value?"
            isOpened={deleteElementOpened}
            onCancel={() => closeDeleteModal(false)}
            onConfirm={() => closeDeleteModal(true)}
            isLoading={false}
            type="delete"
          />
        </div>

        {isDrawerOpened && (
          <DictionariesValueDrawer
            isOpen={isDrawerOpened}
            selectedDictionary={selectedDictionary}
            code={selectedDictionary?.code}
            closeDrawer={closeDrawer}
          />
        )}
      </div>
    </ConfigProvider>
  );
};
