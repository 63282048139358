import { FC } from "react";
import { Form, InputNumber } from "antd";

import { QuestionCircleFilled } from "@ant-design/icons";
import { useHydrateForm } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, RadioGroup } from "@ni/common/ui";
import { numberFormatter, numberParser } from "@ni/common/utils";

import { useInsuranceWizardApi } from "../../hooks/useInsuranceWizardApi";

import { capOption, fixedOption, percentageOption, periodOption, periodTypeOption } from "./constants";

export const PremiumSettingsPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const { wizardResponse, processWizardRequest } = useInsuranceWizardApi();

  useHydrateForm({
    form,
    entityFields: (wizardResponse.insuranceTemplate?.values as { code: string; value: string }[]) ?? [],
    keys: {
      strings: [capOption.code, fixedOption.code, percentageOption.code, periodOption.code, periodTypeOption.code],
    },
  });

  const onFinish = (values: FormValues) => {
    void processWizardRequest({
      pageId: wizardResponse?.pageId as number,
      tenantId: wizardResponse.tenant?.id,
      insuranceTemplateId: wizardResponse.insuranceTemplate?.id,
      collectedValues: values as {
        [key: string]: string;
      },
    });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Premium Settings"
      pageSubtitle="Insurance is billed to the customer at the end of the billing cycle with the amount calculated based on multiple parameters. It can be either a percentage of the statement balance or a fixed amount, charged as an insurance premium.  You can choose to reverse the premium for initial number of months or cycles, while reversing the insurance premium; system will take care of deducting that out of payment due amount and retail plan or full fee cash plan"
      level="tenant"
      additionalRoute="insurance-program-templates"
      size="md"
      formSize="md"
      gap={40}
      submitHandler={onFinish}
    >
      <Form.Item
        name={fixedOption.code}
        label={fixedOption.name}
        tooltip={{
          title: fixedOption.tooltip,
          icon: <QuestionCircleFilled />,
        }}
      >
        <InputNumber
          addonAfter={wizardResponse.insuranceTemplate?.currency as string}
          min={fixedOption.min}
          max={fixedOption.max}
          precision={fixedOption.decimalsNumber}
          formatter={numberFormatter}
          parser={numberParser}
        />
      </Form.Item>

      <Form.Item
        name={percentageOption.code}
        label={percentageOption.name}
        tooltip={{
          title: percentageOption.tooltip,
          icon: <QuestionCircleFilled />,
        }}
        initialValue={percentageOption.default}
      >
        <InputNumber
          addonAfter="%"
          min={percentageOption.min}
          max={percentageOption.max}
          precision={percentageOption.decimalsNumber}
        />
      </Form.Item>

      <Form.Item
        name={capOption.code}
        label={capOption.name}
        tooltip={{
          title: capOption.tooltip,
          icon: <QuestionCircleFilled />,
        }}
      >
        <InputNumber
          addonAfter={wizardResponse.insuranceTemplate?.currency as string}
          min={capOption.min}
          max={capOption.max}
          precision={capOption.decimalsNumber}
          formatter={numberFormatter}
          parser={numberParser}
        />
      </Form.Item>

      <Form.Item
        name={periodOption.code}
        label={periodOption.name}
        tooltip={{
          title: periodOption.tooltip,
          icon: <QuestionCircleFilled />,
        }}
      >
        <InputNumber
          min={periodOption.min}
          max={periodOption.max}
          precision={periodOption.decimalsNumber}
          formatter={numberFormatter}
          parser={numberParser}
        />
      </Form.Item>

      <Form.Item name={periodTypeOption.code} label={periodTypeOption.name} initialValue={periodTypeOption.default}>
        <RadioGroup radioList={periodTypeOption.options} initialValue={periodTypeOption.default as string} />
      </Form.Item>
    </CustomFormWrapper>
  );
};
