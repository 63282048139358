import { FC } from "react";
import { Col, Form, FormInstance, Input, Row, Space, Switch } from "antd";
import NumberFormat from "react-number-format";

import { FEE_PERCENTAGE_LABEL, FEE_PERCENTAGE_TOOLTIP } from "@ni/common/constants";
import { FeeRow, FormValues } from "@ni/common/types";
import { TabContentLayout, TooltipInfo } from "@ni/common/ui";

import {
  CASH_LABEL,
  CASH_TOOLTIP,
  FIN_ONLY_RETAIL_LABEL,
  NIC_IPS_MARKUP_CASH_FEE_RATE,
  NIC_IPS_MARKUP_FEES_FIN_ONLY_RETAIL,
  NIC_IPS_MARKUP_RETAIL_FEE_RATE,
  RETAIL_LABEL,
} from "../TransactionFees.constants";

import styles from "./styles.module.scss";

export interface FXMarkupTabContentProps {
  activeTabKey: string;
  form: FormInstance<FormValues>;
  productCurrency: string;
  description: string;
  rows: FeeRow[];
}

export const FXMarkupTabContent: FC<FXMarkupTabContentProps> = ({ description }) => {
  const sectionMiddle = () => {
    return (
      <div className={styles["fields-wrapper"]}>
        <Row>
          <Col span={4} />
          <Col span={20}>
            <Row>
              <Col span={6} className="col-header">
                <div className="ant-form-vertical ant-form-item-label">
                  <TooltipInfo label={FEE_PERCENTAGE_LABEL} tooltipProps={{ title: FEE_PERCENTAGE_TOOLTIP }} />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className={styles["fees-row-inputs-wrapper"]}>
          <Col span={4}>
            <div className="ant-form-vertical ant-form-item-label">
              <TooltipInfo label={CASH_LABEL} tooltipProps={{ title: CASH_TOOLTIP }} />
            </div>
          </Col>
          <Col span={5}>
            <Form.Item name={NIC_IPS_MARKUP_CASH_FEE_RATE} initialValue="">
              <NumberFormat
                customInput={Input}
                addonAfter="%"
                allowNegative={false}
                isNumericString={true}
                thousandSeparator=" "
                decimalScale={2}
                decimalSeparator="."
                fixedDecimalScale={true}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className={styles["fees-row-inputs-wrapper"]}>
          <Col span={4}>
            <div className="ant-form-vertical ant-form-item-label">{RETAIL_LABEL}</div>
          </Col>
          <Col span={5}>
            <Form.Item name={NIC_IPS_MARKUP_RETAIL_FEE_RATE} initialValue="">
              <NumberFormat
                customInput={Input}
                addonAfter="%"
                allowNegative={false}
                isNumericString={true}
                thousandSeparator=" "
                decimalScale={2}
                decimalSeparator="."
                fixedDecimalScale={true}
              />
            </Form.Item>
          </Col>
        </Row>
        <Space direction="horizontal" size={8}>
          <Form.Item name={NIC_IPS_MARKUP_FEES_FIN_ONLY_RETAIL} valuePropName="checked">
            <Switch />
          </Form.Item>
          {FIN_ONLY_RETAIL_LABEL}
        </Space>
      </div>
    );
  };

  return (
    <div className={styles["currency-conversion-page"]}>
      <TabContentLayout description={description} sectionMiddle={sectionMiddle()} />
    </div>
  );
};
