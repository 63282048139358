import { FC, useEffect, useState } from "react";
import { Button, Drawer } from "antd";
import cn from "classnames";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { DoubleLeftOutlined, MenuOutlined } from "@ant-design/icons";
import { Sidenav } from "@ni/common/ui";
import { DictionaryValues } from "@ni/dictionaries/dictionary-values";
import { DictionariesFeatures } from "@ni/dictionaries/features";
import { ElementTemplates } from "@ni/element-templates";
import { FinancialInstitutions, FinancialInstitutionSettingsLayout } from "@ni/financial-institutions";
import { Phase } from "@ni/phase";
import { SmsTemplates } from "@ni/sms-templates";
import { SmsVariablesDictionary } from "@ni/sms-variables-dictionary";
import { Workflow, WorkflowSettings } from "@ni/workflow";

import { NAVBAR_ITEMS } from "./constants";
import {
  DeploymentDetails,
  Deployments,
  QuestionnaireProcessingData,
  QuestionnaireProcessingDataConfirmation,
} from "./pages";

import styles from "./styles.module.scss";

export const Admin: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [open, setOpen] = useState(true);
  const [width, setWidth] = useState(225);

  const show = () => {
    setOpen(true);
    setWidth(225);
  };

  const close = () => {
    setOpen(false);
    setWidth(48);
  };

  useEffect(() => {
    if (location.pathname === `/admin`) {
      navigate(`workflows`);
    }
  }, [location.pathname, navigate]);

  return (
    <div className={styles["admin-page"]}>
      <div
        className={cn(styles["vertical-drawer"], open && styles["vertical-drawer-open"])}
        style={{ minWidth: width }}
      >
        {open ? (
          <Drawer placement="left" closable={false} open={true} getContainer={false} width={width}>
            <Button
              type="text"
              shape="circle"
              onClick={close}
              className={styles["button"]}
              icon={<DoubleLeftOutlined />}
            />
            <Sidenav items={NAVBAR_ITEMS} />
          </Drawer>
        ) : (
          <div className={styles["min"]}>
            <Button type="text" shape="circle" onClick={show} className={styles["button"]} icon={<MenuOutlined />} />
          </div>
        )}
      </div>
      <Routes>
        <Route path="/workflows/:id/*" element={<WorkflowSettings />} />
        <Route path="/workflows/*" element={<Workflow />} />
        <Route path="/workflow-settings/*" element={<WorkflowSettings />} />
        <Route path="/phases/:id/*" element={<Phase />} />
        <Route path="/phases" element={<Phase />} />
        <Route path="/sms-variables-dictionary" element={<SmsVariablesDictionary />} />
        <Route path="/sms-templates" element={<SmsTemplates />} />
        <Route path="/element-templates" element={<ElementTemplates />} />

        <Route path="/financial-institutions/:id/*" element={<FinancialInstitutionSettingsLayout />} />
        <Route path="/financial-institutions" element={<FinancialInstitutions />} />
        <Route path="/features" element={<DictionariesFeatures />} />
        <Route path="/dictionary-values" element={<DictionaryValues />} />
        <Route path="/deployments" element={<Deployments />} />
        <Route path="/deployments/:deploymentId" element={<DeploymentDetails />} />
        <Route path="/questionnaire-processing-data" element={<QuestionnaireProcessingData />} />
        <Route path="/questionnaire-processing-confirmation" element={<QuestionnaireProcessingDataConfirmation />} />
      </Routes>
    </div>
  );
};
