import { FC, forwardRef, Ref } from "react";
import { Checkbox as AntdCheckbox, Space, SpaceProps } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";

import { SelectOption } from "@ni/common/types";

import { TooltipInfo } from "../TooltipInfo";

import styles from "./styles.module.scss";

interface CheckboxProps {
  checkboxes: Array<Pick<SelectOption, "value" | "label" | "tooltip">>;
  direction?: SpaceProps["direction"];
  size?: SpaceProps["size"];
  className?: string;
  value?: string[] | CheckboxValueType[];
  onChange?: (values: CheckboxValueType[]) => void;
}

export const Checkbox: FC<CheckboxProps> = forwardRef(
  (
    { checkboxes, direction = "vertical", size = 16, className = "", value, onChange }: CheckboxProps,
    ref: Ref<HTMLDivElement> | undefined,
  ) => {
    const handleChange = (checkedValues: CheckboxValueType[]) => {
      if (onChange) {
        onChange(checkedValues);
      }
    };

    return (
      <AntdCheckbox.Group ref={ref} value={value} onChange={handleChange} className={className}>
        <Space direction={direction} size={size}>
          {checkboxes.map(val => (
            <AntdCheckbox key={val.value} value={val.value} className={styles["checkbox"]}>
              <TooltipInfo
                label={val.label}
                code={val.value}
                tooltipProps={val?.tooltip ? { title: val?.tooltip } : {}}
              />
            </AntdCheckbox>
          ))}
        </Space>
      </AntdCheckbox.Group>
    );
  },
);
