import { FC } from "react";
import { Form, Input, InputNumber } from "antd";
import { FormInstance } from "rc-field-form";
import NumberFormat from "react-number-format";

import { QuestionCircleFilled } from "@ant-design/icons";
import { BAL_INQ_ENABLED, NIC_BAL_INQ_FEE_OTHER_MAX_NRR, NIC_BAL_INQ_FEE_OTHER_VAL } from "@ni/common/constants";
import { PageItemLayoutGeneral } from "@ni/common/ui";

import styles from "../../styles.module.scss";

interface DefaultInquiryFeeProps {
  isEnabled: boolean;
  productCurrency: string;
  form: FormInstance;
}

export const DefaultInquiryFee: FC<DefaultInquiryFeeProps> = ({ isEnabled, productCurrency, form }) => (
  <div className={styles["default-inquiry-fee"]}>
    <PageItemLayoutGeneral>
      <Form.Item dependencies={[BAL_INQ_ENABLED]} shouldUpdate={true}>
        {() => {
          return (
            <Form.Item
              name={NIC_BAL_INQ_FEE_OTHER_VAL}
              rules={[
                {
                  required: (form.getFieldValue(BAL_INQ_ENABLED) as boolean) || false,
                  message: "Default fee amount is required",
                },
              ]}
              label="Default fee amount"
            >
              <NumberFormat
                customInput={Input}
                addonAfter={productCurrency}
                allowNegative={false}
                thousandSeparator=" "
                disabled={!isEnabled}
                decimalScale={2}
                decimalSeparator="."
                fixedDecimalScale={true}
              />
            </Form.Item>
          );
        }}
      </Form.Item>

      <Form.Item dependencies={[BAL_INQ_ENABLED]} shouldUpdate={true}>
        {() => {
          return (
            <Form.Item
              name={NIC_BAL_INQ_FEE_OTHER_MAX_NRR}
              label="Free balance inquiries per billing cycle"
              tooltip={{
                title: "Specify number of free of charge balance inquiries per statement cycle",
                icon: <QuestionCircleFilled />,
              }}
            >
              <InputNumber disabled={!isEnabled} decimalSeparator="." min={0} precision={0} step={1} />
            </Form.Item>
          );
        }}
      </Form.Item>
    </PageItemLayoutGeneral>
  </div>
);
