import { FC, useCallback, useEffect, useMemo } from "react";
import { Form, InputNumber, Space, Switch } from "antd";

import { QuestionCircleFilled } from "@ant-design/icons";
import { DECLINE_FEE_ENABLED, NIC_CCY, NIC_DECL_TRANS_FEE_MAX_NR, NIC_DECL_TRANS_FEE_VAL } from "@ni/common/constants";
import { useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, FormItemLabel, TooltipInfo } from "@ni/common/ui";
import { getFormValueFromProductValues } from "@ni/common/utils";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { ProductValue, TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

const wizardServicesApi = new ProductAndTenantWizardApi();

const feeMessage = "Decline transaction fee amount is required";

export const DeclineFeePage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [, setIsLoading] = useReduxState<boolean>("isLoading");
  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});

  const [form] = Form.useForm<FormValues>();
  const isEnabled = Form.useWatch<boolean>(DECLINE_FEE_ENABLED, form);
  const feeValue = Form.useWatch<string>(NIC_DECL_TRANS_FEE_VAL, form);

  const currency = useMemo(
    () => (getFormValueFromProductValues(wizardResponse.product?.productValues, NIC_CCY) as string) || "",
    [wizardResponse.product?.productValues],
  );

  const productValues = useMemo(
    () => wizardResponse.product?.parameterTables?.[0].pctProductValues as ProductValue[],
    [wizardResponse.product?.parameterTables],
  );

  const formDisabledFn = useCallback(
    (field: string, number: string | number) =>
      formDisabled ? (getFormValueFromProductValues(productValues, field, "string") as string) || "" : number,
    [formDisabled, productValues],
  );

  const getInitialValues = (productCurrency: string): string => {
    if (!productCurrency) return "0.10";
    if (productCurrency === "USD") return "0.10";
    if (productCurrency === "AED" || productCurrency === "SAR") return "0.35";

    return "0.10";
  };

  useEffect(() => {
    form.setFieldsValue({
      [DECLINE_FEE_ENABLED]: formDisabled
        ? getFormValueFromProductValues(productValues, DECLINE_FEE_ENABLED, "boolean")
        : true,
      [NIC_DECL_TRANS_FEE_VAL]: formDisabledFn(NIC_DECL_TRANS_FEE_VAL, getInitialValues(currency ?? "")),
      [NIC_DECL_TRANS_FEE_MAX_NR]: formDisabledFn(NIC_DECL_TRANS_FEE_MAX_NR, "3"),
    });
  }, [currency, form, formDisabled, formDisabledFn, productValues]);

  const switchClick = () => {
    if (isEnabled && !feeValue) {
      form.setFields([
        {
          name: NIC_DECL_TRANS_FEE_VAL,
          errors: [],
        },
      ]);
    }

    if (!isEnabled && !feeValue) {
      form.setFields([
        {
          name: NIC_DECL_TRANS_FEE_VAL,
          errors: [feeMessage],
        },
      ]);
    }
  };

  const onFinish = () => {
    setIsLoading(true);

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: isEnabled
        ? {
            [DECLINE_FEE_ENABLED]: String(isEnabled),
            [NIC_DECL_TRANS_FEE_VAL]: String(form.getFieldValue(NIC_DECL_TRANS_FEE_VAL)).replace(/ /g, ""),
            [NIC_DECL_TRANS_FEE_MAX_NR]: String(form.getFieldValue(NIC_DECL_TRANS_FEE_MAX_NR)).replace(/ /g, ""),
          }
        : {
            [DECLINE_FEE_ENABLED]: String(isEnabled),
          },
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      buttonDisabled={isEnabled && +feeValue <= 0}
      pageTitle="Decline Fee"
      pageSubtitle="Decline transaction fee is charged in case of any decline transactions as per response code list. Response
      code list can be defined in the system to consider the declined transactions."
      size="sm"
      formSize="sm"
      gap={24}
      level="root"
      submitHandler={onFinish}
    >
      <Space direction="horizontal" align="center">
        <Form.Item valuePropName="checked" name={DECLINE_FEE_ENABLED} className="col-switch">
          <Switch onChange={switchClick} />
        </Form.Item>
        <TooltipInfo
          label="Enable decline fee for your product"
          code={DECLINE_FEE_ENABLED}
          tooltipProps={{
            title:
              "After wizard completing you can configure this list at tenant settings (visit Fees Tenant Configuration page)",
          }}
        />
      </Space>

      <Form.Item dependencies={[DECLINE_FEE_ENABLED]}>
        {() => {
          return (
            <Form.Item
              name={NIC_DECL_TRANS_FEE_VAL}
              label={<FormItemLabel label="Decline transaction fee amount" code={NIC_DECL_TRANS_FEE_VAL} />}
              rules={[{ required: isEnabled, message: feeMessage }]}
            >
              <InputNumber
                disabled={!form.getFieldValue(DECLINE_FEE_ENABLED) || formDisabled}
                addonAfter={currency}
                decimalSeparator="."
                min={0}
                precision={2}
                step={1}
                style={{ width: "100%" }}
              />
            </Form.Item>
          );
        }}
      </Form.Item>

      <Form.Item dependencies={[DECLINE_FEE_ENABLED]}>
        {() => {
          return (
            <Form.Item
              name={NIC_DECL_TRANS_FEE_MAX_NR}
              label={
                <FormItemLabel label="Free of charge decline transactions per cycle" code={NIC_DECL_TRANS_FEE_MAX_NR} />
              }
              tooltip={{
                title: "Specify the number of courtesy decline transactions for your customer per cycle",
                icon: <QuestionCircleFilled />,
              }}
            >
              <InputNumber
                disabled={!form.getFieldValue(DECLINE_FEE_ENABLED) || formDisabled}
                min={0}
                precision={0}
                step={1}
              />
            </Form.Item>
          );
        }}
      </Form.Item>
    </CustomFormWrapper>
  );
};
