/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { useCallback, useEffect, useMemo } from "react";

import { FormValues, GetProductWithId } from "@ni/common/types";
import { getFormValueFromProductValues, isNumeric, removeGapsFromStringNumeric } from "@ni/common/utils";
import { ProductApi } from "@ni/sdk/apis";
import { ChangeProductRequest, ProductValue } from "@ni/sdk/models";

import { NIC_CCY } from "../../../constants/src";
import { useReduxState } from "../store";

const productServiceApi = new ProductApi();

interface UseProductSettingsProps {
  productId?: number;
  isFetchEnabled: boolean;
}

export const useProductSettings = ({ productId = 0, isFetchEnabled = true }: UseProductSettingsProps) => {
  const [currentProduct, setCurrentProduct] = useReduxState<GetProductWithId>("currentProduct", {} as GetProductWithId);
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  const fetchProductById = useCallback(
    async (productId: number) => {
      setIsLoading(true);
      try {
        const productResponse = await productServiceApi.getProductById(productId);

        setCurrentProduct({ ...productResponse.data, id: productId });
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    },
    [setCurrentProduct, setIsLoading],
  );

  const onUpdateProduct = async (values: FormValues, productValues?: ProductValue[]) => {
    setIsLoading(true);
    try {
      const requestBody: ChangeProductRequest = {
        ...(values["displayName"] ? { displayName: values["displayName"] as string } : undefined),
        productValues:
          productValues ??
          Object.entries(values).map(
            ([key, value]) =>
              ({
                fieldCode: key,
                value: isNumeric(value as string) ? removeGapsFromStringNumeric(value as string) : value,
              }) as ProductValue,
          ),
      };

      const response = await productServiceApi.editProduct(requestBody, productId);
      setCurrentProduct({ ...currentProduct, ...response.data } as GetProductWithId);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const productCurrency = useMemo(
    () => (getFormValueFromProductValues(currentProduct.productValues, NIC_CCY) as string) || "",
    [currentProduct.productValues],
  );

  useEffect(() => {
    if (isFetchEnabled) void fetchProductById(productId);
  }, [fetchProductById, isFetchEnabled, productId]);

  return {
    currentProduct,
    productCurrency,
    setCurrentProduct,
    onUpdateProduct,
    fetchProductById,
  };
};
