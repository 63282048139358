import { FC, useEffect } from "react";
import { Button, Form, SelectProps, Space } from "antd";
import { useNavigate, useParams } from "react-router-dom";

import { initialPlansPagination, useDebaunce, useInstallmentPaymentPlans, useReduxState } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { FilterControls, SettingsPageLayout } from "@ni/common/ui";
import { updateFilters } from "@ni/common/utils";
import { Tenant } from "@ni/sdk/models";

import { IPPTable } from "../../components";

import { ippFilterInputs } from "./constants";

const filterKeys = ["plan.state", "plan.group", "plan.currency"];

export const InstallmentPaymentPlans: FC = () => {
  const [form] = Form.useForm<FormValues>();
  const { id: tenantId } = useParams<{ id: string }>();

  const searchQuery = Form.useWatch<string>("search", form);
  const debouncedQuery = useDebaunce({ value: searchQuery, delay: 500 });

  const [tenant] = useReduxState<Tenant>("tenant", {});
  const [isLoading] = useReduxState<boolean>("isLoading");

  const navigate = useNavigate();

  const { installmentPlans, setInstallmentPlans, pageHasContent, pagination, setPagination, setFilters } =
    useInstallmentPaymentPlans({
      tenantId: parseInt(tenantId ?? "0", 10),
      fetchEnabled: true,
    });

  useEffect(() => {
    const { defaultCurrency } = tenant;

    if (defaultCurrency) {
      const currencyIndex = ippFilterInputs.findIndex(filter => filter.name === "plan.currency");

      if (currencyIndex !== -1) {
        const newOption = { value: defaultCurrency, label: defaultCurrency };
        const currencyOptions = (ippFilterInputs[currencyIndex].props as SelectProps).options;

        const optionExists = currencyOptions?.find(
          option => option.value === newOption.value && option.label === newOption.label,
        );

        if (!optionExists) currencyOptions?.push(newOption);
      }
    }
  }, [tenant]);

  useEffect(() => {
    if (debouncedQuery !== undefined) {
      setFilters(filters => {
        return updateFilters(filterKeys, filters, undefined, debouncedQuery);
      });

      setPagination(initialPlansPagination);
    }
  }, [debouncedQuery, setPagination, setFilters]);

  const handleValuesChange = (changedValues: FormValues) => {
    const { search } = changedValues;

    if (typeof search === "string") return;

    setFilters(filters => {
      return updateFilters(filterKeys, filters, changedValues, (search as string) || debouncedQuery);
    });

    setPagination(initialPlansPagination);
  };

  const handleNewPlanClick = () => {
    const queries = new URLSearchParams({ tenantId: tenantId! });

    navigate({
      pathname: "/create-installment-payment-plan",
      search: queries.toString(),
    });
  };

  const renderSubtitleText = () => {
    return (
      <>
        The Installment Payment or Buy Now Pay Later (BNPL) facility is a convenient option that allows individuals to
        divide the total amount of a retail transaction or the outstanding balance on a credit card into manageable
        Equated Monthly Installments (EMIs). By opting for this facility, individuals can spread out the payment for a
        purchase over a specified duration, making it easier to complete the full payment on a monthly basis.
        {!pageHasContent && (
          <>
            <br />
            <br />
            Currently, no Installment plans have been set up. Our smart setup wizards will provide you with step-by-step
            guidance throughout the entire process.
          </>
        )}
      </>
    );
  };

  return (
    <SettingsPageLayout pageTitle="Installment Payment Plans" pageSubtitle={renderSubtitleText()}>
      <Space direction="vertical" size={40}>
        {pageHasContent && (
          <div>
            <FilterControls
              form={form}
              inputs={ippFilterInputs}
              onValuesChange={handleValuesChange}
              searchName="search"
            />
            <IPPTable
              planList={installmentPlans}
              tenantId={tenantId!}
              pagination={pagination}
              setPagination={setPagination}
              setInstallmentPlans={setInstallmentPlans}
            />
          </div>
        )}

        <Button size="large" type="primary" htmlType="button" loading={isLoading} onClick={handleNewPlanClick}>
          New installment payment plan
        </Button>
      </Space>
    </SettingsPageLayout>
  );
};
