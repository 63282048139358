import { FC, useMemo } from "react";
import { Form } from "antd";
import { useParams } from "react-router-dom";

import { useHydrateForm, useProductSettings, useReduxState } from "@ni/common/hooks";
import { FormValues, Tab } from "@ni/common/types";
import { CustomFormWrapper, Tabs } from "@ni/common/ui";

import { TabKey, tabsPages } from "./constants";

export interface FeesProductConfigurationForm {
  "amf-acc-moment": string;
  "amf-card-moment": string;
  "nic-amf-card-waive-unexp": string;
  "nic-mmf-card-waive-unexp": string;
  "nic-stmt-fee-flag": string[] | string;
  "nic-inact-period-unit": string;
  "nic-inact-period": number;
}

export const FeeSettings: FC = () => {
  const [form] = Form.useForm<FormValues>();
  const [isLoading] = useReduxState<boolean>("isLoading");
  const { id: tenantId, productId } = useParams<{ id: string; productId: string }>();

  const accountMomentValue = Form.useWatch<string>("amf-acc-moment", form);
  const cardMomentValue = Form.useWatch<string>("amf-card-moment", form);

  const { currentProduct: product, onUpdateProduct } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });

  const currencyLabel = useMemo(() => {
    return product?.productValues?.find(item => item.fieldCode === "curr-setup")?.value;
  }, [product?.productValues]);

  const productType = useMemo(() => {
    return product?.productValues?.find(item => item.fieldCode === "product-type")?.value ?? "";
  }, [product?.productValues]);

  const initialValues = useHydrateForm({
    form,
    entityFields: product?.productValues
      ? [
          { fieldCode: "amf-acc-moment", value: "S" },
          { fieldCode: "amf-card-moment", value: "S" },
          { fieldCode: "nic-amf-card-waive-unexp", value: "" },
          { fieldCode: "nic-mmf-card-waive-unexp", value: "" },
          { fieldCode: "nic-stmt-fee-flag", value: "B-P" },
          { fieldCode: "nic-inact-period-unit", value: "M" },
          { fieldCode: "nic-inact-period", value: "3" },
          { fieldCode: "nic-ovl-fee-repeat-mode", value: "ONCE_AND_END_OF_BILLING" },
          ...(product?.productValues ?? []),
          {
            fieldCode: "nic-amf-card-start-mode",
            value: product?.productValues?.find(x => x.fieldCode === "nic-amf-card-start-mode")?.value || "A",
          },
          {
            fieldCode: "nic-amf-acc-start-mode",
            value: product?.productValues?.find(x => x.fieldCode === "nic-amf-acc-start-mode")?.value || "A",
          },
        ]
      : [],
    keys: {
      strings: [
        "amf-acc-moment",
        "amf-card-moment",
        "nic-amf-card-waive-unexp",
        "nic-mmf-card-waive-unexp",
        "nic-inact-period-unit",
        "nic-inact-period",
        "nic-ovl-fee-repeat-mode",
        "nic-amf-acc-start-mode",
        "nic-amf-card-start-mode",
      ],
      lists: ["nic-stmt-fee-flag"],
    },
    allowParse: false,
    listSeperator: "-",
  });

  const tabsList: Tab<TabKey>[] = useMemo(
    () =>
      [
        {
          key: "annual" as TabKey,
          label: tabsPages.annual.title,
          children: tabsPages.annual.page(accountMomentValue, cardMomentValue),
        },
        {
          key: "monthly" as TabKey,
          label: tabsPages.monthly.title,
          children: tabsPages.monthly.page(),
        },
        {
          key: "statement" as TabKey,
          label: tabsPages.statement.title,
          children: tabsPages.statement.page(),
        },
        {
          key: "inactivity" as TabKey,
          label: tabsPages.inactivity.title,
          children: tabsPages.inactivity.page(),
        },
        {
          key: "overlimit" as TabKey,
          label: tabsPages.overlimit.title,
          children: tabsPages.overlimit.page(),
        },
      ]
        .filter(x => !(x.key === "inactivity" && currencyLabel === "mult"))
        .filter(x => !(x.key === "overlimit" && productType?.toLowerCase() !== "credit")),
    [accountMomentValue, cardMomentValue, currencyLabel, productType],
  );

  const pctFeesPagesLinks: { label: string; href: string }[] = useMemo(
    () =>
      product.parameterTables?.reduce(
        (previous: { label: string; href: string }[], parameterTable) => {
          if (parameterTable) {
            previous.push({
              label: `${product?.displayName} - ${parameterTable?.displayName} - Membership Fee`,
              href: `/tenant/${tenantId}/product/${productId}/pct/${parameterTable?.id}/membership-fees`,
            });

            previous.push({
              label: `${product?.displayName} - ${parameterTable?.displayName} - Statement Fee`,
              href: `/tenant/${tenantId}/product/${productId}/pct/${parameterTable?.id}/statement-fee`,
            });

            previous.push({
              label: `${product?.displayName} - ${parameterTable?.displayName} - Inactivity Fee`,
              href: `/tenant/${tenantId}/product/${productId}/pct/${parameterTable?.id}/inactivity-fees`,
            });

            previous.push({
              label: `${product?.displayName} - ${parameterTable?.displayName} - Overlimit Fee`,
              href: `/tenant/${tenantId}/product/${productId}/pct/${parameterTable?.id}/overlimit-fee`,
            });

            previous.push({
              label: `${product?.displayName} - ${parameterTable?.displayName} - Card Production Fees`,
              href: `/tenant/${tenantId}/product/${productId}/pct/${parameterTable?.id}/card-production-fees`,
            });
          }
          return previous;
        },
        [] as { label: string; href: string }[],
      ) ?? [],

    [product?.displayName, product.parameterTables, productId, tenantId],
  );

  const onFinish = (values: FormValues) => {
    if (values["amf-acc-moment"]) values = { "nic-amf-acc-start-mode": "", "nic-amf-card-start-mode": "", ...values };
    const updatedValues: FormValues = { ...values };

    if (updatedValues["nic-stmt-fee-flag"] instanceof Array) {
      updatedValues["nic-stmt-fee-flag"] = updatedValues["nic-stmt-fee-flag"]
        ?.sort((a, b) => a.localeCompare(b))
        .join("-");
    }

    void onUpdateProduct(updatedValues);
  };

  return (
    <CustomFormWrapper
      pageTitle="Fee Settings"
      pageSubtitle="While the fees can have multiple values per each Pricing Control Table, the following settings apply universally to particular fee type of the product."
      form={form}
      submitHandler={onFinish}
      level="root"
      size="full"
      formSize="full"
      submitLabel="Save"
      relatedLinks={[
        { label: "Fee Settings - Global", href: "./../../../fees-tenant-configuration" },
        ...pctFeesPagesLinks,
      ]}
      buttonDisabled={isLoading}
    >
      <Tabs
        pagesList={tabsList}
        isCheckEnabled={true}
        form={form}
        initialValues={initialValues}
        discardAfterChangeTab={false}
        onSave={onFinish}
      />
    </CustomFormWrapper>
  );
};
