import { FC, useEffect, useMemo } from "react";
import { Col, Form, Input, Row, Switch } from "antd";
import { FormInstance } from "antd/lib/form/hooks/useForm";
import NumberFormat from "react-number-format";

import { useReduxState } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { FormItemLabel, TooltipInfo } from "@ni/common/ui";
import { getFormValueFromProductValues, getSwitchesCount } from "@ni/common/utils";
import { ProductValue, TenantProductWizardResponse } from "@ni/sdk/models";

import { checkHeader, headerSwitchHandler } from "../../../utils";

import { checkboxFieldsPage11, switchHeader11 } from "./page11.constants";

interface PctCardDailyProps {
  currency: string;
  header?: string;
  form: FormInstance<FormValues>;
  formDisabled?: boolean;
  editMode?: boolean;
}

export const PctCardMonthly: FC<PctCardDailyProps> = ({ currency, header, form, formDisabled, editMode }) => {
  const [wizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});

  const productTransPresetValues = useMemo(() => {
    const pctProductValues = wizardResponse?.product?.parameterTables?.[0].pctProductValues as ProductValue[];

    return {
      transCatContC: (
        getFormValueFromProductValues(pctProductValues, "trans-lim-trans-cat", "string") as string
      )?.includes("C"),
      transDomForTrue: getFormValueFromProductValues(pctProductValues, "trans-lim-dom-for", "boolean") === true,
      transCatContR: (
        getFormValueFromProductValues(pctProductValues, "trans-lim-trans-cat", "string") as string
      )?.includes("R"),
      transCatContT: (
        getFormValueFromProductValues(pctProductValues, "trans-lim-trans-cat", "string") as string
      )?.includes("T"),
    };
  }, [wizardResponse]);

  useEffect(() => {
    Object.keys(checkboxFieldsPage11).forEach(key => {
      form.setFieldsValue({
        [key]: false,
      });

      // eslint-disable-next-line no-restricted-syntax
      for (const item of checkboxFieldsPage11[key]) {
        if (form.getFieldValue(item)) {
          form.setFieldsValue({
            [key]: true,
          });
          break;
        }
      }
    });

    checkHeader("card-monthly-all-cash-enable", switchHeader11, form);
    checkHeader("card-monthly-all-retail-enable", switchHeader11, form);
    checkHeader("card-monthly-all-total-enable", switchHeader11, form);
  });

  const isShowForeign = useMemo(
    () => productTransPresetValues?.transDomForTrue || editMode,
    [productTransPresetValues?.transDomForTrue, editMode],
  );

  const switchShownCash = {
    all: true,
    foreign: isShowForeign,
  };

  const switchShownRetail = {
    all: true,
    foreign: isShowForeign,
  };

  const cashSwitchesNotOne = getSwitchesCount(switchShownCash) !== 1;
  const retailSwitchesNotOne = getSwitchesCount(switchShownRetail) !== 1;

  return (
    <>
      {header && <div className="text-blue-h3">{header}</div>}
      {(productTransPresetValues?.transCatContC || editMode) && (
        <div className="limits-block">
          <div className="limits-block-header">
            <Form.Item valuePropName="checked" name="card-monthly-all-cash-enable" className="header-switch">
              <Switch
                onClick={value => headerSwitchHandler("card-monthly-all-cash-enable", switchHeader11, value, form)}
              />
            </Form.Item>
            <div className="col-switch-desc">
              <TooltipInfo
                label="Cash"
                code="card-monthly-all-cash-enable"
                tooltipProps={{ title: "Turns off all cash limits." }}
              />
            </div>
          </div>
          <div className="limits-block-body">
            <Row>
              <Col span={6} />
              <Col span={6} className="col-header">
                <TooltipInfo
                  label="Max number"
                  tooltipProps={{
                    title:
                      "This counter restricts maximal number of transactions per period. Leave it empty, if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                  }}
                />
              </Col>
              <Col span={6} className="col-header">
                <TooltipInfo
                  label="Max amount"
                  tooltipProps={{
                    title:
                      "This counter restricts maximal amount of transactions per period. Leave it empty if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                  }}
                />
              </Col>
              <Col span={6} className="col-header">
                <TooltipInfo
                  label="Max single amount"
                  tooltipProps={{
                    title:
                      "This counter restricts maximal amount of single transaction. Leave it empty if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Form.Item valuePropName="checked" name="nic-c-m-cash-enable" className="col-switch">
                  {cashSwitchesNotOne && (
                    <Switch onClick={() => checkHeader("card-monthly-all-cash-enable", switchHeader11, form)} />
                  )}
                </Form.Item>
                <div className="col-switch-desc">
                  <TooltipInfo
                    label="All"
                    code="nic-c-m-cash-enable"
                    tooltipProps={{
                      title:
                        "This limiter counts all cash transactions. Turn it off, if restrictions are not intended.",
                    }}
                  />
                </div>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={["nic-c-m-cash-enable"]}>
                  {() => {
                    return (
                      <FormItemLabel code="nic-c-m-cash-max-nr">
                        <Form.Item name="nic-c-m-cash-max-nr">
                          <NumberFormat
                            customInput={Input}
                            allowNegative={false}
                            disabled={!form.getFieldValue("nic-c-m-cash-enable") || formDisabled}
                            thousandSeparator=" "
                            decimalScale={0}
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={["nic-c-m-cash-enable"]}>
                  {() => {
                    return (
                      <FormItemLabel code="nic-c-m-cash-max-am">
                        <Form.Item name="nic-c-m-cash-max-am">
                          <NumberFormat
                            customInput={Input}
                            addonAfter={currency}
                            allowNegative={false}
                            disabled={!form.getFieldValue("nic-c-m-cash-enable") || formDisabled}
                            thousandSeparator=" "
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={["nic-c-m-cash-enable"]}>
                  {() => {
                    return (
                      <FormItemLabel code="nic-c-m-cash-max-single">
                        <Form.Item name="nic-c-m-cash-max-single">
                          <NumberFormat
                            customInput={Input}
                            addonAfter={currency}
                            allowNegative={false}
                            disabled={!form.getFieldValue("nic-c-m-cash-enable") || formDisabled}
                            thousandSeparator=" "
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
            {isShowForeign && (
              <Row>
                <Col span={6}>
                  <Form.Item valuePropName="checked" name="nic-c-m-cash-for-enable" className="col-switch">
                    {cashSwitchesNotOne && (
                      <Switch onClick={() => checkHeader("card-monthly-all-cash-enable", switchHeader11, form)} />
                    )}
                  </Form.Item>
                  <div className="col-switch-desc">
                    <TooltipInfo
                      label="Foreign"
                      code="nic-c-m-cash-for-enable"
                      tooltipProps={{
                        title:
                          "This limiter counts all foreign cash transactions. Turn it off, if restrictions are not intended.",
                      }}
                    />
                  </div>
                </Col>
                <Col span={6}>
                  <Form.Item dependencies={["nic-c-m-cash-for-enable"]}>
                    {() => {
                      return (
                        <FormItemLabel code="nic-c-m-cash-for-max-nr">
                          <Form.Item name="nic-c-m-cash-for-max-nr">
                            <NumberFormat
                              customInput={Input}
                              allowNegative={false}
                              disabled={!form.getFieldValue("nic-c-m-cash-for-enable") || formDisabled}
                              thousandSeparator=" "
                              decimalScale={0}
                            />
                          </Form.Item>
                        </FormItemLabel>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item dependencies={["nic-c-m-cash-for-enable"]}>
                    {() => {
                      return (
                        <FormItemLabel code="nic-c-m-cash-for-max-am">
                          <Form.Item name="nic-c-m-cash-for-max-am">
                            <NumberFormat
                              customInput={Input}
                              addonAfter={currency}
                              allowNegative={false}
                              disabled={!form.getFieldValue("nic-c-m-cash-for-enable") || formDisabled}
                              thousandSeparator=" "
                            />
                          </Form.Item>
                        </FormItemLabel>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item dependencies={["nic-c-m-cash-for-enable"]}>
                    {() => {
                      return (
                        <FormItemLabel code="nic-c-m-cash-for-max-single">
                          <Form.Item name="nic-c-m-cash-for-max-single">
                            <NumberFormat
                              customInput={Input}
                              addonAfter={currency}
                              allowNegative={false}
                              disabled={!form.getFieldValue("nic-c-m-cash-for-enable") || formDisabled}
                              thousandSeparator=" "
                            />
                          </Form.Item>
                        </FormItemLabel>
                      );
                    }}
                  </Form.Item>
                </Col>
              </Row>
            )}
          </div>
        </div>
      )}
      {(productTransPresetValues?.transCatContR || editMode) && (
        <div className="limits-block">
          <div className="limits-block-header">
            <Form.Item valuePropName="checked" name="card-monthly-all-retail-enable" className="header-switch">
              <Switch
                onClick={value => headerSwitchHandler("card-monthly-all-retail-enable", switchHeader11, value, form)}
              />
            </Form.Item>
            <div className="col-switch-desc">
              <TooltipInfo
                label="Retail"
                code="card-monthly-all-retail-enable"
                tooltipProps={{ title: "Turns off all retail transactions." }}
              />
            </div>
          </div>
          <div className="limits-block-body">
            <Row>
              <Col span={6} />
              <Col span={6} className="col-header">
                <TooltipInfo
                  label="Max number"
                  tooltipProps={{
                    title:
                      "This counter restricts maximal number of transactions per period. Leave it empty, if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                  }}
                />
              </Col>
              <Col span={6} className="col-header">
                <TooltipInfo
                  label="Max amount"
                  tooltipProps={{
                    title:
                      "This counter restricts maximal amount of transactions per period. Leave it empty if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                  }}
                />
              </Col>
              <Col span={6} className="col-header">
                <TooltipInfo
                  label="Max single amount"
                  tooltipProps={{
                    title:
                      "This counter restricts maximal amount of single transaction. Leave it empty if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Form.Item valuePropName="checked" name="nic-c-m-retail-enable" className="col-switch">
                  {retailSwitchesNotOne && (
                    <Switch onClick={() => checkHeader("card-monthly-all-retail-enable", switchHeader11, form)} />
                  )}
                </Form.Item>
                <div className="col-switch-desc">
                  <TooltipInfo
                    label="All"
                    code="nic-c-m-retail-enable"
                    tooltipProps={{
                      title:
                        "This limiter counts all retail transactions. Turn it off, if restrictions are not intended.",
                    }}
                  />
                </div>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={["nic-c-m-retail-enable"]}>
                  {() => {
                    return (
                      <FormItemLabel code="nic-c-m-retail-max-nr">
                        <Form.Item name="nic-c-m-retail-max-nr">
                          <NumberFormat
                            customInput={Input}
                            allowNegative={false}
                            disabled={!form.getFieldValue("nic-c-m-retail-enable") || formDisabled}
                            thousandSeparator=" "
                            decimalScale={0}
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={["nic-c-m-retail-enable"]}>
                  {() => {
                    return (
                      <FormItemLabel code="nic-c-m-retail-max-am">
                        <Form.Item name="nic-c-m-retail-max-am">
                          <NumberFormat
                            customInput={Input}
                            addonAfter={currency}
                            allowNegative={false}
                            disabled={!form.getFieldValue("nic-c-m-retail-enable") || formDisabled}
                            thousandSeparator=" "
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={["nic-c-m-retail-enable"]}>
                  {() => {
                    return (
                      <FormItemLabel code="nic-c-m-retail-max-single">
                        <Form.Item name="nic-c-m-retail-max-single">
                          <NumberFormat
                            customInput={Input}
                            addonAfter={currency}
                            allowNegative={false}
                            disabled={!form.getFieldValue("nic-c-m-retail-enable") || formDisabled}
                            thousandSeparator=" "
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
            {switchShownRetail.foreign && (
              <Row>
                <Col span={6}>
                  <Form.Item valuePropName="checked" name="nic-c-m-retail-for-enable" className="col-switch">
                    {retailSwitchesNotOne && (
                      <Switch onClick={() => checkHeader("card-monthly-all-retail-enable", switchHeader11, form)} />
                    )}
                  </Form.Item>
                  <div className="col-switch-desc">
                    <TooltipInfo
                      label="Foreign"
                      code="nic-c-m-retail-for-enable"
                      tooltipProps={{
                        title:
                          "This limiter counts foreign retail transactions. Turn it off, if restrictions are not intended.",
                      }}
                    />
                  </div>
                </Col>
                <Col span={6}>
                  <Form.Item dependencies={["nic-c-m-retail-for-enable"]}>
                    {() => {
                      return (
                        <FormItemLabel code="nic-c-m-retail-for-max-nr">
                          <Form.Item name="nic-c-m-retail-for-max-nr">
                            <NumberFormat
                              customInput={Input}
                              allowNegative={false}
                              disabled={!form.getFieldValue("nic-c-m-retail-for-enable") || formDisabled}
                              thousandSeparator=" "
                              decimalScale={0}
                            />
                          </Form.Item>
                        </FormItemLabel>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item dependencies={["nic-c-m-retail-for-enable"]}>
                    {() => {
                      return (
                        <FormItemLabel code="nic-c-m-retail-for-max-am">
                          <Form.Item name="nic-c-m-retail-for-max-am">
                            <NumberFormat
                              customInput={Input}
                              addonAfter={currency}
                              allowNegative={false}
                              disabled={!form.getFieldValue("nic-c-m-retail-for-enable") || formDisabled}
                              thousandSeparator=" "
                            />
                          </Form.Item>
                        </FormItemLabel>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item dependencies={["nic-c-m-retail-for-enable"]}>
                    {() => {
                      return (
                        <FormItemLabel code="nic-c-m-retail-for-max-single">
                          <Form.Item name="nic-c-m-retail-for-max-single">
                            <NumberFormat
                              customInput={Input}
                              addonAfter={currency}
                              allowNegative={false}
                              disabled={!form.getFieldValue("nic-c-m-retail-for-enable") || formDisabled}
                              thousandSeparator=" "
                            />
                          </Form.Item>
                        </FormItemLabel>
                      );
                    }}
                  </Form.Item>
                </Col>
              </Row>
            )}
          </div>
        </div>
      )}
      {(productTransPresetValues?.transCatContT || editMode) && (
        <div className="limits-block">
          <div className="limits-block-header">
            <Form.Item valuePropName="checked" name="card-monthly-all-total-enable" className="header-switch">
              <Switch
                onClick={value => headerSwitchHandler("card-monthly-all-total-enable", switchHeader11, value, form)}
              />
            </Form.Item>
            <div className="col-switch-desc">
              <TooltipInfo
                label="Total"
                code="card-monthly-all-total-enable"
                tooltipProps={{
                  title:
                    "Total limiter that includes all transactions (Cash and Retail). Turn it off, if restrictions are not intended.",
                }}
              />
            </div>
          </div>
          <div className="limits-block-body">
            <Row>
              <Col span={6} />
              <Col span={6} className="col-header">
                <TooltipInfo
                  label="Max number"
                  tooltipProps={{
                    title:
                      "This counter restricts maximal number of transactions per period. Leave it empty, if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                  }}
                />
              </Col>
              <Col span={6} className="col-header">
                <TooltipInfo
                  label="Max amount"
                  tooltipProps={{
                    title:
                      "This counter restricts maximal amount of transactions per period. Leave it empty if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                  }}
                />
              </Col>
              <Col span={6} className="col-header">
                <TooltipInfo
                  label="Max single amount"
                  tooltipProps={{
                    title:
                      "This counter restricts maximal amount of single transaction. Leave it empty if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Form.Item valuePropName="checked" name="nic-c-m-total-enable" className="col-switch" />
                <div className="col-switch-desc">
                  <TooltipInfo
                    label="All"
                    code="nic-c-m-total-enable"
                    tooltipProps={{
                      title:
                        "Total limiter that includes all transactions (Cash and Retail). Turn it off, if restrictions are not intended.",
                    }}
                  />
                </div>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={["nic-c-m-total-enable"]}>
                  {() => {
                    return (
                      <FormItemLabel code="nic-c-m-total-max-nr">
                        <Form.Item name="nic-c-m-total-max-nr">
                          <NumberFormat
                            customInput={Input}
                            allowNegative={false}
                            disabled={!form.getFieldValue("nic-c-m-total-enable") || formDisabled}
                            thousandSeparator=" "
                            decimalScale={0}
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={["nic-c-m-total-enable"]}>
                  {() => {
                    return (
                      <FormItemLabel code="nic-c-m-total-max-am">
                        <Form.Item name="nic-c-m-total-max-am">
                          <NumberFormat
                            customInput={Input}
                            addonAfter={currency}
                            allowNegative={false}
                            disabled={!form.getFieldValue("nic-c-m-total-enable") || formDisabled}
                            thousandSeparator=" "
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={["nic-c-m-total-enable"]}>
                  {() => {
                    return (
                      <FormItemLabel code="nic-c-m-total-max-single">
                        <Form.Item name="nic-c-m-total-max-single">
                          <NumberFormat
                            customInput={Input}
                            addonAfter={currency}
                            allowNegative={false}
                            disabled={!form.getFieldValue("nic-c-m-total-enable") || formDisabled}
                            thousandSeparator=" "
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
};

PctCardMonthly.defaultProps = {
  header: undefined,
  formDisabled: true,
  editMode: false,
};

export const getCardMonthlyLimitsPreparedValues = (value: {
  [key: string]: string | boolean;
}): { [key: string]: string } | undefined => {
  let actualValues: { [key: string]: string } | undefined;

  Object.keys(checkboxFieldsPage11).forEach(key => {
    if (value[key]) {
      checkboxFieldsPage11[key].forEach(fieldKey => {
        if (!actualValues) {
          actualValues = {};
        }
        actualValues[fieldKey] = (value[fieldKey] || "").toString().replace(/(\s)/g, "");
      });
    }
  });
  return actualValues;
};
