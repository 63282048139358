import { FC, useEffect, useMemo } from "react";
import { Form, InputNumber, Select, Space, Switch } from "antd";
import cn from "classnames";
import { parseInt } from "lodash";
import { StoreValue } from "rc-field-form/lib/interface";
import { useParams } from "react-router-dom";

import { QuestionCircleFilled } from "@ant-design/icons";
import { TENANT_COUNTRY } from "@ni/common/constants";
import { useReduxState } from "@ni/common/hooks";
import { nicDeclTransFeeValPredefRcOptions, nicQuasiCashOptions } from "@ni/common/mocks";
import { FormValues } from "@ni/common/types";
import {
  CustomFormWrapper,
  DictionarySelect,
  PageItemLayoutElements,
  PageItemLayoutGeneral,
  PageItemLayoutGroup,
} from "@ni/common/ui";
import { filterByDisplayValue, getFormValueTenantValues } from "@ni/common/utils";
import { TenantApi } from "@ni/sdk/apis";
import { ChangeTenantRequest, Tenant } from "@ni/sdk/models";

import styles from "./styles.module.scss";

const tenantsServiceApi = new TenantApi();

export interface FeesTenantConfigurationForm {
  "nic-domestic-country": string;
  "vat-fees-apply": boolean;
  "vat-rate": number;
  "vat-membership-apply": boolean;
  "vat-cardprod-fees-apply": boolean;
  "vat-trans-fees-apply": boolean;
  "vat-markup-fee-apply": boolean;
  "vat-other-fees-apply": boolean;

  "nic-quasi-cash": string;
  "nic-decl-trans-fee-val-pref-rc": string;
  "nic-decl-trans-fee-val-pref-ex_bal_inq": boolean;
  "vat-late-fee-apply": boolean;
  "vat-ovl-fee-apply": boolean;
  "vat-nsf-fee-apply": boolean;
}

export const FeesTenantConfiguration: FC = () => {
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const [tenant, setTenant] = useReduxState<Tenant>("tenant", {});
  const [isLoading, setIsLoading] = useReduxState<boolean>("isLoading");

  useEffect(() => {
    if (!isLoading) {
      const quasiList = getFormValueTenantValues(tenant, "nic-quasi-cash", "string");
      const rcyList = getFormValueTenantValues(tenant, "nic-decl-trans-fee-val-pref-rc", "string");
      form.setFieldsValue({
        "nic-domestic-country":
          getFormValueTenantValues(tenant, "nic-domestic-country") || (tenant.countryCode as string),
        "vat-fees-apply": getFormValueTenantValues(tenant, "vat-fees-apply", "boolean") || false,
        "vat-rate": getFormValueTenantValues(tenant, "vat-rate", "number") || 5.0,
        "vat-membership-apply": getFormValueTenantValues(tenant, "vat-membership-apply", "boolean") || false,
        "vat-cardprod-fees-apply": getFormValueTenantValues(tenant, "vat-cardprod-fees-apply", "boolean") || false,
        "vat-trans-fees-apply": getFormValueTenantValues(tenant, "vat-trans-fees-apply", "boolean") || false,
        "vat-markup-fee-apply": getFormValueTenantValues(tenant, "vat-markup-fee-apply", "boolean") || false,
        "vat-other-fees-apply": getFormValueTenantValues(tenant, "vat-other-fees-apply", "boolean") || false,
        "vat-late-fee-apply": getFormValueTenantValues(tenant, "vat-late-fee-apply", "boolean") || false,
        "vat-ovl-fee-apply": getFormValueTenantValues(tenant, "vat-ovl-fee-apply", "boolean") || false,
        "vat-nsf-fee-apply": getFormValueTenantValues(tenant, "vat-nsf-fee-apply", "boolean") || false,
        "nic-quasi-cash": quasiList ? String(quasiList).split(",") : nicQuasiCashOptions.map(v => v.value),
        "nic-decl-trans-fee-val-pref-rc": rcyList
          ? String(rcyList).split(",")
          : nicDeclTransFeeValPredefRcOptions.map(v => v.value),
        "nic-decl-trans-fee-val-pref-ex_bal_inq":
          getFormValueTenantValues(tenant, "nic-decl-trans-fee-val-pref-ex_bal_inq", "boolean") || false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant]);

  const relatedLinks = useMemo(
    () =>
      tenant?.products?.map(product => ({
        href: `/tenant/${tenant?.id}/product/${product?.id}/fees-settings?tab=annual`,
        label: `${product?.displayName} - Fee Settings`,
      })) ?? [],
    [tenant?.id, tenant?.products],
  );

  const hasCreditProduct = useMemo(
    () =>
      tenant.products?.some(
        product =>
          product.productValues?.find(x => x.fieldCode === "product-type")?.value === "Credit" &&
          product.productValues?.find(x => x.fieldCode === "balance-owner")?.value === "CMS",
      ),
    [tenant.products],
  );

  const onFinish = (value: FeesTenantConfigurationForm) => {
    setIsLoading(true);

    const tenantId = parseInt(id ?? "0", 10);
    const requestBody: ChangeTenantRequest = {
      tenantValues: Object.keys(value).map((val: string) => {
        if (val === "nic-domestic-country" || val === "vat-rate") {
          return {
            fieldCode: val,
            value: (value[val as keyof FeesTenantConfigurationForm] as string) || "",
          };
        }
        if (val === "nic-quasi-cash" || val === "nic-decl-trans-fee-val-pref-rc") {
          return {
            fieldCode: val,
            value: (value[val as keyof FeesTenantConfigurationForm] as unknown as string[]).join(",") || "",
          };
        }
        return {
          fieldCode: val,
          value: value[val as keyof FeesTenantConfigurationForm] ? "true" : "false",
        };
      }),
    };

    tenantsServiceApi
      .editTenant(requestBody, tenantId)
      .then(res => {
        setIsLoading(false);
        setTenant(res.data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const validateNumberInput = (value: StoreValue): Promise<string | void> => {
    if (!value) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject<string>("VAT rate should not be null");
    }
    if (value <= 0 || value >= 100) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject<string>("VAT rate should be between 0 and 100");
    }
    if (String(value).includes(".") && String(value).split(".")[1].length > 2) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject<string>("Only 2 decimal digits allowed for VAT input");
    }
    return Promise.resolve();
  };

  const switchVatBlock = (val: boolean) => {
    form.setFieldsValue({
      "vat-fees-apply": val,
      "vat-membership-apply": val,
      "vat-cardprod-fees-apply": val,
      "vat-trans-fees-apply": val,
      "vat-markup-fee-apply": val,
      "vat-other-fees-apply": val,
      "vat-late-fee-apply": val,
      "vat-ovl-fee-apply": val,
      "vat-nsf-fee-apply": val,
    });
  };

  return (
    <CustomFormWrapper
      pageTitle="Fee Settings"
      pageSubtitle="While the fees can have multiple values per each Pricing Control Table of the particular product, the following settings are applied universally to particular fee type for all products."
      form={form}
      submitHandler={onFinish as unknown as (values: FormValues) => void}
      formSize="lg"
      size="full"
      submitLabel="Save"
      relatedLinks={relatedLinks}
    >
      <PageItemLayoutGeneral size={16}>
        <PageItemLayoutGroup className={styles["tenant-fees-container"]}>
          <Form.Item
            name="nic-domestic-country"
            label="Domestic country"
            tooltip={{
              title:
                'All the rest countries are considered to be "Foreign". These areas are used for transactional limits, IPS transactional fees, balance inquiry fees.',
              icon: <QuestionCircleFilled />,
            }}
          >
            <DictionarySelect
              placeholder="Tenant country"
              filterOption={filterByDisplayValue}
              code={TENANT_COUNTRY}
              showSearch={true}
            />
          </Form.Item>
          <div className={styles["tenant-fees-section"]}>
            <div className={cn(styles["tenant-fees-form-item"], styles["tenant-fees-cb"])}>
              <Space direction="horizontal" size={12}>
                <Form.Item valuePropName="checked" name="vat-fees-apply">
                  <Switch onChange={switchVatBlock} />
                </Form.Item>
                <div className={styles["tenant-fees-text-cb"]}>Enable VAT (value added tax) on fees</div>
              </Space>
            </div>
            <PageItemLayoutElements>
              <Form.Item dependencies={["vat-fees-apply"]}>
                {() => (
                  <div className={cn(styles["tenant-fees-form-item"], styles["item-margin-top"])}>
                    <Form.Item dependencies={["vat-fees-apply"]}>
                      {() => (
                        <Form.Item
                          name="vat-rate"
                          label="Value added tax (VAT) rate, %"
                          rules={[
                            () => ({
                              validator(_, value) {
                                return validateNumberInput(value);
                              },
                            }),
                          ]}
                          tooltip={{
                            icon: <QuestionCircleFilled />,
                            title: (
                              <>
                                Used for applying on different types of fees. For fees setup configuring please visit
                                Pricing control tables of the particular product
                              </>
                            ),
                          }}
                        >
                          <InputNumber
                            className={styles["tenant-fees-input-item"]}
                            disabled={!form.getFieldValue("vat-fees-apply")}
                            step="0.01"
                            stringMode={true}
                          />
                        </Form.Item>
                      )}
                    </Form.Item>
                  </div>
                )}
              </Form.Item>
              <Form.Item dependencies={["vat-fees-apply"]}>
                {() => (
                  <div className={cn(styles["tenant-fees-form-item"], styles["item-margin"])}>
                    <Space direction="horizontal">
                      <Form.Item dependencies={["vat-fees-apply"]}>
                        {() => (
                          <Form.Item valuePropName="checked" name="vat-membership-apply">
                            <Switch disabled={!form.getFieldValue("vat-fees-apply")} />
                          </Form.Item>
                        )}
                      </Form.Item>
                      <div className={styles["tenant-fees-text-cb"]}>Apply VAT on Membership fees, Joining fee</div>
                    </Space>
                  </div>
                )}
              </Form.Item>
              <Form.Item dependencies={["vat-fees-apply"]}>
                {() => (
                  <div className={cn(styles["tenant-fees-form-item"], styles["item-margin"])}>
                    <Space direction="horizontal">
                      <Form.Item dependencies={["vat-fees-apply"]}>
                        {() => (
                          <Form.Item valuePropName="checked" name="vat-cardprod-fees-apply">
                            <Switch disabled={!form.getFieldValue("vat-fees-apply")} />
                          </Form.Item>
                        )}
                      </Form.Item>
                      <div className={styles["tenant-fees-text-cb"]}>Apply VAT on Card production fees</div>
                    </Space>
                  </div>
                )}
              </Form.Item>
              <Form.Item dependencies={["vat-fees-apply"]}>
                {() => (
                  <div className={cn(styles["tenant-fees-form-item"], styles["item-margin"])}>
                    <Space direction="horizontal">
                      <Form.Item dependencies={["vat-fees-apply"]}>
                        {() => (
                          <Form.Item valuePropName="checked" name="vat-trans-fees-apply">
                            <Switch disabled={!form.getFieldValue("vat-fees-apply")} />
                          </Form.Item>
                        )}
                      </Form.Item>
                      <div className={styles["tenant-fees-text-cb"]}>
                        Apply VAT on Transaction fees (Card transactions, Top-up, Payments)
                      </div>
                    </Space>
                  </div>
                )}
              </Form.Item>
              <Form.Item dependencies={["vat-fees-apply"]}>
                {() => (
                  <div className={cn(styles["tenant-fees-form-item"], styles["item-margin"])}>
                    <Space direction="horizontal">
                      <Form.Item dependencies={["vat-fees-apply"]}>
                        {() => (
                          <Form.Item valuePropName="checked" name="vat-markup-fee-apply">
                            <Switch disabled={!form.getFieldValue("vat-fees-apply")} />
                          </Form.Item>
                        )}
                      </Form.Item>
                      <div className={styles["tenant-fees-text-cb"]}>Apply VAT on FX markup fee</div>
                    </Space>
                  </div>
                )}
              </Form.Item>
              {hasCreditProduct && (
                <>
                  <Form.Item dependencies={["vat-fees-apply"]}>
                    {() => (
                      <div className={cn(styles["tenant-fees-form-item"], styles["item-margin"])}>
                        <Space direction="horizontal">
                          <Form.Item dependencies={["vat-fees-apply"]}>
                            {() => (
                              <Form.Item valuePropName="checked" name="vat-late-fee-apply">
                                <Switch disabled={!form.getFieldValue("vat-fees-apply")} />
                              </Form.Item>
                            )}
                          </Form.Item>
                          <div className={styles["tenant-fees-text-cb"]}>Apply VAT on Late payment fee</div>
                        </Space>
                      </div>
                    )}
                  </Form.Item>
                  <Form.Item dependencies={["vat-fees-apply"]}>
                    {() => (
                      <div className={cn(styles["tenant-fees-form-item"], styles["item-margin"])}>
                        <Space direction="horizontal">
                          <Form.Item dependencies={["vat-fees-apply"]}>
                            {() => (
                              <Form.Item valuePropName="checked" name="vat-ovl-fee-apply">
                                <Switch disabled={!form.getFieldValue("vat-fees-apply")} />
                              </Form.Item>
                            )}
                          </Form.Item>
                          <div className={styles["tenant-fees-text-cb"]}>Apply VAT on Overlimit fee</div>
                        </Space>
                      </div>
                    )}
                  </Form.Item>
                  <Form.Item dependencies={["vat-fees-apply"]}>
                    {() => (
                      <div className={cn(styles["tenant-fees-form-item"], styles["item-margin"])}>
                        <Space direction="horizontal">
                          <Form.Item dependencies={["vat-fees-apply"]}>
                            {() => (
                              <Form.Item valuePropName="checked" name="vat-nsf-fee-apply">
                                <Switch disabled={!form.getFieldValue("vat-fees-apply")} />
                              </Form.Item>
                            )}
                          </Form.Item>
                          <div className={styles["tenant-fees-text-cb"]}>Apply VAT on Not sufficient funds fee</div>
                        </Space>
                      </div>
                    )}
                  </Form.Item>
                </>
              )}
              <Form.Item dependencies={["vat-fees-apply"]}>
                {() => (
                  <div className={cn(styles["tenant-fees-form-item"], styles["item-margin-bot"])}>
                    <Space direction="horizontal">
                      <Form.Item dependencies={["vat-fees-apply"]}>
                        {() => (
                          <Form.Item valuePropName="checked" name="vat-other-fees-apply">
                            <Switch disabled={!form.getFieldValue("vat-fees-apply")} />
                          </Form.Item>
                        )}
                      </Form.Item>
                      <div className={styles["tenant-fees-text-cb"]}>
                        Apply VAT on Statement fee, Inactivity fee, Balance inquiry fee, Decline fee
                      </div>
                    </Space>
                  </div>
                )}
              </Form.Item>
            </PageItemLayoutElements>
          </div>
        </PageItemLayoutGroup>
        <PageItemLayoutGroup className={styles["tenant-fees-container"]}>
          <div className="text-blue-h3">Quasi cash</div>
          <div className={styles["section-description"]}>
            System identifies a transaction as Quasi cash transaction based on the transaction Merchant category code
            (MCC). Separate fees could be applied to this type of transaction.
          </div>
          <Form.Item
            name="nic-quasi-cash"
            label="Quasi cash MCC list"
            tooltip={{
              title:
                "System identifies a transaction as Quasi cash transaction based on the transaction Merchant category code (MCC). E.g. '6050 - Quasi Cash, fin Inst', '6051 - Foreign Currency, Money, TC's'",
              icon: <QuestionCircleFilled />,
            }}
          >
            <Select mode="multiple">
              {nicQuasiCashOptions.map(value => (
                <Select.Option key={value.value} value={value.value}>
                  {value.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </PageItemLayoutGroup>

        <PageItemLayoutGroup className={styles["tenant-fees-container"]}>
          <div className="text-blue-h3">Decline fee</div>
          <div className={styles["section-description"]}>
            Decline transaction fee is charged in case of any decline transactions as per response code list. Response
            code list can be defined in the system to consider the declined transactions.
          </div>
          <Form.Item
            name="nic-decl-trans-fee-val-pref-rc"
            label="Choose reason codes to apply decline fee"
            tooltip={{
              title: "Only selected decline reason codes will be charged",
              icon: <QuestionCircleFilled />,
            }}
          >
            <Select mode="multiple">
              {nicDeclTransFeeValPredefRcOptions.map(value => (
                <Select.Option key={value.value} value={value.value}>
                  {value.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Space direction="horizontal">
            <Form.Item valuePropName="checked" name="nic-decl-trans-fee-val-pref-ex_bal_inq">
              <Switch />
            </Form.Item>
            <div className={styles["tenant-fees-text-cb"]}>Exclude declined balance inquiry from decline fee</div>
          </Space>
        </PageItemLayoutGroup>
      </PageItemLayoutGeneral>
    </CustomFormWrapper>
  );
};
