import { Button, Dropdown, MenuProps, notification, Space } from "antd";

import { DownOutlined } from "@ant-design/icons";
import { PRODUCT_CURRENCY, TENANT_COUNTRY, TENANT_CURRENCY } from "@ni/common/constants";
import { useReduxState } from "@ni/common/hooks";
import { TextIcon } from "@ni/common/icons";
import { Dictionaries } from "@ni/sdk/models";

import { WITHOUT_UPDATES_MESSAGE } from "./DictionariesHeader.constants";

import styles from "./styles.module.scss";

interface DictionairesHeaderProps {
  children: React.ReactNode;
  syncWithWay4: (dictionaryCode: string) => Promise<{
    dictionaries: Dictionaries[];
    status: number;
  }>;
  openDrawer: (enumToEdit?: Dictionaries) => void;
}

export const DictionairesHeader = ({ children, syncWithWay4, openDrawer }: DictionairesHeaderProps) => {
  const [isLoading] = useReduxState<boolean>("isLoading");

  const handleMenuClick: MenuProps["onClick"] = e => {
    syncWithWay4(e.key)
      .then(response => {
        const { dictionaries, status } = response;
        let notificationMessage = WITHOUT_UPDATES_MESSAGE;
        if (dictionaries.length !== 0) {
          notificationMessage = `Synchronized dictionaries: ${dictionaries.map(it => it.code).join(", ")}`;

          notification.success({
            placement: "topRight",
            duration: 5,
            message: (
              <div>
                {status}
                <br />
                {notificationMessage}
              </div>
            ),
          });
          return;
        }

        notification.warning({
          placement: "topRight",
          duration: 5,
          message: (
            <div>
              {status}
              <br />
              {notificationMessage}
            </div>
          ),
        });
      })
      .catch(error => {
        notification.error({
          placement: "topRight",
          duration: 5,
          message: (
            <div>
              {error.response.status}
              <br />
              {WITHOUT_UPDATES_MESSAGE}
            </div>
          ),
        });
      });
  };

  const dictionaryCodeItems: MenuProps["items"] = [
    {
      label: "plan-currency",
      key: "plan-currency",
      icon: <TextIcon />,
    },
    {
      label: PRODUCT_CURRENCY,
      key: PRODUCT_CURRENCY,
      icon: <TextIcon />,
    },
    {
      label: TENANT_CURRENCY,
      key: TENANT_CURRENCY,
      icon: <TextIcon />,
    },
    {
      label: TENANT_COUNTRY,
      key: TENANT_COUNTRY,
      icon: <TextIcon />,
    },
  ];

  return (
    <div className={styles["header"]} key={Math.random()}>
      <div className={styles["top-section"]}>
        <Dropdown menu={{ items: dictionaryCodeItems, onClick: handleMenuClick }}>
          <Button disabled={isLoading} className={styles["new-button"]} type="primary" size="middle">
            <Space>
              Sync with Way4
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
        <Button
          disabled={isLoading}
          className={styles["new-button"]}
          type="primary"
          size="middle"
          onClick={() => openDrawer()}
        >
          New dictionary
        </Button>
        {children}
      </div>
    </div>
  );
};
