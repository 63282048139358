import React, { FC, Key, useCallback, useMemo, useState } from "react";
import {
  Button,
  Flex,
  Form,
  InputNumberProps,
  InputProps,
  notification,
  SelectProps,
  Table,
  TablePaginationConfig,
  TableProps,
  Tooltip,
} from "antd";
import { DefaultOptionType } from "antd/es/select";
import { FilterValue, SorterResult } from "antd/es/table/interface";

import { InfoCircleFilled, SaveOutlined, SettingOutlined } from "@ant-design/icons";
import { qpCreate, qpDelete, qpUpdate } from "@ni/common/assets";
import { emptyValue } from "@ni/common/constants";
import { SetReduxState } from "@ni/common/hooks";
import { ChevronDownAltIcon, ChevronUpAltIcon, CopyIcon, DiscardIcon, DoubleLineIcon } from "@ni/common/icons";
import { FormValues, Value } from "@ni/common/types";
import { TooltipInfo } from "@ni/common/ui";
import { numberFormatter, numberParser } from "@ni/common/utils";
import {
  Order,
  QpChange,
  QpDashboard,
  QpDashboardPageData,
  QpRowkey,
  QpRowkeyProduct,
  SortedFilteredPageRequest,
} from "@ni/sdk/models";

import { getObjectByRowkey } from "../../utils";
import { EditableCell, InputType } from "../EditableCell";
import { ParamCodeSelectProps } from "../ParamCodeSelect";

import styles from "./styles.module.scss";

type TableRowSelection<T> = TableProps<T>["rowSelection"];

interface QPEditTableProps {
  data: QpDashboard[];
  setData: SetReduxState<QpDashboardPageData>;
  saveQpData: (oldValues: QpDashboard, changedValues: QpDashboard) => Promise<void>;
  pagination: TablePaginationConfig;
  qpRowkeys: QpRowkey[];
  currentRowkey: string | undefined;
  setPagination: React.Dispatch<React.SetStateAction<TablePaginationConfig>>;
  setFilters: React.Dispatch<React.SetStateAction<SortedFilteredPageRequest>>;
  reviewMode?: boolean;
  isLoading: boolean;
  selectedRowKeys?: React.Key[];
  setSelectedRowKeys?: React.Dispatch<React.SetStateAction<React.Key[]>>;
}

export const QPEditTable: FC<QPEditTableProps> = ({
  data,
  setData,
  saveQpData,
  pagination,
  qpRowkeys,
  currentRowkey,
  setPagination,
  setFilters,
  reviewMode = false,
  isLoading,
  selectedRowKeys,
  setSelectedRowKeys,
}) => {
  const [editingRowKey, setEditingRowKey] = useState<string | null>(null);

  const [form] = Form.useForm<FormValues>();

  const handleTableChange = (
    pagination: TablePaginationConfig,
    _: Record<string, FilterValue | null>,
    sorter: SorterResult<QpDashboard> | SorterResult<QpDashboard>[],
  ) => {
    let newSorting: Array<Order> = [];
    if (Object.keys(sorter).length) {
      const sorterArray = Array.isArray(sorter) ? sorter : [sorter];

      newSorting = sorterArray.map(sorterItem => {
        const { order, columnKey } = sorterItem;

        if (!order) return undefined;

        return {
          direction: order === "ascend" ? "ASC" : order === "descend" ? "DESC" : "",
          property: columnKey,
        };
      }) as Array<Order>;
    }

    setPagination(pagination);

    setFilters(prev => ({
      ...prev,
      sorting: newSorting.filter(Boolean),
    }));
  };

  const isEditing = useCallback(
    (record: QpDashboard) => {
      return record.id === editingRowKey;
    },
    [editingRowKey],
  );

  const edit = (record: QpDashboard) => {
    setEditingRowKey(record.id as string);
  };

  const save = useCallback(
    async ({ record, resetEditRowkey = true }: { record: QpDashboard; resetEditRowkey?: boolean }) => {
      try {
        const row = await form.validateFields();

        const newData = [...data];
        const index = newData.findIndex(item => record.id === item.id);

        if (index > -1) {
          const object = newData[index];
          const changedValues = Object.keys(row).reduce((acc: { [key: string]: Value }, key) => {
            acc[key.split("@")[1]] = row[key];
            return acc;
          }, {});

          if (!changedValues["rowkey"] || (!changedValues["columnValue"] && !record.columnValue)) {
            notification.error({
              placement: "topRight",
              duration: 5,
              message: "Columns cannot be empty.",
            });

            return;
          }

          newData.splice(index, 1, {
            ...object,
            ...changedValues,
          });

          await saveQpData(record, changedValues);

          setData(prev => ({ ...prev, content: newData }));

          if (resetEditRowkey) setEditingRowKey(null);

          notification.success({
            placement: "topRight",
            duration: 5,
            message: `Your changes for "${record.questionnaire}" has been submitted for review successfully!`,
          });
        }
      } catch (err) {
        setEditingRowKey(null);

        notification.error({
          placement: "topRight",
          duration: 5,
          message: "Something went wrong. Please try again later.",
        });
      }
    },
    [data, form, saveQpData, setData],
  );

  const discard = useCallback(
    (record: QpDashboard) => {
      const { id } = record;

      setEditingRowKey(null);
      form.resetFields([`${id}@rowkey`, `${id}@parmCode`, `${id}@columnValue`]);
    },
    [form],
  );

  const onSelectChange = (newSelectedRowKeys: Key[]) => {
    if (setSelectedRowKeys) setSelectedRowKeys(newSelectedRowKeys);
  };

  const columns = useMemo(
    () => [
      ...(reviewMode
        ? [
            {
              title: "Action",
              dataIndex: ["action"],
              key: "action",
              width: "6%",
              align: "center" as const,
              fixed: "left" as const,
              editable: false,
              ellipsis: false,
              render: (_: string, record: QpChange) =>
                ({
                  CREATE: <img src={qpCreate} alt="Create" />,
                  DELETE: <img src={qpDelete} alt="Delete" />,
                  UPDATE: <img src={qpUpdate} alt="Update" />,
                  Unknown: "Unknown",
                })[record?.action ?? "Unknown"],
            },
          ]
        : []),
      {
        title: "Questionnaire",
        dataIndex: ["questionnaire"],
        key: reviewMode ? "questionnaire" : "column.questionnaire",
        width: "10%",
        editable: false,
        ellipsis: true,
        sorter: true,
      },
      {
        title: "Parameter Type",
        dataIndex: ["parmType"],
        key: reviewMode ? "parmType" : "column.parmType",
        width: "11%",
        editable: false,
        ellipsis: true,
        sorter: true,
      },
      {
        title: "Row Key",
        dataIndex: ["rowkey"],
        key: "rowkey.rowkey",
        width: "9%",
        editable: true,
        ellipsis: true,
        sorter: !reviewMode,
      },
      {
        title: "Parameter Code",
        dataIndex: ["parmCode"],
        key: reviewMode ? "parmCode" : "column.parmCode",
        width: "10%",
        editable: true,
        ellipsis: true,
      },
      {
        title: "Column Code",
        dataIndex: ["columnCode"],
        key: reviewMode ? "columnCode" : "column.columnCode",
        width: "10%",
        editable: false,
        ellipsis: true,
        sorter: true,
      },
      {
        title: "Column Name",
        dataIndex: reviewMode ? ["columnName"] : ["name"],
        key: reviewMode ? "columnName" : "column.name",
        width: "14%",
        editable: false,
        ellipsis: true,
        sorter: true,
      },
      ...(reviewMode
        ? [
            {
              title: "Old Column Value",
              dataIndex: ["columnValueOld"],
              key: "columnValueOld",
              width: "10%",
              editable: true,
              ellipsis: true,
              sorter: true,
            },
            {
              title: "New Column Value",
              dataIndex: ["columnValueNew"],
              key: "columnValueNew",
              width: "10%",
              editable: true,
              ellipsis: true,
              sorter: true,
            },
          ]
        : [
            {
              title: "Column Value",
              dataIndex: ["columnValue"],
              key: "column.listValue",
              width: "10%",
              editable: true,
              ellipsis: true,
              sorter: true,
            },
            {
              title: (
                <TooltipInfo
                  label="Default Value"
                  tooltipProps={{
                    title: "If nothing was defined for the row, then the following value will be applied on Way4 side",
                    placement: "bottom",
                    className: "tooltip-title",
                  }}
                />
              ),
              dataIndex: ["defaultValue"],
              key: "column.defaultValue",
              width: "10%",
              editable: false,
              ellipsis: true,
              sorter: true,
              render: (_: string, record: QpDashboard) => {
                return record.defaultValue
                  ? record.defaultValue
                  : record.predefValues?.filter(val => val.isDefault).map(val => val.value);
              },
            },
            {
              title: (
                <TooltipInfo
                  label="Popularity"
                  tooltipProps={{
                    title: (
                      <Flex vertical={true} gap={6}>
                        <div>Defined from AUTOFILL_LEVEL:</div>
                        <ul className="m-b-0">
                          <li>High - 999 and higher</li>
                          <li>Medium - 100</li>
                          <li>Low - 10 and lower</li>
                        </ul>
                      </Flex>
                    ),
                    placement: "bottom",
                    className: "tooltip-title",
                  }}
                />
              ),
              dataIndex: ["autofillLevel"],
              key: "column.autofillLevel",
              width: "8%",
              align: "center" as const,
              editable: false,
              ellipsis: true,
              sorter: true,
              render: (_: string, record: QpDashboard) => {
                return (
                  record?.autofillLevel && (
                    <div className="text-primary">
                      {record.autofillLevel >= 999 ? (
                        <Tooltip title="High popularity">
                          <ChevronUpAltIcon />
                        </Tooltip>
                      ) : record.autofillLevel === 100 ? (
                        <Tooltip title="Medium popularity">
                          <DoubleLineIcon />
                        </Tooltip>
                      ) : record.autofillLevel <= 10 ? (
                        <Tooltip title="Low popularity">
                          <ChevronDownAltIcon />
                        </Tooltip>
                      ) : null}
                    </div>
                  )
                );
              },
            },
            {
              title: "",
              key: "actions",
              width: "7%",
              editable: false,
              ellipsis: true,
              fixed: "right" as const,
              render: (_: string, record: QpDashboard) => (
                <Flex justify="flex-start" className="w-p-100">
                  {isEditing(record) ? (
                    <>
                      <Tooltip title="Save">
                        <Button
                          type="text"
                          htmlType="button"
                          icon={<SaveOutlined />}
                          className="text-primary"
                          onClick={() => save({ record })}
                        />
                      </Tooltip>
                      <Tooltip title="Discard">
                        <Button
                          type="text"
                          htmlType="button"
                          icon={<DiscardIcon />}
                          className="text-primary"
                          onClick={() => discard(record)}
                        />
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <Tooltip title="Edit">
                        <Button
                          type="text"
                          htmlType="button"
                          icon={<SettingOutlined />}
                          onClick={() => edit(record)}
                          style={{ color: "#747477" }}
                          disabled={!currentRowkey}
                        />
                      </Tooltip>
                      <Tooltip title="Duplicate">
                        <Button
                          type="text"
                          htmlType="button"
                          icon={<CopyIcon />}
                          style={{ color: "#747477" }}
                          disabled={!record.rowkey}
                        />
                      </Tooltip>
                      {record?.description && (
                        <Tooltip title={record?.description}>
                          <Button
                            type="text"
                            htmlType="button"
                            icon={<InfoCircleFilled />}
                            className="text-secondary"
                          />
                        </Tooltip>
                      )}
                    </>
                  )}
                </Flex>
              ),
            },
          ]),
    ],
    [currentRowkey, discard, isEditing, reviewMode, save],
  );

  const rowSelection: TableRowSelection<QpDashboard> = {
    selectedRowKeys,
    onChange: onSelectChange,
    columnWidth: 18,
    fixed: "left",
    selections: [
      Table.SELECTION_ALL,
      {
        key: "CREATE",
        text: "Select create only",
        onSelect: () => {
          if (setSelectedRowKeys)
            setSelectedRowKeys((data as QpChange[])?.filter(x => x.action === "CREATE")?.map(x => x.id) as Key[]);
        },
      },
      {
        key: "UPDATE",
        text: "Select update only",
        onSelect: () => {
          if (setSelectedRowKeys)
            setSelectedRowKeys((data as QpChange[])?.filter(x => x.action === "UPDATE")?.map(x => x.id) as Key[]);
        },
      },
      {
        key: "DELETE",
        text: "Select delete only",
        onSelect: () => {
          if (setSelectedRowKeys)
            setSelectedRowKeys((data as QpChange[])?.filter(x => x.action === "DELETE")?.map(x => x.id) as Key[]);
        },
      },
      Table.SELECTION_NONE,
    ],
  };

  const addRowkeys = (qpRowkeys: QpRowkey[], rowkey: string, level: number, result: DefaultOptionType[] = []) => {
    if (!rowkey) return [];

    const tenantObject = getObjectByRowkey<QpRowkey & { products: QpRowkeyProduct[] }>(qpRowkeys, rowkey, true);

    if (tenantObject) {
      result.push({ value: tenantObject.rowkey ?? "0", label: tenantObject.rowkey ?? "0" });

      if (level === 2) {
        tenantObject.products?.forEach(product => {
          result.push({ value: product.rowkey!, label: product.rowkey });
        });
      } else if (level === 3) {
        tenantObject.products?.forEach(product => {
          result.push({ value: product.rowkey!, label: product.rowkey });

          if (product.pcts) {
            product.pcts.forEach(pct => {
              result.push({ value: pct.rowkey!, label: pct.rowkey });
            });
          }
        });
      }
    }

    return result;
  };

  const mutableColumns = columns.map(col => {
    if (!col.editable) return col;

    return {
      ...col,
      onCell: (record: QpDashboard) => {
        let inputType: InputType = "text";
        let props;

        if (col.key === "rowkey.rowkey") {
          inputType = "select";

          props = {} as SelectProps;
          props.showSearch = true;
          props.filterOption = true;
          props.options = addRowkeys(qpRowkeys, currentRowkey ?? "0", record.maxLevel!);
        }

        if (col.key === "column.parmCode") {
          if (
            (record.parmCode !== "(null)" && record.parmCode !== undefined) ||
            (record?.parmType && record?.parmType !== "(null)")
          ) {
            inputType = "paramCodeSelect";
            props = {} as ParamCodeSelectProps;
            props.fiCode = currentRowkey!;
            props.paramType = record.parmType!;
            props.showSearch = true;
            props.popupMatchSelectWidth = false;
          } else {
            inputType = "none";
          }
        }

        if (col.key === "column.listValue") {
          switch (record.listValue) {
            case "Y":
              inputType = "select";

              props = {} as SelectProps;
              props.options = record.predefValues?.map(value => ({
                value: value.value,
                label: value.value,
                tooltip: value.description,
              }));

              props?.options?.push({
                value: "",
                label: emptyValue,
              });
              break;

            case "F":
              inputType = "text";

              props = {} as InputProps;
              props.disabled = true;
              break;

            default:
              inputType = "text";
              props = {} as InputProps;
          }

          if (record.listValue !== "Y" && record.listValue !== "F") {
            switch (record.dataType) {
              case "M":
                inputType = "number";

                props = {} as InputNumberProps;
                props.controls = false;
                props.precision = 2;
                props.parser = numberParser;
                props.formatter = numberFormatter;
                props.className = "w-p-100";

                props.suffix = (
                  <div className="currency-suffix" style={{ background: "white" }}>
                    {record.currency}
                  </div>
                );
                break;

              case "I":
                inputType = "number";
                props = {} as InputNumberProps;
                props.controls = false;
                props.precision = 0;
                break;

              case "N":
                inputType = "number";

                props = {} as InputNumberProps;
                props.controls = false;
                props.precision = 2;
                props.suffix = (
                  <div className="currency-suffix" style={{ background: "white" }}>
                    %
                  </div>
                );
                props.className = "w-p-100";
                break;

              case "S":
                inputType = "text";
                props = {} as InputProps;
                break;

              default:
                break;
            }
          }
        }

        return {
          record,
          inputType,
          props,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        };
      },
    };
  });

  const onClickEdit = (recordId: string) => {
    if (reviewMode || !currentRowkey) return;

    if (editingRowKey && form.isFieldsTouched()) {
      const record = data.find(x => x.id === editingRowKey)!;
      void save({ record, resetEditRowkey: false });
    }

    setEditingRowKey(recordId);
  };

  const handleTableKeys = (e: KeyboardEvent) => {
    if (reviewMode && !editingRowKey) return;

    switch (e.key) {
      case "Escape": {
        setEditingRowKey(null);
        break;
      }

      case "Enter": {
        if (!form.isFieldsTouched()) {
          setEditingRowKey(null);
          return;
        }

        const record = data.find(x => x.id === editingRowKey)!;
        void save({ record });

        break;
      }

      default:
    }
  };

  return (
    <Form form={form} component={false}>
      <Table<QpDashboard>
        fixed={true}
        className={styles[reviewMode ? "qp-confirm-table" : "qp-table"]}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        onRow={record => ({
          onClick: ({ target }: { target: EventTarget & { cellIndex: number } }) =>
            target.cellIndex && target.cellIndex !== 9 && onClickEdit(record.id!),
          onKeyDown: handleTableKeys,
          tabIndex: 0,
        })}
        rowKey="id"
        columns={mutableColumns}
        rowSelection={reviewMode && data?.length > 0 ? rowSelection : undefined}
        dataSource={data}
        onChange={handleTableChange}
        loading={isLoading}
        pagination={pagination}
        bordered={true}
        scroll={{ x: true }}
      />
    </Form>
  );
};
