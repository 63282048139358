import { FC, useEffect, useState } from "react";
import { Form, notification } from "antd";
import { useParams } from "react-router-dom";

import {
  B_PAYM_A_TOTAL_ENABLED,
  D_PAYM_A_TOTAL_ENABLED,
  D365_PAYM_A_TOTAL_ENABLED,
  E_PAYM_A_TOTAL_ENABLED,
  NIC_B_PAYM_A_TOTAL_AM,
  NIC_B_PAYM_A_TOTAL_NR,
  NIC_B_PAYM_A_TOTAL_SINGLE,
  NIC_D_PAYM_A_TOTAL_AM,
  NIC_D_PAYM_A_TOTAL_NR,
  NIC_D_PAYM_A_TOTAL_SINGLE,
  NIC_D365_PAYM_A_TOTAL_AM,
  NIC_D365_PAYM_A_TOTAL_NR,
  NIC_D365_PAYM_A_TOTAL_SINGLE,
  NIC_E_PAYM_A_TOTAL_AM,
  NIC_E_PAYM_A_TOTAL_NR,
  NIC_E_PAYM_A_TOTAL_SINGLE,
  PAYM_B_ENABLED,
  PAYM_D_ENABLED,
  PAYM_D365_ENABLED,
  PAYM_E_ENABLED,
  SERVICE_UNAVAILABLE,
} from "@ni/common/constants";
import { useProductSettings } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper } from "@ni/common/ui";
import { extractNumberFromString, getErrorInstance, parseBooleanOrNumber } from "@ni/common/utils";

import { LimitsBlock, RuleKeys } from "../../../../components";
import { usePct } from "../../../../hooks";
import { checkRuleKeyMatch, filterDisabledValues, getPaymentsEnabled } from "../../../../utils";

interface AccountLimitsPageProps {
  isCredit: boolean;
}

export const AccountLimitsPage: FC<AccountLimitsPageProps> = ({ isCredit }) => {
  const [form] = Form.useForm<FormValues>();

  const { id: tenantId, productId, pctId } = useParams<{ id: string; productId: string; pctId: string }>();
  const { currentProduct, productCurrency } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });

  const { pct, onSavePct } = usePct({
    pctId: parseInt(pctId ?? "0", 10),
  });

  const [existedRules, setExistedRules] = useState<RuleKeys[]>([]);

  const relatedLinks = [
    {
      href: `/tenant/${tenantId}/product/${currentProduct.id ?? 0}/pct/${pct.id ?? 0}/additional-rules`,
      label: `Additional rules configuration - ${pct?.displayName ?? ""} PCT`,
    },
  ];

  useEffect(() => {
    if (pct.pctProductValues) {
      const sortedExistedRules = (pct.pctProductValues ?? [])
        .filter(pctValue => checkRuleKeyMatch(pctValue.fieldCode, 1, 3))
        .map(pctValue => pctValue.fieldCode as RuleKeys)
        .sort((a, b) => (extractNumberFromString(a) ?? 0) - (extractNumberFromString(b) ?? 0));

      setExistedRules(sortedExistedRules);
    }
  }, [pct.pctProductValues]);

  useEffect(() => {
    pct.pctProductValues?.forEach(pctValue => {
      if (pctValue.fieldCode.includes("paym-a")) {
        form.setFieldsValue({
          [pctValue.fieldCode]: parseBooleanOrNumber(pctValue.value ?? ""),
        });
      }
    });
  }, [form, pct.pctProductValues]);

  const onFinish = async (values: FormValues): Promise<void> => {
    try {
      await onSavePct(filterDisabledValues(values, form));
    } catch (error) {
      const errorInstance = getErrorInstance(error);
      notification.error({
        placement: "topRight",
        duration: 3,
        message: (
          <div>
            {errorInstance?.response?.status ?? 400} <br />
            {SERVICE_UNAVAILABLE}
          </div>
        ),
      });
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle={`Account Limits – ${isCredit ? "Payment" : "Top-up"}`}
      pageSubtitle="Account limits govern payments to cards related to the account and payments to the account itself. Both primary and supplementary card loads contribute towards the account limits."
      submitHandler={onFinish}
      size="full"
      formSize="full"
      level="pct"
      submitLabel="Save"
      relatedLinks={relatedLinks}
      gap={8}
    >
      <LimitsBlock
        title="Per Day"
        tooltipText="Switch it off if restrictions are not intended per calendar day (from 00:00:00 to 23:59:59)."
        productCurrency={productCurrency}
        existedRules={existedRules}
        totalPaymentsEnabled={getPaymentsEnabled(PAYM_D_ENABLED, [
          D_PAYM_A_TOTAL_ENABLED,
          ...existedRules.map(rule => `d-paym-a-u0${extractNumberFromString(rule) ?? 0}-enabled`),
        ])}
        keys={{
          allFieldsEnabledKey: PAYM_D_ENABLED,
          totalEnabledKey: D_PAYM_A_TOTAL_ENABLED,
          totalNrKey: NIC_D_PAYM_A_TOTAL_NR,
          totalAmKey: NIC_D_PAYM_A_TOTAL_AM,
          totalSingleKey: NIC_D_PAYM_A_TOTAL_SINGLE,
        }}
        rulesKeyPrefix="d"
        limitType="a"
        form={form}
      />

      <LimitsBlock
        title="Per billing"
        tooltipText="Switch it off if restrictions are not intended per billing cycle. "
        productCurrency={productCurrency}
        existedRules={existedRules}
        totalPaymentsEnabled={getPaymentsEnabled(PAYM_B_ENABLED, [
          B_PAYM_A_TOTAL_ENABLED,
          ...existedRules.map(rule => `b-paym-a-u0${extractNumberFromString(rule) ?? 0}-enabled`),
        ])}
        keys={{
          allFieldsEnabledKey: PAYM_B_ENABLED,
          totalEnabledKey: B_PAYM_A_TOTAL_ENABLED,
          totalNrKey: NIC_B_PAYM_A_TOTAL_NR,
          totalAmKey: NIC_B_PAYM_A_TOTAL_AM,
          totalSingleKey: NIC_B_PAYM_A_TOTAL_SINGLE,
        }}
        rulesKeyPrefix="b"
        limitType="a"
        form={form}
      />

      <LimitsBlock
        title="Per 365 days"
        tooltipText="365 sliding days, means that the limiter calculates transactions for 365 days before the current transaction was made. Switch it off if restrictions are not intended"
        productCurrency={productCurrency}
        existedRules={existedRules.filter(rule => extractNumberFromString(rule) === 1)}
        totalPaymentsEnabled={getPaymentsEnabled(PAYM_D365_ENABLED, [
          D365_PAYM_A_TOTAL_ENABLED,
          ...existedRules.map(rule => `d365-paym-a-u0${extractNumberFromString(rule) ?? 0}-enabled`),
        ])}
        keys={{
          allFieldsEnabledKey: PAYM_D365_ENABLED,
          totalEnabledKey: D365_PAYM_A_TOTAL_ENABLED,
          totalNrKey: NIC_D365_PAYM_A_TOTAL_NR,
          totalAmKey: NIC_D365_PAYM_A_TOTAL_AM,
          totalSingleKey: NIC_D365_PAYM_A_TOTAL_SINGLE,
        }}
        rulesKeyPrefix="d365"
        limitType="a"
        form={form}
      />

      <LimitsBlock
        title="Forever"
        tooltipText="No frequency cycle is set for the limiter; counter contents accumulate constantly, and counters do not reset to zero. Switch it off if restrictions are not intended."
        productCurrency={productCurrency}
        existedRules={existedRules}
        totalPaymentsEnabled={getPaymentsEnabled(PAYM_E_ENABLED, [
          E_PAYM_A_TOTAL_ENABLED,
          ...existedRules.map(rule => `e-paym-a-u0${extractNumberFromString(rule) ?? 0}-enabled`),
        ])}
        keys={{
          allFieldsEnabledKey: PAYM_E_ENABLED,
          totalEnabledKey: E_PAYM_A_TOTAL_ENABLED,
          totalNrKey: NIC_E_PAYM_A_TOTAL_NR,
          totalAmKey: NIC_E_PAYM_A_TOTAL_AM,
          totalSingleKey: NIC_E_PAYM_A_TOTAL_SINGLE,
        }}
        rulesKeyPrefix="e"
        limitType="a"
        form={form}
      />
    </CustomFormWrapper>
  );
};
