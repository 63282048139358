import { SelectOption } from "@ni/common/types";

export const paymentMethodsOptions: SelectOption[] = [
  { value: "Visa", key: "Visa" },
  { value: "MasterCard", key: "MC" },
];

export const paymentMethodKeyToValueMapper: { [key: string]: string } = {
  MC: "MasterCard",
  Visa: "Visa",
};
