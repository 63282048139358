import { FC } from "react";
import { Form, InputNumber } from "antd";

import { QuestionCircleFilled } from "@ant-design/icons";
import { useHydrateForm } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, RadioGroup } from "@ni/common/ui";
import { numberFormatter, numberParser } from "@ni/common/utils";

import { useInsuranceWizardApi } from "../../hooks/useInsuranceWizardApi";

import { actionOption, coverageOption, insuranceCoverOption } from "./constants";

export const InsuranceCoveragePage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const { wizardResponse, processWizardRequest } = useInsuranceWizardApi();

  useHydrateForm({
    form,
    entityFields: (wizardResponse.insuranceTemplate?.values as { code: string; value: string }[]) ?? [],
    keys: {
      strings: [actionOption.code, coverageOption.code, insuranceCoverOption.code],
    },
  });

  const onFinish = (values: FormValues) => {
    void processWizardRequest({
      pageId: wizardResponse?.pageId as number,
      tenantId: wizardResponse.tenant?.id,
      insuranceTemplateId: wizardResponse.insuranceTemplate?.id,
      collectedValues: values as {
        [key: string]: string;
      },
    });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Insurance Coverage Settings"
      pageSubtitle="Specify the insured balances' eligible components, you can also identify maximum coverage amount which will impact the truncation indicator and the premium amount calculation"
      level="tenant"
      additionalRoute="insurance-program-templates"
      size="md"
      formSize="full"
      gap={40}
      submitHandler={onFinish}
    >
      <Form.Item
        name={coverageOption.code}
        label={coverageOption.name}
        tooltip={{
          title: coverageOption.tooltip,
          icon: <QuestionCircleFilled />,
        }}
      >
        <InputNumber
          addonAfter={wizardResponse.insuranceTemplate?.currency as string}
          min={coverageOption.min}
          precision={coverageOption.decimalsNumber}
          formatter={numberFormatter}
          parser={numberParser}
        />
      </Form.Item>

      <Form.Item
        name={insuranceCoverOption.code}
        label={insuranceCoverOption.name}
        initialValue={insuranceCoverOption.default}
      >
        <RadioGroup radioList={insuranceCoverOption.options} initialValue={insuranceCoverOption.default as string} />
      </Form.Item>

      <Form.Item
        name={actionOption.code}
        label={actionOption.name}
        initialValue={actionOption.default}
        tooltip={{
          title: actionOption.tooltip,
          icon: <QuestionCircleFilled />,
        }}
      >
        <RadioGroup radioList={actionOption.options} initialValue={actionOption.default as string} />
      </Form.Item>
    </CustomFormWrapper>
  );
};
