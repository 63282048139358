import { FC, useEffect } from "react";
import { Form, InputNumber, Space, Switch } from "antd";

import { QuestionCircleFilled } from "@ant-design/icons";
import {
  LTY_PNT_MODE,
  LTY_POINT_MODE_RADIO_GROUP,
  LTY_REFUND_MODE,
  NIC_LTY_ACCR_ROUNDING_DECIMALS,
} from "@ni/common/constants";
import { useReduxState } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, RadioGroup, TooltipInfo } from "@ni/common/ui";
import { validateNumberInput } from "@ni/common/utils";
import { LoyaltyWizardRequest, LoyaltyWizardResponse } from "@ni/sdk/models";

import { useLoyaltyWizardApi } from "../../hooks";

interface LoyaltyPointsPageProps {
  formDisabled: boolean;
}

export const LoyaltyPointsPage: FC<LoyaltyPointsPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const [, setIsLoading] = useReduxState("isLoading", false);
  const [wizardResponse] = useReduxState<LoyaltyWizardResponse>("loyaltyWizard", {} as LoyaltyWizardResponse);

  const { processWizardRequest } = useLoyaltyWizardApi();

  useEffect(() => {
    if (wizardResponse.loyaltyProgramTemplate) {
      const fieldNames = Object.keys(form.getFieldsValue());
      wizardResponse.loyaltyProgramTemplate.programValues?.forEach(programValue => {
        if (fieldNames.includes(programValue.code!)) {
          form.setFieldValue(programValue.code!, programValue.value);
        }
      });
    }
  }, [form, wizardResponse.loyaltyProgramTemplate]);

  const onFinish = async (values: FormValues) => {
    setIsLoading(true);
    try {
      await processWizardRequest({
        pageId: wizardResponse.pageId as number,
        tenantId: wizardResponse.tenant?.id,
        loyaltyTemplateId: wizardResponse.loyaltyProgramTemplate?.id as number,
        collectedValues: values as LoyaltyWizardRequest["collectedValues"],
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Loyalty Points"
      pageSubtitle="When a cardholder makes purchases, they earn points based on the count or amount of transaction which can then be redeemed for a value."
      size="sm"
      level="tenant"
      submitHandler={onFinish}
      additionalRoute="loyalty-program-templates"
    >
      <Form.Item name={LTY_PNT_MODE} label="Store loyalty points in" initialValue={LTY_POINT_MODE_RADIO_GROUP[0].value}>
        <RadioGroup radioList={LTY_POINT_MODE_RADIO_GROUP} initialValue={LTY_POINT_MODE_RADIO_GROUP[0].value} />
      </Form.Item>

      <Form.Item
        name={NIC_LTY_ACCR_ROUNDING_DECIMALS}
        rules={[
          () => ({
            validator(_, value) {
              return validateNumberInput(value as string | number | undefined, false);
            },
          }),
        ]}
        tooltip={{
          title:
            'Default is rounding to "cents" - fractional units of the currency used for points. 0 will round to basic unit of the currency, 1 - to 0.1, -1 to tens etc.',
          icon: <QuestionCircleFilled />,
        }}
        label="Number of decimals to round points on accrual"
        initialValue={2}
      >
        <InputNumber />
      </Form.Item>
      <Space direction="horizontal">
        <Form.Item name={LTY_REFUND_MODE} valuePropName="checked" initialValue={false}>
          <Switch style={{ padding: "0" }} />
        </Form.Item>
        <TooltipInfo
          label="Deduct points for refund transactions"
          tooltipProps={{
            title:
              "Considering that refunds are not linked to the original transaction, points could be deducted even in case when original transaction might have accrued zero points due to capping or similar.",
          }}
        />
      </Space>
    </CustomFormWrapper>
  );
};
