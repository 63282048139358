export const deliveryChannel: { name: string; value: string }[] = [
  {
    name: "Tenant SMS Gateway",
    value: "TIBCO",
  },
  {
    name: "Network International SMS Gateway",
    value: "M",
  },
];
