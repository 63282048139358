import { FC, RefObject, useEffect, useImperativeHandle, useState } from "react";
import { Checkbox, Col, Form, Input, InputNumber, Modal, Row, Select, Space, Switch } from "antd";
import cn from "classnames";
import { useParams } from "react-router-dom";

import { QuestionCircleFilled } from "@ant-design/icons";
import {
  blockCodeValuesAndLabels,
  booleanFields,
  evaluationLinks,
  FieldValue,
  ProductBcParams,
  SHOULD_NOT_BE_EMPTY,
  tableCellValues,
  TableParams,
} from "@ni/common/constants";
import { useReduxState } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { Tabs, TooltipInfo } from "@ni/common/ui";
import { checkIfOneProductHasFieldCodeValue } from "@ni/common/utils";
import { TenantApi } from "@ni/sdk/apis";
import { BlockCode, ChangeTenantRequest, Tenant } from "@ni/sdk/models";

import { PPRIORITY_SHOULD_NOT_BE_MORE_THAN_99 } from "../../../constants";

import styles from "./styles.module.scss";

const tenantsServiceApi = new TenantApi();

interface BlockCodeEditingProps {
  blockCode: string;
  saveDefaultHandler: RefObject<() => void>;
  saveHandler: RefObject<() => void>;
  formErrorHandler?: (hasError: boolean) => void;
}

export const BlockCodeEditing: FC<BlockCodeEditingProps> = ({
  blockCode,
  saveDefaultHandler,
  saveHandler,
  formErrorHandler,
}) => {
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const [tenant, setTenant] = useReduxState<Tenant>("tenant", {});
  const [isLoading, setIsLoading] = useReduxState<boolean>("isLoading");
  const [modalVisible, setModalVisible] = useState(false);
  const [tableHeaderError, setTableHeaderError] = useState<string>("");
  const [isA1SwitchedOn, setIsA1SwitchedOn] = useState<boolean>();
  const [isA2SwitchedOn, setIsA2SwitchedOn] = useState<boolean>();
  const [isA3SwitchedOn, setIsA3SwitchedOn] = useState<boolean>();

  const isAccountAutomaticlyEnabled = Form.useWatch<boolean>("nic-bc-a1-allow", form);
  const delinquencyLevel = Form.useWatch<string>(ProductBcParams.delinquencyLevel, form);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const blockCodeValues = tenant.blockCodes?.filter(item => item.code === blockCode);

    const fieldsValues: { [index: string]: string | boolean } = {};
    Object.values(ProductBcParams).forEach(value => {
      const formValue = blockCodeValues?.find(item => item.fieldCode === value)?.value || "";

      if (booleanFields.includes(value)) {
        fieldsValues[value] = formValue === "true" || formValue === "Y";
      } else {
        fieldsValues[value] = formValue;
      }
    });

    if (!fieldsValues[ProductBcParams.insurancePrograms]) {
      const manualCodesSet = new Set(["b", "c", "d", "e", "h", "y", "z"]);
      if (manualCodesSet.has(blockCode?.toLocaleLowerCase())) fieldsValues[ProductBcParams.insurancePrograms] = "M";
      else if (blockCode?.toLocaleLowerCase() === "x") fieldsValues[ProductBcParams.insurancePrograms] = "A";
      else fieldsValues[ProductBcParams.insurancePrograms] = "N";
    }

    evaluationLinks.forEach(link => {
      let result = "";

      link.value.forEach(fieldCode => {
        const formValue = blockCodeValues?.find(item => item.fieldCode === fieldCode)?.value || "";

        result += formValue === "true" || formValue === "Y" ? "1" : "0";
      });

      fieldsValues[link.key] = result;
    });
    setIsA1SwitchedOn(fieldsValues[ProductBcParams.a1] as boolean);
    setIsA2SwitchedOn(fieldsValues[ProductBcParams.a2] as boolean);
    setIsA3SwitchedOn(fieldsValues[ProductBcParams.a3] as boolean);

    form.setFieldsValue(fieldsValues);
    if (
      !(fieldsValues[ProductBcParams.a1] as boolean) &&
      !(fieldsValues[ProductBcParams.a2] as boolean) &&
      !(fieldsValues[ProductBcParams.a3] as boolean)
    ) {
      setTableHeaderError("At least one option is mandatory");
    }
  }, [blockCode, form, tenant]);

  const handleErrorCheck = (_: FormValues, values: FormValues) => {
    if (formErrorHandler && form) {
      const name: string = values["nic-bc-name"] as string;
      const priority: string = values["nic-bc-prior"] as string;
      const isApplied: boolean =
        !!values[ProductBcParams.a1] || !!values[ProductBcParams.a2] || !!values[ProductBcParams.a3];

      formErrorHandler(!(!!name && !!priority && isApplied));
    }
  };

  const onFinish = () => {
    form
      .validateFields()
      .then(() => {
        setIsLoading(true);
        const tenantId = parseInt(id ?? "0", 10);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const value: FormValues = form.getFieldsValue();

        const requestBody: ChangeTenantRequest = {
          blockCodes: Object.keys(value).map((val: string) => {
            return {
              code: blockCode,
              fieldCode: val,
              value: value[val] as string,
            };
          }),
        };

        if (requestBody) {
          requestBody.blockCodes = [
            ...((requestBody.blockCodes as BlockCode[]) || []),
            {
              code: blockCode,
              fieldCode: ProductBcParams.alreadyEdited,
              value: "true",
            },
          ];
        }

        Object.values(TableParams).forEach(cellValue => {
          const map = evaluationLinks.find(item => item.key === cellValue) || { value: [] };
          const setValue = form.getFieldValue(cellValue)[0] === "1" ? "true" : "false";
          const remValue = form.getFieldValue(cellValue)[1] === "1" ? "true" : "false";

          if (requestBody) {
            requestBody.blockCodes = [
              ...((requestBody.blockCodes as BlockCode[]) || []),
              {
                code: blockCode,
                fieldCode: map.value[0],
                value: setValue,
              },
              {
                code: blockCode,
                fieldCode: map.value[1],
                value: remValue,
              },
            ];
          }
        });

        tenantsServiceApi
          .editTenant(requestBody, tenantId)
          .then(res => {
            setTenant(res.data);
            setIsLoading(false);
          })
          .catch(() => setIsLoading(false));
      })
      .catch(() => setIsLoading(false));
  };

  useImperativeHandle(saveHandler, () => onFinish, []);

  const onDefaultClickHandler = () => {
    setModalVisible(false);
    setIsLoading(true);

    const tenantId = parseInt(id ?? "0", 10);
    let blockCodes: BlockCode[] = [];

    tenantsServiceApi
      .getDefaultBlockCodesByCode(blockCode)
      .then(resp => {
        resp.data.forEach(bc => {
          blockCodes = [
            ...blockCodes,
            {
              code: bc.code,
              fieldCode: bc.fieldCode,
              value: bc.value,
            },
          ];
        });

        blockCodes = [
          ...blockCodes,
          {
            code: blockCode,
            fieldCode: ProductBcParams.alreadyEdited,
            value: "false",
          },
        ];

        const requestBody: ChangeTenantRequest = {
          blockCodes,
        };

        tenantsServiceApi
          .editTenant(requestBody, tenantId)
          .then(res => {
            setTenant(res.data);
            setIsLoading(false);
          })
          .catch(() => setIsLoading(false));

        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };

  const confirmRestoreToDefault = () => {
    setModalVisible(true);
  };

  useImperativeHandle(saveDefaultHandler, () => confirmRestoreToDefault, []);

  const cancelRestoreToDefault = () => {
    setModalVisible(false);
  };

  const blockCodeValuesAndLabelsMap = (options: FieldValue[] | undefined) => {
    return options?.map(({ value, displayed, tooltip }) => (
      <Select.Option key={value + displayed} value={value}>
        <TooltipInfo label={displayed} tooltipProps={tooltip ? { title: tooltip } : {}} />
      </Select.Option>
    ));
  };

  return (
    <div className={styles["block-codes-editing-wrapper"]}>
      <Modal
        title="Return to defaults"
        open={modalVisible}
        okText="Confirm"
        cancelText="Cancel"
        width="570px"
        onOk={onDefaultClickHandler}
        onCancel={cancelRestoreToDefault}
      >
        Restoring default settings will remove all your changes.
      </Modal>
      <Form
        form={form}
        className={styles["block-codes-editing-container"]}
        layout="vertical"
        onValuesChange={handleErrorCheck}
      >
        <Tabs
          pagesList={[
            {
              key: "code-settings",
              label: <>Code Settings</>,
              children: (
                <>
                  <div className={styles["section-title"]}>Code settings</div>
                  <Form.Item
                    className={styles["block-codes-editing-status-select-item"]}
                    name={ProductBcParams.name}
                    label="Name"
                    required={true}
                    rules={[
                      () => ({
                        validator(_, value) {
                          if ((value as string).length === 0) {
                            // eslint-disable-next-line prefer-promise-reject-errors
                            return Promise.reject<string>(" Should not be empty");
                          }
                          if ((value as string).length > 42) {
                            // eslint-disable-next-line prefer-promise-reject-errors
                            return Promise.reject<string>(" Max length is 42 letters");
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                    tooltip={{
                      title: "Block code name is intended for describing what the meaning of particular block code is",
                      icon: <QuestionCircleFilled />,
                    }}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    className={styles["block-codes-editing-status-select-item"]}
                    name={ProductBcParams.priority}
                    required={true}
                    label="Priority"
                    rules={[
                      () => ({
                        validator(_, value: number) {
                          if (value === undefined || value === null) {
                            return Promise.reject<string>(SHOULD_NOT_BE_EMPTY);
                          }
                          if (value > 99) {
                            // eslint-disable-next-line prefer-promise-reject-errors
                            return Promise.reject<string>(PPRIORITY_SHOULD_NOT_BE_MORE_THAN_99);
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                    tooltip={{
                      title:
                        "This parameter specifies the priority of the block code. Each block code is associated with block priority varies from 00 to 99. Lower the number has the lower priority. Less priority blocks cannot be overridden by highest priority block codes",
                      icon: <QuestionCircleFilled />,
                    }}
                  >
                    <InputNumber min={0} />
                  </Form.Item>
                  <Form.Item
                    className={styles["block-codes-editing-status-select-item"]}
                    name={ProductBcParams.authorisation}
                    label="Authorisation response"
                    tooltip={{
                      title:
                        "Authorization approve or decline or response for the auth will be decided based on this block code",
                      icon: <QuestionCircleFilled />,
                    }}
                  >
                    <Select>
                      {blockCodeValuesAndLabels.get(ProductBcParams.authorisation)?.map(({ value, displayed }) => (
                        <Select.Option key={value + displayed} value={value}>
                          {`${value} ${displayed}`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <div className={styles["block-codes-table"]}>
                    <div className={styles["block-codes-table-header"]}>
                      <Row>
                        <Col span={6}>
                          <div className={styles["table-first-subheader"]}>
                            <TooltipInfo
                              label="Could be applied on:"
                              tooltipProps={{
                                title:
                                  "There are 3 placeholders for block codes: two placeholders at account level (system-driven and manual) and one at card level. First select placeholder and then select the channel the block code for appropriate placeholder could be applied via. Leave it empty if the channel is not intendent to be used",
                              }}
                            />
                          </div>
                        </Col>
                        <Col span={6}>
                          <Space>
                            <Form.Item
                              valuePropName="checked"
                              name={ProductBcParams.a1}
                              rules={[
                                () => ({
                                  validator(_, value) {
                                    setIsA1SwitchedOn(value as boolean);
                                    if (
                                      form.getFieldValue(ProductBcParams.a3) === false &&
                                      form.getFieldValue(ProductBcParams.a2) === false &&
                                      value === false
                                    ) {
                                      setTableHeaderError("At least one option is mandatory");
                                      // eslint-disable-next-line prefer-promise-reject-errors
                                      return Promise.reject<string>();
                                    }
                                    setTableHeaderError("");
                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                            >
                              <Checkbox />
                            </Form.Item>
                            <div className={styles["table-subheader"]}>Account automatically</div>
                          </Space>
                        </Col>
                        <Col span={6} className={styles["second-column"]}>
                          <Space>
                            <Form.Item
                              valuePropName="checked"
                              name={ProductBcParams.a2}
                              rules={[
                                () => ({
                                  validator(_, value) {
                                    setIsA2SwitchedOn(value as boolean);
                                    if (
                                      form.getFieldValue(ProductBcParams.a3) === false &&
                                      form.getFieldValue(ProductBcParams.a1) === false &&
                                      value === false
                                    ) {
                                      setTableHeaderError("At least one option is mandatory");
                                      // eslint-disable-next-line prefer-promise-reject-errors
                                      return Promise.reject<string>();
                                    }
                                    setTableHeaderError("");
                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                            >
                              <Checkbox />
                            </Form.Item>
                            <div className={styles["table-subheader"]}>Account manually</div>
                          </Space>
                        </Col>
                        <Col span={6} className={styles["third-column"]}>
                          <Space>
                            <Form.Item
                              valuePropName="checked"
                              name={ProductBcParams.a3}
                              rules={[
                                () => ({
                                  validator(_, value) {
                                    setIsA3SwitchedOn(value as boolean);
                                    if (
                                      form.getFieldValue(ProductBcParams.a1) === false &&
                                      form.getFieldValue(ProductBcParams.a2) === false &&
                                      value === false
                                    ) {
                                      setTableHeaderError("At least one option is mandatory");
                                      // eslint-disable-next-line prefer-promise-reject-errors
                                      return Promise.reject<string>();
                                    }
                                    setTableHeaderError("");
                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                            >
                              <Checkbox />
                            </Form.Item>
                            <div className={styles["table-subheader"]}>Card</div>
                          </Space>
                        </Col>
                      </Row>
                    </div>
                    <Row hidden={tableHeaderError.length === 0}>
                      <Col span={6}>
                        <div className={styles["table-error"]}>{tableHeaderError}</div>
                      </Col>
                      <Col span={6} />
                      <Col span={6} />
                      <Col span={6} />
                    </Row>
                    <Row>
                      <Col span={6}>
                        <div className={styles["row-name"]}>
                          <div className={styles["via"]}>via</div>
                          <div>Customer support screen</div>
                        </div>
                      </Col>
                      <Col span={6}>
                        <Form.Item noStyle={true} dependencies={[ProductBcParams.a1]}>
                          {() => {
                            return (
                              <Form.Item className={styles["cell-wrapper"]} name={TableParams.a1Man}>
                                <Select disabled={!form.getFieldValue(ProductBcParams.a1)}>
                                  {tableCellValues?.map(({ value, displayed }) => (
                                    <Select.Option key={value + displayed} value={value}>
                                      {displayed}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item noStyle={true} dependencies={[ProductBcParams.a2]}>
                          {() => {
                            return (
                              <Form.Item className={styles["cell-wrapper"]} name={TableParams.a2Man}>
                                <Select disabled={!form.getFieldValue(ProductBcParams.a2)}>
                                  {tableCellValues?.map(({ value, displayed }) => (
                                    <Select.Option key={value + displayed} value={value}>
                                      {displayed}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item noStyle={true} dependencies={[ProductBcParams.a3]}>
                          {() => {
                            return (
                              <Form.Item className={styles["cell-wrapper"]} name={TableParams.a3Man}>
                                <Select disabled={!form.getFieldValue(ProductBcParams.a3)}>
                                  {tableCellValues?.map(({ value, displayed }) => (
                                    <Select.Option key={value + displayed} value={value}>
                                      {displayed}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={6}>
                        <div className={styles["row-name"]}>
                          <div className={styles["via"]}>via</div>
                          <div>API by Client-driven application (e.g. Online banking)</div>
                        </div>
                      </Col>
                      <Col span={6}>
                        <Form.Item noStyle={true} dependencies={[ProductBcParams.a1]}>
                          {() => {
                            return (
                              <Form.Item className={styles["cell-wrapper"]} name={TableParams.a1Tib1}>
                                <Select disabled={!form.getFieldValue(ProductBcParams.a1)}>
                                  {tableCellValues?.map(({ value, displayed }) => (
                                    <Select.Option key={value + displayed} value={value}>
                                      {displayed}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item noStyle={true} dependencies={[ProductBcParams.a2]}>
                          {() => {
                            return (
                              <Form.Item className={styles["cell-wrapper"]} name={TableParams.a2Tib1}>
                                <Select disabled={!form.getFieldValue(ProductBcParams.a2)}>
                                  {tableCellValues?.map(({ value, displayed }) => (
                                    <Select.Option key={value + displayed} value={value}>
                                      {displayed}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item noStyle={true} dependencies={[ProductBcParams.a3]}>
                          {() => {
                            return (
                              <Form.Item className={styles["cell-wrapper"]} name={TableParams.a3Tib1}>
                                <Select disabled={!form.getFieldValue(ProductBcParams.a3)}>
                                  {tableCellValues?.map(({ value, displayed }) => (
                                    <Select.Option key={value + displayed} value={value}>
                                      {displayed}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={6}>
                        <div className={styles["row-name"]}>
                          <div className={styles["via"]}>via</div>
                          <div>API by Issuer-driven application (e.g. CRM)</div>
                        </div>
                      </Col>
                      <Col span={6}>
                        <Form.Item noStyle={true} dependencies={[ProductBcParams.a1]}>
                          {() => {
                            return (
                              <Form.Item className={styles["cell-wrapper"]} name={TableParams.a1Tib2}>
                                <Select disabled={!form.getFieldValue(ProductBcParams.a1)}>
                                  {tableCellValues?.map(({ value, displayed }) => (
                                    <Select.Option key={value + displayed} value={value}>
                                      {displayed}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item noStyle={true} dependencies={[ProductBcParams.a2]}>
                          {() => {
                            return (
                              <Form.Item className={styles["cell-wrapper"]} name={TableParams.a2Tib2}>
                                <Select disabled={!form.getFieldValue(ProductBcParams.a2)}>
                                  {tableCellValues?.map(({ value, displayed }) => (
                                    <Select.Option key={value + displayed} value={value}>
                                      {displayed}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item noStyle={true} dependencies={[ProductBcParams.a3]}>
                          {() => {
                            return (
                              <Form.Item className={styles["cell-wrapper"]} name={TableParams.a3Tib2}>
                                <Select disabled={!form.getFieldValue(ProductBcParams.a3)}>
                                  {tableCellValues?.map(({ value, displayed }) => (
                                    <Select.Option key={value + displayed} value={value}>
                                      {displayed}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={6}>
                        <div className={styles["row-name"]}>
                          <div className={styles["via"]}>via</div>
                          <div>File application</div>
                        </div>
                      </Col>
                      <Col span={6}>
                        <Form.Item noStyle={true} dependencies={[ProductBcParams.a1]}>
                          {() => {
                            return (
                              <Form.Item className={styles["cell-wrapper"]} name={TableParams.a1Fa}>
                                <Select disabled={!form.getFieldValue(ProductBcParams.a1)}>
                                  {tableCellValues?.map(({ value, displayed }) => (
                                    <Select.Option key={value + displayed} value={value}>
                                      {displayed}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item noStyle={true} dependencies={[ProductBcParams.a2]}>
                          {() => {
                            return (
                              <Form.Item className={styles["cell-wrapper"]} name={TableParams.a2Fa}>
                                <Select disabled={!form.getFieldValue(ProductBcParams.a2)}>
                                  {tableCellValues?.map(({ value, displayed }) => (
                                    <Select.Option key={value + displayed} value={value}>
                                      {displayed}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item noStyle={true} dependencies={[ProductBcParams.a3]}>
                          {() => {
                            return (
                              <Form.Item className={styles["cell-wrapper"]} name={TableParams.a3Fa}>
                                <Select disabled={!form.getFieldValue(ProductBcParams.a3)}>
                                  {tableCellValues?.map(({ value, displayed }) => (
                                    <Select.Option key={value + displayed} value={value}>
                                      {displayed}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={6}>
                        <div className={styles["row-name"]}>
                          <div className={styles["via"]}>via</div>
                          <div>Screen application</div>
                        </div>
                      </Col>
                      <Col span={6}>
                        <Form.Item noStyle={true} dependencies={[ProductBcParams.a1]}>
                          {() => {
                            return (
                              <Form.Item className={styles["cell-wrapper"]} name={TableParams.a1Sa}>
                                <Select disabled={!form.getFieldValue(ProductBcParams.a1)}>
                                  {tableCellValues?.map(({ value, displayed }) => (
                                    <Select.Option key={value + displayed} value={value}>
                                      {displayed}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item noStyle={true} dependencies={[ProductBcParams.a2]}>
                          {() => {
                            return (
                              <Form.Item className={styles["cell-wrapper"]} name={TableParams.a2Sa}>
                                <Select disabled={!form.getFieldValue(ProductBcParams.a2)}>
                                  {tableCellValues?.map(({ value, displayed }) => (
                                    <Select.Option key={value + displayed} value={value}>
                                      {displayed}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item noStyle={true} dependencies={[ProductBcParams.a3]}>
                          {() => {
                            return (
                              <Form.Item className={styles["cell-wrapper"]} name={TableParams.a3Sa}>
                                <Select disabled={!form.getFieldValue(ProductBcParams.a3)}>
                                  {tableCellValues?.map(({ value, displayed }) => (
                                    <Select.Option key={value + displayed} value={value}>
                                      {displayed}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </>
              ),
            },
            {
              key: "assignment-conditions",
              label: <>Assignment Conditions</>,
              dependency: isA1SwitchedOn || isA3SwitchedOn || isA2SwitchedOn,
              children: (
                <>
                  <div className={styles["section-title"]}>
                    <TooltipInfo
                      largeLabel="Assignment conditions"
                      tooltipProps={{
                        title:
                          "Some factors could trigger automatic assigning of block codes to card or to account. You can specify these factors here",
                      }}
                    />
                  </div>
                  <Space direction="horizontal" className="m-b-16 w-p-100" size={8}>
                    <Form.Item
                      className={cn(styles["block-codes-editing-status-select-item"], "m-b-0")}
                      name={ProductBcParams.accountHolder}
                      valuePropName="checked"
                    >
                      <Switch />
                    </Form.Item>
                    Assigned due to primary client ID expiration (account holder)
                  </Space>
                  <Space direction="horizontal" hidden={!isA3SwitchedOn} className="m-b-16 w-p-100" size={8}>
                    <Form.Item
                      className={cn(styles["block-codes-editing-status-select-item"], "m-b-0")}
                      name={ProductBcParams.cardHolder}
                      valuePropName="checked"
                    >
                      <Switch />
                    </Form.Item>
                    Assigned due to client ID expiration (card holder)
                  </Space>
                  <Form.Item
                    className={styles["block-codes-editing-status-select-item"]}
                    name={ProductBcParams.cardStatus}
                    label="Assigned by card status"
                    hidden={!isA3SwitchedOn}
                    tooltip={{
                      title: "Defines how card status can influence this block code",
                      icon: <QuestionCircleFilled />,
                    }}
                  >
                    <Select>
                      {blockCodeValuesAndLabelsMap(blockCodeValuesAndLabels.get(ProductBcParams.cardStatus))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className={styles["block-codes-editing-status-select-item"]}
                    name={ProductBcParams.delinquencyLevel}
                    label="Assigned due to delinquency level"
                    hidden={
                      !checkIfOneProductHasFieldCodeValue(tenant, "balance-owner", "CMS") ||
                      !checkIfOneProductHasFieldCodeValue(tenant, "product-type", "Credit") ||
                      !(form.getFieldValue(ProductBcParams.a1) as boolean)
                    }
                    tooltip={{
                      title:
                        "This is the automated delinquency block. Once the account reaches to the delinquency level defined in this parameter then corresponding block code value will be applied on account automatically by the system based on block priority.",
                      icon: <QuestionCircleFilled />,
                    }}
                  >
                    <Select>
                      {blockCodeValuesAndLabelsMap(blockCodeValuesAndLabels.get(ProductBcParams.delinquencyLevel))}
                    </Select>
                  </Form.Item>
                  {isAccountAutomaticlyEnabled && delinquencyLevel !== "" && (
                    <Space className={styles["block-codes-switch-form-item"]} size={8}>
                      <Form.Item
                        className={styles["block-codes-editing-status-select-item"]}
                        name={ProductBcParams.blockCodeIsPermanent}
                        valuePropName="checked"
                      >
                        <Switch />
                      </Form.Item>
                      <div>Delinquency block code is permanent</div>
                      <TooltipInfo
                        tooltipProps={{
                          title:
                            "The parameter specifies whether the delinquency block is permanent or removable. A permanent block will not be removed automatically even if the delinquency is reduced. Permanent block can be overridden by a higher delinquency automatic block code or can be removed manually.",
                        }}
                      />
                    </Space>
                  )}
                  <Form.Item
                    className={styles["block-codes-editing-status-select-item"]}
                    name={ProductBcParams.falconBlock}
                    label="Assigned by falcon block"
                    hidden={!isA3SwitchedOn || !tenant.isFalconSelected}
                    tooltip={{
                      title:
                        "Falcon is the Fraud monitoring system, and it can send block, unblock requests to apply on the account based on rules defined in the falcon system",
                      icon: <QuestionCircleFilled />,
                    }}
                  >
                    <Select>
                      {blockCodeValuesAndLabelsMap(blockCodeValuesAndLabels.get(ProductBcParams.falconBlock))}
                    </Select>
                  </Form.Item>
                </>
              ),
            },
            {
              key: "affected-features",
              label: <>Affected Features</>,
              children: (
                <>
                  <div className={styles["section-title"]}>Affected features</div>
                  <Form.Item
                    className={styles["block-codes-editing-status-select-item"]}
                    name={ProductBcParams.reissue}
                    label="Card issue/reissue"
                  >
                    <Select>
                      {blockCodeValuesAndLabelsMap(blockCodeValuesAndLabels.get(ProductBcParams.reissue))}
                    </Select>
                  </Form.Item>
                  <Space direction="horizontal" className="m-b-16 w-p-100" size={8}>
                    <Form.Item valuePropName="checked" name={ProductBcParams.activation} className="m-b-0">
                      <Switch />
                    </Form.Item>
                    Card activation allowed
                  </Space>
                  <Space direction="horizontal" className="m-b-16 w-p-100" size={8}>
                    <Form.Item valuePropName="checked" name={ProductBcParams.autoReplacement} className="m-b-0">
                      <Switch />
                    </Form.Item>
                    Card auto-replacement triggered
                  </Space>
                  <Space
                    direction="horizontal"
                    className="m-b-16 w-p-100"
                    hidden={!tenant.isCardControlSelected}
                    size={8}
                  >
                    <Form.Item valuePropName="checked" name={ProductBcParams.control} className="m-b-0">
                      <Switch />
                    </Form.Item>
                    Card control enrollment
                  </Space>
                  <Form.Item
                    className={styles["block-codes-editing-status-select-item"]}
                    name={ProductBcParams.token}
                    label="Token Action"
                    hidden={
                      !checkIfOneProductHasFieldCodeValue(tenant, "nic-card-subtype-token-used", "true") &&
                      !checkIfOneProductHasFieldCodeValue(tenant, "nic-card-subtype-token-mada-used", "true")
                    }
                  >
                    <Select>{blockCodeValuesAndLabelsMap(blockCodeValuesAndLabels.get(ProductBcParams.token))}</Select>
                  </Form.Item>
                  <Form.Item
                    className={styles["block-codes-editing-status-select-item"]}
                    name={ProductBcParams.agingCalculation}
                    label="Due aging and calculation"
                    hidden={
                      !checkIfOneProductHasFieldCodeValue(tenant, "balance-owner", "CMS") ||
                      (form.getFieldValue("nic-bc-a1-allow") === false &&
                        form.getFieldValue("nic-bc-a2-allow") === false)
                    }
                    tooltip={{
                      title: "The feature controls the Minimum To Pay (MTP) calculation and aging for credit cards",
                      icon: <QuestionCircleFilled />,
                    }}
                  >
                    <Select>
                      {blockCodeValuesAndLabelsMap(blockCodeValuesAndLabels.get(ProductBcParams.agingCalculation))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className={styles["block-codes-editing-status-select-item"]}
                    name={ProductBcParams.bureauReporting}
                    label="Credit Bureau reporting"
                    hidden={
                      !checkIfOneProductHasFieldCodeValue(tenant, "balance-owner", "CMS") ||
                      (form.getFieldValue("nic-bc-a1-allow") === false &&
                        form.getFieldValue("nic-bc-a2-allow") === false)
                    }
                    tooltip={{
                      title:
                        "This feature specifies whether report account to credit bureau as part of credit bureau reports or not if certain block code is applied",
                      icon: <QuestionCircleFilled />,
                    }}
                  >
                    <Select>
                      {blockCodeValuesAndLabelsMap(blockCodeValuesAndLabels.get(ProductBcParams.bureauReporting))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className={styles["block-codes-editing-status-select-item"]}
                    name={ProductBcParams.directDebit}
                    label="Direct debit"
                    hidden={
                      !checkIfOneProductHasFieldCodeValue(tenant, "balance-owner", "CMS") ||
                      (form.getFieldValue("nic-bc-a1-allow") === false &&
                        form.getFieldValue("nic-bc-a2-allow") === false)
                    }
                    tooltip={{
                      title:
                        "This feature is to control the Direct debit functionality if certain block code is applied",
                      icon: <QuestionCircleFilled />,
                    }}
                  >
                    <Select>
                      {blockCodeValuesAndLabelsMap(blockCodeValuesAndLabels.get(ProductBcParams.directDebit))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className={styles["block-codes-editing-status-select-item"]}
                    name={ProductBcParams.pointsAccrual}
                    label="Loyalty points accrual"
                    tooltip={{
                      title:
                        "This feature controls the accrual of loyalty points or requests their write-off  once certain block code is applied",
                      icon: <QuestionCircleFilled />,
                    }}
                  >
                    <Select>
                      {blockCodeValuesAndLabelsMap(blockCodeValuesAndLabels.get(ProductBcParams.pointsAccrual))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className={styles["block-codes-editing-status-select-item"]}
                    name={ProductBcParams.pointsRedemption}
                    label="Loyalty points redemption"
                    tooltip={{
                      title: "This feature defines the redemption of loyalty points once certain block code is applied",
                      icon: <QuestionCircleFilled />,
                    }}
                  >
                    <Select>
                      {blockCodeValuesAndLabelsMap(blockCodeValuesAndLabels.get(ProductBcParams.pointsRedemption))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className={styles["block-codes-editing-status-select-item"]}
                    name={ProductBcParams.insurancePrograms}
                    label="Insurance Programs"
                    tooltip={{
                      title: "This feature contains the insurance functionality behavior incase certain block code",
                      icon: <QuestionCircleFilled />,
                    }}
                  >
                    <Select>
                      {blockCodeValuesAndLabelsMap(blockCodeValuesAndLabels.get(ProductBcParams.insurancePrograms))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className={styles["block-codes-editing-status-select-item"]}
                    name={ProductBcParams.agreement}
                    label="Cardholder agreement"
                    tooltip={{
                      title:
                        "This feature specifies the Status of Cardholder agreement specifically for cancelled cards reports and cancellation charge",
                      icon: <QuestionCircleFilled />,
                    }}
                  >
                    <Select>
                      {blockCodeValuesAndLabelsMap(blockCodeValuesAndLabels.get(ProductBcParams.agreement))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className={styles["block-codes-editing-status-select-item"]}
                    name={ProductBcParams.fcPosting}
                    label="Financial transactions posting"
                    hidden={!checkIfOneProductHasFieldCodeValue(tenant, "balance-owner", "CMS")}
                  >
                    <Select>
                      {blockCodeValuesAndLabelsMap(blockCodeValuesAndLabels.get(ProductBcParams.fcPosting))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className={styles["block-codes-editing-status-select-item"]}
                    name={ProductBcParams.sms}
                    label="SMS notification"
                    tooltip={{
                      title: "This feature defines the blocking of SMS notifications for certain block codes",
                      icon: <QuestionCircleFilled />,
                    }}
                  >
                    <Select>{blockCodeValuesAndLabelsMap(blockCodeValuesAndLabels.get(ProductBcParams.sms))}</Select>
                  </Form.Item>
                  <Form.Item
                    className={styles["block-codes-editing-status-select-item"]}
                    name={ProductBcParams.fcStmt}
                    label="Statement generation"
                    hidden={
                      (!isA1SwitchedOn && !isA2SwitchedOn) ||
                      !checkIfOneProductHasFieldCodeValue(tenant, "balance-owner", "CMS")
                    }
                    tooltip={{
                      title:
                        "Generation of statement depends on this feature. Based on statement generation parameter setup, generation of statement can be blocked for the corresponding block code",
                      icon: <QuestionCircleFilled />,
                    }}
                  >
                    <Select>{blockCodeValuesAndLabelsMap(blockCodeValuesAndLabels.get(ProductBcParams.fcStmt))}</Select>
                  </Form.Item>
                  <Form.Item
                    className={styles["block-codes-editing-status-select-item"]}
                    name={ProductBcParams.fcReport}
                    label="Block account report"
                    hidden={!isA1SwitchedOn && !isA2SwitchedOn}
                    tooltip={{
                      title: "This feature specifies accounts which are blocked should be part of reports or not",
                      icon: <QuestionCircleFilled />,
                    }}
                  >
                    <Select>
                      {blockCodeValuesAndLabelsMap(blockCodeValuesAndLabels.get(ProductBcParams.fcReport))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className={styles["block-codes-editing-status-select-item"]}
                    name={ProductBcParams.abuStatus}
                    label="ABU status"
                    hidden={!checkIfOneProductHasFieldCodeValue(tenant, "nic-card-subtype-abu-active", "true")}
                    tooltip={{
                      title:
                        "This feature defines the type of message that would be sent to ABU (Automatic Billing Updater) for certain block codes",
                      icon: <QuestionCircleFilled />,
                    }}
                  >
                    <Select>
                      {blockCodeValuesAndLabelsMap(blockCodeValuesAndLabels.get(ProductBcParams.abuStatus))}
                    </Select>
                  </Form.Item>
                </>
              ),
            },
            {
              key: "waivers",
              label: <>Waivers</>,
              dependency: checkIfOneProductHasFieldCodeValue(tenant, "balance-owner", "CMS"),
              children: (
                <div>
                  <div className={styles["section-title"]}>Waivers</div>
                  <Form.Item
                    className={styles["block-codes-editing-status-select-item"]}
                    name={ProductBcParams.financeCharges}
                    label="Finance charges"
                    tooltip={{
                      title:
                        "This feature specifies the Finance charges can be waived or not in case customer eligible to charge finance charges and block code is applied.",
                      icon: <QuestionCircleFilled />,
                    }}
                    hidden={
                      !checkIfOneProductHasFieldCodeValue(tenant, "balance-owner", "CMS") ||
                      (form.getFieldValue("nic-bc-a1-allow") === false &&
                        form.getFieldValue("nic-bc-a2-allow") === false)
                    }
                  >
                    <Select>
                      {blockCodeValuesAndLabelsMap(blockCodeValuesAndLabels.get(ProductBcParams.financeCharges))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className={styles["block-codes-editing-status-select-item"]}
                    name={ProductBcParams.fcMf}
                    label="Membership fees"
                    tooltip={{
                      title:
                        "This feature specifies the Membership fees can be waived or not incase customer eligible to charge membership fees and block code is applied",
                      icon: <QuestionCircleFilled />,
                    }}
                  >
                    <Select>{blockCodeValuesAndLabelsMap(blockCodeValuesAndLabels.get(ProductBcParams.fcMf))}</Select>
                  </Form.Item>
                  <Form.Item
                    className={styles["block-codes-editing-status-select-item"]}
                    name={ProductBcParams.paymentsFee}
                    label="Late payment fee"
                    tooltip={{
                      title:
                        "This feature specifies the late payment fee can be waived or not in case customer is eligible to charge late payment fee and certain block code is applied.",
                      icon: <QuestionCircleFilled />,
                    }}
                    hidden={
                      !checkIfOneProductHasFieldCodeValue(tenant, "balance-owner", "CMS") ||
                      (form.getFieldValue("nic-bc-a1-allow") === false &&
                        form.getFieldValue("nic-bc-a2-allow") === false)
                    }
                  >
                    <Select>
                      {blockCodeValuesAndLabelsMap(blockCodeValuesAndLabels.get(ProductBcParams.paymentsFee))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className={styles["block-codes-editing-status-select-item"]}
                    name={ProductBcParams.overlimitFee}
                    label="Overlimit fee"
                    tooltip={{
                      title:
                        "This feature specifies the overlimit fee can be waived or not in case customer is in overlimit and block code is applied.",
                      icon: <QuestionCircleFilled />,
                    }}
                    hidden={
                      !checkIfOneProductHasFieldCodeValue(tenant, "balance-owner", "CMS") ||
                      (form.getFieldValue("nic-bc-a1-allow") === false &&
                        form.getFieldValue("nic-bc-a2-allow") === false)
                    }
                  >
                    <Select>
                      {blockCodeValuesAndLabelsMap(blockCodeValuesAndLabels.get(ProductBcParams.paymentsFee))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className={styles["block-codes-editing-status-select-item"]}
                    name={ProductBcParams.fcStmtFee}
                    label="Statement  fees"
                    hidden={
                      form.getFieldValue("nic-bc-a1-allow") === false && form.getFieldValue("nic-bc-a2-allow") === false
                    }
                    tooltip={{
                      title:
                        "This feature specifies the Statement fees can be waived or not if certain block code is applied",
                      icon: <QuestionCircleFilled />,
                    }}
                  >
                    <Select>
                      {blockCodeValuesAndLabelsMap(blockCodeValuesAndLabels.get(ProductBcParams.fcStmtFee))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className={styles["block-codes-editing-status-select-item"]}
                    name={ProductBcParams.fcInactFee}
                    label="Inactivity fees"
                    tooltip={{
                      title:
                        "This feature specifies the Inactivity fee can be waived or not if certain block code is applied",
                      icon: <QuestionCircleFilled />,
                    }}
                  >
                    <Select>
                      {blockCodeValuesAndLabelsMap(blockCodeValuesAndLabels.get(ProductBcParams.fcInactFee))}
                    </Select>
                  </Form.Item>
                </div>
              ),
            },
          ]}
          isCheckEnabled={false}
        />
      </Form>
    </div>
  );
};
