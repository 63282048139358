import { FC, useEffect } from "react";
import { Form, Select, Space, Switch } from "antd";
import cn from "classnames";
import { Link, useParams } from "react-router-dom";

import { ArrowRightOutlined, QuestionCircleFilled } from "@ant-design/icons";
import { useReduxState } from "@ni/common/hooks";
import { authBlockResponseOptions } from "@ni/common/mocks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, PageItemLayoutElements, PageItemLayoutGroup, TooltipInfo } from "@ni/common/ui";
import { TenantApi } from "@ni/sdk/apis";
import { ChangeTenantRequest, Tenant } from "@ni/sdk/models";

import styles from "./styles.module.scss";

const tenantsServiceApi = new TenantApi();

export interface ClientIdentificationForm {
  "nic-unique-client-reg-number": boolean;
  "nic-unique-client-number": boolean;
  "nic-cs-domain-noncompl": boolean;
  "nic-noncompl-auth-block-3": string;
  "nic-noncompl_level_a-recalc-client-update": boolean;
  "nic-noncompl_level_a-recalc-dec-daily-sa": boolean;
  "nic-noncompl_c-auth-block-3": string;
  "nic-noncompl_level_c-suppl-recalc-client-update": boolean;
  "nic-noncompl_level_c-suppl-recalc-dec-daily-sa": boolean;
}

export const ClientIdentification: FC = () => {
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const [tenant, setTenant] = useReduxState<Tenant>("tenant", {});
  const [isLoading, setIsLoading] = useReduxState<boolean>("isLoading");

  useEffect(() => {
    if (!isLoading) {
      const nicCsDomainValue = tenant.tenantValues?.find(
        tenantValue => tenantValue.fieldCode === "nic-cs-domain-noncompl",
      );
      form.setFieldsValue({
        "nic-unique-client-reg-number":
          tenant.tenantValues?.find(tenantValue => tenantValue.fieldCode === "nic-unique-client-reg-number")?.value ===
          "true",
        "nic-unique-client-number":
          tenant.tenantValues?.find(tenantValue => tenantValue.fieldCode === "nic-unique-client-number")?.value !==
          "false",
        "nic-cs-domain-noncompl": nicCsDomainValue
          ? nicCsDomainValue.value !== "false"
          : process.env["REACT_APP_DEPLOYMENT_COUNTRY"] === "SA",
        "nic-noncompl-auth-block-3":
          tenant.tenantValues?.find(tenantValue => tenantValue.fieldCode === "nic-noncompl-auth-block-3")?.value ||
          "RC=05",
        "nic-noncompl_level_a-recalc-client-update":
          tenant.tenantValues?.find(
            tenantValue => tenantValue.fieldCode === "nic-noncompl_level_a-recalc-client-update",
          )?.value !== "false",
        "nic-noncompl_level_a-recalc-dec-daily-sa":
          tenant.tenantValues?.find(tenantValue => tenantValue.fieldCode === "nic-noncompl_level_a-recalc-dec-daily-sa")
            ?.value !== "false",
        "nic-noncompl_c-auth-block-3":
          tenant.tenantValues?.find(tenantValue => tenantValue.fieldCode === "nic-noncompl_c-auth-block-3")?.value ||
          "RC=05",
        "nic-noncompl_level_c-suppl-recalc-client-update":
          tenant.tenantValues?.find(
            tenantValue => tenantValue.fieldCode === "nic-noncompl_level_c-suppl-recalc-client-update",
          )?.value !== "false",
        "nic-noncompl_level_c-suppl-recalc-dec-daily-sa":
          tenant.tenantValues?.find(
            tenantValue => tenantValue.fieldCode === "nic-noncompl_level_c-suppl-recalc-dec-daily-sa",
          )?.value !== "false",
      });
    }
  }, [form, isLoading, tenant]);

  const onFinish = (value: ClientIdentificationForm) => {
    setIsLoading(true);
    const tenantId = parseInt(id ?? "0", 10);
    const requestBody: ChangeTenantRequest = {
      tenantValues: Object.keys(value).map((val: string) => {
        if (val === "nic-noncompl-auth-block-3" || val === "nic-noncompl_c-auth-block-3") {
          return {
            fieldCode: val,
            value: value[val] || "",
          };
        }

        return {
          fieldCode: val,
          value: value[val as keyof ClientIdentificationForm] ? "true" : "false",
        };
      }),
    };
    tenantsServiceApi
      .editTenant(requestBody, tenantId)
      .then(res => {
        setIsLoading(false);
        setTenant(res.data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      pageTitle="Client Identification"
      form={form}
      submitHandler={onFinish as unknown as (values: FormValues) => void}
      formSize="lg"
      size="full"
      submitLabel="Save"
    >
      <PageItemLayoutGroup className={styles["client-identification-section-wrapper"]}>
        <div className={cn(styles["section-title"], styles["text-uppercase"])}>Client Identification</div>
        <PageItemLayoutElements>
          <Space direction="horizontal" size={8}>
            <Form.Item valuePropName="checked" name="nic-unique-client-reg-number">
              <Switch />
            </Form.Item>
            <TooltipInfo
              largeLabel="Impose Uniqueness of the field that stores client ID number"
              tooltipProps={{
                title: "Domestic identification document number (like IQAMA ID or Passport number)",
              }}
            />
          </Space>
          <Space direction="horizontal" size={8}>
            <Form.Item valuePropName="checked" name="nic-unique-client-number">
              <Switch />
            </Form.Item>
            <TooltipInfo
              largeLabel="Impose Uniqueness of the field that stores CIF number"
              tooltipProps={{
                title:
                  "CIF number is a unique number that the banks assign to each customer. This number is a part of the digital file – CIF, which is an acronym for Customer Information File. It is an electronic file on a bank’s computer system that stores all pertinent information about a customer.",
              }}
            />
          </Space>
        </PageItemLayoutElements>
      </PageItemLayoutGroup>
      <PageItemLayoutGroup className={styles["id-expire-wrapper"]}>
        <div className={cn(styles["section-title"], styles["text-uppercase"])}>ID expire functionality</div>
        <div>
          <div className={styles["content-header"]}>
            <Space direction="horizontal" size={12}>
              <Form.Item valuePropName="checked" name="nic-cs-domain-noncompl">
                <Switch />
              </Form.Item>
              <span className={styles["header-text"]}>
                <TooltipInfo
                  label="Enable ID Expire Functionality"
                  tooltipProps={{
                    title:
                      "In order to comply with local KYC (know your customer) procedures, client has to provide their ID details (Iqama ID or Passport Number ID) to respective issuing bank. Functionality implies blocking client’s cards once this ID get expired. Based on submission of new/renewed ID details, cards will get unblocked for further usage.",
                  }}
                />
              </span>
            </Space>
          </div>
          <div className={styles["content-wrapper"]}>
            <PageItemLayoutGroup>
              <PageItemLayoutElements>
                <div className={styles["section-title"]}>For Account</div>
                <div className={styles["section-description"]}>
                  Following rules will be applied for primary and supplementary cards if primary cardholder ID is
                  expired.
                </div>
              </PageItemLayoutElements>
              <Form.Item noStyle={true} dependencies={["nic-cs-domain-noncompl"]}>
                {() => (
                  <Form.Item
                    className={styles["select-item"]}
                    name="nic-noncompl-auth-block-3"
                    label="Response code"
                    tooltip={{
                      title:
                        "The following response will be received (by POS or ATM) in case of transaction by card which was blocked due to ID expiration.",
                      icon: <QuestionCircleFilled />,
                    }}
                  >
                    <Select disabled={!form.getFieldValue("nic-cs-domain-noncompl")}>
                      {authBlockResponseOptions.map(value => (
                        <Select.Option key={value.value} value={value.value}>
                          {value.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Form.Item>
              <Space direction="horizontal" size={8}>
                <Form.Item noStyle={true} dependencies={["nic-cs-domain-noncompl"]}>
                  {() => (
                    <Form.Item valuePropName="checked" name="nic-noncompl_level_a-recalc-client-update">
                      <Switch disabled={!form.getFieldValue("nic-cs-domain-noncompl")} />
                    </Form.Item>
                  )}
                </Form.Item>
                <div>
                  Enable checking ID renewal state immediately once the client/contract was updated in the system
                </div>
              </Space>
              <Space direction="horizontal" size={8}>
                <Form.Item noStyle={true} dependencies={["nic-cs-domain-noncompl"]}>
                  {() => (
                    <Form.Item valuePropName="checked" name="nic-noncompl_level_a-recalc-dec-daily-sa">
                      <Switch disabled={!form.getFieldValue("nic-cs-domain-noncompl")} />
                    </Form.Item>
                  )}
                </Form.Item>
                <div>Enable checking ID renewal state in every morning CDU (Contract Daily Updates) procedure</div>
              </Space>
              <div className={styles["section-title"]}>For Card</div>
              <div className={styles["section-description"]}>
                Following rules will be applied for card if cardholder ID is expired.
              </div>

              <Form.Item noStyle={true} dependencies={["nic-cs-domain-noncompl"]}>
                {() => (
                  <Form.Item
                    className={styles["select-item"]}
                    name="nic-noncompl_c-auth-block-3"
                    label="Response code"
                    tooltip={{
                      title:
                        "The following response will be received (by POS or ATM) in case of transaction by card which was blocked due to ID expiration.",
                      icon: <QuestionCircleFilled />,
                    }}
                  >
                    <Select disabled={!form.getFieldValue("nic-cs-domain-noncompl")}>
                      {authBlockResponseOptions.map(value => (
                        <Select.Option key={value.value} value={value.value}>
                          {value.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Form.Item>
              <Space direction="horizontal" size={8}>
                <Form.Item noStyle={true} dependencies={["nic-cs-domain-noncompl"]}>
                  {() => (
                    <Form.Item valuePropName="checked" name="nic-noncompl_level_c-suppl-recalc-client-update">
                      <Switch disabled={!form.getFieldValue("nic-cs-domain-noncompl")} />
                    </Form.Item>
                  )}
                </Form.Item>
                <div>
                  Enable checking ID renewal state immediately once the client/contract was updated in the system
                </div>
              </Space>
              <Space direction="horizontal" size={8}>
                <Form.Item noStyle={true} dependencies={["nic-cs-domain-noncompl"]}>
                  {() => (
                    <Form.Item valuePropName="checked" name="nic-noncompl_level_c-suppl-recalc-dec-daily-sa">
                      <Switch disabled={!form.getFieldValue("nic-cs-domain-noncompl")} />
                    </Form.Item>
                  )}
                </Form.Item>
                <div>Enable checking ID renewal state in every morning CDU (Contract Daily Updates) procedure</div>
              </Space>
              <PageItemLayoutElements>
                <div className={styles["info"]}>
                  <span>Appropriate Block Code for ID expiration functionality could be configured.</span>
                  <Link to={`/tenant/${id}/block-codes`}>
                    <span className={styles["info-link-text"]}>View Block codes settings</span>
                    <ArrowRightOutlined />
                  </Link>
                </div>
                <div className={styles["info"]}>
                  <span>
                    ID Expiry SMS could be sent to remind the customers to update the ID details. For convenience this
                    has been generated for you, please visit the SMS page from the dashboard to modify the SMS settings.
                  </span>
                  <Link to={`/tenant/${id}/sms-templates`}>
                    <span className={styles["info-link-text"]}>View SMS templates settings</span>
                    <ArrowRightOutlined />
                  </Link>
                </div>
              </PageItemLayoutElements>
            </PageItemLayoutGroup>
          </div>
        </div>
      </PageItemLayoutGroup>
    </CustomFormWrapper>
  );
};
