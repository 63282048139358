import { FC, useState } from "react";
import { Button, Form, InputNumber, Modal, Space } from "antd";
import cn from "classnames";

import { ClearOutlined, EditOutlined, SettingOutlined } from "@ant-design/icons";
import { BlockCodeType, notSpecified, ProductBcParams } from "@ni/common/constants";
import { FormValues } from "@ni/common/types";
import { TooltipInfo } from "@ni/common/ui";
import { BlockCode } from "@ni/sdk/models";

import styles from "./styles.module.scss";

export interface BlockItemProps {
  code: BlockCodeType;
  name: string;
  priority: string;
  onEditClick(code: string): void;
  onPriorityChange(payload: BlockCode): void;
  onReset(code: string): void;
  isLoading: boolean;
}

const defaultModalOptions: {
  shouldShow: boolean;
  message: string;
  action: (value?: FormValues) => void;
  hasForm: boolean;
} = {
  shouldShow: false,
  message: "",
  action: () => {},
  hasForm: false,
};

export const BlockItem: FC<BlockItemProps> = ({
  code,
  name,
  onEditClick,
  priority,
  onPriorityChange,
  onReset,
  isLoading,
}) => {
  const [modalForm] = Form.useForm<FormValues>();
  const [modalOptions, setModalOptions] = useState(defaultModalOptions);

  const hideModal = () => {
    setModalOptions(defaultModalOptions);
  };

  const handlePriorityChange = (formValues?: FormValues) => {
    if (formValues) {
      if (formValues["value"]?.toString() !== priority)
        onPriorityChange({
          code,
          fieldCode: ProductBcParams.priority,
          value: formValues["value"]?.toString() as string,
        });
      hideModal();
    }
  };

  const handleBlockReset = () => {
    if (onReset) {
      onReset(code);
    }
    hideModal();
  };

  const editOnClickHandler = () => {
    onEditClick(code);
  };

  return (
    <div className={styles["block-item-wrapper"]}>
      <div className={styles["code-style"]}>{code}</div>
      <div className={cn(styles["name-style"], name === notSpecified ? styles["unfilled"] : styles["filled"])}>
        {name}
      </div>
      <div className={styles["second-section-style"]}>
        <div className={styles["priority-style"]}>
          <Space>Priority: {priority === "00" ? "null" : priority}</Space>
        </div>

        <div className={styles["edit-button"]}>
          <Button
            type="link"
            onClick={() =>
              setModalOptions({
                action: handlePriorityChange,
                message: `Update block code "${code}" priority`,
                hasForm: true,
                shouldShow: true,
              })
            }
            disabled={name === "Not specified" || priority === "00"}
            icon={
              <TooltipInfo tooltipProps={{ title: "Update priority" }}>
                <EditOutlined />
              </TooltipInfo>
            }
          />
          <Button
            type="link"
            onClick={editOnClickHandler}
            icon={
              <TooltipInfo tooltipProps={{ title: "Edit block" }}>
                <SettingOutlined />
              </TooltipInfo>
            }
          />
          <Button
            type="link"
            onClick={() =>
              setModalOptions({
                action: handleBlockReset,
                message: `Are you sure you want to clear block code "${code}" values?`,
                hasForm: false,
                shouldShow: true,
              })
            }
            disabled={name === "Not specified" || priority === "00"}
            icon={
              <TooltipInfo tooltipProps={{ title: "Clear" }}>
                <ClearOutlined />
              </TooltipInfo>
            }
          />
        </div>
      </div>

      <Modal
        title={modalOptions.message}
        width="500px"
        open={modalOptions.shouldShow}
        onCancel={hideModal}
        footer={[
          <Button key="back" disabled={isLoading} onClick={hideModal}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={isLoading} onClick={modalForm.submit}>
            Submit
          </Button>,
        ]}
      >
        <Form form={modalForm} autoComplete="off" layout="vertical" disabled={isLoading} onFinish={modalOptions.action}>
          {modalOptions.hasForm && (
            <Form.Item
              name="value"
              label="priority"
              rules={[
                {
                  type: "number",
                  required: true,
                  min: 0,
                  max: 99,
                },
              ]}
              initialValue={+(priority as unknown as number)}
            >
              <InputNumber min={0} max={99} precision={0} />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </div>
  );
};
