import { useEffect, useMemo } from "react";
import { Form, InputNumber } from "antd";
import { useParams } from "react-router-dom";

import { QuestionCircleFilled } from "@ant-design/icons";
import { useHydrateForm, useRemainingCycleDayAfterDueDate } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CheckboxGroup, CustomFormWrapper, RadioGroup } from "@ni/common/ui";
import { mapOptionsValue, reverseOptionsValue } from "@ni/common/utils";

import { usePct } from "../../../../hooks";

import {
  fullPaymentDate,
  interestStart,
  interestStartNumber,
  murabahaProfitRate,
  zeroProfitOnFees,
  zeroProfitOnInsurance,
  zeroProfitOnProfit,
} from "./constants";

const stringsKeys = [fullPaymentDate.code, interestStart.code, interestStartNumber.code, murabahaProfitRate.code];

export const MurabahaProfitCalculationPage = () => {
  const [form] = Form.useForm<FormValues>();

  const { id: tenantId, productId, pctId } = useParams<{ id: string; productId: string; pctId: string }>();
  const { currentProduct, pct, onSavePct } = usePct({
    pctId: parseInt(pctId ?? "0", 10),
  });
  const { zeroIntOnAllInt, zeroIntOnAllFees, relatedLinks } = useMemo(
    () => ({
      zeroIntOnAllInt: currentProduct.productValues?.find(obj => obj.fieldCode === "nic-int-rate-zero-all-int"),
      zeroIntOnAllFees: currentProduct.productValues?.find(obj => obj.fieldCode === "nic-int-rate-zero-all-fees"),

      relatedLinks: [
        {
          href: `/tenant/${tenantId}/credit-products-configuration`,
          label: "Interest Calculation - Global",
        },
        {
          href: `/tenant/${tenantId}/product/${productId}/interest-grace-options`,
          label: `${currentProduct.displayName} - Interest Grace Options`,
        },
      ],
    }),
    [currentProduct.displayName, currentProduct.productValues, productId, tenantId],
  );

  const interestStartType = Form.useWatch(interestStart.code, form);
  const dueDateDays = useRemainingCycleDayAfterDueDate(pct.pctProductValues);

  useHydrateForm(
    {
      form,
      entityFields: [...(currentProduct?.productValues ?? []), ...(pct?.pctProductValues ?? [])],
      keys: {
        strings: stringsKeys,
      },
    },
    [pct.pctProductValues],
  );

  useEffect(() => {
    form.setFieldsValue({
      [zeroProfitOnFees.code]: reverseOptionsValue(zeroProfitOnFees, pct?.pctProductValues),
      [zeroProfitOnInsurance.code]: reverseOptionsValue(zeroProfitOnInsurance, pct?.pctProductValues),
      [zeroProfitOnProfit.code]: reverseOptionsValue(zeroProfitOnProfit, pct?.pctProductValues),
    });
  }, [form, pct?.pctProductValues]);

  const onFinish = (values: FormValues) => {
    [...stringsKeys].forEach(key => {
      if (!values[key]?.toString() && pct.pctProductValues?.find(item => item.fieldCode === key)) {
        values[key] = "";
      }
    });

    void onSavePct({
      ...values,
      ...mapOptionsValue(zeroProfitOnFees, values[zeroProfitOnFees.code] as string[]),
      ...mapOptionsValue(zeroProfitOnInsurance, values[zeroProfitOnInsurance.code] as string[]),
      ...mapOptionsValue(zeroProfitOnProfit, values[zeroProfitOnProfit.code] as string[]),
    });
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Murabaha Profit Calculation"
      pageSubtitle="Murabaha computation is done through two transactions, Monthly Murabaha Finance amount payment due; which gets debited to account holder on statement date. And the Special Deposit Profit and Bonus; which represents the monthly earned profit payment credited to accountholder, the profit calculation is done on daily balance algorithm which calculates the interest on daily balances and post the profit at the end of billing cycle."
      size="md"
      formSize="md"
      gap={30}
      level="pct"
      submitLabel="Save"
      submitHandler={onFinish}
      relatedLinks={relatedLinks}
    >
      <Form.Item
        name={murabahaProfitRate.code}
        label={murabahaProfitRate.label}
        tooltip={{
          icon: <QuestionCircleFilled />,
          title: murabahaProfitRate.tooltip,
        }}
        rules={[{ required: true, message: "Murabaha profit rate is required" }]}
      >
        <InputNumber {...murabahaProfitRate.props} />
      </Form.Item>

      <Form.Item name={interestStart.code} label={interestStart.label} initialValue={interestStart.default}>
        <RadioGroup radioList={interestStart.options} gap={8} />
      </Form.Item>

      {interestStartType === "T" && (
        <Form.Item
          name={interestStartNumber.code}
          label={interestStartNumber.label}
          tooltip={{
            icon: <QuestionCircleFilled />,
            title: interestStartNumber.tooltip,
          }}
        >
          <InputNumber {...interestStartNumber.props} />
        </Form.Item>
      )}

      <Form.Item
        name={fullPaymentDate.code}
        label={fullPaymentDate.label}
        tooltip={{
          icon: <QuestionCircleFilled />,
          title: fullPaymentDate.tooltip,
        }}
        rules={[
          {
            type: "number",
            max: 30 - dueDateDays,
            message: "Late payment date cannot exceed remaining cycle days after setting a due date",
          },
        ]}
      >
        <InputNumber {...fullPaymentDate.props} />
      </Form.Item>

      {zeroIntOnAllInt && (
        <Form.Item name={zeroProfitOnProfit.code}>
          <CheckboxGroup selectAllTitle={zeroProfitOnProfit.label} checkboxes={zeroProfitOnProfit.options} />
        </Form.Item>
      )}

      {zeroIntOnAllFees && (
        <Form.Item name={zeroProfitOnFees.code}>
          <CheckboxGroup selectAllTitle={zeroProfitOnFees.label} checkboxes={zeroProfitOnFees.options} />
        </Form.Item>
      )}

      <Form.Item name={zeroProfitOnInsurance.code}>
        <CheckboxGroup selectAllTitle={zeroProfitOnInsurance.label} checkboxes={zeroProfitOnInsurance.options} />
      </Form.Item>
    </CustomFormWrapper>
  );
};
