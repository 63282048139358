import { ReactNode } from "react";
import { Rule } from "rc-field-form/lib/interface";

interface Options {
  name: string;
  code: string;
  default?: boolean | string | number;
  tooltip?: ReactNode;
  type?: "switch" | "integer" | "number";
}

interface NumericOptions extends Options {
  decimalsNumber?: number;
  min?: string | number;
  max?: string | number;
  rules?: Rule[];
  addonAfter?: ReactNode;
}

// Total switch

export const enableATotal: Options = {
  code: "paym-a-total-enabled",
  name: "Account Limits",
  default: true,
};

// Per Day

export const enableDTotalOption: Options = {
  code: "d-paym-a-total-enabled",
  name: "Per Day",
  default: true,
  type: "switch",
  tooltip: "Switch it off if restrictions are not intended per calendar day (from 00:00:00 to 23:59:59).",
};

export const dTotalNrOption: NumericOptions = {
  code: "nic-d-paym-a-total-nr",
  name: "Max Number",
  default: 10,
  min: 0,
  type: "integer",
  tooltip:
    "This counter restricts maximal number of payments per period. Leave it empty if restrictions are not intended. All zeros prohibit payments at all.",
};

export const dTotalAmOption: NumericOptions = {
  code: "nic-d-paym-a-total-am",
  name: "Max Amount",
  default: 1000000,
  decimalsNumber: 2,
  type: "number",
  tooltip:
    "This counter restricts maximal amount of payments per period. Leave it empty if restrictions are not intended. All zeros prohibit payments at all.",
};

export const dTotalSingleOption: NumericOptions = {
  code: "nic-d-paym-a-total-single",
  name: "Max Single Amount",
  default: 1000000,
  decimalsNumber: 2,
  type: "number",
  tooltip:
    "This counter restricts maximal amount of single payment. Leave it empty if restrictions are not intended. All zeros prohibit payments at all.",
};

// Per billing

export const enableBTotalOption: Options = {
  code: "b-paym-a-total-enabled",
  name: "Per Billing",
  default: true,
  type: "switch",
  tooltip: "Switch it off if restrictions are not intended per billing cycle. ",
};

export const bTotalNrOption: NumericOptions = {
  code: "nic-b-paym-a-total-nr",
  name: "Max Number",
  default: 100,
  min: 0,
  type: "integer",
  tooltip:
    "This counter restricts maximal number of payments per period. Leave it empty if restrictions are not intended. All zeros prohibit payments at all.",
};

export const bTotalAmOption: NumericOptions = {
  code: "nic-b-paym-a-total-am",
  name: "Max Amount",
  default: 1000000,
  decimalsNumber: 2,
  type: "number",
  tooltip:
    "This counter restricts maximal amount of payments per period. Leave it empty if restrictions are not intended. All zeros prohibit payments at all.",
};

export const bTotalSingleOption: NumericOptions = {
  code: "nic-b-paym-a-total-single",
  name: "Max Single Amount",
  default: 1000000,
  decimalsNumber: 2,
  type: "number",
  tooltip:
    "This counter restricts maximal amount of single payment. Leave it empty if restrictions are not intended. All zeros prohibit payments at all.",
};

// Per 365 days

export const enableD365TotalOption: Options = {
  code: "d365-paym-a-total-enabled",
  name: "Per 365 days",
  default: true,
  type: "switch",
  tooltip:
    "365 sliding days, means that the limiter calculates transactions for 365 days before the current transaction was made. Switch it off if restrictions are not intended",
};

export const d365TotalNrOption: NumericOptions = {
  code: "nic-d365-paym-a-total-nr",
  name: "Max Number",
  default: 1000,
  min: 0,
  type: "integer",
  tooltip:
    "This counter restricts maximal number of payments per period. Leave it empty if restrictions are not intended. All zeros prohibit payments at all.",
};

export const d365TotalAmOption: NumericOptions = {
  code: "nic-d365-paym-a-total-am",
  name: "Max Amount",
  default: 1000000,
  decimalsNumber: 2,
  type: "number",
  tooltip:
    "This counter restricts maximal amount of payments per period. Leave it empty if restrictions are not intended. All zeros prohibit payments at all.",
};

export const d365TotalSingleOption: NumericOptions = {
  code: "nic-d365-paym-a-total-single",
  name: "Max Single Amount",
  default: 1000000,
  decimalsNumber: 2,
  type: "number",
  tooltip:
    "This counter restricts maximal amount of single payment. Leave it empty if restrictions are not intended. All zeros prohibit payments at all.",
};

// Forever

export const enableETotalOption: Options = {
  code: "e-paym-a-total-enabled",
  name: "Forever",
  default: true,
  type: "switch",
  tooltip:
    "No frequency cycle is set for the limiter; counter contents accumulate constantly, and counters do not reset to zero. Switch it off if restrictions are not intended.",
};

export const eTotalNrOption: NumericOptions = {
  code: "nic-e-paym-a-total-nr",
  name: "Max Number",
  default: 10000,
  min: 0,
  type: "integer",
  tooltip:
    "This counter restricts maximal number of payments per period. Leave it empty if restrictions are not intended. All zeros prohibit payments at all.",
};

export const eTotalAmOption: NumericOptions = {
  code: "nic-e-paym-a-total-am",
  name: "Max Amount",
  default: 10000000,
  decimalsNumber: 2,
  type: "number",
  tooltip:
    "This counter restricts maximal amount of payments per period. Leave it empty if restrictions are not intended. All zeros prohibit payments at all.",
};

export const eTotalSingleOption: NumericOptions = {
  code: "nic-e-paym-a-total-single",
  name: "Max Single Amount",
  default: 10000000,
  decimalsNumber: 2,
  type: "number",
  tooltip:
    "This counter restricts maximal amount of single payment. Leave it empty if restrictions are not intended. All zeros prohibit payments at all.",
};
