import React, { FC, useEffect, useMemo, useState } from "react";
import { Form, Input } from "antd";
import NumberFormat from "react-number-format";

import { QuestionCircleFilled } from "@ant-design/icons";
import { LTY_ACCR_CAP_CTD, LTY_ACCR_RATE, LTY_PROGRAM_ACCR_FREQ, LTY_PROGRAM_ACCR_MODE } from "@ni/common/constants";
import { useHydrateForm, useNumberOfTotalPointesCapDecimals, useParamsList } from "@ni/common/hooks";
import { FormValues, GetProductWithId } from "@ni/common/types";
import { CustomFormWrapper, Tabs } from "@ni/common/ui";
import { getFormValueFromLoyaltyValues } from "@ni/common/utils";
import { LoyaltyProgramType, Tenant } from "@ni/sdk/models";

import {
  getSpecificRateFieldName,
  getSpecificRateSwitchFieldName,
  PointAccrualFrequencyConfiguration,
} from "../../components";
import { LoyaltyProgramPagesProps } from "../../types";

interface PointAccrualSetupPageProps extends LoyaltyProgramPagesProps {
  currentProduct: GetProductWithId;
  tenant: Tenant;
}

type BillingKey = "" | "Y" | "B" | "B-Y";

export const PointAccrualSetupPage: FC<PointAccrualSetupPageProps> = ({
  tenant,
  currentProduct,
  loyaltyProgram,
  editLoyaltyProgram,
}) => {
  const [form] = Form.useForm<FormValues>();

  const isProgramCumulative = useMemo(
    () => loyaltyProgram?.type === LoyaltyProgramType.CUMULATIVE,
    [loyaltyProgram?.type],
  );

  const amountCoundMode = useMemo(
    () => getFormValueFromLoyaltyValues(loyaltyProgram?.programValues ?? [], LTY_PROGRAM_ACCR_MODE, "string") as string,
    [loyaltyProgram?.programValues],
  );

  const {
    params: { tab },
  } = useParamsList(["tab"]);

  const [loyaltyBillingKey, setLoyaltyBillingKey] = useState<BillingKey>("");

  const doesTabsDisplayed = loyaltyBillingKey === "B-Y";

  const tiers = useMemo(() => {
    const regex = /tier-t\d+/;

    const tierFields = isProgramCumulative
      ? (loyaltyProgram?.programPctValues ?? []).filter(pctValue => regex.test(pctValue?.code ?? "") && pctValue.value)
      : [];

    return {
      fields: tierFields,
      codes: tierFields.map(value => value.code) as string[],
    };
  }, [isProgramCumulative, loyaltyProgram?.programPctValues]);

  const NumberOfTotalPointesCapDecimals = useNumberOfTotalPointesCapDecimals(tenant);

  const stringsKeys = useMemo(() => {
    const values = [
      LTY_ACCR_RATE,
      getSpecificRateSwitchFieldName("B"),
      getSpecificRateSwitchFieldName("Y"),
      getSpecificRateFieldName("B"),
      getSpecificRateFieldName("Y"),
      LTY_ACCR_CAP_CTD,
      LTY_ACCR_CAP_CTD,
    ];

    return [...values, ...tiers.codes];
  }, [tiers.codes]);

  const initialValues = useHydrateForm({
    form,
    entityFields: loyaltyProgram?.programPctValues ?? [],
    keys: {
      strings: stringsKeys,
    },
  });

  useEffect(() => {
    if (isProgramCumulative) {
      const billingKey = getFormValueFromLoyaltyValues(
        loyaltyProgram?.programValues ?? [],
        LTY_PROGRAM_ACCR_FREQ,
        "string",
      ) as BillingKey;

      setLoyaltyBillingKey(billingKey);
    }
  }, [isProgramCumulative, loyaltyProgram?.programValues]);

  const onFinish = (values: FormValues) => {
    editLoyaltyProgram(values);
  };

  const pagesList = [
    {
      key: "billing" as const,
      label: <div title="Billing">Billing</div>,
      children: (
        <PointAccrualFrequencyConfiguration
          form={form}
          amountCoundMode={amountCoundMode as "COUNT" | "AMOUNT"}
          currentFrequencyKey="B"
          codes={tiers.codes.filter(code => code.includes("-b-"))}
        />
      ),
    },
    {
      key: "yearly" as const,
      label: <div title="Yearly">Yearly</div>,
      children: (
        <PointAccrualFrequencyConfiguration
          form={form}
          amountCoundMode={amountCoundMode as "COUNT" | "AMOUNT"}
          currentFrequencyKey="Y"
          codes={tiers.codes.filter(code => code.includes("-y-"))}
        />
      ),
    },
  ];

  const relatedLinks = useMemo(() => {
    return (currentProduct.shortLoyaltyPrograms ?? [])
      .filter(program => program.id === loyaltyProgram?.id)
      .map(program => ({
        href: `../../tenant/${tenant?.id}/product/${currentProduct?.id}/edit-program/${program?.id}/point-accrual-setup`,
        label: `${currentProduct?.displayName} - Loyalty Programs - "${loyaltyProgram?.name}" - Point Accrual Setup`,
      }));
  }, [
    currentProduct?.displayName,
    currentProduct?.id,
    currentProduct?.shortLoyaltyPrograms,
    loyaltyProgram?.id,
    loyaltyProgram?.name,
    tenant?.id,
  ]);

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Point Accrual Setup"
      pageSubtitle="Loyalty points are earned for eligible transactions. Please define the basic accrual setup for the loyalty program."
      size="full"
      formSize="full"
      level="pct-loyalty"
      submitLabel="Save"
      submitHandler={onFinish}
      relatedLinks={relatedLinks}
    >
      {isProgramCumulative ? (
        <div>
          {doesTabsDisplayed ? (
            <Tabs
              pagesList={pagesList}
              isCheckEnabled={true}
              form={form}
              initialValues={initialValues}
              discardAfterChangeTab={false}
              onSave={onFinish}
            />
          ) : (
            <PointAccrualFrequencyConfiguration
              form={form}
              amountCoundMode={amountCoundMode as "COUNT" | "AMOUNT"}
              currentFrequencyKey={loyaltyBillingKey as "B" | "Y"}
              codes={tiers.codes}
            />
          )}
        </div>
      ) : (
        <Form.Item
          name={LTY_ACCR_RATE}
          rules={[{ required: true }]}
          label="Points accrual percentage rate"
          tooltip={{
            title:
              "This parameter specifies the rate at which points are accrued for the transaction made by the customer. This field is percentage value,  for example if it is set as 1% then points are accrued at the rate of 1% for transactions. ",
            icon: <QuestionCircleFilled />,
          }}
        >
          <NumberFormat
            customInput={Input}
            addonAfter="%"
            allowNegative={false}
            isNumericString={true}
            thousandSeparator=" "
            decimalScale={2}
            decimalSeparator="."
            fixedDecimalScale={true}
          />
        </Form.Item>
      )}

      {loyaltyBillingKey !== "Y" && tab !== pagesList[1].key ? (
        <Form.Item
          name={LTY_ACCR_CAP_CTD}
          label="Total points cap per cycle"
          tooltip={{
            title:
              "This parameter specifies about the maximum points that can be earned under one program in the single billing cycle. For example, if maximum cap is defined as 500, then 500 is the maximum points that can be earned on cycle to date transactions even though total accrued points are more than 500.",
            icon: <QuestionCircleFilled />,
          }}
        >
          <NumberFormat
            customInput={Input}
            allowNegative={false}
            isNumericString={true}
            thousandSeparator=" "
            decimalScale={NumberOfTotalPointesCapDecimals}
            decimalSeparator="."
            fixedDecimalScale={true}
          />
        </Form.Item>
      ) : null}
    </CustomFormWrapper>
  );
};
