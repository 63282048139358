import { FC, useEffect } from "react";
import { Form, InputNumber, Radio, Space } from "antd";
import { useParams } from "react-router-dom";

import { useProductSettings } from "@ni/common/hooks";
import { autoClousureOptions, periodRadioOptions } from "@ni/common/mocks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, PageItemLayoutGroup } from "@ni/common/ui";
import { checkValueBetweenRangeValidator, getFormValueFromProductValues } from "@ni/common/utils";

import styles from "./styles.module.scss";

export const AutoClosure: FC = () => {
  const [form] = Form.useForm<FormValues>();
  const { productId } = useParams<{ id: string; productId: string }>();

  const { currentProduct: product, onUpdateProduct } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });

  const periodOptions = periodRadioOptions;
  const nicAccStAutoDormEodList = autoClousureOptions;
  const getAutoClosePeriodOptions = () =>
    periodOptions.map(val => <Radio key={val.key} value={val.value}>{` ${val.label} `}</Radio>);
  const getAutoDormEodOptions = () =>
    nicAccStAutoDormEodList.map(val => <Radio key={val.key} value={val.value}>{` ${val.label} `}</Radio>);

  useEffect(() => {
    if (!product?.id) {
      return;
    }

    form.setFieldsValue({
      "nic-acc-st-auto-close-per-type":
        getFormValueFromProductValues(product.productValues, "nic-acc-st-auto-close-per-type") ||
        periodRadioOptions[0].key,
      "nic-acc-st-auto-close-per":
        getFormValueFromProductValues(product.productValues, "nic-acc-st-auto-close-per") || "",
      "nic-acc-st-auto-dorm-eod":
        getFormValueFromProductValues(product.productValues, "nic-acc-st-auto-dorm-eod") || "",
    });
  });

  const checkBalanceOwner = (validating: string): boolean => {
    return validating === (getFormValueFromProductValues(product.productValues, "balance-owner") as string);
  };
  const checkMulticurrencyProducts = (validating: string): boolean => {
    return validating === (getFormValueFromProductValues(product.productValues, "curr-setup") as string);
  };
  const onSubmit = (values: FormValues) => {
    void onUpdateProduct(values);
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Contract Auto-closure"
      pageSubtitle={
        <div className={styles["after-wizard-form-smaller-width"]}>
          {checkBalanceOwner("CBS") || checkMulticurrencyProducts("mult") ? (
            <div>Contract auto-closure can be triggered by dormancy factor below: </div>
          ) : (
            <div>Contract auto-closure can be triggered by different factors: </div>
          )}
          <ul>
            {checkBalanceOwner("CBS") || checkMulticurrencyProducts("mult") ? (
              <li>
                <div>
                  Dormancy due to card status, when no cards exist under the account except cancelled, replaced or
                  expired (for accounts) at end of billing cycle check.
                </div>
              </li>
            ) : (
              <>
                <li>
                  <div>
                    By dormancy due to card status, when no cards exist under the account except cancelled, replaced or
                    expired (for accounts) at end of billing cycle check.
                  </div>
                </li>
                <li>
                  <div>
                    By dormancy due to inactivity, when there are no financial transactions on card excluding fees and
                    charges throughout a full billing cycle.
                  </div>
                </li>
              </>
            )}
          </ul>
          <div>
            Triggering closure will change certain features as an immediate action like, blocking the authorization,
            Card Control feature. Contract will not be closed immediately once the closure process is triggered, instead
            based upon the parameter &quot;Pre-auto closure period&quot;, contract status is set to closure after the
            stipulated period. The main role of the &quot;Pre-auto closure period&quot; is allowing presentments to come
            from the payment scheme. After closure, the contract will be excluded from all reports and then purged as
            per the purge policy.
          </div>
        </div>
      }
      submitHandler={onSubmit}
      size="full"
      formSize="full"
      submitLabel="Save"
      gap={24}
    >
      <PageItemLayoutGroup>
        <div className="text-blue-h3">Auto-closure (Dormancy) settings</div>
        {!checkBalanceOwner("CBS") && !checkMulticurrencyProducts("mult") ? (
          <Form.Item name="nic-acc-st-auto-dorm-eod" label="Auto closure triggers" className="input-group-label">
            <Radio.Group>
              <Space direction="vertical">{getAutoDormEodOptions()}</Space>
            </Radio.Group>
          </Form.Item>
        ) : null}
        <Form.Item
          name="nic-acc-st-auto-close-per-type"
          label="Pre-auto closure period type"
          className="input-group-label"
        >
          <Radio.Group>
            <Space direction="vertical">{getAutoClosePeriodOptions()}</Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item noStyle={true} dependencies={["nic-acc-st-auto-close-per-type"]}>
          {() => (
            <Form.Item
              name="nic-acc-st-auto-close-per"
              label="Pre-auto closure period number"
              className={styles["product-details-form-input"]}
              dependencies={["nic-acc-st-auto-close-per-type"]}
              rules={[
                () => ({
                  validator(_, value) {
                    return checkValueBetweenRangeValidator(
                      value,
                      form.getFieldValue("nic-acc-st-auto-close-per-type") === "M"
                        ? "Number of months"
                        : "Number of days",
                      0,
                      form.getFieldValue("nic-acc-st-auto-close-per-type") === "M" ? 12 : 365,
                    );
                  },
                }),
              ]}
            >
              <InputNumber />
            </Form.Item>
          )}
        </Form.Item>
      </PageItemLayoutGroup>
    </CustomFormWrapper>
  );
};
