import { FC, useEffect, useState } from "react";
import { Form, notification } from "antd";
import { useParams } from "react-router-dom";

import {
  D_PAYM_C_TOTAL_ENABLED,
  D365_PAYM_C_TOTAL_ENABLED,
  M_PAYM_C_TOTAL_ENABLED,
  NIC_D_PAYM_C_TOTAL_AM,
  NIC_D_PAYM_C_TOTAL_NR,
  NIC_D_PAYM_C_TOTAL_SINGLE,
  NIC_D365_PAYM_C_TOTAL_AM,
  NIC_D365_PAYM_C_TOTAL_NR,
  NIC_D365_PAYM_C_TOTAL_SINGLE,
  NIC_M_PAYM_C_TOTAL_AM,
  NIC_M_PAYM_C_TOTAL_NR,
  NIC_M_PAYM_C_TOTAL_SINGLE,
  PAYM_B_ENABLED,
  PAYM_D_ENABLED,
  PAYM_D365_ENABLED,
  SERVICE_UNAVAILABLE,
} from "@ni/common/constants";
import { useProductSettings } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper } from "@ni/common/ui";
import { extractNumberFromString, getErrorInstance, parseBooleanOrNumber } from "@ni/common/utils";

import { LimitsBlock, RuleKeys } from "../../../../components";
import { usePct } from "../../../../hooks";
import { checkRuleKeyMatch, filterDisabledValues, getPaymentsEnabled } from "../../../../utils";

interface CardLimitPageProps {
  isCredit: boolean;
}

export const CardLimitPage: FC<CardLimitPageProps> = ({ isCredit }) => {
  const [form] = Form.useForm<FormValues>();

  const { id: tenantId, productId, pctId } = useParams<{ id: string; productId: string; pctId: string }>();
  const { currentProduct, productCurrency } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });

  const { pct, onSavePct } = usePct({
    pctId: parseInt(pctId ?? "0", 10),
  });

  const [existedRules, setExistedRules] = useState<RuleKeys[]>([]);

  const relatedLinks = [
    {
      href: `/tenant/${tenantId}/product/${currentProduct.id ?? 0}/pct/${pct.id ?? 0}/additional-rules`,
      label: `Additional rules configuration - ${pct?.displayName ?? ""} PCT`,
    },
  ];

  useEffect(() => {
    if (pct.pctProductValues) {
      const sortedExistedRules = (pct.pctProductValues ?? [])
        .filter(pctValue => checkRuleKeyMatch(pctValue.fieldCode, 1, 3))
        .map(pctValue => pctValue.fieldCode as RuleKeys)
        .sort((a, b) => (extractNumberFromString(a) ?? 0) - (extractNumberFromString(b) ?? 0));

      setExistedRules(sortedExistedRules);
    }
  }, [pct.pctProductValues]);

  useEffect(() => {
    pct.pctProductValues?.forEach(pctValue => {
      if (pctValue.fieldCode.includes("paym-c")) {
        form.setFieldsValue({
          [pctValue.fieldCode]: parseBooleanOrNumber(pctValue.value ?? ""),
        });
      }
    });
  }, [form, pct.pctProductValues]);

  const onFinish = async (values: FormValues): Promise<void> => {
    try {
      await onSavePct(filterDisabledValues(values, form));
    } catch (error) {
      const errorInstance = getErrorInstance(error);
      notification.error({
        placement: "topRight",
        duration: 3,
        message: (
          <div>
            {errorInstance?.response?.status ?? 400} <br />
            {SERVICE_UNAVAILABLE}
          </div>
        ),
      });
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle={`Card Limits – ${isCredit ? "Payment" : "Top-up"}`}
      pageSubtitle="Card limits control payments to the particular card."
      submitHandler={onFinish}
      size="full"
      formSize="full"
      level="pct"
      submitLabel="Save"
      relatedLinks={relatedLinks}
      gap={8}
    >
      <LimitsBlock
        title="Per Day"
        tooltipText="Switch it off if restrictions are not intended per calendar day (from 00:00:00 to 23:59:59)"
        productCurrency={productCurrency}
        existedRules={existedRules}
        totalPaymentsEnabled={getPaymentsEnabled(PAYM_D_ENABLED, [
          D_PAYM_C_TOTAL_ENABLED,
          ...existedRules.map(rule => `d-paym-c-u0${extractNumberFromString(rule) ?? 0}-enabled`),
        ])}
        keys={{
          allFieldsEnabledKey: PAYM_D_ENABLED,
          totalEnabledKey: D_PAYM_C_TOTAL_ENABLED,
          totalNrKey: NIC_D_PAYM_C_TOTAL_NR,
          totalAmKey: NIC_D_PAYM_C_TOTAL_AM,
          totalSingleKey: NIC_D_PAYM_C_TOTAL_SINGLE,
        }}
        rulesKeyPrefix="d"
        limitType="c"
        form={form}
      />

      <LimitsBlock
        title="Per month"
        tooltipText="Switch it off if restrictions are not intended per calendar month (from the 1st till the last day of the month)"
        productCurrency={productCurrency}
        existedRules={existedRules}
        totalPaymentsEnabled={getPaymentsEnabled(PAYM_B_ENABLED, [
          M_PAYM_C_TOTAL_ENABLED,
          ...existedRules.map(rule => `m-paym-c-u0${extractNumberFromString(rule) ?? 0}-enabled`),
        ])}
        keys={{
          allFieldsEnabledKey: PAYM_B_ENABLED,
          totalEnabledKey: M_PAYM_C_TOTAL_ENABLED,
          totalNrKey: NIC_M_PAYM_C_TOTAL_NR,
          totalAmKey: NIC_M_PAYM_C_TOTAL_AM,
          totalSingleKey: NIC_M_PAYM_C_TOTAL_SINGLE,
        }}
        rulesKeyPrefix="m"
        limitType="c"
        form={form}
      />

      <LimitsBlock
        title="Per 365 days"
        tooltipText="365 sliding days, means that the limiter calculates transactions for 365 days before the current transaction was made. Switch it off if restrictions are not intended."
        productCurrency={productCurrency}
        existedRules={existedRules.filter(rule => extractNumberFromString(rule) === 1)}
        totalPaymentsEnabled={getPaymentsEnabled(PAYM_D365_ENABLED, [
          D365_PAYM_C_TOTAL_ENABLED,
          ...existedRules.map(rule => `d365-paym-c-u0${extractNumberFromString(rule) ?? 0}-enabled`),
        ])}
        keys={{
          allFieldsEnabledKey: PAYM_D365_ENABLED,
          totalEnabledKey: D365_PAYM_C_TOTAL_ENABLED,
          totalNrKey: NIC_D365_PAYM_C_TOTAL_NR,
          totalAmKey: NIC_D365_PAYM_C_TOTAL_AM,
          totalSingleKey: NIC_D365_PAYM_C_TOTAL_SINGLE,
        }}
        rulesKeyPrefix="d365"
        limitType="c"
        form={form}
      />
    </CustomFormWrapper>
  );
};
