import { useCallback, useMemo, useState } from "react";
import { FormInstance } from "antd";

import { NIC_CARD_SUBTYPE_PINSET_ALLOWED, NIC_CP_PLRE, SDE } from "@ni/common/constants";
import { FormValues } from "@ni/common/types";
import {
  enforceOrder,
  getFormValueFromProductValues,
  parseBooleanOrNumber,
  removeElement,
  removeElementByIndex,
  transformArrayToObject,
  updateElementByIndex,
} from "@ni/common/utils";
import { ParameterTableValue, Product } from "@ni/sdk/models";

import { CARD_PRODUCTION_FEES_PREFIX } from "../../../../components/Pct/Rules/RestrictionRules";
import { RestrictionRuleItem, TabKeysCPF } from "../../../../types";

import { CARD_PRODUCTION_FEES_OPTIONS_AVIABILITY } from "./CardProductionFees.constants";

interface UseCardProductionFeesProps {
  activeKey: Exclude<TabKeysCPF, "">;
  currentProduct: Product;
  pctProductValues: ParameterTableValue[];
  initialForm: FormInstance<FormValues>;
}

interface FeesConfigurationKeys {
  sde?: "sde" | undefined;
  pink?: "pink" | undefined;
  pins?: "pins" | undefined;
  preemb?: "preemb" | undefined;
}

type RemovedRule = Pick<RestrictionRuleItem, "feeKey" | "tabKey">;

const SORTER = ["preemb", "pins", "pink", "sde"];

const EXCLUDED_RULE_CODES = [`${NIC_CP_PLRE}-pink`];

const extractSubstring = (str: string, prefix: string): string => {
  const extractedSubstring = str.substring(prefix.length);
  return extractedSubstring.length > 0 ? `-${extractedSubstring}` : "";
};

const filterObjectByNonNumericKeys = (obj: FormValues): FormValues => {
  return Object.fromEntries(Object.entries(obj).filter(([key]) => !/\d/.test(key)));
};

export const useCardProductionFees = ({
  activeKey,
  pctProductValues,
  initialForm,
  currentProduct,
}: UseCardProductionFeesProps) => {
  const [restrictionRules, setRestrictionRules] = useState<RestrictionRuleItem[]>([]);
  const [removedRules, setRemovedRules] = useState<RemovedRule[]>([]);
  const [initialRules, setInitialRules] = useState<string[]>([]);

  const aviability = useMemo(() => CARD_PRODUCTION_FEES_OPTIONS_AVIABILITY, []);

  const feesConfigurationKeys: FeesConfigurationKeys = useMemo(
    () => ({
      ...(aviability[activeKey].preemb && { preemb: "preemb" as const }),
      ...(getFormValueFromProductValues(currentProduct.productValues, NIC_CARD_SUBTYPE_PINSET_ALLOWED) &&
        aviability[activeKey].pins && { pins: "pins" as const }),
      ...(aviability[activeKey].pink && { pink: "pink" as const }),
      ...(getFormValueFromProductValues(currentProduct.productValues, SDE) &&
        aviability[activeKey].sde && { sde: "sde" as const }),
    }),
    [activeKey, aviability, currentProduct.productValues],
  );

  const onAddNewRestictionRule = () => {
    const id = (restrictionRules[restrictionRules.length - 1]?.id ?? 0) + 1;
    const newRule = {
      tabKey: activeKey,
      keys: feesConfigurationKeys,
      feeKey: "",
      id,
      feeAmount: "",
      isDeleted: false,
    };

    setRestrictionRules([...restrictionRules, newRule]);
  };

  const onUpdateRuleFeeKey = (
    oldKey: string,
    checked: boolean,
    key: "preemb" | "pins" | "pink" | "sde",
    id: number,
    array: RestrictionRuleItem[],
    switchKey?: string,
  ): void => {
    const index = array.findIndex(item => item.id === id);
    const oldKeySplitted = oldKey.split("-");
    let feeKey = oldKey;

    if (activeKey === "plre") {
      if (oldKeySplitted.includes("pink")) {
        removeElement(oldKeySplitted, "pink");
      }
    }

    if (!checked) {
      feeKey = enforceOrder(removeElement(oldKeySplitted, key), SORTER).join("-");
      setRestrictionRules([
        ...updateElementByIndex<RestrictionRuleItem>(array, index, {
          ...array[index],
          feeKey,
        }),
      ]);

      return;
    }

    if (key === "preemb" && oldKeySplitted.includes("sde") && switchKey) {
      oldKeySplitted.splice(oldKeySplitted.indexOf("sde"), 1);
      initialForm.setFieldValue(switchKey, false);
    }

    if (key === "pink" && oldKeySplitted.includes("pins") && switchKey) {
      oldKeySplitted.splice(oldKeySplitted.indexOf("pins"), 1);
      initialForm.setFieldValue(switchKey, false);
    }

    if (key === "pins" && oldKeySplitted.includes("pink") && switchKey) {
      oldKeySplitted.splice(oldKeySplitted.indexOf("pink"), 1);
      initialForm.setFieldValue(switchKey, false);
    }

    if (key === "sde" && oldKeySplitted.includes("preemb") && switchKey) {
      oldKeySplitted.splice(oldKeySplitted.indexOf("preemb"), 1);
      initialForm.setFieldValue(switchKey, false);
    }

    oldKeySplitted.push(key);

    if (activeKey === "plre") {
      oldKeySplitted.push("pink");
    }

    feeKey = enforceOrder(oldKeySplitted, SORTER).join("-");

    setRestrictionRules([
      ...updateElementByIndex<RestrictionRuleItem>(array, index, {
        ...array[index],
        feeKey,
      }),
    ]);
  };

  const handleRemovedRules = (rule: RemovedRule) => {
    if (!removedRules.some(item => item.feeKey === rule.feeKey && item.tabKey === rule.tabKey)) {
      setRemovedRules([...removedRules, rule]);
    }
  };

  const onRemoveRestrictionRule = (id: number, array: RestrictionRuleItem[]) => {
    const index = array.findIndex(item => item.id === id);
    handleRemovedRules({ feeKey: array[index].feeKey, tabKey: array[index].tabKey });
    setRestrictionRules([...removeElementByIndex(array, index)]);
  };

  const onUpdateRuleFeeAmount = (value: string, id: number, array: RestrictionRuleItem[]) => {
    const index = array.findIndex(item => item.id === id);
    setRestrictionRules([
      ...updateElementByIndex<RestrictionRuleItem>(array, index, {
        ...array[index],
        feeAmount: value,
      }),
    ]);
  };

  const onChangeRulesSwitch = (checked: boolean) => {
    if (!checked) {
      setRemovedRules([
        ...removedRules,
        ...restrictionRules.filter(
          item => !removedRules.some(rule => rule.feeKey === item.feeKey && rule.tabKey === item.tabKey),
        ),
      ]);
    }

    if (checked) {
      setRemovedRules([
        ...removedRules.filter(
          item => !restrictionRules.some(rule => rule.feeKey === item.feeKey && rule.tabKey === item.tabKey),
        ),
      ]);
    }
  };

  const handlePresetProductionFees = useCallback(() => {
    const rules: RestrictionRuleItem[] = [];
    const currentSectionValues = pctProductValues?.filter(
      item => item.fieldCode.includes(CARD_PRODUCTION_FEES_PREFIX) && item.fieldCode.includes(activeKey),
    );
    const currentSectionOpsEnabledValues = pctProductValues?.filter(item => item.fieldCode.includes("add-opt-enabled"));

    currentSectionOpsEnabledValues?.forEach(item => {
      initialForm.setFieldValue(item.fieldCode, item.value === "true");
    });

    currentSectionValues?.forEach((item, index) => {
      const tabKey = item.fieldCode.split("-")[2];

      if (item.fieldCode === `${CARD_PRODUCTION_FEES_PREFIX}-${tabKey}`) {
        initialForm.setFieldValue(item.fieldCode, parseBooleanOrNumber(item.value ?? ""));
      }

      const feeCode = extractSubstring(item.fieldCode, `${CARD_PRODUCTION_FEES_PREFIX}-${tabKey}-`);

      if (feeCode && item.value && tabKey === activeKey && !EXCLUDED_RULE_CODES.includes(item.fieldCode)) {
        rules.push({
          tabKey,
          feeKey: feeCode,
          feeAmount: item.value ?? "",
          keys: feesConfigurationKeys,
          id: index + 1,
        });
        initialForm.setFieldValue(item.fieldCode, parseBooleanOrNumber(item.value ?? ""));
      }

      if (EXCLUDED_RULE_CODES.includes(item.fieldCode)) {
        initialForm.setFieldValue(item.fieldCode, parseBooleanOrNumber(item.value ?? ""));
      }
    });

    setInitialRules(rules.map(rule => `${CARD_PRODUCTION_FEES_PREFIX}-${rule.tabKey}${rule.feeKey}`));
    setRestrictionRules(rules);
    setRemovedRules([]);
  }, [activeKey, feesConfigurationKeys, initialForm, pctProductValues]);

  const prepareProductionFees = (values: FormValues) => {
    return {
      ...transformArrayToObject(
        removedRules
          .filter(rule => rule.tabKey === activeKey)
          .map(rule => ({ [`${CARD_PRODUCTION_FEES_PREFIX}-${rule.tabKey}${rule.feeKey}`]: "" })),
      ),
      ...filterObjectByNonNumericKeys(values),
      ...transformArrayToObject((initialRules ?? []).flatMap(rule => (!values[rule] ? [{ [rule]: "" }] : []))),
    };
  };

  return {
    form: initialForm,
    feesConfigurationKeys,
    restrictionRules,
    onAddNewRestictionRule,
    onUpdateRuleFeeKey,
    onUpdateRuleFeeAmount,
    onRemoveRestrictionRule,
    onChangeRulesSwitch,
    handlePresetProductionFees,
    prepareProductionFees,
    setRestrictionRules,
    setRemovedRules,
    setInitialRules,
  };
};
