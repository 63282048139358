import { NumberFormatValues } from "react-number-format";

export const preventNumberFormatterLeadingZeros = (values: NumberFormatValues) => {
  const { formattedValue, value, floatValue } = values;

  if (value.charAt(0) === "0" && value.charAt(1) !== "0") {
    return true;
  }

  if (value.charAt(0) === "0") {
    if (value.charAt(1) && value.charAt(1) !== ".") {
      return false;
    }
  }

  if (floatValue) return formattedValue === "" || (floatValue <= Infinity && floatValue >= 0);
  return true;
};
