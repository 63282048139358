import { ReactNode } from "react";
import { InputNumberProps } from "antd";

import { FormField, SelectOption } from "@ni/common/types";

interface Options {
  label: string;
  code: string;
  default?: boolean | string | number;
  tooltip?: ReactNode;
}

interface RadioOptions extends Options {
  options: SelectOption[];
}

interface CheckBoxOptions extends Options {
  options: Pick<SelectOption, "tooltip" | "label" | "value">[];
}

export const TawarruqProfitBase: RadioOptions = {
  code: "tawarruq-profit-type",
  label: "Tawarruq profit base",
  default: "Percentage",
  options: [
    {
      label: "Percentage",
      value: "Percentage",
      tooltip: "On Full Payment Date Charge rate used in the Tawarruq profit calculation to remaining balance",
    },
    {
      label: "Fixed amount",
      value: "Amount",
      tooltip: "On Full Payment Date, Charge fixed amount used in the Tawarruq profit calculation on remaining balance",
    },
    {
      label: "Both",
      value: "Both",
    },
  ],
};

export const tawarruqProfitPercentage: FormField<InputNumberProps> = {
  code: "tawarruq-profit-percentage",
  label: "Tawarruq profit percentage",
  props: {
    min: 0,
    max: 100,
    precision: 2,
    controls: false,
    addonAfter: "%",
  },
};

export const tawarruqProfitAmount: FormField<InputNumberProps> = {
  code: "tawarruq-profit-amount",
  label: "Tawarruq profit amount",
  props: {
    min: 0,
    precision: 2,
    controls: false,
  },
};

export const salesApply: CheckBoxOptions = {
  code: "tawarruq-sales-fee",
  label: "Apply Tawarruq sales fee?",
  tooltip: "You can select to apply a Tawarruq sales fee to customer if Tawarruq profit was charged",
  options: [
    {
      label: "Yes",
      value: "Y",
    },
    {
      label: "No",
      value: "N",
    },
  ],
};

export const salesFeeBase: RadioOptions = {
  code: "tawarruq-sales-fee-type",
  label: "Sales fee base",
  default: "Amount",
  options: [
    {
      label: "Percentage",
      value: "Percentage",
      tooltip: "Sales fee percentage is charged to balance when Tawarruq Profit is charged",
    },
    {
      label: "Fixed amount",
      value: "Amount",
      tooltip: "Fixed sales fee amount is charged when Tawarruq Profit is charged",
    },
  ],
};

export const salesFeePercentage: FormField<InputNumberProps> = {
  code: "tawarruq-sales-fee-percentage",
  label: "Tawarruq sales fee Percentage",
  props: {
    min: 0,
    precision: 2,
    controls: false,
  },
};

export const salesFeeAmount: FormField<InputNumberProps> = {
  code: "tawarruq-sales-fee-amount",
  label: "Tawarruq sales fee amount",
  props: {
    min: 0,
    precision: 2,
    controls: false,
  },
};
