import { useMemo } from "react";
import { Form, Space, Switch, Typography } from "antd";
import { useParams, useSearchParams } from "react-router-dom";

import { useHydrateForm, useProductSettings, useTenant } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, Tabs } from "@ni/common/ui";

import { usePct } from "../../../../hooks";
import { getAllFieldNames } from "../../../../utils";

import {
  ExternalTopupChannelsFields,
  externalTopupChannelsKey,
  FEE_P2P_RADIO_TARGET_KEY,
  FEE_P2P_SOURCE_TARGET,
  InApplicationBoardingFileFields,
  inApplicationBoardingFileKey,
  internalDirectDebit,
  internalDirectDebitFields,
  InternalP2PFieldsSourceFields,
  InternalP2PFieldsTargetFields,
  internalP2PKey,
  OnlineFields,
  onlineKey,
  PAYM_FEE_ENABLED,
  paymentFeesDescription,
  topUpFeesDescription,
} from "./TopUpFees.constants";
import { TopupFeesTabContent } from "./TopupFeesTabContent";

export const TopUpFeesPage = () => {
  const [form] = Form.useForm<FormValues>();
  const { id: tenantId, productId, pctId } = useParams<{ id: string; productId: string; pctId: string }>();
  const [parameters] = useSearchParams();
  const isPaymFeeEnabled = Form.useWatch<boolean>(PAYM_FEE_ENABLED, form);
  const sourceTarget = Form.useWatch<string>(FEE_P2P_SOURCE_TARGET, form);

  const { productCurrency, currentProduct } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });

  const { tenant } = useTenant({ tenantId: parseInt(tenantId ?? "0", 10) });

  const { pct, onSavePct } = usePct({
    pctId: parseInt(pctId ?? "0", 10),
  });

  const { isCreditProduct, isCountryUAE } = useMemo(
    () => ({
      isCreditProduct: currentProduct?.productValues?.find(x => x.fieldCode === "product-type")?.value === "Credit",
      isCountryUAE: tenant?.countryCode === "AE",
    }),
    [currentProduct?.productValues, tenant?.countryCode],
  );

  const p2pFields = useMemo(
    () =>
      sourceTarget && form.getFieldValue(FEE_P2P_SOURCE_TARGET) === FEE_P2P_RADIO_TARGET_KEY
        ? InternalP2PFieldsSourceFields(isCreditProduct)
        : InternalP2PFieldsTargetFields(isCreditProduct),
    [form, isCreditProduct, sourceTarget],
  );

  const initialValues = useHydrateForm({
    form,
    entityFields: [
      { fieldCode: FEE_P2P_SOURCE_TARGET, value: "target" },
      ...(pct?.pctProductValues?.filter(x => x.fieldCode !== FEE_P2P_SOURCE_TARGET || x.value) ?? []),
    ],
    keys: {
      strings: [
        PAYM_FEE_ENABLED,
        FEE_P2P_SOURCE_TARGET,
        ...getAllFieldNames(OnlineFields(isCreditProduct)),
        ...getAllFieldNames(InApplicationBoardingFileFields(isCreditProduct)),
        ...getAllFieldNames(InternalP2PFieldsSourceFields(isCreditProduct)),
        ...getAllFieldNames(InternalP2PFieldsTargetFields(isCreditProduct)),
        ...getAllFieldNames(ExternalTopupChannelsFields(isCreditProduct)),
        ...getAllFieldNames(internalDirectDebitFields(isCountryUAE)),
      ],
    },
  });

  const pagesList = useMemo(() => {
    const tabsList = [
      {
        key: onlineKey,
        children: (
          <TopupFeesTabContent
            activeTabKey={onlineKey}
            form={form}
            productCurrency={productCurrency}
            description={OnlineFields(isCreditProduct).description}
            rows={OnlineFields(isCreditProduct).rows}
            isCreditProduct={isCreditProduct}
          />
        ),
        label: <div title="Online">Online</div>,
      },
      {
        key: inApplicationBoardingFileKey,
        children: (
          <TopupFeesTabContent
            activeTabKey={inApplicationBoardingFileKey}
            form={form}
            productCurrency={productCurrency}
            description={InApplicationBoardingFileFields(isCreditProduct).description}
            rows={InApplicationBoardingFileFields(isCreditProduct).rows}
            isCreditProduct={isCreditProduct}
          />
        ),
        label: <div title="In application boarding file">In application boarding file</div>,
      },
      {
        key: internalP2PKey,
        children: (
          <TopupFeesTabContent
            activeTabKey={internalP2PKey}
            form={form}
            productCurrency={productCurrency}
            description={p2pFields.description}
            rows={p2pFields.rows}
            isCreditProduct={isCreditProduct}
          />
        ),
        label: <div title="Internal P2P">Internal P2P</div>,
      },
      {
        key: externalTopupChannelsKey,
        children: (
          <TopupFeesTabContent
            activeTabKey={externalTopupChannelsKey}
            form={form}
            productCurrency={productCurrency}
            description={ExternalTopupChannelsFields(isCreditProduct).description}
            rows={ExternalTopupChannelsFields(isCreditProduct).rows}
            isCreditProduct={isCreditProduct}
          />
        ),
        label: (
          <div title="External top-up channels">{`External ${isCreditProduct ? "payment" : "top-up"} channels`}</div>
        ),
      },
    ];
    if (isCreditProduct) {
      tabsList.push({
        key: internalDirectDebit,
        children: (
          <TopupFeesTabContent
            activeTabKey={internalDirectDebit}
            form={form}
            productCurrency={productCurrency}
            description={internalDirectDebitFields(isCountryUAE).description}
            rows={internalDirectDebitFields(isCountryUAE).rows}
            isCreditProduct={isCreditProduct}
          />
        ),
        label: <div title="Direct Debit">Direct Debit</div>,
      });
    }
    return tabsList;
  }, [form, isCountryUAE, isCreditProduct, p2pFields.description, p2pFields.rows, productCurrency]);

  const relatedLinksList = useMemo(() => {
    const relatedLinks = [
      {
        href: `/tenant/${tenantId}/fees-tenant-configuration`,
        label: "Fee Settings - Global",
      },
    ];
    if (isCreditProduct) {
      relatedLinks.push({
        href: `/tenant/${tenantId}/product/${productId}/direct-debit-configuration`,
        label: `${currentProduct.displayName} - Credit Product Settings - Direct Debit`,
      });
    }
    return relatedLinks;
  }, [currentProduct.displayName, isCreditProduct, productId, tenantId]);

  const onFinish = (values: FormValues, currentRoute: string | undefined) => {
    const defaultTabsKeys = {
      [onlineKey]: [...getAllFieldNames(OnlineFields(isCreditProduct))],
      [inApplicationBoardingFileKey]: [...getAllFieldNames(InApplicationBoardingFileFields(isCreditProduct))],
      [internalP2PKey]: [
        ...getAllFieldNames(InternalP2PFieldsSourceFields(isCreditProduct)),
        ...getAllFieldNames(InternalP2PFieldsTargetFields(isCreditProduct)),
      ],
      [externalTopupChannelsKey]: [...getAllFieldNames(ExternalTopupChannelsFields(isCreditProduct))],
      [internalDirectDebit]: [...getAllFieldNames(internalDirectDebitFields(isCountryUAE))],
    } as const;

    if (
      form.getFieldValue(PAYM_FEE_ENABLED) &&
      defaultTabsKeys[(currentRoute ?? parameters.get("tab")) as keyof typeof defaultTabsKeys]
    ) {
      [...defaultTabsKeys[(currentRoute ?? parameters.get("tab")) as keyof typeof defaultTabsKeys]].forEach(key => {
        if (!values[key]?.toString()) {
          values[key] = "";
        }
      });
    } else {
      [
        ...defaultTabsKeys[externalTopupChannelsKey],
        ...defaultTabsKeys[inApplicationBoardingFileKey],
        ...defaultTabsKeys[internalDirectDebit],
        ...defaultTabsKeys[internalP2PKey],
        ...defaultTabsKeys[onlineKey],
        FEE_P2P_SOURCE_TARGET,
      ].forEach(key => {
        if (!values[key]?.toString()) {
          values[key] = "";
        }
      });
    }
    void onSavePct(values);
  };

  return (
    Object.keys(initialValues).length > 0 && (
      <CustomFormWrapper
        form={form}
        pageTitle={isCreditProduct ? "Payment Fees" : "Top-up Fees"}
        pageSubtitle={isCreditProduct ? paymentFeesDescription : topUpFeesDescription}
        submitHandler={onFinish as (values: FormValues) => void}
        size="md"
        formSize="full"
        level="pct"
        submitLabel="Save"
        relatedLinks={relatedLinksList}
      >
        <Space direction="vertical" size={40}>
          <Space direction="horizontal">
            <Form.Item valuePropName="checked" name={PAYM_FEE_ENABLED}>
              <Switch />
            </Form.Item>
            <Typography.Text strong={true}>
              {isCreditProduct ? "Enable payment fees on your product" : "Enable top-up fees on your product"}
            </Typography.Text>
          </Space>

          {isPaymFeeEnabled && tenant && (
            <Tabs
              defaultActiveKey="online"
              pagesList={pagesList}
              isCheckEnabled={true}
              excludedFieldsListFromCheck={[PAYM_FEE_ENABLED, FEE_P2P_SOURCE_TARGET]}
              form={form}
              initialValues={initialValues}
              discardAfterChangeTab={true}
              onSave={onFinish}
            />
          )}
        </Space>
      </CustomFormWrapper>
    )
  );
};
