import { FC, useEffect, useState } from "react";

import { SwitchItem } from "@ni/common/ui";
import { Event, Sms } from "@ni/sdk/models";

import styles from "./styles.module.scss";

interface SmsPanelHeaderProps {
  isGeneral?: boolean;
  smsEvent?: Event;
  groupId?: number;
  onEditClick: (event: Event, _groupId: number, smsDetails: Sms) => void;
  smsDetails?: Sms;
  onSwitchClick: (event: Event, smsDetails: Sms) => void;
}

export const SmsPanelHeader: FC<SmsPanelHeaderProps> = ({
  smsEvent,
  groupId,
  onEditClick,
  smsDetails,
  onSwitchClick,
}) => {
  const [smsActive, setSmsActive] = useState<boolean>(!!smsDetails?.isActive);

  useEffect(() => {
    setSmsActive(!!smsDetails?.isActive);
  }, [smsDetails]);

  return (
    <div className={styles["sms-panel-header"]}>
      <SwitchItem
        value={smsActive}
        smsEvent={smsEvent}
        smsDetails={smsDetails}
        title={smsEvent?.eventName || ""}
        groupId={groupId}
        onEditClick={onEditClick}
        onSwitchClick={onSwitchClick}
        noPadding={true}
        tooltip="Turning the switch on will use default SMS templates for all events of the group except those that are configured individually below. If the switch is off, SMS's from the group will not be sent unless the individual switch is turned on below."
        layout="panel"
      />
    </div>
  );
};

SmsPanelHeader.defaultProps = {
  isGeneral: false,
  smsEvent: undefined,
  groupId: undefined,
  smsDetails: undefined,
};
