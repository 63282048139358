import React, { FC, useEffect, useState } from "react";
import { Form, Input, Slider } from "antd";
import cn from "classnames";
import { parseInt } from "lodash";
import NumberFormat, { NumberFormatValues } from "react-number-format";

import { MinusSquareOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { SliderRanges } from "@ni/common/types";

import { PageItemLayoutGroup } from "../PageItemLayout";

import styles from "./styles.module.scss";

interface InputRangeSliderProps {
  disabled?: boolean;
  value?: SliderRanges;
  onChange?: (value: SliderRanges) => void;
  binValue?: number;
}

export const InputRangeSlider: FC<InputRangeSliderProps> = ({ value = {}, onChange, binValue, disabled }) => {
  const [minRange, setMinRange] = useState("0000000000");
  const [maxRange, setMaxRange] = useState("9999999999");
  const [binMask, setBinMask] = useState("XXXX XX");

  const padLeft = (text: string, padChar: string, size: number): string => {
    return (String(padChar).repeat(size) + text).substr(size * -1, size);
  };

  useEffect(() => {
    setMinRange(
      value["nic-card-subtype-pan-full-min"]
        ? padLeft(value["nic-card-subtype-pan-full-min"]?.toString(), "0", 10)
        : "0000000000",
    );
    setMaxRange(
      value["nic-card-subtype-pan-full-max"]
        ? padLeft(value["nic-card-subtype-pan-full-max"]?.toString(), "0", 10)
        : "9999999999",
    );
  }, [value]);

  useEffect(() => {
    if (!binValue) {
      setBinMask("XXXX XX");
      return;
    }

    let maskFirstPart = new Array(4).fill("X");
    let markSecondPart = new Array(2).fill("X");

    maskFirstPart = maskFirstPart.map((maskVal, index) => binValue?.toString().slice(0, 4)[index] || "X");
    markSecondPart = markSecondPart.map((maskVal, index) => binValue?.toString().slice(4, 6)[index] || "X");

    setBinMask(`${maskFirstPart.join("")} ${markSecondPart.join("")}`);
  }, [binValue]);

  const triggerChange = (changedValue: { minRange?: string; maxRange?: string }) => {
    onChange?.({
      "nic-card-subtype-pan-full-min": changedValue.minRange ? changedValue.minRange : minRange,
      "nic-card-subtype-pan-full-max": changedValue.maxRange ? changedValue.maxRange : maxRange,
    });
  };

  const changeMinRange = (e: NumberFormatValues) => {
    const re = /^[0-9\b]+$/;

    if (re.test(e.value)) {
      setMinRange(e.value);
      triggerChange({ minRange: e.value });
    } else {
      const minRangeDefaultValue = "0000000000";
      setMinRange(minRangeDefaultValue);
      triggerChange({ minRange: minRangeDefaultValue });
    }
  };

  const changeMaxRange = (e: NumberFormatValues) => {
    const re = /^[0-9\b]+$/;

    if (re.test(e.value)) {
      setMaxRange(e.value);
      triggerChange({ maxRange: e.value });
    } else {
      const maxRangeDefaultValue = "9999999999";
      setMaxRange(maxRangeDefaultValue);
      triggerChange({ maxRange: maxRangeDefaultValue });
    }
  };

  const setSliderRange = (event: [number, number]) => {
    const minRangeVal = padLeft(event[0].toString(), "0", 10);
    const maxRangeVal = padLeft(event[1].toString(), "0", 10);
    setMinRange(minRangeVal);
    setMaxRange(maxRangeVal);
    triggerChange({ minRange: minRangeVal, maxRange: maxRangeVal });
  };

  const subtractFromRange = () => {
    const newMinRange = parseInt(minRange) - 1 < 0 ? 0 : parseInt(minRange) - 1;
    setMinRange(padLeft(newMinRange.toString(), "0", 10));
  };

  const addToRange = () => {
    const newMaxRange = parseInt(maxRange) + 1 < 9999999999 ? parseInt(maxRange) + 1 : 9999999999;
    setMaxRange(padLeft(newMaxRange.toString(), "0", 10));
  };

  return (
    <PageItemLayoutGroup className={styles["input-range-slider"]}>
      <Input.Group className={styles["input-group"]}>
        <Form.Item label="Min">
          <NumberFormat
            customInput={Input}
            format={`${binMask}## #### ####`}
            placeholder={`${binMask}00 0000 0000`}
            mask="_"
            defaultValue="0000000000"
            value={minRange}
            allowNegative={false}
            onValueChange={(event: NumberFormatValues) => changeMinRange(event)}
          />
        </Form.Item>

        <Form.Item label="Max" className="m-l-16">
          <NumberFormat
            customInput={Input}
            format={`${binMask}## #### ####`}
            placeholder={`${binMask}99 9999 9999`}
            mask="_"
            defaultValue="9999999999"
            value={maxRange}
            onValueChange={(event: NumberFormatValues) => changeMaxRange(event)}
            allowNegative={false}
          />
        </Form.Item>
      </Input.Group>
      <div className={styles["icons-slider-wrapper"]}>
        <MinusSquareOutlined
          className={cn(styles["slider-icon"], disabled && styles["slider-icon-disabled"])}
          color="primary"
          onClick={subtractFromRange}
        />
        <Slider
          range={true}
          min={0}
          max={9999999999}
          step={1}
          tooltip={{ placement: "bottom" }}
          value={[parseInt(minRange), parseInt(maxRange)]}
          onChange={setSliderRange}
          disabled={disabled}
        />
        <PlusSquareOutlined
          className={cn(styles["slider-icon"], disabled && styles["slider-icon-disabled"])}
          onClick={addToRange}
        />
      </div>
    </PageItemLayoutGroup>
  );
};

InputRangeSlider.defaultProps = {
  disabled: false,
  value: undefined,
  binValue: undefined,
  onChange: undefined,
};
