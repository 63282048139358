import { useCallback, useEffect, useState } from "react";
import { TablePaginationConfig } from "antd";

import { useReduxState } from "@ni/common/hooks";
import { getErrorInstance } from "@ni/common/utils";
import { ConfigurationApi } from "@ni/sdk/apis";
import { Dictionaries, SortedFilteredPageRequest } from "@ni/sdk/models";

const configurationApi = new ConfigurationApi();

interface UseDictionariesProps {
  searchValue: string;
}

export const useDictionaries = ({ searchValue }: UseDictionariesProps) => {
  const [dictionaries, setDictionaries] = useReduxState<Dictionaries[]>("dictionaries");
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>({
    pageSize: 10,
    current: 1,
    total: 0,
    showSizeChanger: true,
  });

  const getDictionariesList = useCallback(
    (pageRequest: SortedFilteredPageRequest): void => {
      if (pageRequest && Object.keys(pageRequest)) {
        setIsLoading(true);
        configurationApi
          .getDictionariesList(pageRequest)
          .then(response => {
            const dictionaries = response.data.content?.sort((a, b) => (a.code > b.code ? 1 : -1)) ?? [];
            setDictionaries(dictionaries);
            setTablePagination(prevTablePagination => ({
              ...prevTablePagination,
              total: response.data.totalElements,
            }));
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
          });
      }
    },
    [setDictionaries, setIsLoading],
  );

  const deleteDictionariesByCode = async (code: string) => {
    setIsLoading(true);
    try {
      await configurationApi.deleteDictionariesByCode(code);
      const tempDictionaries = dictionaries.filter(elem => elem.code !== code);
      setDictionaries(tempDictionaries);
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  };

  const syncWithWay4 = async (dictionaryCode: string) => {
    setIsLoading(true);
    try {
      const response = await configurationApi.syncWithWay4({ dictionaryCodes: [dictionaryCode] });
      getDictionariesList({
        pageLimits: {
          size: tablePagination.pageSize,
          number: (tablePagination.current ?? 1) - 1,
        },
      });

      setIsLoading(false);

      return {
        dictionaries: response.data.dictionaries,
        status: response.status,
      };
    } catch (error) {
      const errorInstance = getErrorInstance(error);

      setIsLoading(false);
      return {
        dictionaries: [],
        status: errorInstance.response.status,
      };
    }
  };

  useEffect(
    () =>
      getDictionariesList({
        pageLimits: {
          size: tablePagination.pageSize,
          number: (tablePagination.current ?? 1) - 1,
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getDictionariesList, tablePagination.pageSize, tablePagination.current],
  );

  const onUpdateTablePagination = (pagination: TablePaginationConfig) => {
    setTablePagination(prevPagination => ({
      ...prevPagination,
      ...pagination,
    }));
  };

  return {
    tablePagination: [tablePagination, onUpdateTablePagination] as [
      TablePaginationConfig,
      (pagination: TablePaginationConfig) => void,
    ],
    filteredDictionaries: searchValue
      ? dictionaries.filter(elem => elem.code.toLowerCase().includes(searchValue.toLowerCase()))
      : dictionaries,
    deleteDictionariesByCode,
    syncWithWay4,
  };
};
