import { useParams } from "react-router-dom";

import { useReduxState } from "@ni/common/hooks";
import { GetProductWithId } from "@ni/common/types";
import { CollapsedLinks, SettingsPageLayout } from "@ni/common/ui";
import { InsuranceProgramState } from "@ni/sdk/models";

import { PCTInsuranceTable } from "../../../components";
import { useInsurance } from "../../../hooks";

export const PctInsurancePrograms = () => {
  const [currentProduct] = useReduxState<GetProductWithId>("currentProduct", {} as GetProductWithId);

  const { id: tenantId, productId } = useParams<{ id: string; productId: string }>();
  const { insurancePrograms } = useInsurance(parseInt(tenantId ?? "0", 10), parseInt(productId ?? "0", 10));

  const relatedLinks = [
    {
      href: `/tenant/${tenantId}/insurance-program-templates`,
      label: "Insurance Program Templates",
    },
    {
      href: `/tenant/${tenantId}/product/${productId}/insurance-programs`,
      label: `${currentProduct.displayName} - Insurance Programs`,
    },
  ];

  return (
    <SettingsPageLayout
      pageTitle="Insurance Programs"
      pageSubtitle="A product can be associated with up to 12 insurance programs. Below is a list of applied insurance programs. Click the edit button to view customizable parameters on pricing control tables level."
      size="md"
      level="pct"
    >
      <PCTInsuranceTable
        insuranceList={insurancePrograms?.filter(program => program.state === InsuranceProgramState.ACTIVE)}
      />

      <CollapsedLinks list={relatedLinks} />
    </SettingsPageLayout>
  );
};
