/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Button, FormInstance, Space, Switch } from "antd";
import { useLocation } from "react-router-dom";

import { CopyOutlined, PlusCircleOutlined, RightCircleOutlined, RiseOutlined } from "@ant-design/icons";
import { FormValues } from "@ni/common/types";

import { useProceedWizardForm, useReduxState } from "../../../../../hooks/src";
import { TooltipInfo } from "../TooltipInfo";

import styles from "./style.module.scss";

export const WizardShortcutToggle = () => {
  const [canShowCode, setCanShowCode] = useReduxState<boolean>("canShowShortcut", false);

  return (
    <Switch
      checked={canShowCode}
      onChange={setCanShowCode}
      checkedChildren={<RiseOutlined />}
      unCheckedChildren={<RiseOutlined />}
    />
  );
};

export const WizardShortcut = ({
  form,
  isFormDisabled,
}: {
  form: FormInstance<FormValues>;
  isFormDisabled?: boolean;
}) => {
  const { pathname } = useLocation();
  const [canShowCode] = useReduxState<boolean>("canShowShortcut", false);

  const {
    handleCopy: onCopy,
    handleSave: onSave,
    handleSubmit: onSubmit,
    canSubmit,
    isWizard,
  } = useProceedWizardForm(form, pathname);

  if (canShowCode && isWizard)
    return (
      <Space className={styles["shortcut-button-container"]} size={14}>
        <Button type="text" onClick={onCopy} disabled={isFormDisabled} className={styles["shortcut-button"]}>
          <TooltipInfo tooltipProps={{ title: "Copy values" }}>
            <CopyOutlined className={styles["shortcut-copy-button-icon"]} />
          </TooltipInfo>
        </Button>
        <Button type="text" onClick={onSave} disabled={isFormDisabled} className={styles["shortcut-button"]}>
          <TooltipInfo tooltipProps={{ title: "Save values" }}>
            <PlusCircleOutlined className={styles["shortcut-button-icon"]} />
          </TooltipInfo>
        </Button>
        <Button
          type="text"
          onClick={onSubmit}
          disabled={!canSubmit || isFormDisabled}
          className={styles["shortcut-button"]}
        >
          <TooltipInfo tooltipProps={{ title: "Submit values" }}>
            <RightCircleOutlined className={styles["shortcut-button-icon"]} />
          </TooltipInfo>
        </Button>
      </Space>
    );
  return null;
};
