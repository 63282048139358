import { FC } from "react";
import { Form, InputNumber, Select } from "antd";

import { QuestionCircleFilled } from "@ant-design/icons";
import { FieldValue } from "@ni/common/constants";
import { useHydrateForm } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper } from "@ni/common/ui";

import { useInsuranceApi } from "../../../../hooks";

import { ageOption, autoOption, manualOption } from "./constants";

export const CancellationSettings: FC = () => {
  const [form] = Form.useForm<FormValues>();
  const { currentInsurance, editInsurance, formValuesConverter } = useInsuranceApi();

  useHydrateForm({
    form,
    entityFields: currentInsurance?.values ?? [],
    keys: {
      strings: [ageOption.code, autoOption.code, manualOption.code],
    },
    allowParse: false,
  });

  const blockCodeValuesAndLabelsMap = (options: FieldValue[] | undefined) => {
    return options?.map(({ value, displayed }) => (
      <Select.Option key={value} value={value}>
        {displayed}
      </Select.Option>
    ));
  };

  const onFinish = async (values: FormValues) => {
    await editInsurance({ values: formValuesConverter(values) });
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Insurance Cancellation Settings"
      pageSubtitle="Insurance can be cancelled for an account at any time manually by the customer, or automatically by system, based upon certain factors like account delinquency level or customer age. You can customize the age parameter on either product level settings or a specific pricing control table level settings, from related screens after wizard."
      level="tenant"
      size="md"
      formSize="full"
      gap={40}
      submitLabel="Save"
      linkTitle="Insurance Templates"
      onValuesChange={form.validateFields}
      submitHandler={onFinish}
      additionalRoute="./insurance-program-templates"
    >
      <Form.Item
        name={autoOption.code}
        label={autoOption.name}
        tooltip={{
          title: autoOption.tooltip,
          icon: <QuestionCircleFilled />,
        }}
        initialValue={autoOption.default}
        rules={[
          {
            validator: (_, value) =>
              value >= form.getFieldValue(manualOption.code) ? Promise.reject() : Promise.resolve(),
            message:
              "Delinquency level for auto re-instantiation can not be greater than or equal to selected manual re-instantiation level",
          },
        ]}
      >
        <Select>{blockCodeValuesAndLabelsMap(autoOption.options)}</Select>
      </Form.Item>

      <Form.Item
        name={manualOption.code}
        label={manualOption.name}
        tooltip={{
          title: manualOption.tooltip,
          icon: <QuestionCircleFilled />,
        }}
        initialValue={manualOption.default}
        rules={[
          {
            validator: (_, value) =>
              value <= form.getFieldValue(autoOption.code) ? Promise.reject() : Promise.resolve(),
            message:
              "Delinquency level for manual re-instantiation cannot be less than selected for automated re-instantiation",
          },
        ]}
      >
        <Select>{blockCodeValuesAndLabelsMap(manualOption.options)}</Select>
      </Form.Item>

      <Form.Item
        name={ageOption.code}
        label={ageOption.name}
        tooltip={{
          title: ageOption.tooltip,
          icon: <QuestionCircleFilled />,
        }}
        initialValue={ageOption.default}
      >
        <InputNumber addonAfter="years" min={ageOption.min} max={ageOption.max} precision={ageOption.decimalsNumber} />
      </Form.Item>
    </CustomFormWrapper>
  );
};
