export const numberFormatter = (value: string | number | undefined) => {
  if (typeof value === "string" && value.includes(".")) {
    const [wholePart, decimalPart] = value.split(".");
    const formattedWholePart = `${wholePart}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    return `${formattedWholePart}.${decimalPart}`;
  }

  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const numberParser = (value: string | undefined) => value?.replace(/\$\s?|( *)/g, "") || "";
