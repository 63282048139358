import { FC } from "react";
import { Form } from "antd";

import { QuestionCircleFilled } from "@ant-design/icons";
import {
  CHECKBOX_GROUP_ELIGIBILITY_CRITERIA,
  LTY_CHECK_RULES,
  LTY_RULES_CRITERIA,
  RADIO_GROUP_ELIGIBLE,
} from "@ni/common/constants";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { Checkbox, CustomFormWrapper, RadioGroup } from "@ni/common/ui";
import { LoyaltyWizardResponse } from "@ni/sdk/models";

import { useLoyaltyWizardApi } from "../../hooks";

export const TransactionCriteria: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const isCheckboxShown = Form.useWatch<string>(LTY_CHECK_RULES, form) === RADIO_GROUP_ELIGIBLE[1].value;

  const { processWizardRequest } = useLoyaltyWizardApi();

  const [, setIsLoading] = useReduxState("isLoading", false);
  const [wizardResponse] = useReduxState<LoyaltyWizardResponse>("loyaltyWizard", {} as LoyaltyWizardResponse);

  useHydrateForm({
    form,
    entityFields: wizardResponse?.loyaltyProgramTemplate?.programPctValues ?? [],
    keys: {
      strings: [LTY_CHECK_RULES],
      lists: [LTY_RULES_CRITERIA],
    },
  });

  const onFinish = async (values: FormValues) => {
    setIsLoading(true);
    try {
      await processWizardRequest({
        pageId: wizardResponse.pageId as number,
        tenantId: wizardResponse?.tenant?.id,
        loyaltyTemplateId: wizardResponse.loyaltyProgramTemplate?.id,
        collectedValues: {
          ...values,
          ...(values[LTY_RULES_CRITERIA]
            ? { [LTY_RULES_CRITERIA]: (values[LTY_RULES_CRITERIA] as string[]).join(",") }
            : {}),
        },
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Transaction Criteria"
      pageSubtitle="Specify transaction eligibility to participate in the loyalty program"
      size="sm"
      level="tenant"
      submitHandler={onFinish}
      additionalRoute="loyalty-program-templates"
    >
      <Form.Item name={LTY_CHECK_RULES} label="Transaction eligibility mode" initialValue="N">
        <RadioGroup radioList={RADIO_GROUP_ELIGIBLE} initialValue="N" />
      </Form.Item>

      {isCheckboxShown && (
        <Form.Item
          name={LTY_RULES_CRITERIA}
          label="Eligibility criteria"
          tooltip={{
            title: "You can specify details for selected criteria on upcoming pages",
            icon: <QuestionCircleFilled />,
          }}
        >
          <Checkbox className="m-t-16" checkboxes={CHECKBOX_GROUP_ELIGIBILITY_CRITERIA} />
        </Form.Item>
      )}
    </CustomFormWrapper>
  );
};
