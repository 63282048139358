export const additionalLanguages: { name: string; value: string }[] = [
  {
    name: "Russian",
    value: "Russian",
  },
  {
    name: "German",
    value: "German",
  },
  {
    name: "Arabic",
    value: "Arabic",
  },
];
