import { FC } from "react";
import { Button, Space, Switch } from "antd";
import cn from "classnames";

import { Event, Sms } from "@ni/sdk/models";

import { TooltipInfo } from "../TooltipInfo";

import styles from "./styles.module.scss";

interface SwitchItemProps {
  value: string | boolean | null;
  title?: string;
  smsEvent?: Event;
  groupId?: number;
  tooltip?: string;
  onEditClick: (event: Event, _groupId: number, smsDetails: Sms) => void;
  onSwitchClick: (smsEvent: Event, smsDetails: Sms) => void;
  noPadding?: boolean;
  smsDetails?: Sms;
  layout: "panel" | "listItem";
}

export const SwitchItem: FC<SwitchItemProps> = props => {
  const { value, smsEvent, groupId, tooltip, onEditClick, title, noPadding, onSwitchClick, layout = "panel" } = props;
  const { smsDetails } = props;

  const switchActiveSms = (switchValue: boolean) => {
    onSwitchClick(smsEvent!, {
      ...smsDetails,
      isActive: switchValue,
    } as Sms);
  };

  return (
    <div
      className={cn(
        styles["switch-item"],
        noPadding && styles["no-padding"],
        layout === "listItem" && styles["listItem"],
      )}
    >
      <Space direction="horizontal" className={styles["switch-content"]}>
        <Switch checked={!!value} onChange={switchActiveSms} />
        <div className={styles["title"]}>
          {title && <TooltipInfo label={title} tooltipProps={{ className: styles["warning-sign"], title: tooltip }} />}
        </div>
      </Space>
      <div className={styles["edit-button"]}>
        <Button type="link" onClick={() => onEditClick(smsEvent!, groupId!, smsDetails!)}>
          Edit
        </Button>
      </div>
    </div>
  );
};

SwitchItem.defaultProps = {
  tooltip: "",
  noPadding: true,
  smsDetails: undefined,
  groupId: undefined,
  title: "",
  smsEvent: undefined,
};
