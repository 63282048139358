import { FC, useEffect, useState } from "react";
import { Form, Input } from "antd";
import NumberFormat from "react-number-format";

import { QuestionCircleFilled } from "@ant-design/icons";
import { LTY_REDEEM_TYPE, NIC_LTY_RED_RATE_CASH_RATE, NIC_LTY_RED_RATE_EXT_RATE } from "@ni/common/constants";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper } from "@ni/common/ui";
import { isNumeric, removeGapsFromStringNumeric } from "@ni/common/utils";
import { LoyaltyWizardResponse } from "@ni/sdk/models";

import { useLoyaltyWizardApi } from "../../hooks";

import { NIC_LTY_RED_RATE_CASH_RATE_TOOPTIP, NIC_LTY_RED_RATE_EXT_RATE_TOOPTIP } from "./constants";

export const RedemptionRate: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();

  const { processWizardRequest } = useLoyaltyWizardApi();

  const [, setIsLoading] = useReduxState("isLoading", false);
  const [wizardResponse] = useReduxState<LoyaltyWizardResponse>("loyaltyWizard", {} as LoyaltyWizardResponse);

  const [redeemType, setRedeemType] = useState<string>();

  useHydrateForm({
    form,
    entityFields: wizardResponse?.loyaltyProgramTemplate?.programValues ?? [],
    keys: { strings: [NIC_LTY_RED_RATE_CASH_RATE, NIC_LTY_RED_RATE_EXT_RATE] },
  });

  useEffect(() => {
    if (wizardResponse.loyaltyProgramTemplate) {
      const redeemTypeValue = wizardResponse.loyaltyProgramTemplate.programValues?.find(
        field => field?.code === LTY_REDEEM_TYPE,
      )?.value;

      setRedeemType(redeemTypeValue);
    }
  }, [wizardResponse.loyaltyProgramTemplate]);

  const onFinish = async (values: FormValues) => {
    setIsLoading(true);
    try {
      const formValues: FormValues = Object.keys(values).reduce((newValues, fieldName) => {
        const value = values[fieldName];
        newValues[fieldName] = isNumeric(value as string) ? removeGapsFromStringNumeric(value as string) : value;
        return newValues;
      }, {} as FormValues);

      await processWizardRequest({
        pageId: wizardResponse.pageId as number,
        tenantId: wizardResponse?.tenant?.id,
        loyaltyTemplateId: wizardResponse.loyaltyProgramTemplate?.id,
        collectedValues: formValues as {
          [key: string]: string;
        },
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Redemption Rate"
      pageSubtitle={
        redeemType === "C"
          ? "Redemption rate is a certain percentage of accrued loyalty points, that is redeemed for a money back to a customer"
          : "Redemption rate is a certain percentage of accrued loyalty points, that is redeemed for a voucher amount."
      }
      size="sm"
      level="tenant"
      submitHandler={onFinish}
      additionalRoute="loyalty-program-templates"
    >
      {redeemType !== "E" && (
        <Form.Item
          name={NIC_LTY_RED_RATE_CASH_RATE}
          label="Cashback redemption rate"
          initialValue={100}
          tooltip={{ title: NIC_LTY_RED_RATE_CASH_RATE_TOOPTIP, icon: <QuestionCircleFilled /> }}
          rules={[{ required: redeemType === "C" }]}
        >
          <NumberFormat
            customInput={Input}
            addonAfter="%"
            allowNegative={false}
            isNumericString={true}
            thousandSeparator=" "
            decimalScale={2}
            decimalSeparator="."
            fixedDecimalScale={true}
          />
        </Form.Item>
      )}
      {redeemType !== "C" && (
        <Form.Item
          name={NIC_LTY_RED_RATE_EXT_RATE}
          label="External voucher redemption rate"
          initialValue={100}
          tooltip={{ title: NIC_LTY_RED_RATE_EXT_RATE_TOOPTIP, icon: <QuestionCircleFilled /> }}
          rules={[
            {
              required: redeemType === "E",
            },
          ]}
          required={redeemType === "E"}
        >
          <NumberFormat
            customInput={Input}
            addonAfter="%"
            allowNegative={false}
            isNumericString={true}
            thousandSeparator=" "
            decimalScale={2}
            decimalSeparator="."
            fixedDecimalScale={true}
          />
        </Form.Item>
      )}
    </CustomFormWrapper>
  );
};
