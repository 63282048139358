import { FC } from "react";
import { Collapse, Row, Space } from "antd";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import { BankOutlined, CreditCardOutlined } from "@ant-design/icons";
import { defaultTenantLogo } from "@ni/common/assets";
import { ChevronRightIcon, ChevronUpIcon } from "@ni/common/icons";
import { DashboardTenantUI } from "@ni/common/types";

import { CardBlock } from "../CardBlock";

import styles from "./styles.module.scss";

interface TenantBlockProperties {
  tenant: DashboardTenantUI;
  onDuplicateSuccess: () => void;
  onCreateProduct: (id: number) => void;
}

const { Panel } = Collapse;

export const TenantBlock: FC<TenantBlockProperties> = ({ tenant, onDuplicateSuccess, onCreateProduct }) => {
  const navigate = useNavigate();

  const handleTenantSettings = (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
    navigate(`/tenant/${tenant.id || ""}/details`);
  };

  const header = () => (
    <div className={styles["tenant-header"]}>
      <img className={styles["tenant-logo"]} src={defaultTenantLogo} alt="tenant-logo" />
      <div className={styles["tenant-name"]}>{tenant.name}</div>
    </div>
  );

  return (
    <div className={styles["tenant-div"]}>
      <Collapse
        className={styles["tenant-collapse"]}
        defaultActiveKey={["1"]}
        expandIcon={({ isActive }) =>
          isActive ? (
            <ChevronUpIcon className="icon-vertical-middle" />
          ) : (
            <ChevronRightIcon className="icon-vertical-middle" />
          )
        }
      >
        <Panel
          header={header()}
          key="1"
          extra={
            <Space direction="horizontal">
              <button
                className={classNames(styles["tenant-collapse-extra"], styles["tenant-collapse-create-Product"])}
                type="button"
                onClick={() => {
                  onCreateProduct(tenant?.id);
                }}
              >
                <CreditCardOutlined />
                New product
              </button>
              <button className={styles["tenant-collapse-extra"]} type="button" onClick={handleTenantSettings}>
                <BankOutlined /> Tenant settings
              </button>
            </Space>
          }
        >
          <Row wrap={true} gutter={[16, 16]}>
            {tenant.products?.map(product => (
              <CardBlock
                key={product.id}
                product={product}
                tenantId={tenant.id?.toString()}
                onDuplicateSuccess={onDuplicateSuccess}
              />
            ))}
          </Row>
        </Panel>
      </Collapse>
    </div>
  );
};
