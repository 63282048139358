import { FC, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { Admin } from "@ni/admin";
import { NavigationBar, TopBarLoader } from "@ni/common/ui";
import { DashboardLayout } from "@ni/dashboard";
import { FinancialInstitutionRegistration } from "@ni/financial-institution-registration";
import { ProductSettings } from "@ni/product-settings";
import { TenantSettings } from "@ni/tenant-settings";
import { Wizard } from "@ni/wizard";
import { LoyaltyWizard } from "@ni/loyalty-wizard";
import { LoyaltyProgramTemplatesEdit } from "@ni/loyalty-templates-edit";
import { LoyaltyProgramEdit } from "@ni/loyalty-program-edit";

import { withProviders } from "./providers";
import { ErrorBoundary } from "@ni/common/utils";
import { useToggleFeature } from "@ni/common/hooks";
import { IPPWizard } from "@ni/ipp-wizard";
import { InsuranceWizard } from "@ni/insurance-wizard";
import {
  InstallmentPaymentPlansEdit,
  InstallmentPaymentPlansEditPCT,
  InsuranceEditPCT,
  InsuranceProgramEdit,
  ProductInsuranceProgramEdit,
} from "@ni/programs-edit";

const App: FC = () => {
  useEffect(() => {
    document.title = "Network MarketPlace - Card as a Service";
  }, []);

  useToggleFeature();

  return (
    <Router>
      <ErrorBoundary>
        <TopBarLoader />
        <NavigationBar />
        <Routes>
          <Route path="/" element={<DashboardLayout />} />
          <Route path="/admin/*" element={<Admin />} />
          <Route path="/register-fi" element={<FinancialInstitutionRegistration />} />
          <Route path="/financial-institution/:id/dashboard" element={<DashboardLayout />} />

          {/* 👇 Wizards */}
          <Route path="/create-product" element={<Wizard />} />
          <Route path="/create-new-loyalty-program" element={<LoyaltyWizard />} />
          <Route path="/create-installment-payment-plan" element={<IPPWizard />} />
          <Route path="/create-insurance-program-template" element={<InsuranceWizard />} />

          <Route path="/tenant/:id/*" element={<TenantSettings />} />
          <Route path="/tenant/:id/product/:productId/*" element={<ProductSettings />} />

          {/* 👇 Tenant level edit */}
          <Route path="/tenant/:id/edit-template/:templateId/*" element={<LoyaltyProgramTemplatesEdit />} />
          <Route path="/tenant/:id/edit-insurance/:insuranceId/*" element={<InsuranceProgramEdit />} />
          <Route path="/tenant/:id/edit-ipp/:planId/*" element={<InstallmentPaymentPlansEdit />} />

          {/* 👇 Product level edit */}
          <Route
            path="/tenant/:id/product/:productId/edit-insurance/:insuranceId/*"
            element={<ProductInsuranceProgramEdit />}
          />

          {/* 👇 PCT level edit  */}
          <Route
            path="/tenant/:id/product/:productId/pct/:pctId/installment-payment-plans/:planId/*"
            element={<InstallmentPaymentPlansEditPCT />}
          />
          <Route
            path="/tenant/:id/product/:productId/pct/:pctId/insurance-edit/:insuranceId/*"
            element={<InsuranceEditPCT />}
          />
          <Route
            path="/tenant/:id/product/:productId/pct/:pctId/loyalty-program/:programId/*"
            element={<LoyaltyProgramEdit />}
          />
        </Routes>
      </ErrorBoundary>
    </Router>
  );
};

export default withProviders(App);
