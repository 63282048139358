import { FC, ReactNode } from "react";
import { Modal, Typography } from "antd";

interface PctModalProps {
  isOpen: boolean;
  footer: ReactNode;
}

export const UnsavedChangesModal: FC<PctModalProps> = ({ isOpen, footer }) => {
  return (
    <Modal title="Confirmation" footer={footer} forceRender={true} getContainer={false} open={isOpen} centered={true}>
      <Typography.Text>Are you sure you want to navigate away? Any unsaved changes will be lost.</Typography.Text>
    </Modal>
  );
};
