import { FC } from "react";
import { Form, InputNumber, Space, Switch } from "antd";

import { QuestionCircleFilled } from "@ant-design/icons";
import { LTY_POINT_EXPIRY, LTY_POINT_VALIDITY_PERIOD, LTY_POINT_WRITE_OFF } from "@ni/common/constants";
import { useHydrateForm } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, TooltipInfo } from "@ni/common/ui";
import { minMaxValidator } from "@ni/common/utils";

import { LoyaltyProgramTemplatesPagesProps } from "../../types";

const stringsKeys = [LTY_POINT_EXPIRY, LTY_POINT_VALIDITY_PERIOD, LTY_POINT_WRITE_OFF];

export const PointExpiry: FC<LoyaltyProgramTemplatesPagesProps> = ({
  loyaltyProgramTemplate,
  editLoyaltyProgramTemplate,
}) => {
  const [form] = Form.useForm<FormValues>();

  const isPointsExpiryEnabled = Form.useWatch<string>(LTY_POINT_EXPIRY, form);
  useHydrateForm(
    {
      form,
      entityFields: [
        ...(loyaltyProgramTemplate?.programValues ?? []),
        ...(loyaltyProgramTemplate?.programPctValues ?? []),
      ],
      keys: {
        strings: stringsKeys,
      },
    },
    [loyaltyProgramTemplate],
  );

  const onFinish = (values: FormValues) => {
    [...stringsKeys].forEach(key => {
      if (!values[key]?.toString() && loyaltyProgramTemplate?.programPctValues?.find(item => item.code === key)) {
        values[key] = "";
      }
    });
    editLoyaltyProgramTemplate(values);
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Point Expiry"
      pageSubtitle="Expiration period can be setup for accrued loyalty points. Points can not be redeemed for a value after expiration."
      size="md"
      level="tenant"
      submitLabel="Save"
      submitHandler={onFinish}
      additionalRoute="loyalty-program-templates"
    >
      <Space direction="horizontal">
        <Form.Item name={LTY_POINT_EXPIRY} valuePropName="checked" initialValue={false}>
          <Switch />
        </Form.Item>
        <TooltipInfo
          label="Enable points expiry"
          tooltipProps={{
            title:
              'This parameter specifies whether "Points Expiry" feature is enabled or not for a product. If the feature is enabled, then points under a program are expired after defined period.',
          }}
        />
      </Space>
      {isPointsExpiryEnabled && (
        <>
          <Form.Item
            name={LTY_POINT_VALIDITY_PERIOD}
            label="Points validity period"
            tooltip={{
              title:
                "This parameter is used to define the number of cycles after accrual after which points are moved to expired bucket for the program.",
              icon: <QuestionCircleFilled />,
            }}
            rules={[
              { required: true, message: "Points validity period is required" },
              () => ({
                validator(_, value: number) {
                  return minMaxValidator(value, 0, 999);
                },
              }),
            ]}
          >
            <InputNumber controls={false} precision={0} />
          </Form.Item>
          <Form.Item
            name={LTY_POINT_WRITE_OFF}
            label="Points write off period"
            tooltip={{
              title:
                "This parameter is used to define the number of cycles after accrual after which the points are going to be written-off from expired bucket for the program.",
              icon: <QuestionCircleFilled />,
            }}
            rules={[
              { required: true, message: "Points write off period is required" },
              () => ({
                validator(_, value: number) {
                  return minMaxValidator(value, 0, 999);
                },
              }),
            ]}
          >
            <InputNumber controls={false} precision={0} />
          </Form.Item>
        </>
      )}
    </CustomFormWrapper>
  );
};
