/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Button, Form, FormInstance, FormProps, Switch } from "antd";
import cn from "classnames";

import { PrinterOutlined } from "@ant-design/icons";
import { useUATDisableForm } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";

import { useReduxState } from "../../../../../hooks/src/store/useReduxState";
import { CollapsedLinks } from "../Collapse";
import { FormContent } from "../FormBlocks";
import { SettingsPageLayout, SettingsPageLayoutProps } from "../SettingsPageLayout";
import { WizardShortcut } from "../WizardShortcut";

import styles from "./styles.module.scss";

type RelatedLink = {
  href: string;
  label: string;
};

interface CustomFormWrapperProps
  extends Pick<SettingsPageLayoutProps, "pageTitle" | "pageSubtitle" | "level" | "size" | "additionalRoute"> {
  form: FormInstance<FormValues>;
  formSize?: SettingsPageLayoutProps["size"];
  formTitle?: React.ReactNode;
  gap?: number;
  customClassName?: string;
  disabled?: boolean;
  buttonDisabled?: boolean;
  relatedLinks?: RelatedLink[];
  children: React.ReactNode;
  onValuesChange?: ((changedValues: Partial<FormValues>, values: FormValues) => void) | undefined;
  submitHandler: (values: FormValues) => void;
  submitLabel?: string;
  formProps?: FormProps;
  linkTitle?: string | undefined;
  showSubmit?: boolean;
}

export const PrintModeToggle = () => {
  const [isPrintMode, setIsPrintMode] = useReduxState<boolean>("isPrintMode", false);

  return (
    <Switch
      checked={isPrintMode}
      onChange={setIsPrintMode}
      checkedChildren={<PrinterOutlined />}
      unCheckedChildren={<PrinterOutlined />}
    />
  );
};

export const CustomFormWrapper = ({
  form,
  formSize = "sm",
  formTitle = "",
  gap = 40,
  customClassName,
  disabled,
  buttonDisabled,
  pageTitle,
  pageSubtitle,
  size,
  additionalRoute = "",
  level,
  relatedLinks = [],
  children,
  onValuesChange = () => {},
  submitHandler,
  submitLabel,
  formProps,
  linkTitle,
  showSubmit = true,
}: CustomFormWrapperProps) => {
  const [isLoading] = useReduxState<boolean>("isLoading", false);
  const [isPrintMode] = useReduxState<boolean>("isPrintMode", false);

  const formDisabled = useUATDisableForm();

  const onFinish = (values: FormValues) => {
    submitHandler(values);
  };

  return (
    <SettingsPageLayout
      additionalRoute={additionalRoute}
      size={size}
      pageTitle={pageTitle}
      pageSubtitle={pageSubtitle}
      level={level}
      linkTitle={linkTitle}
    >
      <Form
        form={form}
        className={cn(styles["form-wrapper"], customClassName, formSize && styles[`form-wrapper-${formSize}`])}
        layout="vertical"
        autoComplete="off"
        disabled={!!(formDisabled || disabled)}
        onValuesChange={onValuesChange}
        onFinish={onFinish}
        {...formProps}
      >
        <FormContent gap={gap} title={formTitle}>
          {children}
        </FormContent>

        {showSubmit && !isPrintMode && (
          <Form.Item shouldUpdate={true}>
            {() => (
              <Button
                className={styles["button"]}
                size="large"
                type="primary"
                htmlType="submit"
                loading={isLoading}
                disabled={!!(formDisabled || disabled || buttonDisabled)}
              >
                {submitLabel || "Continue"}
              </Button>
            )}
          </Form.Item>
        )}
      </Form>
      {relatedLinks.length > 0 && !isPrintMode ? <CollapsedLinks list={relatedLinks} /> : null}
      <WizardShortcut form={form} isFormDisabled={disabled} />
    </SettingsPageLayout>
  );
};
