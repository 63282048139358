/* tslint:disable */
/* eslint-disable */
/**
 * Front API
 * End-point for all Front API calls
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const ProductState = {
    DRAFT: 'DRAFT',
    LOCKED: 'LOCKED',
    UAT_PENDING: 'UAT_PENDING',
    REJECTED: 'REJECTED',
    UAT: 'UAT',
    SUSPENDED: 'SUSPENDED',
    DEACTIVATED: 'DEACTIVATED',
    PRODUCTION_PENDING: 'PRODUCTION_PENDING',
    PRODUCTION: 'PRODUCTION',
    CHANGES_PENDING: 'CHANGES_PENDING',
    CHANGES_REJECTED: 'CHANGES_REJECTED'
} as const;

export type ProductState = typeof ProductState[keyof typeof ProductState];



